import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgThumbsup: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 30}
        height={height || 30}
        viewBox="0 0 30 30"
    >
        <path data-name="Rectangle 360" fill="none" d="M0 0h30v30H0z" />
        <path
            className="icon-fill"
            data-name="Path 379"
            d="M14.987 28a12.451 12.451 0 0 0 5.018-1.024 13.32 13.32 0 0 0 4.148-2.817 13.247 13.247 0 0 0 2.81-4.142 12.507 12.507 0 0 0 1.017-5.024 12.451 12.451 0 0 0-1.024-5.018 13.4 13.4 0 0 0-2.817-4.155 13.077 13.077 0 0 0-4.148-2.81A12.593 12.593 0 0 0 14.973 2a12.545 12.545 0 0 0-5.011 1.01 13.177 13.177 0 0 0-4.148 2.81A13.25 13.25 0 0 0 3 9.975a12.522 12.522 0 0 0-1.02 5.018A12.437 12.437 0 0 0 3 20.018a13.342 13.342 0 0 0 2.821 4.142 13.342 13.342 0 0 0 4.141 2.817A12.437 12.437 0 0 0 14.987 28Zm-7.4-10.917a4.933 4.933 0 0 1 .368-1.94 3.5 3.5 0 0 1 .99-1.378 2.189 2.189 0 0 1 1.425-.508h.963a8.128 8.128 0 0 0-.85 1.532 5.669 5.669 0 0 0-.422 2.228 5.309 5.309 0 0 0 .4 2.215 4.059 4.059 0 0 0 1.382 1.703h-1.232a2.468 2.468 0 0 1-1.552-.529 3.624 3.624 0 0 1-1.077-1.4 4.609 4.609 0 0 1-.395-1.923ZM11 17.027a6.029 6.029 0 0 1 .368-2.081 7.454 7.454 0 0 1 1.01-1.746q.789-1.071 1.74-2.322a18.6 18.6 0 0 0 1.726-2.763 2.537 2.537 0 0 1 .428-.636.8.8 0 0 1 .6-.234.967.967 0 0 1 .7.281 1.017 1.017 0 0 1 .288.763 3.569 3.569 0 0 1-.294 1.338q-.294.723-.582 1.392a3.007 3.007 0 0 0-.284 1.151.706.706 0 0 0 .274.589 1.176 1.176 0 0 0 .743.214h2.462a1.165 1.165 0 0 1 .87.375 1.246 1.246 0 0 1 .361.9 1.231 1.231 0 0 1-.348.91q-.161.12-.027.227a.886.886 0 0 1 .207.375 1.6 1.6 0 0 1 .074.468 1.147 1.147 0 0 1-.548 1.055.164.164 0 0 0-.04.268 1.178 1.178 0 0 1 .254.776 1.131 1.131 0 0 1-.234.716 1.261 1.261 0 0 1-.555.422q-.12.054-.04.2a1.407 1.407 0 0 1 .107.261 1.113 1.113 0 0 1 .054.341.92.92 0 0 1-.348.776 2.287 2.287 0 0 1-.766.387 5.851 5.851 0 0 1-.789.107q-.5.04-1.164.04l-.947-.013a6.365 6.365 0 0 1-2.823-.609 4.52 4.52 0 0 1-1.84-1.612A4.128 4.128 0 0 1 11 17.027Z"
            fill={color || '#636363'}
        />
    </svg>
);

export default SvgThumbsup;
