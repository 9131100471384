import { GLOBAL_CONFIG } from '../../../config/config';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';
import { ApiService } from '../../../services/Api.service';

type Input = {
    email?: string;
    password?: string;
    contentfulToken?: string;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    // Test token
    const contentfulToken =
        props.input.contentfulToken || GLOBAL_CONFIG.TEST_MARKET_ADMIN_TOKEN || GLOBAL_CONFIG.TEST_SUPER_ADMIN_TOKEN;

    const apiToken = await ApiService.login(contentfulToken);

    return {
        success: true,
        contentfulToken,
        apiToken,
    };
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useLoginAPI = (
    contentfulToken?: string
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: false,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { contentfulToken } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: true,
        errorFn,
    });
};
