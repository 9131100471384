import styled, { css } from 'styled-components';
import { COLORS } from '../styles/variables';

interface FlexGridProps {
    display?: string;
    direction?: 'row' | 'column';
    justifyContent?: 'space-between' | 'flex-end' | 'flex-start' | 'center';
    alignItems?: 'space-between' | 'flex-end' | 'flex-start' | 'center';
    width?: string;
    height?: string;
    m?: string;
    p?: string;
    gap?: string;
    flex?: string;
    position?: string;
    wrap?: string;
    bg?: string;
    line?: boolean;
    transform?: string;
}

export const FlexGrid = styled.div<FlexGridProps>`
    display: flex;
    transform: ${({ transform }) => transform || 'none'};
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    margin: ${({ m }) => m || '0'};
    padding: ${({ p }) => p || '0'};
    gap: ${({ gap }) => gap || 'auto'};
    flex-wrap: ${({ wrap }) => wrap || 'unset'};
    background-color: ${({ bg }) => bg || undefined};
    border-bottom: ${({ line }) =>
        line ? `1px solid ${COLORS.veryLightGray}` : 'none'};

    ${({ flex }) =>
        flex &&
        css`
            flex: ${flex};
        `}

    ${({ position }) =>
        position &&
        css`
            position: ${position};
        `}
`;
