import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgShemeOffers: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 16}
        height={height || 24}
        viewBox="0 0 16 24"
    >
        <g
            className="icon-stroke"
            transform="translate(-4)"
            fill="none"
            stroke={color || '#009343'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <circle
                data-name="Ellipse 163"
                cx={7}
                cy={7}
                r={7}
                transform="translate(5 1)"
            />
            <path
                data-name="Path 4722"
                d="M8.21 13.89 7 23l5-3 5 3-1.21-9.12"
            />
        </g>
    </svg>
);

export default SvgShemeOffers;
