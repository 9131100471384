import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgHide: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 22}
        height={height || 15}
        viewBox="0 0 22 15"
    >
        <path
            className="icon-fill"
            data-name="Path 4524"
            d="M11 0A11.827 11.827 0 0 0 0 7.5a11.817 11.817 0 0 0 22 0A11.827 11.827 0 0 0 11 0Zm0 12.5a5 5 0 1 1 5-5 5 5 0 0 1-5 5Zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgHide;
