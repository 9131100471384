import { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { Text } from '../../components/Text';
import * as qs from 'query-string';
import { ApiService } from '../../services/Api.service';
import { getUrlKeepQuery } from '../../utils';

const Wrapper = styled.div`
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
`;

export const Redirect: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const qsObj = qs.parse(location.hash);
    let contentfulToken;
    if (qsObj['access_token']) {
        contentfulToken = qsObj['access_token'] as string;
        // contentfulToken = "CFPAT-vFHaLxQYdoU_j-LD2GIw5czq_yOWGoyRQJs6TvrKVks"; // UK manager for testing
        // console.log('===contentfulToken==', contentfulToken);
    }

    useEffect(() => {
        if (contentfulToken) {
            ApiService.login(contentfulToken).then((apiToken) => {
                sessionStorage.setItem('contentfulToken', contentfulToken);
                sessionStorage.setItem('apiToken', apiToken);
                navigate(getUrlKeepQuery('/dashboard'));
            });
        }
    }, [contentfulToken]);

    return (
        <Wrapper>
            <Text font="OS Bold" fontSize="28px">
                Redirecting...
            </Text>
        </Wrapper>
    );
};
