import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { HideIcon } from '../assets/icons';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { InputContainer } from './InputContainer';
import { Tag } from './Tag';

const StyledButton = styled(Button)`
    margin: 0;
    padding: 0;
`;

interface InputProps {
    label?: string;
    value?: string | string[];
    // eslint-disable-next-line no-unused-vars
    onChange?: (e: any) => void;
    onEnter?: () => void;
    onBlur?: () => void;
    type?: 'text' | 'password' | 'number';
    multiline?: boolean;
    placeholder?: string;
    info?: { title: any; content: any };
    icon?: any;
    iconPosition?: 'start' | 'end';
    onIconClicked?: () => void;
    className?: string;
    flat?: boolean;
    m?: string;
    p?: string;
    multiple?: boolean;
    rows?: number;
    height?: string;
    cRef?: any;
    maxLength?: number;
    disabled?: boolean;
}

const TextArea = styled.textarea`
    font-family: 'AR';
`;
const InputElement = styled.input`
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const Input: React.FC<InputProps> = ({
    label,
    value,
    onChange,
    onEnter,
    onBlur,
    cRef,
    type,
    multiline,
    placeholder,
    info,
    icon,
    iconPosition,
    onIconClicked,
    className,
    flat,
    p,
    m,
    multiple,
    rows,
    height,
    maxLength,
    disabled,
}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [tempValue, setTempValue] = useState('');
    const [inputWidth, setInpuWidth] = useState(0);
    const [currentWidth, setCurrentWidth] = useState(0);
    const [allWidth, setAllWidth] = useState(0);
    const inputRef = useRef(null);
    const divRef = useRef(null);
    const divRef0 = useRef(null);

    useLayoutEffect(() => {
        if (inputRef.current) {
            const rect = inputRef.current.getBoundingClientRect();
            setInpuWidth(rect.width);
        }
    }, [inputRef.current]);
    useLayoutEffect(() => {
        if (divRef.current) {
            const rect = (
                divRef.current as HTMLDivElement
            ).getBoundingClientRect();
            setAllWidth(rect.width);

            if (divRef0.current) {
                setCurrentWidth(divRef0.current.getBoundingClientRect().width);
            }
        }
    }, [divRef.current, value]);

    const handleChange = (op: string, val: string) => {
        switch (op) {
            case 'add':
                if (onChange) {
                    onChange(val);
                }
                break;
            case 'remove':
                if (onChange && Array.isArray(value)) {
                    onChange(value.filter((el) => el !== val).join(' '));
                }
                break;
            default:
                break;
        }
    };

    const handleMultipleChange = () => {
        handleChange('add', `${(value as string[]).join(' ')} ${tempValue}`);
        setTempValue('');
    };

    if (cRef) {
        cRef.handleMultipleChange = handleMultipleChange;
    }

    if (multiline) {
        return (
            <InputContainer
                className={className}
                info={info}
                label={label}
                type="textArea"
                flat={flat}
                p={p}
                m={m}
                border
                height={height}
            >
                <TextArea
                    maxLength={maxLength}
                    placeholder={placeholder}
                    value={value}
                    rows={rows || 5}
                    onChange={(e) => handleChange('add', e.target.value)}
                    disabled={disabled}
                />
            </InputContainer>
        );
    }

    return (
        <InputContainer
            flat={flat}
            className={className}
            info={info}
            label={label}
            p={p}
            m={m}
            border
            height={height}
            containerRef={inputRef}
        >
            {icon && iconPosition && iconPosition === 'start' && (
                <FlexGrid m="0em 0.5em 0em 0em">{icon}</FlexGrid>
            )}
            {multiple && Array.isArray(value) && (
                <>
                    <div
                        ref={divRef0}
                        style={{
                            maxWidth: `${inputWidth * 0.5}px`,
                            overflow: 'hidden',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                transform: `translate(${
                                    allWidth - inputWidth * 0.5 < 0
                                        ? 0
                                        : currentWidth - allWidth
                                }px, 0)`,
                            }}
                        >
                            {value.map((val, index) => (
                                <Tag
                                    key={`input-tag-${index}`}
                                    removeBtn
                                    title={val}
                                    onRemove={() => handleChange('remove', val)}
                                />
                            ))}
                        </div>
                    </div>
                    <div
                        ref={divRef}
                        style={{
                            display: 'flex',
                            position: 'absolute',
                            visibility: 'hidden',
                        }}
                    >
                        {value.map((val, index) => (
                            <Tag
                                key={`input-tag2-${index}`}
                                removeBtn
                                title={val}
                                onRemove={() => () => {}}
                            />
                        ))}
                    </div>
                    <InputElement
                        maxLength={maxLength}
                        type={showPassword ? 'text' : type || 'text'}
                        value={tempValue}
                        onBlur={onBlur}
                        onChange={(e) => setTempValue(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.code === 'Space') {
                                handleMultipleChange();
                            }
                        }}
                        placeholder={placeholder || 'Enter'}
                        disabled={disabled}
                    />
                </>
            )}
            {!multiple && (
                <InputElement
                    maxLength={maxLength}
                    type={showPassword ? 'text' : type || 'text'}
                    value={value}
                    onBlur={onBlur}
                    onChange={(e) => handleChange('add', e.target.value)}
                    placeholder={placeholder || 'Enter'}
                    onKeyPress={(e) => {
                        if (e.code === 'Enter') {
                            onEnter && onEnter();
                        }
                    }}
                    disabled={disabled}
                />
            )}
            {type === 'password' && (
                <StyledButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    negative
                    icon={<HideIcon color={COLORS.green} />}
                    disabled={disabled}
                />
            )}
            {icon &&
                (!iconPosition || iconPosition === 'end') &&
                (onIconClicked ? (
                    <StyledButton
                        onClick={onIconClicked}
                        negative
                        icon={icon}
                        disabled={disabled}
                    />
                ) : (
                    <FlexGrid m="0em 0.5em 0em 0em">{icon}</FlexGrid>
                ))}
        </InputContainer>
    );
};
