import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/variables';
import { FlexGrid } from './FlexGrid';

interface HorizontalLineProps {
    height: string;
    backgroundColor: string;
    progressColor?: string;
    progressPercentage?: string;
    m?: string;
}

const Background = styled(FlexGrid)<HorizontalLineProps>`
    position: relative;
    width: 100%;
    height: ${({ height }) => height};
    background-color: ${({ backgroundColor }) => backgroundColor};
    margin: ${({ m }) => m || '0.5em 0em'};
`;

interface ProgressProps {
    color?: string;
    height: string;
    width?: string;
}

const Progress = styled(FlexGrid)<ProgressProps>`
    position: absolute;
    width: ${({ width }) => width || '0%'};
    background-color: ${({ color }) => color || COLORS.transparent};
    left: 0;
    height: ${({ height }) => height};
`;

export const HorizontalLine: React.FC<HorizontalLineProps> = ({
    height,
    backgroundColor,
    progressColor,
    progressPercentage,
    m,
}) => {
    return (
        <Background m={m} height={height} backgroundColor={backgroundColor}>
            <Progress
                width={progressPercentage}
                height={height}
                color={progressColor}
            />
        </Background>
    );
};
