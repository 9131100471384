import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
    BonusPointsIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MinusIcon,
    PlusIcon,
    TargetScansIcon,
} from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { RadioGroup } from '../../../components/RadioGroup';
import { SearchSelect } from '../../../components/SearchSelect';
import { PointsPickerInput } from '../../../components/PointsPickerInput';
import { Text } from '../../../components/Text';
import { updateModalAction } from '../../../store/store.actions';
import { COLORS } from '../../../styles/variables';
import { LookupValue, ScanningContestType } from '../../../types';
import {
    ContentStatus,
    composeDate,
    genNewSchemeOffer,
    getContentStatus,
    isStringRequiredMissing,
} from '../../../utils';
import { SectionHeader } from '../../../components/SectionHeader';
import { IconButton } from '../../../components/IconButton';
import { useBrandSKUListHook } from '../../BrandSKUList/hooks/use-brand-sku-list-hook';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { Input } from '../../../components/Input';
import {
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_HEADLINE_LENGTH,
    MAX_NUM_CONTEST_SCHEMES,
} from '../../../common/constants';
import { HorizontalLine } from '../../../components/HorizontalLine';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    max-width: 500px;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    stepNavigator?: any;
    scanningContestState: [
        ScanningContestType,
        React.Dispatch<React.SetStateAction<ScanningContestType>>
    ];
    initScanningContestData: ScanningContestType;
}

export const ScanningContestDefintion: React.FC<Props> = ({
    stepNavigator,
    scanningContestState,
    initScanningContestData,
}) => {
    const dispatch = useDispatch();
    const [scanningContest, setScanningContest] = scanningContestState;
    const { content } = scanningContest;

    const [activeSchemeOfferIndex, setActiveSchemeOfferIndex] = useState(0);

    const [schemeOffersCount, setSchemeOffersCount] = useState(
        scanningContest?.content.schemes.length || 0
    );

    const { appLanguages } = scanningContest.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);

    const [{ isLoading, hookData: productsData }] = useBrandSKUListHook({});

    useEffect(() => {
        const newScanningContest = { ...scanningContest };
        newScanningContest.identifierDefinition.appLanguages.forEach(
            (language) => {
                if (!newScanningContest.content.headline[language]) {
                    newScanningContest.content.headline[language] = '';
                }
                if (!newScanningContest.content.description[language]) {
                    newScanningContest.content.description[language] = '';
                }
            }
        );
        setScanningContest(newScanningContest);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const products: any[] = productsData.data ?? [];

    const scanningContestStatus = getContentStatus(
        initScanningContestData,
        composeDate(
            initScanningContestData?.identifierDefinition.startDate,
            initScanningContestData?.identifierDefinition.startTime
        )?.toDate(),
        composeDate(
            initScanningContestData?.identifierDefinition.endDate,
            initScanningContestData?.identifierDefinition.endDate
        )?.toDate()
    );

    const isContestPublished =
        scanningContestStatus == ContentStatus.LIVE ||
        scanningContestStatus == ContentStatus.EXPIRED;

    useEffect(() => {
        const newScanningContest = { ...scanningContest };
        const numSchemeOffers = newScanningContest.content.schemes.length;
        if (numSchemeOffers <= 0) {
            newScanningContest.content.schemes = [genNewSchemeOffer()];
        }
        for (let i = 0; i < numSchemeOffers; ++i) {
            const scheme = newScanningContest.content.schemes[i];
            if (!scheme.content.schemeType) {
                scheme.content.schemeType = '';
            }
            if (!scheme.content.awardedPoints) {
                scheme.content.awardedPoints = 0;
            }
            if (!scheme.content.targetScans) {
                scheme.content.targetScans = 0;
            }
        }
        newScanningContest.identifierDefinition.appLanguages.forEach(
            (language) => {
                if (!newScanningContest.content.headline[language]) {
                    newScanningContest.content.headline[language] = '';
                }
                if (!newScanningContest.content.description[language]) {
                    newScanningContest.content.description[language] = '';
                }
            }
        );
        console.log('=====newScanningContest====', newScanningContest);
        setScanningContest(newScanningContest);
    }, []);

    const isSchemmesValid = (scanningContest, forNext = false) => {
        const schemes = scanningContest.content.schemes;
        if (!schemes || schemes.length <= 0) {
            return {
                reason: 'No schemes',
                result: false,
            };
        }
        if (
            (forNext && schemes.length > MAX_NUM_CONTEST_SCHEMES) ||
            (!forNext && schemes.length >= MAX_NUM_CONTEST_SCHEMES)
        ) {
            return {
                reason: `There should be only up to ${MAX_NUM_CONTEST_SCHEMES} schemes`,
                result: false,
            };
        }
        const numSchemes = schemes.length;
        let scheme;
        for (let i = 0; i < numSchemes; ++i) {
            scheme = schemes[i];
            if (scheme.content.schemeType === '') {
                return {
                    reason: 'Please select a type',
                    result: false,
                };
            }

            if (
                (!forNext || i !== numSchemes - 1) &&
                scheme.content.schemeType === 'Multiplier'
            ) {
                return {
                    reason: 'There should be only up to one Multiplier Scheme Type and the multiplier type must be the last scheme.',
                    result: false,
                };
            }

            if (scheme.content.awardedPoints === 0) {
                return {
                    reason: 'Points for Achievements cannot be 0.',
                    result: false,
                };
            }

            if (scheme.content.targetScans === 0) {
                return {
                    reason: 'Target scans cannot be 0.',
                    result: false,
                };
            }
        }

        return {
            reason: 'Good',
            result: true,
        };
    };

    const dataIncomplete =
        content.schemes.length === 0 ||
        content.applicableBrands.length === 0 ||
        content.applicableSKUs.length === 0;

    const getAvailableBrands = useCallback((): LookupValue[] => {
        if (isLoading) {
            return [];
        }
        return products
            .filter(
                (product, index, self) =>
                    index ===
                    self.findIndex((p) => p.brandName === product.brandName)
            )
            .map(
                (product) =>
                    ({
                        id: product.brandName,
                        name: product.productName,
                        code: product.brandName,
                    } as LookupValue)
            );
    }, [products, isLoading]);

    const getAvailableSKUs = useCallback((): LookupValue[] => {
        if (isLoading) {
            return [];
        }
        return products
            .filter((product) =>
                scanningContest.content.applicableBrands.includes(
                    product.brandName
                )
            )
            .map(
                (product) =>
                    ({
                        id: product.skuCode,
                        name: product.productName,
                        code: product.skuCode,
                    } as LookupValue)
            );
    }, [products, isLoading, scanningContest]);

    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(
            //     content.headline[language] &&
            //         content.description[language] &&
            //         content.actionText[language]
            // )
            language === activeLanguage
    );

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Contest Definition
                    </Text>
                }
            />
            <Form>
                <ButtonGroup
                    titles={appLanguages}
                    info={{
                        title: 'Multiple Languages',
                        content:
                            'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                    }}
                    active={activeLanguage}
                    onChange={setActiveLanguage}
                    checked={checkedLanguage}
                />
                <Input
                    label="Headline"
                    value={content.headline[activeLanguage]}
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    onChange={(val) =>
                        setScanningContest((state) => {
                            const newState = { ...state };
                            newState.content.headline[activeLanguage] = val;
                            return newState;
                        })
                    }
                    disabled={isContestPublished}
                />
                <Input
                    label="Description"
                    multiline
                    height="250px"
                    placeholder="Enter"
                    rows={10}
                    value={content.description[activeLanguage]}
                    maxLength={LIMIT_DESCRIPTION_LENGTH}
                    onChange={(val) =>
                        setScanningContest((state) => {
                            const newState = { ...state };
                            newState.content.description[activeLanguage] = val;
                            return newState;
                        })
                    }
                    disabled={isContestPublished}
                />
                <HorizontalLine
                    backgroundColor={COLORS.lightGray}
                    height="1px"
                />

                <SearchSelect
                    value={scanningContest.content.applicableBrands}
                    onChange={(val) =>
                        setScanningContest({
                            ...scanningContest,
                            content: {
                                ...scanningContest.content,
                                applicableBrands: val,
                            },
                        })
                    }
                    options={getAvailableBrands()}
                    label="Applicable Brands"
                    innerLabel="Brands"
                    info={{
                        title: 'Applicable Brand',
                        content:
                            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
                    }}
                    renderOption={(v) => `${v.code}`}
                    disabled={isContestPublished}
                />
                <SearchSelect
                    value={scanningContest.content.applicableSKUs}
                    onChange={(val) =>
                        setScanningContest({
                            ...scanningContest,
                            content: {
                                ...scanningContest.content,
                                applicableSKUs: val,
                            },
                        })
                    }
                    options={getAvailableSKUs()}
                    label="Applicable SKUs"
                    innerLabel="SKUs"
                    info={{
                        title: 'Applicable SKUs',
                        content:
                            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
                    }}
                    disabled={isContestPublished}
                />

                <SectionHeader
                    title={`SCHEME AND OFFER #${activeSchemeOfferIndex + 1}`}
                    bg="#F5F5F5"
                    fontFamily="OS"
                    height="40px"
                    titleMargin="0 0 0 1em"
                    right={
                        <FlexGrid alignItems="center">
                            <FlexGrid alignItems="center">
                                {schemeOffersCount > 1 && (
                                    <IconButton
                                        negative
                                        p="0"
                                        m="0em 1em"
                                        disabled={isContestPublished}
                                        icon={<MinusIcon />}
                                        onClick={() => {
                                            setScanningContest((state) => {
                                                const newState = { ...state };
                                                newState.content.schemes.splice(
                                                    activeSchemeOfferIndex,
                                                    1
                                                );
                                                return newState;
                                            });
                                            const prevSchemeOffersCount =
                                                schemeOffersCount;
                                            setSchemeOffersCount(
                                                prevSchemeOffersCount - 1
                                            );
                                            if (
                                                activeSchemeOfferIndex >=
                                                prevSchemeOffersCount - 1
                                            ) {
                                                setActiveSchemeOfferIndex(
                                                    prevSchemeOffersCount - 2
                                                );
                                            }
                                        }}
                                    />
                                )}
                                {activeSchemeOfferIndex ===
                                    schemeOffersCount - 1 && (
                                    <IconButton
                                        negative
                                        p="0"
                                        m="0em 1em"
                                        disabled={isContestPublished}
                                        icon={<PlusIcon />}
                                        onClick={() => {
                                            const ret =
                                                isSchemmesValid(
                                                    scanningContest
                                                );
                                            if (!ret.result) {
                                                dispatch(
                                                    updateModalAction({
                                                        open: true,
                                                        title: 'error',
                                                        content: ret.reason,
                                                    })
                                                );
                                                return;
                                            }

                                            setScanningContest((state) => {
                                                const newState = { ...state };
                                                newState.content.schemes.push(
                                                    genNewSchemeOffer()
                                                );
                                                return newState;
                                            });
                                            const prevSchemeOffersCount =
                                                schemeOffersCount;
                                            setSchemeOffersCount(
                                                schemeOffersCount + 1
                                            );
                                            setActiveSchemeOfferIndex(
                                                prevSchemeOffersCount
                                            );
                                        }}
                                    />
                                )}
                                <Button
                                    p="0"
                                    m="0em 1em"
                                    negative
                                    onClick={() => {
                                        setActiveSchemeOfferIndex(
                                            Math.max(
                                                0,
                                                activeSchemeOfferIndex - 1
                                            )
                                        );
                                    }}
                                    disabled={activeSchemeOfferIndex < 1}
                                >
                                    <ChevronRightIcon
                                        color={
                                            activeSchemeOfferIndex < 1
                                                ? COLORS.gray
                                                : undefined
                                        }
                                    />
                                </Button>
                                <Button
                                    p="0"
                                    m="0em 1em"
                                    negative
                                    onClick={() => {
                                        setActiveSchemeOfferIndex(
                                            Math.min(
                                                schemeOffersCount - 1,
                                                activeSchemeOfferIndex + 1
                                            )
                                        );
                                    }}
                                    disabled={
                                        activeSchemeOfferIndex + 1 >=
                                        schemeOffersCount
                                    }
                                >
                                    <ChevronLeftIcon
                                        color={
                                            activeSchemeOfferIndex + 1 >=
                                            schemeOffersCount
                                                ? COLORS.gray
                                                : undefined
                                        }
                                    />
                                </Button>
                            </FlexGrid>
                        </FlexGrid>
                    }
                />

                <FlexGrid
                    direction="column"
                    flex="1"
                    width="100%"
                    p="0em 2em 0em 1em"
                >
                    <RadioGroup
                        label="Select Type"
                        info={{
                            title: 'Select Type',
                            content:
                                'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                        }}
                        options={[
                            { label: 'Slab', value: 'Slab' },
                            { label: 'Multiplier', value: 'Multiplier' },
                        ]}
                        value={
                            content.schemes[activeSchemeOfferIndex].content
                                .schemeType
                        }
                        onChange={(val) => {
                            setScanningContest((state) => {
                                const newState = { ...state };
                                const schemeOffer =
                                    newState.content.schemes[
                                        activeSchemeOfferIndex
                                    ];
                                schemeOffer.content.schemeType = val;
                                return newState;
                            });
                        }}
                        disabled={isContestPublished}
                    />
                    <FlexGrid width="100%" gap="0.5em">
                        <PointsPickerInput
                            label="Target scans"
                            width="5rem"
                            explicitPlaceHolder="Coupon"
                            icon={<TargetScansIcon />}
                            info={{
                                title: 'Target scans',
                                content:
                                    'You can add bonus points goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                            }}
                            value={
                                content.schemes[activeSchemeOfferIndex].content
                                    .targetScans
                            }
                            onChange={(val) => {
                                setScanningContest((state) => {
                                    const newState = { ...state };
                                    const schemeOffer =
                                        newState.content.schemes[
                                            activeSchemeOfferIndex
                                        ];
                                    schemeOffer.content.targetScans = val;
                                    return newState;
                                });
                            }}
                            disabled={isContestPublished}
                        />
                        <PointsPickerInput
                            label="Points for Achievement"
                            width="5rem"
                            explicitPlaceHolder="pts"
                            icon={<BonusPointsIcon />}
                            info={{
                                title: 'Points for Achievement',
                                content:
                                    'You can add bonus points goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                            }}
                            value={
                                content.schemes[activeSchemeOfferIndex].content
                                    .awardedPoints
                            }
                            onChange={(val) => {
                                setScanningContest((state) => {
                                    const newState = { ...state };
                                    const schemeOffer =
                                        newState.content.schemes[
                                            activeSchemeOfferIndex
                                        ];
                                    schemeOffer.content.awardedPoints = val;
                                    return newState;
                                });
                            }}
                            disabled={isContestPublished}
                        />
                    </FlexGrid>
                </FlexGrid>

                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() => {
                            // Check multi-language fields are filled
                            for (const language of appLanguages) {
                                if (
                                    isStringRequiredMissing(
                                        content.headline[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Headline in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.description[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Description in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                            }
                            const schemeOffers = content.schemes;
                            for (let i = 0; i < schemeOffers.length; ++i) {
                                const schemeOffer = schemeOffers[i];
                                if (
                                    isStringRequiredMissing(
                                        schemeOffer.content.schemeType
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `The Scheme Type of Scheme and Offer #${
                                                i + 1
                                            } is missing`,
                                        })
                                    );
                                    return;
                                }

                                if (schemeOffer.content.awardedPoints <= 0) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Please assign points to Scheme and Offer #${
                                                i + 1
                                            }.`,
                                        })
                                    );
                                    return;
                                }

                                if (schemeOffer.content.targetScans <= 0) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Please assign coupons to Scheme and Offer #${
                                                i + 1
                                            }.`,
                                        })
                                    );
                                    return;
                                }
                            }

                            const ret = isSchemmesValid(scanningContest, true);
                            if (!ret.result) {
                                dispatch(
                                    updateModalAction({
                                        open: true,
                                        title: 'error',
                                        content: ret.reason,
                                    })
                                );
                                return;
                            }

                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
