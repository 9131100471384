import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';
import { ChevronDownIcon, ChevronUpIcon } from '../assets/icons';
import { Button } from './Button';
import { InfoDetails } from './Info';
import useClickOutside from '../utils/useClickOutside';

interface AccordionProps {
    title?: any;
    content?: any;
    info?: any;
    defaultOpen?: boolean;
}

// const Content = styled(FlexGrid)`
//     width: 100%;
// `;

const ChevronButton = styled(Button)`
    position: relative;
`;

export const Accordion: React.FC<AccordionProps> = ({
    title,
    content,
    info,
    defaultOpen,
}) => {
    const [open, setOpen] = useState(defaultOpen ?? true);
    const [infoOpen, setInfoOpen] = useState(false);

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const close = useCallback(() => setInfoOpen(false), []);
    useClickOutside(ref, close);

    return (
        <FlexGrid direction="column" width="100%" p="1em 0em">
            <FlexGrid
                p="0em 1.5em"
                width="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                {typeof title === 'string' ? (
                    <Text uppercase font="AR Bold">
                        {title}
                    </Text>
                ) : (
                    title
                )}
                <FlexGrid
                    onMouseEnter={() => setInfoOpen(true)}
                    onMouseLeave={() => setInfoOpen(false)}
                    ref={ref}
                >
                    <ChevronButton negative onClick={() => setOpen((p) => !p)}>
                        {open ? (
                            <ChevronUpIcon height="8" />
                        ) : (
                            <ChevronDownIcon height="8" />
                        )}
                        {info && (
                            <InfoDetails
                                width="250px"
                                info={info}
                                open={infoOpen}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                setInfoOpen={setInfoOpen}
                            />
                        )}
                    </ChevronButton>
                </FlexGrid>
            </FlexGrid>
            {open && <>{content}</>}
        </FlexGrid>
    );
};
