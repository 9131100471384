import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgLink: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 22.01}
        height={height || 21.99}
        viewBox="0 0 22.01 21.99"
    >
        <g
            className="icon-stroke"
            fill="none"
            stroke={color || '#ddd'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <path
                data-name="Path 4803"
                d="M9.005 11.995a5 5 0 0 0 7.54.54l3-3a5 5 0 1 0-7.071-7.071l-1.719 1.711"
            />
            <path
                data-name="Path 4804"
                d="M13.005 9.995a5 5 0 0 0-7.54-.54l-3 3a5 5 0 1 0 7.071 7.071l1.709-1.711"
            />
        </g>
    </svg>
);

export default SvgLink;
