import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgVideos: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path data-name="Path 4774" d="M0 0h24v24H0Z" fill="none" />
        <path
            className="icon-fill"
            data-name="Path 4775"
            d="M4 6H2v14a2.006 2.006 0 0 0 2 2h14v-2H4Zm16-4H8a2.006 2.006 0 0 0-2 2v12a2.006 2.006 0 0 0 2 2h12a2.006 2.006 0 0 0 2-2V4a2.006 2.006 0 0 0-2-2Zm0 14H8V4h12ZM12 5.5v9l6-4.5Z"
            fill={color || '#333'}
        />
    </svg>
);

export default SvgVideos;
