import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../components/Button';
import { FlexGrid } from '../components/FlexGrid';
import { Input } from '../components/Input';
import { Modal } from '../components/Modal';
import { CheckCircleBigIcon } from '../assets/icons';
import { Text } from '../components/Text';
import { COLORS } from '../styles/variables';
import { ContentfulApiService } from '../services/ContentfulApi.service';
import { isStringNotRequireAndWhiteSpaces } from '../utils';

interface CreateNewModalProps {
    open: boolean;
    onCreate?: (data: { name: string; description: string }) => void;
    onClose?: () => void;
    title: string;
    success: boolean | null;
    disabled?: boolean;
    nameLabel?: string;
    setSuccess: (v: boolean | null) => void;
}

const ActionButton = styled(Button)`
    font-family: 'OS';
    font-size: 1rem;
`;

const BorderBottom = styled(FlexGrid)`
    padding-bottom: 2.5em;
    border-bottom: 1px solid ${COLORS.lightGray};
    gap: 1em;
`;

import { useDispatch, useSelector } from 'react-redux';
import { AppState, ModalState } from '../store/store.types';
import { updateModalAction } from '../store/store.actions';

interface Props {}

export const Processor = ({}: Props) => {
    const modal = useSelector<AppState, ModalState>((s) => s.modal);
    const { title, open, content } = modal;
    const dispatch = useDispatch();

    const reset = () => {
        dispatch(updateModalAction({ open: false }));
    };

    const successContent = (
        <FlexGrid
            p="1em 0em 1em 0em"
            alignItems="center"
            width="100%"
            gap="0.5em"
            m="0em 0em 2em 0em"
        >
            <Text color={COLORS.darkGray} workBreakAll>{content}</Text>
        </FlexGrid>
    );

    const successActions = (
        <ActionButton onClick={reset} p="0.6em 2.5em" m="0 0 2em 0">
            Ok
        </ActionButton>
    );

    return (
        <Modal
            open={open}
            width={'500px'}
            header={title}
            onClose={reset}
            content={successContent}
            actions={modal.noAction ? null : successActions}
        />
    );
};
