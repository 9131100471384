import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
}

const SvgPlayButton: React.FC<Props> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 45}
        height={height || 45}
        viewBox="0 0 45 45"
    >
        <defs>
            <linearGradient
                id="play-button_svg__a"
                x1={1}
                y1={1}
                x2={0}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} />
                <stop offset={1} stopColor="#6c6c6c" />
            </linearGradient>
        </defs>
        <g
            data-name="Ellipse 176"
            stroke="#fff"
            strokeWidth={2}
            fill="url(#play-button_svg__a)"
        >
            <circle cx={22.5} cy={22.5} r={22.5} stroke="none" />
            <circle cx={22.5} cy={22.5} r={21.5} fill="none" />
        </g>
        <path
            data-name="Polygon 35"
            d="M31.629 22.5 16 31.575v-18.15Z"
            fill="#fff"
        />
    </svg>
);

export default SvgPlayButton;
