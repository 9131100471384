import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgSort1: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path data-name="Path 4808" d="M0 0h24v24H0Z" fill="none" />
        <path
            className="icon-fill"
            data-name="Path 4809"
            d="M3 18h6v-2H3ZM3 6v2h18V6Zm0 7h12v-2H3Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgSort1;
