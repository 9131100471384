import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgImage: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 22.409}
        height={height || 22.414}
        viewBox="0 0 22.409 22.414"
    >
        <g
            className="icon-stroke"
            transform="translate(-2 -1.992)"
            fill="none"
            stroke={color || '#666'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <rect
                data-name="Rectangle 94"
                width={20}
                height={20}
                rx={2}
                transform="translate(3 2.992)"
            />
            <circle
                data-name="Ellipse 81"
                cx={1.782}
                cy={1.782}
                r={1.782}
                transform="translate(7.288 7.28)"
            />
            <path
                data-name="Path 120"
                d="m22.994 16.342-5.556-5.541L5.216 22.992"
            />
        </g>
    </svg>
);

export default SvgImage;
