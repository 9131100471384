import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon, ChevronRightIcon } from '../assets/icons';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { Header } from './Header';
import { Navigation } from './Navigation';
import { Text } from './Text';

const PaginationWrapper = styled(FlexGrid)`
    align-self: flex-end;
    align-items: center;
    gap: 1em;
    padding: 1em;
`;

const Pages = styled(FlexGrid)`
    border: 1px solid ${COLORS.lightGray};
    align-items: center;
`;

interface PageBtnProps {
    active?: boolean;
}

const PageBtn = styled(Button)<PageBtnProps>`
    border-radius: 0;
    padding: 0.2em 0.5em;
    margin: 5px 2px;
    background-color: ${({ active }) =>
        active ? COLORS.green : COLORS.transparent};
    color: ${({ active }) => (active ? COLORS.white : COLORS.darkGray)};
    font-family: 'AR';
    font-size: 1rem;
`;

export interface PaginationType {
    perPage?: number;
    page?: number;
    total?: number;
}

interface PaginationProps {
    perPage?: number;
    page?: number;
    total?: number;
    setPage?: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
    perPage = 1,
    page = 1,
    total = 0,
    setPage,
}) => {
    const totalPage = Math.max(Math.ceil(total / perPage), 1);
    const hasDots = totalPage > 3;
    const isDotsLeft = page > totalPage / 2 && page >= totalPage - 1;

    let firstPart = [];
    let lastPart = isDotsLeft
        ? [totalPage - 2, totalPage - 1, totalPage]
        : [totalPage];

    for (let i = 1; i < totalPage + 1; i++) {
        if (i > 3) {
            break;
        }
        firstPart.push(i);
    }

    if (hasDots) {
        if (isDotsLeft) {
            firstPart = [1];
        } else {
            // translate transform
            if (page > 2) {
                firstPart = firstPart.map((v) => v + page - 2);
            }
        }
    }

    return (
        <PaginationWrapper>
            <Text fontSize="0.875rem" color={COLORS.darkGray}>
                Page {page} from {totalPage}
            </Text>
            <Pages>
                <Button
                    negative
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                >
                    <ChevronRightIcon color={page === 1 ? 'gray' : 'green'} />
                </Button>
                {firstPart.map((p, key) => (
                    <PageBtn
                        key={key}
                        active={p === page}
                        onClick={() => {
                            setPage(p);
                        }}
                    >
                        {p}
                    </PageBtn>
                ))}
                {hasDots && (
                    <>
                        <Text>...</Text>
                        {lastPart.map((p, key) => (
                            <PageBtn
                                key={key}
                                active={p === page}
                                onClick={() => {
                                    setPage(p);
                                }}
                            >
                                {p}
                            </PageBtn>
                        ))}
                    </>
                )}
                <Button
                    negative
                    onClick={() => setPage(page + 1)}
                    disabled={page === totalPage}
                >
                    <ChevronLeftIcon
                        color={page === totalPage ? 'gray' : 'green'}
                    />
                </Button>
            </Pages>
        </PaginationWrapper>
    );
};
