import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgLearning: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 50 50"
    >
        <g
            className="icon-stroke"
            transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)"
            fill="none"
            stroke={color || '#009343'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={20}
        >
            <path
                d="M12 418 c-18 -18 -16 -322 2 -337 19 -16 453 -16 472 0 20 16 20 321
1 337 -19 16 -460 15 -475 0z m228 -168 l0 -160 -110 0 -110 0 0 160 0 160
110 0 110 0 0 -160z m240 0 l0 -160 -110 0 -110 0 0 160 0 160 110 0 110 0 0
-160z"
            />
            <path
                d="M50 350 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25
0 -45 -4 -45 -10z"
            />
            <path
                d="M50 300 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25
0 -45 -4 -45 -10z"
            />
            <path
                d="M50 250 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25
0 -45 -4 -45 -10z"
            />
            <path
                d="M50 200 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25
0 -45 -4 -45 -10z"
            />
            <path
                d="M50 150 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25
0 -45 -4 -45 -10z"
            />
            <path
                d="M290 350 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25
0 -45 -4 -45 -10z"
            />
            <path
                d="M290 300 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25
0 -45 -4 -45 -10z"
            />
            <path
                d="M290 250 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25
0 -45 -4 -45 -10z"
            />
        </g>
    </svg>
);

export default SvgLearning;
