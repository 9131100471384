import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgVideoPlaylist2: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 14.53}
        height={height || 13.612}
        viewBox="0 0 14.53 13.612"
    >
        <path
            className="icon-fill"
            d="M1 4h10a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2Zm0-4h10a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2Zm0 8h6a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2Zm9 .88v4.23a.5.5 0 0 0 .76.43l3.53-2.12a.505.505 0 0 0 0-.86l-3.53-2.12a.508.508 0 0 0-.76.44Z"
            fill={color || '#333'}
        />
    </svg>
);

export default SvgVideoPlaylist2;
