export const GLOBAL_CONFIG = {
    BASE_API_PATH: process.env.REACT_APP_BASE_URL || 'https://preview.contentful.com',
    CONTENTFUL_API_PATH: process.env.REACT_APP_BASE_URL || 'https://api.contentful.com',
    APP_API_BASE: process.env.REACT_APP_API_BASE || 'https://d1soa8pblvwwct.cloudfront.net',
    CONTENTFUL_AUTH_URL: process.env.REACT_APP_CONTENTFUL_AUTH_URL || 'https://be.contentful.com/oauth/authorize',
    CONTENTFUL_AUTH_APP_CLIENT_ID: process.env.REACT_APP_CONTENTFUL_AUTH_APP_CLIENT_ID || 'zXosweq8gRMBgRPsyr6LF1b3eLH46rePXNzfiHwPxC8',
    CONTENTFUL_AUTH_APP_REDIRECT_URI: process.env.REACT_APP_CONTENTFUL_AUTH_APP_REDIRECT_URI || 'https://localhost:3000/redirect',
    APP_ID: process.env.REACT_APP_APP_ID || '/app/one',
    CONTENTFUL_SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID || '4wfl0fpp9t0j',
    ROLE_DATA: {
        '2gLWX854BMpAJXva0uF0Jp': {
            name: 'Market Manager - France',
            tagId: 'france',
        },
        '5yxs87hlgxSq0YPDrfaibl': {
            name: 'Market Manager - Germany',
            tagId: 'germany',
        },
        '3wmWNUz1a7KhmTUyP13hPx': {
            name: 'Market Manager - Poland',
            tagId: 'poland',
        },
        '03eJNAqd6Gj43wwf5YowVz': {
            name: 'Market Manager - Span',
            tagId: 'spain',
        },
        '52HP3dxE0eo4JPyvwa1s35': {
            name: 'Market Manager - Thailand',
            tagId: 'thailand',
        },
        '4xXTPsrmRlctOerkkpNbzC': {
            name: 'Market Manager - UK',
            tagId: 'uk',
        },
        '1QroDVkKkftYTagAvPPEjx': {
            name: 'Market Manager - Portugal',
            tagId: 'portugal',
        },
        '5TzpR9D26zqqlaQXiBrOA4': {
            name: 'Market Manager - Czech Republic',
            tagId: 'czech',
        },
        '5TC1P4f0h8xWdQTI1GzdCI': {
            name: 'Market Manager - Slovakia',
            tagId: 'slovakia',
        },
        '3dIcJtX9pW0b6ie1eyWgeJ': {
            name: 'Market Manager - Hungary',
            tagId: 'hungary',
        },
    },
    environment: process.env.REACT_APP_CONTENTFUL_SPACE_ENV || 'staging',
    MOCK_LOGIN_ENABLED: process.env.REACT_APP_MOCK_LOGIN_ENABLED === "true",
    TEST_MARKET_ADMIN_TOKEN: process.env.REACT_APP_TEST_MARKET_ADMIN_TOKEN,
    TEST_SUPER_ADMIN_TOKEN: process.env.REACT_APP_TEST_SUPER_ADMIN_TOKEN,

    SAVE_AS_DRAFT_TO_LOCAL: false
};
