import { useRef } from "react";

export const useShowMore = (page, pageSize, total, replace: any[]) => {
    const dataRef = useRef([]);
    if (pageSize * page < dataRef.current.length) {
        dataRef.current = dataRef.current.slice(0, pageSize * page);
    }

    dataRef.current = dataRef.current.slice(0, pageSize * (page - 1));

    if (dataRef.current.length === pageSize * (page - 1)) {
        for (let i = 0; i < replace.length; i++) {
            // dataRef.current[i + pageSize * (page - 1)] = replace[i];
            dataRef.current.push(replace[i]);
        }
    }

    const hasShowMore = dataRef.current.length < total;

    return { data: dataRef.current, hasShowMore };
};