import { contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import { ApiService } from '../../../services/Api.service';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { getAssetIdsParams } from '../../../common';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { IPagination } from '../../../types';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const videos: any = await ContentfulApiService.searchContent({
        contentType: 'video',
        limit: props.input.pagination.perPage,
        page: props.input.pagination.page,
        sortBy: props.input.pagination.sortBy
    });


    const playlist = await ContentfulApiService.searchContent({
        contentType: 'videoPlaylist',
    });
    videos.playlist = playlist;

    // ContentfulApiService.searchContent({
    //     contentType: 'videoPlaylist',
    // }).then((res) => {
    //     videos.playlist = res;
    //     props.updateData({ ...videos });
    // });

    console.log('videos', videos);
    // for (let i = 0; i < videos.items.length; ++i) {
    //     const video = videos.items[i];
    //     const views = await ApiService.getViews(
    //         video.sys.id,
    //         video.sys.space.sys.id,
    //         video.sys.environment.sys.id,
    //         video.sys.contentType.sys.id
    //     );
    //     video.views = views;
    //     const comments = await ApiService.getComments(
    //         video.sys.id,
    //         video.sys.space.sys.id,
    //         video.sys.environment.sys.id,
    //         video.sys.contentType.sys.id
    //     );
    //     video.comments = comments;
    // }

    videos.items.forEach((video) => {
        ApiService.getViews(
            video.sys.id,
            video.sys.space.sys.id,
            video.sys.environment.sys.id,
            video.sys.contentType.sys.id
        ).then((res) => {
            video.views = res.views;
            video.uniqueViews = res.uniqueViews;
            props.updateData({ ...videos });
        });
    });

    videos.items.forEach((video) => {
        ApiService.getComments(
            video.sys.id,
            video.sys.space.sys.id,
            video.sys.environment.sys.id,
            video.sys.contentType.sys.id
        ).then((res) => {
            video.comments = res;
            props.updateData({ ...videos });
        });
    });

    const assetsResponse = await ContentfulApiService.getAssets(
        {
            ...getAssetIdsParams(
                ContentfulTransformService.getVideosAssets(videos.items)
            ),
        }
    );
    videos.assets = assetsResponse;
    props.updateData({ ...videos });

    // contentfulApiRequest({
    //     url: `${GLOBAL_CONFIG.CONTENTFUL_API_PATH}/spaces/${GLOBAL_CONFIG.CONTENTFUL_SPACE_ID}/environments/${GLOBAL_CONFIG.environment}/assets`,
    //     method: 'GET',
    //     params: {
    //         ...getAssetIdsParams(
    //             ContentfulTransformService.getVideosAssets(videos.items)
    //         ),
    //     },
    // }).then((res) => {
    //     videos.assets = res.data;
    //     props.updateData({ ...videos });
    // });

    return videos;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useVideosHook = (
    pagination,
    changeList: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: true,
        initialData: null,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
