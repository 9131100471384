import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_HEADLINE_LENGTH,
    LIMIT_NOTIFICATION_LENGTH,
} from '../../../common/constants';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { FlexGrid } from '../../../components/FlexGrid';
import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Select } from '../../../components/Select';
import { TargetLink } from '../../../components/TargetLink';
import { Text } from '../../../components/Text';
import { updateModalAction } from '../../../store/store.actions';
import { COLORS } from '../../../styles/variables';
import { PushNotificationType } from '../../../types';
import {
    isNotRequireAndWhiteSpaces,
    isStringRequiredMissing,
} from '../../../utils';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    max-width: 500px;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    stepNavigator?: any;
    pushNotificationState: [
        PushNotificationType,
        React.Dispatch<React.SetStateAction<PushNotificationType>>
    ];
}

export const PushNotificationContent: React.FC<Props> = ({
    stepNavigator,
    pushNotificationState,
}) => {
    const dispatch = useDispatch();
    const [pushNotification, setPushNotification] = pushNotificationState;
    const { content } = pushNotification;
    const { appLanguages } = pushNotification.identifierDefinition;

    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);

    useEffect(() => {
        const newPushNotification = { ...pushNotification };
        newPushNotification.identifierDefinition.appLanguages.forEach(
            (language) => {
                if (!newPushNotification.content.previewText[language]) {
                    newPushNotification.content.previewText[language] = '';
                }
                if (!newPushNotification.content.notificationText[language]) {
                    newPushNotification.content.notificationText[language] = '';
                }
                if (!newPushNotification.content.targetLink[language]) {
                    newPushNotification.content.targetLink[language] = '';
                }
            }
        );
        setPushNotification(newPushNotification);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(
            //     content.previewText[language] && content.notificationText[language]
            // )
            language === activeLanguage
    );

    const dataIncomplete =
        // !content.notificationType ||
        isNotRequireAndWhiteSpaces(content.previewText) ||
        isNotRequireAndWhiteSpaces(content.notificationText);

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Content
                    </Text>
                }
            />
            <Form>
                {/* <Select
                    form
                    label="Type of push notification"
                    placeholder="- Select -"
                    info={{
                        title: 'Type of push notification',
                        content:
                            'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                    }}
                    options={['Alert', 'Comment', 'Reward', 'New Release']}
                    value={content.notificationType}
                    onChange={(val) =>
                        setPushNotification({
                            ...pushNotification,
                            content: {
                                ...pushNotification.content,
                                notificationType: val,
                            },
                        })
                    }
                /> */}
                <ButtonGroup
                    titles={appLanguages}
                    info={{
                        title: 'Multiple Languages',
                        content:
                            'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                    }}
                    active={activeLanguage}
                    onChange={setActiveLanguage}
                    checked={checkedLanguage}
                />
                <Input
                    label="Preview Text"
                    value={content.previewText[activeLanguage]}
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    onChange={(val) =>
                        setPushNotification((state) => {
                            const newState = { ...state };
                            newState.content.previewText[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Input
                    multiline
                    height="200px"
                    placeholder="Enter"
                    label="Notification Text"
                    maxLength={LIMIT_NOTIFICATION_LENGTH}
                    value={content.notificationText[activeLanguage]}
                    onChange={(val) =>
                        setPushNotification((state) => {
                            const newState = { ...state };
                            newState.content.notificationText[activeLanguage] =
                                val;
                            return newState;
                        })
                    }
                />
                <TargetLink
                    value={content.targetLink[activeLanguage]}
                    isLinkInternal={content.isLinkInternal}
                    title="Target Link"
                    onChange={(val) =>
                        setPushNotification((state) => {
                            const newState = { ...state };
                            newState.content.isLinkInternal =
                                val.isLinkInternal;
                            newState.content.targetLink[activeLanguage] =
                                val.linkSource;
                            return newState;
                        })
                    }
                />
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() => {
                            // Check multi-language fields are filled
                            for (const language of appLanguages) {
                                if (
                                    isStringRequiredMissing(
                                        content.previewText[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Preview Text in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.notificationText[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Notification Text in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                            }
                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
