import _ from 'lodash';
import moment from 'moment';
import { getMapOrEmpty } from '../utils';

export const getAssetIdsParams = (assetIds: string | string[]) => {
    let value = assetIds;
    if (_.isArray(assetIds)) {
        value = _.join(value, ',');
    }
    return getMapOrEmpty('sys.id[in]', value);
};

export const sortFunc = (
    sort: any,
    items: any[],
    sortKeyMapping: any = undefined
) => {
    // sort[key]
    // 1: ascendingly
    // -1: descendingly
    // 0: original order

    // Sometimes sort key is not the same as the data key
    // Need to provide sortKeyMapping to get the mapping from sort key to data key

    let sortKey;
    let value;
    for (const key of Object.keys(sort)) {
        value = sort[key];
        // console.log('===value==', key, value);
        if (value !== 0) {
            sortKey = (sortKeyMapping && sortKeyMapping[key]) ?? key;
            break;
        }
    }
    // console.log('===sortKey==', sortKey);
    if (sortKey) {
        items.sort((x, y) => {
            const key = sortKey;
            // console.log('====1===', x, y);
            if (y[key] === undefined) {
                return 1;
            }
            // console.log('====2===');
            if (x[key] === undefined) {
                return -1;
            }
            let xElem;
            let yElem;
            // console.log('===x[key]===', moment.isMoment(x[key]), '==key==', key);
            if (typeof x[key] === 'string' && typeof y[key] === 'string') {
                xElem = x[key].toLowerCase();
                yElem = y[key].toLowerCase();
            } else if (moment.isMoment(x[key]) && moment.isMoment(y[key])) {
                const diff = y[key].diff(x[key]);
                if (diff > 0) {
                    return value === -1 ? 1 : -1;
                } else if (diff < 0) {
                    return value === -1 ? -1 : 1;
                }
            } else {
                xElem = x[key];
                yElem = y[key];
            }
            if (yElem > xElem) {
                return value === -1 ? 1 : -1;
            } else if (yElem < xElem) {
                return value === -1 ? -1 : 1;
            }
            return 0;
        });
    }
    return items;
};
