import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgInAppNotification2: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 17.443}
        height={height || 22}
        viewBox="0 0 17.443 22"
    >
        <path
            className="icon-fill"
            d="M15.443 0h-10a2.006 2.006 0 0 0-2 2v2a1 1 0 0 0 2 0V3h10v16h-10v-1a1 1 0 0 0-2 0v2a2.006 2.006 0 0 0 2 2h10a2.006 2.006 0 0 0 2-2V2a2.006 2.006 0 0 0-2-2ZM3.453 12.47l-1.92-1.92a.9.9 0 0 0-1.27 1.27l2.47 2.47a1 1 0 0 0 1.41 0l5.85-5.85a.9.9 0 0 0-1.27-1.27l-5.27 5.3Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgInAppNotification2;
