import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgInfo: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 18}
        height={height || 18}
        viewBox="0 0 18 18"
    >
        <path data-name="Path 4717" d="M0 0h18v18H0Z" fill="none" />
        <path
            className="icon-fill"
            data-name="Path 4718"
            d="M9 2a7 7 0 1 0 7 7 7 7 0 0 0-7-7Zm.7 10.5H8.3V8.3h1.4Zm0-5.6H8.3V5.5h1.4Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgInfo;
