import { contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import { GLOBAL_CONFIG } from '../../../config/config';
import _ from 'lodash';
import { getAssetIdsParams } from '../../../common';

type Input = {
    assetIds?: string | string[];
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const { data: assets } = await contentfulApiRequest({
        url: `${GLOBAL_CONFIG.CONTENTFUL_API_PATH}/spaces/${GLOBAL_CONFIG.CONTENTFUL_SPACE_ID}/environments/${GLOBAL_CONFIG.environment}/assets`,
        method: 'GET',
        params: {
            ...getAssetIdsParams(props.input.assetIds),
        },
    });

    console.log('get assets', assets);
    return assets;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useDashboardAssetsHook = (
    assetIds?: string | string[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialData: null,
        initialIsLoading: true,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { assetIds } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
