import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexGrid } from '../../../components/FlexGrid';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { Button } from '../../../components/Button';
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components/Table';
import { ApiService } from '../../../services/Api.service';
import {
    ROLE_TAG_ID_MAP,
    SHORT_TAG_COUNTRY_MAP,
    TAG_COUNTRY_MAP,
} from '../../../common/constants';
import { RadioGroup } from '../../../components/RadioGroup';
import { SuccessModal } from '../../../components/SuccessModal';
import { getAvailableRoleIds, getRoleId } from '../../../utils';

const Content = styled(FlexGrid)`
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: scroll;
    max-height: 450px;
`;

interface OilSelectorCountryType {
    countryName: string;
    countryTag: string;
    countryTagUnique?: string;
    enabled: boolean;
}

export const OilSelectors = () => {
    const [oilSelectorCountries, setOilSelectorCountries] = useState<
        OilSelectorCountryType[]
    >([]);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [viewCount, setViewCounts] = useState<any>({});

    const availableRoleIds = getAvailableRoleIds();
    const roleTagId = ROLE_TAG_ID_MAP[getRoleId()];
    let countryList;
    if (roleTagId) {
        const defaultCountry = TAG_COUNTRY_MAP[roleTagId];
        if (availableRoleIds && availableRoleIds.length > 0) {
            countryList = availableRoleIds.map(
                (roleId) => TAG_COUNTRY_MAP[ROLE_TAG_ID_MAP[roleId]]
            );
        } else {
            countryList = [defaultCountry];
        }
    }

    useEffect(() => {
        // console.log('==countryList==', countryList);
        ApiService.getProductFinderStats().then((response: any) => {
            setViewCounts(response.data);
        });
        ApiService.getOilSelectorConfig()
            .then((response) => {
                // console.log('===respose===', response);
                let { data } = response;
                if (countryList !== undefined && countryList.length > 0) {
                    data = data.filter((elem) =>
                        countryList.includes(
                            SHORT_TAG_COUNTRY_MAP[elem.country] || elem.country
                        )
                    );
                }
                setOilSelectorCountries(
                    data.map((elem) => ({
                        countryName:
                            SHORT_TAG_COUNTRY_MAP[elem.country] || elem.country,
                        countryTag: elem.country,
                        countryTagUnique: `${elem.country}-unique`,
                        enabled: !!elem.oilSelectorEnabled,
                    }))
                );
            })
            .catch((err) =>
                console.error('===Failed: getOilSelectorConfig====', err)
            );
    }, []);

    const submit = () => {
        setDisableSubmit(true);
        const payload = oilSelectorCountries.map((country) => ({
            country: country.countryTag,
            oilSelectorEnabled: country.enabled,
        }));
        ApiService.setOilSelectorConfig(payload)
            .then((result) => {
                setDisableSubmit(false);
                setSubmitModalOpen(true);
            })
            .catch((e) => {
                console.log('=======false====', e);
                setDisableSubmit(false);
            });
    };

    return (
        <>
            <Content>
                <SuccessModal
                    header="Configure Oil Selector"
                    open={submitModalOpen}
                    onClose={() => {
                        setSubmitModalOpen(false);
                    }}
                    body="The oil selector has been configured successfully."
                />
                <Table>
                    <TableRow>
                        <TableHead>
                            <FlexGrid alignItems="center" gap="0.5em">
                                Country
                            </FlexGrid>
                        </TableHead>
                        <TableHead>Views</TableHead>
                        <TableHead>Unique Views</TableHead>
                        <TableHead>Status</TableHead>
                    </TableRow>
                    {oilSelectorCountries.map((country, index) => (
                        <TableRow key={country.countryTag}>
                            <TableCell>
                                <FlexGrid alignItems="center" gap="0.5em">
                                    <Text
                                        fontSize="0.875rem"
                                        color={COLORS.green}
                                    >
                                        {country.countryName}
                                    </Text>
                                </FlexGrid>
                            </TableCell>
                            <TableCell>
                                <FlexGrid alignItems="center" gap="0.5em">
                                    <Text
                                        fontSize="0.875rem"
                                        color={COLORS.green}
                                    >
                                        {viewCount[country.countryTag]
                                            ? viewCount[country.countryTag]
                                            : 0}
                                    </Text>
                                </FlexGrid>
                            </TableCell>
                            <TableCell>
                                <FlexGrid alignItems="center" gap="0.5em">
                                    <Text
                                        fontSize="0.875rem"
                                        color={COLORS.green}
                                    >
                                        {viewCount[country.countryTagUnique]
                                            ? viewCount[
                                                  country.countryTagUnique
                                              ]
                                            : 0}
                                    </Text>
                                </FlexGrid>
                            </TableCell>
                            <TableCell>
                                <RadioGroup
                                    value={
                                        country.enabled ? 'Enabled' : 'Disabled'
                                    }
                                    onChange={(val: string) => {
                                        const enabled = val === 'Enabled';
                                        const oilSelectorCountriesCopy = [
                                            ...oilSelectorCountries,
                                        ];
                                        oilSelectorCountriesCopy[
                                            index
                                        ].enabled = enabled;
                                        setOilSelectorCountries(
                                            oilSelectorCountriesCopy
                                        );
                                    }}
                                    options={[
                                        { label: 'Enabled', value: 'Enabled' },
                                        {
                                            label: 'Disabled',
                                            value: 'Disabled',
                                        },
                                    ]}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </Table>
            </Content>
            <Button
                disabled={disableSubmit || oilSelectorCountries.length === 0}
                onClick={submit}
                m="0em 0em 0em auto"
                p="1em 3em"
            >
                <Text uppercase font="OS">
                    Submit
                </Text>
            </Button>
        </>
    );
};
