import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { FlexGrid } from '../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../components/Table';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { Button } from '../../../components/Button';
import { ArrowDownIcon } from '../../../assets/icons';
import { Modal } from '../../../components/Modal';
// import { sampleInAppNotificationValue } from './sampleValue';
import { useInAppNotificationHook } from '../hooks/use-in-app-notification-hook';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { Pagination } from '../../../components/Pagination';
import { INTERNAL_PAGE_LINK_REVERSED, LANGUAGE_CONTENTFUL_MAP_REVERSE } from '../../../common/constants';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { getUrlKeepQuery } from '../../../utils';
import { IconButton } from '../../../components/IconButton';
import { sortFunc } from '../../../common';

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

export const InappNotificationItemToEditModel = (content: any) => {
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };
    const toReturn = {
        raw: content.contentfulRawData,
        status: content.status,

        identifierDefinition: {
            name: content.name,
            appLanguages: content.language,
            startDate:
                content.startDate && content.startDate.format('DD MMMM YYYY'),
            startTime: content.startDate && content.startDate.format('HH:mm'),
            endDate: content.endDate && content.endDate.format('DD MMMM YYYY'),
            endTime: content.endDate && content.endDate.format('HH:mm'),
        },
        content: {
            icon: content.icon || 'Comment',
            // headline: getLanField(content.lanHeadline),
            isLinkInternal: content.isLinkInternal,
            bodyText: getLanField(content.lanBodyText),
            targetLink: getLanField(content.lanTargetLink),
        },
        targetGeography: {
            regions: content.regions,
            states: content.stateprovinces,
            distributors: content.distributors,
            districts: content.district,
            pincode: content.pincode,
            rmAreas: ['A', 'B'],
        },
        targetUser: {
            userTypes: content.targetUsers,
            spaceOfMechanics: content.spaceOfMechanics,
            connekt: content.connekt,
            mobile: content.mobile,
            PID: content.pid,
            dealerSegments: content.dealerSegments,
            dealerSubSegments: content.dealerSubSegments,
            mechanicsSegments: content.mechanicsSegments,
            mechanicsSubSegments: content.mechanicsSubSegments,
        },
    };

    console.log('inAppnotifications table item', toReturn);

    return toReturn;
};

export const InAppNotificationsTable: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const DEFAULT_SORT = {
        name: 0,
        bodyText: 0,
        date: 0,
        targetLink: 0,
        views: 0,
        uniqueViews: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);
    const perPage = 5;
    const [{ hookData: notificationsContentful }] = useInAppNotificationHook(
        {
            // perPage: 10,
            // page,
            // sortBy: `${sort.headline ? '' : '-'}fields.headline`,
        },
        [update]
    );

    const notificationsObj = ContentfulTransformService.getInAppNotifications(
        notificationsContentful
    );

    // const sortFunc = (items: any[], order) => {
    //     return items.sort((x, y) => {
    //         if (x.headline < y.headline) return !order ? 1 : -1;
    //         if (x.headline > y.headline) return !order ? -1 : 1;
    //         return 0;
    //     });
    // };
    let notifications = sortFunc(sort, notificationsObj.mapped, {
        date: 'startDate',
    });
    // const notifications = notificationsObj.mapped;
    const total = notificationsObj.raw?.total || 0;

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    notifications = notifications.slice(startIndex, endIndex);

    const [deleteModal, setDeleteModal] = useState(false);

    const itemToEditModel = (key: number) => {
        return InappNotificationItemToEditModel(notifications[key]);
    };

    return (
        <>
            <Modal
                open={deleteModal}
                header="Delete APP Notification"
                onClose={() => setDeleteModal(false)}
                content={
                    <FlexGrid
                        direction="column"
                        width="100%"
                        gap="2em"
                        p="2em 0em"
                    >
                        <Text color={COLORS.darkGray}>
                            Are you sure you want to delete this notification?
                        </Text>
                        <Text color={COLORS.darkGray}>
                            <Text color={COLORS.darkGray} span font="AR Bold">
                                Note:{' '}
                            </Text>
                            Deleting notification is a permanent action and
                            cannot be undone.
                        </Text>
                    </FlexGrid>
                }
                actions={
                    <FlexGrid alignItems="center">
                        <Button
                            onClick={() => {
                                ContentfulApiService.deleteContent(
                                    current.id
                                ).then(() => {
                                    setUpdate(!update);
                                    if (total % perPage === 1) {
                                        setPage(Math.max(page - 1, 1));
                                    }
                                });
                                setDeleteModal(false);
                            }}
                            p="1.3em 2.5em"
                        >
                            DELETE
                        </Button>
                        <Button
                            onClick={() => setDeleteModal(false)}
                            negative
                            p="0.5em 1em"
                        >
                            CANCEL
                        </Button>
                    </FlexGrid>
                }
            />
            <Table>
                <TableRow>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Name
                            {sort.name === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: 1 });
                                    }}
                                />
                            )}
                            {sort.name === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: -1 });
                                    }}
                                />
                            )}
                            {sort.name === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: 0 });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Body Text
                            {sort.bodyText === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            bodyText: 1,
                                        });
                                    }}
                                />
                            )}
                            {sort.bodyText === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            bodyText: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.bodyText === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            bodyText: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Date
                            {sort.date === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, date: 1 });
                                    }}
                                />
                            )}
                            {sort.date === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, date: -1 });
                                    }}
                                />
                            )}
                            {sort.date === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, date: 0 });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Target Link
                            {sort.targetLink === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            targetLink: 1,
                                        });
                                    }}
                                />
                            )}
                            {sort.targetLink === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            targetLink: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.targetLink === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            targetLink: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Views
                            {sort.views === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, views: 1 });
                                    }}
                                />
                            )}
                            {sort.views === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, views: -1 });
                                    }}
                                />
                            )}
                            {sort.views === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, views: 0 });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Unique Views
                            {sort.uniqueViews === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            uniqueViews: 1,
                                        });
                                    }}
                                />
                            )}
                            {sort.uniqueViews === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            uniqueViews: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.uniqueViews === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            uniqueViews: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>Actions</TableHead>
                </TableRow>
                {notifications.map((notification, key) => (
                    <TableRow key={key}>
                        <TableCell verticalAlign="top" width="250px">
                            <Text
                                color={COLORS.green}
                                fontSize="0.875rem"
                                workBreakAll
                            >
                                {notification.name}
                            </Text>
                        </TableCell>
                        <TableCell verticalAlign="top" width="350px">
                            {notification.bodyText}
                        </TableCell>
                        <TableCell verticalAlign="top">
                            <FlexGrid direction="column">
                                <Text fontSize="0.875rem">
                                    {notification.date}
                                </Text>
                                <Text fontSize="0.875rem">
                                    {notification.status}
                                </Text>
                            </FlexGrid>
                        </TableCell>
                        <TableCell verticalAlign="top">
                            <Text color={COLORS.green} fontSize="0.875rem">
                                {notification.isLinkInternal
                                    ? INTERNAL_PAGE_LINK_REVERSED[
                                          notification.targetLink
                                      ] || notification.targetLink
                                    : notification.targetLink}
                            </Text>
                        </TableCell>
                        <TableCell>{notification.views ?? 0}</TableCell>
                        <TableCell>{notification.uniqueViews ?? 0}</TableCell>
                        <TableCell verticalAlign="top">
                            <FlexGrid alignItems="center">
                                <TableActionBtn
                                    onClick={() =>
                                        navigate(
                                            getUrlKeepQuery(
                                                '/communication/in-app-notifications/create'
                                            ),
                                            {
                                                state: {
                                                    data: itemToEditModel(key),
                                                    page,
                                                    viewOnly: true,
                                                },
                                            }
                                        )
                                    }
                                    negative
                                >
                                    View
                                </TableActionBtn>
                                <TableActionBtn
                                    onClick={() =>
                                        navigate(
                                            getUrlKeepQuery(
                                                '/communication/in-app-notifications/create'
                                            ),
                                            {
                                                state: {
                                                    data: itemToEditModel(key),
                                                    page,
                                                },
                                            }
                                        )
                                    }
                                    negative
                                >
                                    Edit
                                </TableActionBtn>
                                <TableActionBtn
                                    onClick={() => {
                                        setDeleteModal(true);
                                        setCurrent(notification);
                                    }}
                                    negative
                                >
                                    Delete
                                </TableActionBtn>
                            </FlexGrid>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
            <Pagination
                page={page}
                perPage={perPage}
                total={total}
                setPage={(v) => {
                    setPage(v);
                }}
            />
        </>
    );
};
