import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgNewFolderArticle: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path data-name="Path 4836" d="M0 0h24v24H0Z" fill="none" />
        <path
            className="icon-fill"
            data-name="Path 4837"
            d="M20 6h-8l-2-2H4a1.985 1.985 0 0 0-1.99 2L2 18a1.993 1.993 0 0 0 2 2h16a1.993 1.993 0 0 0 2-2V8a1.993 1.993 0 0 0-2-2Zm0 12H4V6h5.17l2 2H20Zm-8-4h2v2h2v-2h2v-2h-2v-2h-2v2h-2Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgNewFolderArticle;
