import * as React from 'react';
import './logo.css';

interface Props {
    width?: string;
    height?: string;
    style?: any;
}

const SvgLogoCastrolNotif: React.FC<Props> = ({ width, height, style }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={width || 307}
        height={height || 53}
        viewBox="0 0 307 53"
        style={style}
        // style={{
        //     backgroundColor: 'white',
        //     height: 'inherit',
        //     padding: '0px 20px',
        // }}
    >
        <svg
            id="Layer_2"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 583.69 71.58"
        >
            <g id="Layer_1-2" data-name="Layer 1">
                <g id="Group_839" data-name="Group 839">
                    <g id="Group_830" data-name="Group 830">
                        <path
                            id="Path_546"
                            data-name="Path 546"
                            className="cls-1"
                            d="m33.42,23.91c-2.86,0-4.01-1.07-3.58-3.51L33.71.08c.69-.06,1.38-.09,2.07-.07,15.2-.02,28.75,9.56,33.78,23.9h-36.13ZM0,35.79C.02,20.79,9.39,7.39,23.48,2.23l-11.09,25.41c-5.22,12.02,1.36,20.33,16.6,20.33h40.43c-6.72,18.58-27.23,28.2-45.81,21.48C9.44,64.32,0,50.86,0,35.79"
                        />
                        <path
                            id="Path_547"
                            data-name="Path 547"
                            className="cls-2"
                            d="m29.85,41.22c-10.52,0-15.24-5.79-12.02-14.96L26.7,1.15c1.35-.33,2.71-.59,4.08-.79l-5.73,21.76c-1.57,6.08.43,8.52,6.94,8.52h39.22c.23,1.71.35,3.43.36,5.15,0,1.82-.14,3.64-.43,5.44H29.85Z"
                        />
                        <path
                            id="Path_548"
                            data-name="Path 548"
                            className="cls-2"
                            d="m83.38,39.11c-.44-13.98,10.54-25.67,24.52-26.11.39-.01.78-.02,1.17,0,8.92-.15,16.92,5.49,19.75,13.96l-10.31,2.93c-1.47-4.47-5.75-7.4-10.45-7.16-8.52,0-14.67,6.51-14.67,15.46-.4,6.05,4.17,11.27,10.22,11.67.53.04,1.06.03,1.59,0,4.92.05,9.44-2.73,11.59-7.16l9.09,4.58c-4.45,7.77-12.74,12.53-21.68,12.45-11,.5-20.32-8.02-20.82-19.02-.02-.53-.03-1.06,0-1.6"
                        />
                        <path
                            id="Path_549"
                            data-name="Path 549"
                            className="cls-2"
                            d="m157.31,39.62c.19-4.68-3.44-8.62-8.12-8.81-.3-.01-.6,0-.9.01-6.05,0-10.96,4.9-10.96,10.95,0,.14,0,.29,0,.43-.26,4.61,3.27,8.55,7.88,8.81.33.02.66.02.99,0,6.09.03,11.06-4.88,11.1-10.97,0-.14,0-.27,0-.41m-29.27,3.51c0-11.59,8.8-21.11,19.47-21.11,4.37-.31,8.6,1.66,11.16,5.22l1.86-4.22h7.8l-3.79,35.78h-9.23l.5-4.22c-3.11,3.46-7.59,5.37-12.24,5.22-8.87,0-15.53-7.23-15.53-16.68"
                        />
                        <path
                            id="Path_550"
                            data-name="Path 550"
                            className="cls-2"
                            d="m206.83,46.77l1.57-14.88h-6.23l.93-8.87h6.23l.79-7.23,9.52-2.79-1.07,10.02h8.09l-.93,8.87h-8.09l-1.57,14.88c-.29,2.93,1.29,3.79,4.87,3.79.79,0,1.86-.07,2.86-.14l-.93,8.37c-1.59.19-3.19.29-4.79.29-9.3,0-12.1-4.08-11.24-12.31"
                        />
                        <path
                            id="Path_551"
                            data-name="Path 551"
                            className="cls-2"
                            d="m227.8,58.79l3.79-35.78h7.8l1,4.22c2.91-3.2,7.05-5,11.38-4.94l-1,9.59h-1.5c-5.39-.32-10.03,3.77-10.38,9.16l-1.86,17.75h-9.23Z"
                        />
                        <path
                            id="Path_552"
                            data-name="Path 552"
                            className="cls-2"
                            d="m279.12,39.62c.24-4.51-3.22-8.35-7.73-8.59-.31-.02-.62-.02-.93,0-5.93,0-10.74,4.8-10.74,10.73,0,.14,0,.29,0,.43-.24,4.51,3.22,8.35,7.73,8.59.31.02.62.02.93,0,5.93,0,10.74-4.79,10.74-10.72,0-.15,0-.29,0-.44m-28.7,3.36c-.06-11.52,9.23-20.91,20.75-20.97.07,0,.14,0,.22,0,9.08-.32,16.71,6.78,17.03,15.87.01.32.01.64,0,.95.06,11.52-9.23,20.91-20.75,20.97-.07,0-.14,0-.22,0-9.08.32-16.71-6.78-17.03-15.87-.01-.32-.01-.64,0-.95"
                        />
                        <path
                            id="Path_553"
                            data-name="Path 553"
                            className="cls-2"
                            d="m290.57,58.79l4.51-43.01,9.51-2.79-4.79,45.8h-9.22Z"
                        />
                        <path
                            id="Path_554"
                            data-name="Path 554"
                            className="cls-2"
                            d="m168.04,49.92l9.38-3.15c.93,2.9,3.69,4.81,6.73,4.65,3.22,0,5.01-1.22,5.01-2.93,0-5.15-16.96-2.79-16.96-13.88,0-7.3,6.3-12.6,14.88-12.6,6.01-.09,11.25,4.07,12.52,9.95l-9.09,2.58c-.72-2.86-2.29-4.29-4.72-4.29s-4.08,1.36-4.08,3.08c0,5.08,17.18,2.07,17.18,14.03,0,7.37-6.37,12.45-15.89,12.45-7.51,0-12.6-3.36-14.96-9.88"
                        />
                    </g>
                    <g id="FastScan" className="cls-3">
                        <g className="cls-3">
                            <path
                                className="cls-1"
                                d="m327.47,58.86h-6.65l6.97-43.85h29.9l-1,6.17h-23.25l-1.94,12.24h18.27l-1,6.17h-18.24l-3.07,19.28Z"
                            />
                            <path
                                className="cls-1"
                                d="m353.63,45.69c0-10.78,8.39-18.47,18.05-18.47,5.17,0,8.27,1.94,10.04,4.88l.68-4.23h6.26l-4.91,31h-6.26l.68-4.23c-2.71,2.94-6.46,4.88-11.59,4.88-7.68,0-12.95-5.71-12.95-13.82Zm17.89-12.72c-6.52,0-11.53,5.39-11.53,11.82,0,4.94,3.07,8.98,8.23,8.98,6.49,0,11.85-5.68,11.85-12.01,0-4.94-3.33-8.78-8.56-8.78Z"
                            />
                            <path
                                className="cls-1"
                                d="m408.46,40.91c5.26,1.13,8.14,3.49,8.14,7.88,0,7.2-5.84,10.72-13.98,10.72-4.62,0-8.78-.87-11.2-2.32l1.65-5.52c2.87,1.39,6.65,2.23,10.01,2.23,5.04,0,7.3-1.36,7.3-4,0-1.81-1.52-2.84-4.97-3.58l-4.36-.94c-4.52-.97-6.91-3.68-6.91-7.62,0-6.13,6.04-10.53,14.27-10.53,3.71,0,7.56.74,9.91,1.87l-1.55,5.55c-2.29-1.1-5.78-1.81-8.94-1.81-4.97,0-7.49,1.52-7.49,4.04,0,1.65,1.36,2.55,4.71,3.29l3.42.74Z"
                            />
                            <path
                                className="cls-1"
                                d="m434.07,33.29l-2.45,15.5c-.52,3.29,1.1,4.94,4.2,4.94,1.45,0,3.49-.29,5.04-.74l-.03,5.49c-1.62.61-4.36,1.03-6.81,1.03-6.36,0-9.75-3.65-8.78-9.94l2.58-16.27h-5.39l.84-5.42h5.52l1.58-9.94h6.1l-1.58,9.94h9.01l-.84,5.42h-8.98Z"
                            />
                            <path
                                className="cls-1"
                                d="m480.03,16.76l-1.58,6.43c-2.68-1.68-7.2-2.65-11.27-2.65-6.2,0-10.4,2.29-10.4,7.2,0,3.71,3.07,4.39,6.04,5.33l5.97,1.91c5.36,1.68,8.78,4.36,8.78,9.62,0,8.4-7.65,14.92-17.73,14.92-5.81,0-12.27-1.29-15.08-3.04l1.55-6.23c2.91,1.52,8.94,3.04,13.75,3.04,5.62,0,10.2-2.58,10.2-7.49,0-2.74-1.65-4.07-5.13-5.1l-6.59-1.97c-4.42-1.36-9.07-3.91-9.07-9.65,0-8.43,6.81-14.69,17.86-14.69,5.81,0,9.56,1.07,12.69,2.39Z"
                            />
                            <path
                                className="cls-1"
                                d="m502.41,27.22c4,0,7.49,1.26,8.78,2.07l-1.58,5.71c-2-1-4.68-1.84-8.14-1.84-7.2,0-12.69,4.84-12.69,11.56,0,5.23,3.65,8.85,9.49,8.85,2.87,0,6.23-.78,8.81-1.91l.03,5.81c-3.1,1.16-6.36,2.03-9.78,2.03-9.17,0-14.92-5.62-14.92-13.92,0-10.72,8.62-18.37,19.99-18.37Z"
                            />
                            <path
                                className="cls-1"
                                d="m513.01,45.69c0-10.78,8.39-18.47,18.05-18.47,5.17,0,8.27,1.94,10.04,4.88l.68-4.23h6.26l-4.91,31h-6.26l.68-4.23c-2.71,2.94-6.46,4.88-11.59,4.88-7.68,0-12.95-5.71-12.95-13.82Zm17.89-12.72c-6.52,0-11.53,5.39-11.53,11.82,0,4.94,3.07,8.98,8.23,8.98,6.49,0,11.85-5.68,11.85-12.01,0-4.94-3.33-8.78-8.56-8.78Z"
                            />
                            <path
                                className="cls-1"
                                d="m583.46,40.04l-2.97,18.82h-6.26l2.78-17.47c.81-5.13-1.49-8.1-6.2-8.1s-8.85,2.97-9.65,8.1l-2.78,17.47h-6.26l4.91-31h6.3l-.61,3.91c1.26-1.58,4.62-4.55,10.04-4.55,8.36,0,11.91,5.29,10.72,12.82Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </svg>
);

export default SvgLogoCastrolNotif;
