import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgStarCircle: React.FC<Props> = ({ width, height, color }) => (
    <svg
        data-name="Component 219 \u2013 19"
        xmlns="http://www.w3.org/2000/svg"
        width={width || 30}
        height={height || 30}
        viewBox="0 0 30 30"
    >
        <path data-name="Rectangle 429" fill="none" d="M0 0h30v30H0z" />
        <path
            className="icon-fill"
            data-name="Path 384"
            d="M15.007 28a12.451 12.451 0 0 0 5.018-1.024 13.328 13.328 0 0 0 6.958-6.958 12.866 12.866 0 0 0-.007-10.043A13.4 13.4 0 0 0 24.16 5.82a13.077 13.077 0 0 0-4.148-2.81A12.593 12.593 0 0 0 14.994 2a12.545 12.545 0 0 0-5.011 1.01 13.29 13.29 0 0 0-6.965 6.965 12.866 12.866 0 0 0 .007 10.043 13.39 13.39 0 0 0 6.958 6.958A12.437 12.437 0 0 0 15.007 28ZM11.3 21.724a.954.954 0 0 1-.743.214.731.731 0 0 1-.529-.381.944.944 0 0 1-.027-.769l1.472-4.362-3.745-2.674a.89.89 0 0 1-.408-.622.812.812 0 0 1 .187-.649.863.863 0 0 1 .729-.268l4.59.027 1.392-4.388a.921.921 0 0 1 .468-.589.737.737 0 0 1 .642 0 .88.88 0 0 1 .455.589l1.405 4.389 4.59-.027a.861.861 0 0 1 .736.268.8.8 0 0 1 .174.649.924.924 0 0 1-.4.622l-3.748 2.672 1.472 4.362a.957.957 0 0 1-.027.776.735.735 0 0 1-.529.375.992.992 0 0 1-.756-.214l-3.693-2.716Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgStarCircle;
