import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgComment: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 30}
        height={height || 30}
        viewBox="0 0 30 30"
    >
        <path data-name="Rectangle 428" fill="none" d="M0 0h30v30H0z" />
        <path
            className="icon-fill"
            data-name="Path 382"
            d="M15.007 28a12.451 12.451 0 0 0 5.018-1.024 13.328 13.328 0 0 0 6.958-6.958 12.866 12.866 0 0 0-.007-10.043 13.4 13.4 0 0 0-2.816-4.154 13.077 13.077 0 0 0-4.148-2.81 12.593 12.593 0 0 0-5.018-1.01 12.545 12.545 0 0 0-5.011 1.01 13.29 13.29 0 0 0-6.965 6.965 12.866 12.866 0 0 0 .007 10.043 13.39 13.39 0 0 0 6.958 6.958A12.437 12.437 0 0 0 15.007 28Zm0-7.882h-.428a2.742 2.742 0 0 1-.388-.027.58.58 0 0 0-.361.12 13.569 13.569 0 0 1-1.867.963 4.024 4.024 0 0 1-1.425.375q-.442 0-.522-.281a.625.625 0 0 1 .187-.589l.448-.542a3.137 3.137 0 0 0 .408-.636q.134-.254-.08-.375a7.094 7.094 0 0 1-2.254-2.083 4.791 4.791 0 0 1-.863-2.77 4.935 4.935 0 0 1 .95-2.944 6.648 6.648 0 0 1 2.569-2.108 8.223 8.223 0 0 1 3.626-.783 8.18 8.18 0 0 1 3.62.783 6.714 6.714 0 0 1 2.569 2.108 5.018 5.018 0 0 1 0 5.894 6.689 6.689 0 0 1-2.569 2.114 8.18 8.18 0 0 1-3.62.782Z"
            fill={color || '#636363'}
        />
    </svg>
);

export default SvgComment;
