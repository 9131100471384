import React from 'react';
import styled from 'styled-components';
import { CheckCircleBigIcon } from '../assets/icons';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { Modal } from './Modal';
import { Text } from './Text';

interface SuccessModalProps {
    header: string;
    body: string;
    onClose: () => void;
    open: boolean;
}

const BodyText = styled(Text)`
    flex-shrink: 0;
`;

export const SuccessModal: React.FC<SuccessModalProps> = ({
    header,
    body,
    onClose,
    open,
}) => {
    return (
        <Modal
            header={header}
            open={open}
            onClose={onClose}
            width="530px"
            actions={
                <Button onClick={onClose} p="1.1em 3em" m="0 0 2em 0">
                    Done
                </Button>
            }
            content={
                <FlexGrid
                    flex="0 0 auto"
                    width="100%"
                    alignItems="center"
                    gap="0.5em"
                    p="1em 0em 3.5em"
                >
                    <CheckCircleBigIcon height="30" />
                    <BodyText color={COLORS.darkGray}>{body}</BodyText>
                </FlexGrid>
            }
        />
    );
};
