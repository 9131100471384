import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { Input } from '../../../components/Input';
import { Modal } from '../../../components/Modal';
import { CheckCircleBigIcon } from '../../../assets/icons';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { LIMIT_DEFAULT_TEXT_LENGTH } from '../../../common/constants';
import { ScanningContestProductPayload } from '../../../services/Api.service';

interface ProductModalProps {
    open: boolean;
    onConfirm?: (data: {
        brandName: string;
        skuCode: string;
        productName: string;
    }) => void;
    onClose?: () => void;
    title: string;
    success: boolean | null;
    setSuccess: (v: boolean | null) => void;
    errorMsg: string | undefined;
    setErrorMsg: (v: string | undefined) => void;
    disabled?: boolean;
    successContentText: string;
    params?: ScanningContestProductPayload;
}

const ActionButton = styled(Button)`
    font-family: 'OS';
    font-size: 1rem;
`;

const BorderBottom = styled(FlexGrid)`
    padding-bottom: 2.5em;
    border-bottom: 1px solid ${COLORS.lightGray};
    gap: 1em;
`;

export const ProductModal: React.FC<ProductModalProps> = ({
    open,
    onClose,
    onConfirm,
    success = null,
    setSuccess,
    errorMsg = undefined,
    setErrorMsg,
    successContentText,
    title,
    disabled,
    params,
}) => {
    const [brandName, setBrandName] = useState(undefined);
    const [skuCode, setSKUCode] = useState(undefined);
    const [productName, setProductName] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);

    const dataIncomplete =
        disabled ||
        !brandName ||
        brandName?.trim() === '' ||
        !skuCode ||
        skuCode?.trim() === '' ||
        !productName ||
        productName?.trim() === '';

    const reset = () => {
        if (onClose) {
            onClose();
        }
        setBrandName(undefined);
        setSKUCode(undefined);
        setProductName(undefined);
        setSuccess(null);
        setErrorMsg(undefined);
        setSubmitted(false);
    };

    useEffect(() => {
        if (submitted) {
            return;
        }
        setBrandName(params?.brandName ?? '');
        setSKUCode(params?.skuCode ?? '');
        setProductName(params?.productName ?? '');
    }, [params]);

    const successContent = (
        <FlexGrid
            p="1em 0em 1em 0em"
            alignItems="center"
            width="100%"
            gap="0.5em"
            m="0em 0em 2em 0em"
        >
            <CheckCircleBigIcon height="36" />
            <Text color={COLORS.darkGray}>{successContentText}</Text>
        </FlexGrid>
    );

    const inputContent = (
        <BorderBottom direction="column" width="100%" p="1em 0em">
            <Input
                disabled={disabled}
                maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                label="Brand Name"
                value={brandName ?? params?.brandName ?? ''}
                onChange={(val) => setBrandName(val)}
            />
            <Input
                disabled={disabled}
                maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                label="SKU Code"
                value={skuCode ?? params?.skuCode ?? ''}
                onChange={(val) => setSKUCode(val)}
            />
            <Input
                disabled={disabled}
                maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                label="Product Name"
                value={productName ?? params?.productName ?? ''}
                onChange={(val) => setProductName(val)}
            />
            {errorMsg && <Text
                link
                fontSize="0.875rem"
                color={COLORS.red}
            >
                {errorMsg}
            </Text>}
        </BorderBottom>
    );

    const inputActions = (
        <FlexGrid alignItems="center">
            <ActionButton
                disabled={dataIncomplete}
                onClick={() => {
                    setSubmitted(true);
                    onConfirm && onConfirm({ brandName, skuCode, productName });
                }}
                p="0.6em 2em"
            >
                {params ? 'Update' : 'Add'} product
            </ActionButton>
            <ActionButton onClick={reset} negative>
                Cancel
            </ActionButton>
        </FlexGrid>
    );

    const successActions = (
        <ActionButton onClick={reset} p="0.6em 2.5em" m="0 0 2em 0">
            Done
        </ActionButton>
    );

    return (
        <Modal
            open={open}
            header={title}
            onClose={reset}
            content={success ? successContent : inputContent}
            actions={success ? successActions : inputActions}
        />
    );
};
