import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgLove: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 30}
        height={height || 30}
        viewBox="0 0 30 30"
    >
        <path data-name="Rectangle 429" fill="none" d="M0 0h30v30H0z" />
        <path
            className="icon-fill"
            data-name="Path 443"
            d="M15.007 28a12.451 12.451 0 0 0 5.018-1.024 13.328 13.328 0 0 0 6.958-6.958 12.866 12.866 0 0 0-.007-10.043A13.4 13.4 0 0 0 24.16 5.82a13.077 13.077 0 0 0-4.148-2.81A12.593 12.593 0 0 0 14.994 2a12.545 12.545 0 0 0-5.011 1.01 13.29 13.29 0 0 0-6.965 6.965 12.866 12.866 0 0 0 .007 10.043 13.39 13.39 0 0 0 6.958 6.958A12.437 12.437 0 0 0 15.007 28ZM11.876 9.239a3.155 3.155 0 0 1 1.847.549 4.275 4.275 0 0 1 1.285 1.418 4.119 4.119 0 0 1 1.278-1.418 3.163 3.163 0 0 1 1.84-.549 3.753 3.753 0 0 1 1.954.508 3.6 3.6 0 0 1 1.345 1.412 4.277 4.277 0 0 1 .488 2.067 5.418 5.418 0 0 1-.4 2.041 8.409 8.409 0 0 1-1.077 1.9 14.189 14.189 0 0 1-1.5 1.7 18.968 18.968 0 0 1-1.673 1.445q-.85.649-1.586 1.131-.161.107-.335.207a.651.651 0 0 1-.321.1.778.778 0 0 1-.321-.1q-.2-.1-.375-.207a19.151 19.151 0 0 1-1.592-1.124 21.3 21.3 0 0 1-1.666-1.445 13.531 13.531 0 0 1-1.5-1.7 8.549 8.549 0 0 1-1.077-1.907 5.418 5.418 0 0 1-.4-2.041 4.277 4.277 0 0 1 .488-2.067 3.6 3.6 0 0 1 1.344-1.407 3.753 3.753 0 0 1 1.954-.513Z"
            fill={color || '#636363'}
        />
    </svg>
);

export default SvgLove;
