import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { FlexGrid } from '../../../../components/FlexGrid';
import { PageHeader } from '../../../../components/PageHeader';
import { Text } from '../../../../components/Text';
import { COLORS, COMMON } from '../../../../styles/variables';

import { CheckIcon, DeleteIcon } from '../../../../assets/icons';
import { PageWrapper } from '../../../../components/PageWrapper';
import { HorizontalLine } from '../../../../components/HorizontalLine';
import { IdentifierDefinition } from './IdentifierDefinition';
import { TargetGeography } from './TargetGeography';
import { TargetUser } from './TargetUser';
import { SendNotication } from './SendNotification';
import { Review } from './Review';
import { LearningCardType } from '../../../../types';
import { useDropdownPopulateHook } from '../../../../hooks/api/use-dropdown-populate-hook';
import { GLOBAL_CONFIG } from '../../../../config/config';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../../store/store.actions';
import { LearningCardDefintion } from './LearningCardDefinition';
import { CreationService } from '../../../../services/Creation.service';
import { AddQuiz } from './AddQuiz';

const SaveDraftBtn = styled(Button)`
    border-radius: 0;
    margin: 5px 0;
    padding: 0.2em 1em;
    border-left: 1px solid ${COLORS.lightGray};
    border-right: 1px solid ${COLORS.lightGray};
    font-family: 'OS Bold';
    font-size: 1rem;
`;

const PageContent = styled(FlexGrid)`
    background-color: ${COLORS.veryLightGray};
    flex: 1;
    padding: 2em;
    gap: 2em;
`;

const Card = styled(FlexGrid)`
    background-color: ${COLORS.white};
    box-shadow: ${COMMON.shadow};
    border-radius: 5px;
`;

const ProgressCard = styled(Card)`
    width: 300px;
    padding: 1em 2em;
`;

const FormCard = styled(Card)`
    flex: 1;
    min-height: 500px;
`;

const Head = styled(Text)`
    color: ${COLORS.darkGray};
    padding: 1em 0em 0.5em;
    width: 100%;
`;

interface StepNumberProps {
    active?: boolean;
}

const StepNumber = styled(FlexGrid)<StepNumberProps>`
    width: 30px;
    height: 30px;
    justify-content: center;
    border-radius: 100%;
    align-items: center;
    background-color: ${({ active }) =>
        active ? COLORS.green : COLORS.veryLightGray};
    color: ${({ active }) => (active ? COLORS.white : COLORS.darkGray)};
    font-family: ${({ active }) => (active ? 'AR Bold' : 'AR')};
`;

interface LocationState {
    data: LearningCardType;
    page: number;
    viewOnly?: boolean;
    quizEdit?: boolean;
}

export const CreateLearningCard = () => {
    const defaultValue: LearningCardType = {
        identifierDefinition: {
            name: '',
            appLanguages: [],
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
        },
        content: {
            tags: [],
            slides: [
                {
                    content: {
                        image: null,
                        headline: {},
                        description: {},
                        isFromVideo: false,
                    }
                }
            ],
            headline: {},
            description: {},
            referencedSection: null,
            prevReferencedSection: null,
        },
        targetGeography: {
            regions: [],
            states: [],
            distributors: [],
            districts: [],
            pincode: null,
            rmAreas: [],
        },
        targetUser: {
            userTypes: [],
            spaceOfMechanics: [],
            connekt: false,
            mobile: null,
            PID: null,
            dealerSegments: [],
            dealerSubSegments: [],
            mechanicsSegments: [],
            mechanicsSubSegments: [],
        },
        sendNotification: {
            notificationType: [],
            icon: '',
            inAppNotificationContent: {
                bodyText: {},
            },
            pushNotificationContent: {
                headline: {},
                bodyText: {},
            },
            // headline: {},
            // bodyText: {},
            targetLink: {},
            isLinkInternal: true,
        },
    };

    const [{ hookData: dropdownPopulate }] = useDropdownPopulateHook();
    const location = useLocation();
    const state = location.state as LocationState;
    const initLearningCardData = state?.data || defaultValue;
    const learningCardState = useState<LearningCardType>(initLearningCardData);
    const viewOnlyMode = state?.viewOnly;
    const quizEditMode = state?.quizEdit;
    const steps = [
        'Identifier Definition',
        'Content Definition',
        'Target Geography',
        'Target User',
        'Add Quiz',
        'Send Notification',
        'Review',
    ];
    const [activeStep, setActiveStep] = useState(
        quizEditMode ? 4 : viewOnlyMode ? steps.length - 1 : 0
    );

    const stepsComponent = [
        <IdentifierDefinition
            learningCardState={learningCardState}
            initLearningCardData={initLearningCardData}
            stepNavigator={setActiveStep}
        />,
        <LearningCardDefintion
            learningCardState={learningCardState}
            initLearningCardData={initLearningCardData}
            stepNavigator={setActiveStep}
        />,
        <TargetGeography
            dropdownPopulate={dropdownPopulate}
            learningCardState={learningCardState}
            stepNavigator={setActiveStep}
        />,
        <TargetUser
            dropdownPopulate={dropdownPopulate}
            learningCardState={learningCardState}
            stepNavigator={setActiveStep}
        />,
        <AddQuiz
            learningCardState={learningCardState}
            initLearningCardData={initLearningCardData}
            stepNavigator={setActiveStep}
        />,
        <SendNotication
            learningCardState={learningCardState}
            stepNavigator={setActiveStep}
        />,
        <Review
            mode={viewOnlyMode ? 'View' : !!state?.data ? 'Edit' : 'Create'}
            learningCardState={learningCardState}
            stepNavigator={setActiveStep}
            dropdownPopulate={dropdownPopulate}
        />,
    ];

    const percentage = (activeStep / (steps.length - 1)) * 100;
    const dispatch = useDispatch();

    const saveAsDraft = () => {
        if (GLOBAL_CONFIG.SAVE_AS_DRAFT_TO_LOCAL) {
            const s = JSON.stringify(learningCardState[0]);
            localStorage.setItem('fastscan-learningCards-draft', s);
        } else {
            dispatch(
                updateModalAction({
                    open: true,
                    noAction: true,
                    title: 'Save as draft',
                    content: 'Saving content as draft...',
                })
            );
            CreationService.createUpdateLearningCard(
                !!learningCardState[0].raw,
                false,
                learningCardState[0]
            )
                .then((result) => {
                    learningCardState[0].raw = result;
                    dispatch(
                        updateModalAction({
                            open: true,
                            title: 'Save as draft',
                            content: 'Saved',
                        })
                    );
                })
                .catch((e) => {
                    dispatch(
                        updateModalAction({
                            open: true,
                            title: 'Error',
                            content: `${e}`,
                        })
                    );
                });
        }
    };

    const navigate = useNavigate();

    const onDelete = () => {
        if (GLOBAL_CONFIG.SAVE_AS_DRAFT_TO_LOCAL) {
            localStorage.setItem('fastscan-learningCards-draft', '');
        }

        learningCardState[1](defaultValue);
        // setActiveStep(0);
        navigate(-1);
    };

    useEffect(() => {
        if (GLOBAL_CONFIG.SAVE_AS_DRAFT_TO_LOCAL) {
            const draftS = localStorage.getItem('fastscan-learningCards-draft');
            if (draftS) {
                const draft = JSON.parse(draftS);
                learningCardState[1](draft);
            }
        }
    }, []);

    return (
        <PageWrapper>
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.5rem" uppercase={false}>
                        {viewOnlyMode
                            ? 'View '
                            : state?.data
                            ? 'Edit '
                            : 'Create New '}
                        Learning Card
                    </Text>
                }
                actions={
                    <FlexGrid gap="1em" alignItems="center">
                        <Text fontSize="0.875rem" color={COLORS.darkGray}>
                            <Text fontSize="0.875rem" font="AR Bold" span>
                                ID:
                            </Text>{`  ${learningCardState[0]?.raw?.sys?.id || "[Pending...]"}`}
                        </Text>
                        <SaveDraftBtn negative onClick={saveAsDraft}>
                            SAVE AS DRAFT
                        </SaveDraftBtn>
                        <Button negative onClick={onDelete}>
                            <DeleteIcon />
                        </Button>
                    </FlexGrid>
                }
            />
            <PageContent width="100%" justifyContent="center">
                <ProgressCard direction="column">
                    <Head>{percentage.toFixed(2)}% Complete</Head>
                    <HorizontalLine
                        backgroundColor={COLORS.lightGray}
                        height="3px"
                        progressColor={COLORS.green}
                        progressPercentage={`${percentage}%`}
                    />
                    <FlexGrid direction="column" gap="1.5em" p="1.5em 0em">
                        {steps.map((step, i) => (
                            <FlexGrid key={`learningCard-flexgrid-${i}`} gap="1em" alignItems="center">
                                {i < activeStep ? (
                                    <StepNumber active>
                                        <CheckIcon color={COLORS.white} />
                                    </StepNumber>
                                ) : i === activeStep ? (
                                    <StepNumber active>{i + 1}</StepNumber>
                                ) : (
                                    <StepNumber>{i + 1}</StepNumber>
                                )}
                                <Text
                                    font={i === activeStep ? 'AR Bold' : 'AR'}
                                    color={i < activeStep ? 'green' : 'black'}
                                >
                                    {step}
                                </Text>
                            </FlexGrid>
                        ))}
                    </FlexGrid>
                </ProgressCard>
                <FormCard>{stepsComponent[activeStep]}</FormCard>
            </PageContent>
        </PageWrapper>
    );
};
