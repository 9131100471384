import React from 'react';
import styled from 'styled-components';
import { FlexGrid } from './FlexGrid';
import { COLORS } from '../styles/variables';
import { FileIcon } from '../assets/icons';
import { Text } from './Text';

const Container = styled(FlexGrid)`
    width: 100%;
    padding: 1em;
    background-color: ${COLORS.veryLightGreen};
    color: ${COLORS.green};
    gap: 1em;
    border-radius: 10px;
`;

interface DownloadTemplateProps {
    title: string;
    buttonText: string;
}

export const DownloadTemplate: React.FC<DownloadTemplateProps> = ({
    title,
    buttonText,
}) => {
    return (
        <Container>
            <FlexGrid m="0.5em 0em">
                <FileIcon />
            </FlexGrid>
            <FlexGrid direction="column" flex="1" gap="0.5em">
                <Text uppercase font="OS" color={COLORS.green}>
                    {title}
                </Text>
                <FlexGrid width="80%">
                    <Text fontSize="0.75rem">
                        You can use this template in order to{' '}
                        <Text
                            fontSize="0.75rem"
                            link
                            span
                            color={COLORS.darkRed}
                            font="AR Bold"
                        >
                            {buttonText}
                        </Text>{' '}
                        to the system.
                    </Text>
                </FlexGrid>
            </FlexGrid>
        </Container>
    );
};
