// import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

// import { GLOBAL_CONFIG } from '../../../config/config';
import { IPagination } from '../../../types';
// import { ROLE_TAG_ID_MAP } from '../../../common/constants';
// import { getMetaTag, getRoleId } from '../../../utils';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { getAssetIdsParams } from '../../../common';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { ApiService } from '../../../services/Api.service';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    
    const banners = await ContentfulApiService.searchContent({
        contentType: 'banner',
        limit: props.input.pagination.perPage,
        page: props.input.pagination.page,
        sortBy: props.input.pagination.sortBy
    })

    // order: '-sys.createdAt'

    ContentfulApiService.getAssets({
        ...getAssetIdsParams(
            ContentfulTransformService.getBannersAssets(banners.items)
        ),
    }).then((res) => {
        banners.assets = res;
        props.updateData({ ...banners });
    });

    // banners.items.map((banner) => {
    //     ApiService.getViews(
    //         banner.sys.id,
    //         banner.sys.space.sys.id,
    //         banner.sys.environment.sys.id,
    //         banner.sys.contentType.sys.id
    //     ).then((res) => {
    //         banner.views = res.views;
    //         banner.uniqueViews = res.uniqueViews;
    //         // props.updateData({ ...banners, views: res.views, uniqueViews: res.uniqueViews });
    //     });
    // });

    const promisies = [];
    for (let i = 0; i < banners.items.length; ++i) {
        const banner = banners.items[i];
        promisies.push(
            ApiService.getViews(
                banner.sys.id,
                banner.sys.space.sys.id,
                banner.sys.environment.sys.id,
                banner.sys.contentType.sys.id
            )
        );
    }

    Promise.all(promisies).then((results) => {
        for (let i = 0; i < results.length; ++i) {
            const banner = banners.items[i];
            const result = results[i];
            banner.views = result.views;
            banner.uniqueViews = result.uniqueViews;
        }
        props.updateData({ ...banners });
    });

    return banners;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useDashboardBannerHook = (
    pagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
