import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgArticleFolder: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path data-name="Path 4806" d="M0 0h24v24H0Z" fill="none" />
        <path
            className="icon-fill"
            data-name="Path 4807"
            d="M20 6h-8l-1.41-1.41A2.006 2.006 0 0 0 9.17 4H4a2 2 0 0 0-1.99 2L2 18a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2V8a2.006 2.006 0 0 0-2-2Zm-1 12H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1Z"
            fill={color || '#333'}
        />
    </svg>
);

export default SvgArticleFolder;
