import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgPlay: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 11}
        height={height || 14}
        viewBox="0 0 11 14"
    >
        <path
            className="icon-fill"
            data-name="Path 4848"
            d="M0 0v14l11-7Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgPlay;
