import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { FlexGrid } from '../../components/FlexGrid';
import { ListItem } from '../../components/ListItem';
import { PageHeader } from '../../components/PageHeader';
import { PageWrapper } from '../../components/PageWrapper';
import { SearchBar } from '../../components/SearchBar';
import { Text } from '../../components/Text';

import {
    FolderIcon,
    NewFolderArticleIcon,
    DeleteIcon,
    EditIcon,
    SortIcon,
    MoreIcon,
    CheckCircleBigIcon,
} from '../../assets/icons';
import { COLORS } from '../../styles/variables';
import { IconButton } from '../../components/IconButton';
import { Button } from '../../components/Button';
import { Tooltip } from '../../components/Tooltip';
import { Modal } from '../../components/Modal';
import { Input } from '../../components/Input';
import { CreateEditModal } from '../../components/CreateEditModal';
import { SelectCountry } from '../../components/SelectCountry';
import { useDashboardArticleFolderHook } from '../Dashboard/hooks/use-article-folder-hook';
import { ContentfulApiService } from '../../services/ContentfulApi.service';
import {
    DEFAULT_LANGUAGE_CODE,
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_NAME_LENGTH,
} from '../../common/constants';
import { ContentfulTransformService } from '../../services/ContentfulTransform.service';
import { getUrlKeepQuery, isStringNotRequireAndWhiteSpaces } from '../../utils';

const BottomBorder = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
    padding: 1em 0em;
`;

const ActionButton = styled(Button)`
    gap: 0.5em;
    padding: 0em 1.5em;
    border-left: 1px solid ${COLORS.lightGray};
    border-radius: 0;
    margin: 0.5em 0em;
    padding-right: 1em;

    &.first {
        border-left: none;
        padding-left: 1em;
        padding-right: 1.5em;
    }
`;

const ImageContainer = styled(FlexGrid)`
    width: 120px;
    height: 64px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    img {
        object-fit: cover;
        height: 100%;
    }
`;

const NewTag = styled(Text)`
    padding: 0.3em 0.5em;
    background-color: ${COLORS.darkRed};
    color: ${COLORS.white};
    font-family: 'AR';
    text-transform: uppercase;
    border-radius: 2px;
    margin-left: 0.5em;
`;

const BorderBottom = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
`;

const PageContent = styled(FlexGrid)`
    max-width: 1020px;
    width: 100%;
    padding: 2em 0em;
    gap: 1em;
`;

export const ArticleFolder: React.FC = () => {
    const [editModal, setEditModal] = useState(false);
    const [editNameValue, setEditNameValue] = useState('');
    const [editDescriptionValue, setDescriptionValue] = useState('');

    const [folderName, setFolderName] = useState('');
    const [folderDescription, setFolderDescription] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [createEditSuccess, setCreateEditSuccess] = useState(null);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [createArticleDisabled, setCreateArticleDisabled] = useState(false);
    const [deleteSuccessModal, setDeleteSuccessModal] = useState(false);
    // const [activeFolder, setActiveFolder] = useState(0);
    const [update, setUpdate] = useState(false);
    const [{ hookData: articleFolderData }] = useDashboardArticleFolderHook(
        {},
        [update]
    );

    const [articles, setArticles] = useState<any>([]);
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(0);
    const params = useParams();
    const navigate = useNavigate();

    const [selectedArticleFolderId, setSelectedArticleFolderId] = useState(
        params.articleFolderId
    );
    const [selectedArticle, setSelectedArticle] = useState<any>({});
    const lan = DEFAULT_LANGUAGE_CODE;

    useEffect(() => {
        const selected = (articleFolderData?.items || []).filter((item) => {
            return item?.sys?.id === selectedArticleFolderId;
        });
        if (selected && selected.length) {
            setArticles(selected[0].articles?.items || []);
            setSelectedArticle(selected[0]);
            setEditNameValue(selected[0]?.fields?.name[lan]);
            setDescriptionValue(
                selected[0]?.fields?.description &&
                    selected[0]?.fields?.description[lan]
            );
        }
    }, [
        selectedArticleFolderId,
        params,
        articleFolderData,
        selectedFolder,
        setSelectedFolder,
        update,
    ]);

    useEffect(() => {
        const fds = articleFolderData?.items || [];
        setFolders(fds);

        if (articleFolderData) {
            if (!fds.length) {
                navigate(getUrlKeepQuery(`/communication/article-folder`));
            } else {
                if (
                    !fds.find(
                        (item) => item?.sys?.id === selectedArticleFolderId
                    )
                ) {
                    navigate(
                        getUrlKeepQuery(
                            `/communication/article-folder/${fds[0].sys.id}`
                        )
                    );
                }
            }
        }
    }, [articleFolderData]);

    useEffect(() => {
        setSelectedArticleFolderId(params.articleFolderId);
    }, [params.articleFolderId]);

    // console.log("articleFolder data", articleFolderData)
    // const [{ hookData: assets }] = useDashboardAssetsHook();

    console.log('get articles', articles);
    const articleTrans = ContentfulTransformService.getArticles(
        articleFolderData?.assets,
        articles,
        articleFolderData?.items || []
    );

    return (
        <PageWrapper>
            <CreateEditModal
                mode="create"
                open={createModal}
                onClose={() => {
                    setFolderName('');
                    setFolderDescription('');
                    setCreateModal(false);
                }}
                onSubmit={() => {
                    setCreateArticleDisabled(true);
                    ContentfulApiService.createContent('articleFolder', {
                        name: folderName,
                        description: folderDescription,
                    })
                        .then(() => {
                            setCreateArticleDisabled(true);
                            // setCreateEditSuccess(true);
                            // navigate(`/communication/article-folder`);
                            setUpdate(!update);
                            setCreateEditSuccess(true);
                            setFolderName('');
                            setFolderDescription('');
                            navigate(
                                getUrlKeepQuery(`/communication/article-folder`)
                            );
                        })
                        .finally(() => {
                            setCreateArticleDisabled(false);
                        });
                }}
                setSuccess={setCreateEditSuccess}
                success={createEditSuccess}
                title="Create New Folder"
                disabled={
                    createArticleDisabled ||
                    folderName.trim() === '' ||
                    isStringNotRequireAndWhiteSpaces(folderDescription)
                }
                inputComponents={[
                    <Input
                        maxLength={LIMIT_NAME_LENGTH}
                        label="Folder Name"
                        value={folderName}
                        onChange={(val) => setFolderName(val)}
                    />,
                    <Input
                        maxLength={LIMIT_DESCRIPTION_LENGTH}
                        placeholder="Enter"
                        multiline
                        height="88px"
                        label="Description"
                        value={folderDescription}
                        onChange={(val) => setFolderDescription(val)}
                    />,
                ]}
            />
            <CreateEditModal
                mode="edit"
                open={editModal}
                onClose={() => {
                    setEditNameValue('');
                    setDescriptionValue('');
                    setEditModal(false);
                }}
                onSubmit={async () => {
                    try {
                        setDisableSubmit(true);
                        const response = await ContentfulApiService.updateContentFields(
                            selectedArticle?.sys?.id,
                            selectedArticle,
                            {
                                name: editNameValue,
                                description: editDescriptionValue,
                            }
                        );
                        await ContentfulApiService.publish(response.sys.id);
                        setCreateEditSuccess(true);
                        console.log('Update article folder published');
                    } catch (error) {
                        console.error('Failed to update article folder', error);
                    } finally {
                        setDisableSubmit(false);
                        setUpdate(!update);
                    }
                }}
                setSuccess={setCreateEditSuccess}
                success={createEditSuccess}
                title="Edit Folder"
                disabled={
                    disableSubmit ||
                    editNameValue.trim() === '' ||
                    isStringNotRequireAndWhiteSpaces(editDescriptionValue)
                }
                inputComponents={[
                    <Input
                        maxLength={LIMIT_NAME_LENGTH}
                        label="Folder Name"
                        value={editNameValue}
                        onChange={(val) => setEditNameValue(val)}
                    />,
                    <Input
                        maxLength={LIMIT_DESCRIPTION_LENGTH}
                        placeholder="Enter"
                        multiline
                        height="88px"
                        label="Description"
                        value={editDescriptionValue}
                        onChange={(val) => setDescriptionValue(val)}
                    />,
                ]}
            />
            <Modal
                open={deleteModal}
                header="Delete Folder"
                onClose={() => setDeleteModal(false)}
                content={
                    <FlexGrid
                        direction="column"
                        width="100%"
                        gap="2em"
                        p="2em 0em"
                    >
                        <Text color={COLORS.darkGray}>
                            Are you sure you want to delete Folder?
                        </Text>
                        <Text color={COLORS.darkGray}>
                            <Text color={COLORS.darkGray} span font="AR Bold">
                                Note:{' '}
                            </Text>
                            Deleting folders is a permanent action and cannot be
                            undone.
                        </Text>
                    </FlexGrid>
                }
                actions={
                    <FlexGrid alignItems="center">
                        <Button
                            p="1.3em 2.5em"
                            onClick={() => {
                                ContentfulApiService.deleteContent(
                                    selectedArticleFolderId
                                )
                                    .then(() => {
                                        setUpdate(!update);
                                        setDeleteSuccessModal(true);
                                    })
                                    .finally(() => {
                                        setDeleteModal(false);
                                    });
                            }}
                        >
                            DELETE
                        </Button>
                        <Button
                            negative
                            p="0.5em 1em"
                            onClick={() => setDeleteModal(false)}
                        >
                            CANCEL
                        </Button>
                    </FlexGrid>
                }
            />

            {deleteSuccessModal && (
                <Modal
                    header="Delete Successful"
                    content={
                        <FlexGrid
                            direction="row"
                            width="100%"
                            gap="1em"
                            p="2em 0em"
                            alignItems="center"
                        >
                            <CheckCircleBigIcon />

                            <Text color={COLORS.darkGray}>
                                Folder has been deleted successfully
                            </Text>
                        </FlexGrid>
                    }
                    actions={
                        <FlexGrid alignItems="center">
                            <Button
                                p="1.3em 2.5em"
                                onClick={() => {
                                    setDeleteSuccessModal(false);
                                }}
                            >
                                DONE
                            </Button>
                        </FlexGrid>
                    }
                    open={deleteSuccessModal}
                    onClose={() => setDeleteSuccessModal(!deleteSuccessModal)}
                />
            )}
            <PageHeader
                back
                actions={
                    <FlexGrid gap="1.5em">
                        <SearchBar placeholder="Search banners, videos, articles here…" />
                        <SelectCountry />
                    </FlexGrid>
                }
            />
            <FlexGrid width="100%" justifyContent="center">
                <PageContent>
                    <FlexGrid direction="column" width="30%" gap="1em">
                        <Text font="OS" fontSize="2.25rem" uppercase>
                            FOLDER ARTICLE
                        </Text>
                        <FlexGrid direction="column" width="100%">
                            {folders.map((folder, i) => {
                                const { fields } = folder;
                                return (
                                    <ListItem
                                        middleFlexible
                                        active={
                                            folder.sys.id ===
                                            selectedArticleFolderId
                                        }
                                        key={uuidv4()}
                                        onClick={() => {
                                            setSelectedArticleFolderId(
                                                folder?.sys?.id
                                            );
                                            navigate(
                                                getUrlKeepQuery(
                                                    `/communication/article-folder/${folder.sys.id}`
                                                )
                                            );
                                        }}
                                        title={fields?.name[lan]}
                                        icon={<FolderIcon height="24" />}
                                        color={COLORS.green}
                                        bottom={
                                            <FlexGrid
                                                gap="0.2em"
                                                alignItems="center"
                                            >
                                                <Text fontSize="0.75rem">
                                                    {folder?.total} Articles
                                                </Text>
                                            </FlexGrid>
                                        }
                                    />
                                );
                            })}
                            <ListItem
                                onClick={() => setCreateModal(true)}
                                title="Create New Folder"
                                icon={<NewFolderArticleIcon height="24" />}
                                color={COLORS.green}
                            />
                        </FlexGrid>
                    </FlexGrid>
                    <FlexGrid flex="1" direction="column">
                        <BottomBorder
                            width="100%"
                            justifyContent="space-between"
                        >
                            <FlexGrid direction="column" width="70%" gap="1em">
                                <Text fontSize="1.5rem">
                                    {selectedArticle?.fields?.name[lan]}
                                </Text>
                                <Text
                                    workBreakAll
                                    fontSize="0.875rem"
                                    color={COLORS.darkGray}
                                >
                                    {selectedArticle?.fields?.description &&
                                        selectedArticle?.fields?.description[
                                            lan
                                        ]}
                                </Text>
                            </FlexGrid>
                            <FlexGrid gap="1.5em">
                                <Tooltip title="Edit Folder">
                                    <IconButton
                                        onClick={() => setEditModal(true)}
                                        negative
                                        icon={<EditIcon />}
                                    />
                                </Tooltip>
                                <Tooltip title="Delete Folder">
                                    <IconButton
                                        onClick={() => setDeleteModal(true)}
                                        negative
                                        icon={<DeleteIcon />}
                                    />
                                </Tooltip>
                            </FlexGrid>
                        </BottomBorder>
                        <FlexGrid
                            width="100%"
                            justifyContent="space-between"
                            p="1em 0em"
                        >
                            <Text fontSize="1.125rem">Article List</Text>
                            <FlexGrid>
                                <Tooltip title="Sort by">
                                    <ActionButton className="first" negative>
                                        <SortIcon />
                                        <Text fontSize="1rem" font="AR">
                                            Latest
                                        </Text>
                                    </ActionButton>
                                </Tooltip>
                            </FlexGrid>
                        </FlexGrid>
                        <FlexGrid flex="1" direction="column" width="100%">
                            {articleTrans.map((article) => (
                                <BottomBorder
                                    justifyContent="space-between"
                                    width="100%"
                                    alignItems="center"
                                >
                                    <FlexGrid flex="1" gap="1em">
                                        <ImageContainer>
                                            <img
                                                style={{ cursor: 'pointer' }}
                                                src={article.image}
                                                alt="article thumbnail"
                                                onClick={() => {
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            `/article/${article.id}`
                                                        )
                                                    );
                                                }}
                                            />
                                        </ImageContainer>
                                        <FlexGrid
                                            direction="column"
                                            width="100%"
                                            gap="0.5em"
                                        >
                                            <FlexGrid>
                                                <Text
                                                    link
                                                    linkHoverUnderline={false}
                                                    font="AR Bold"
                                                    color={COLORS.green}
                                                    onClick={() => {
                                                        navigate(
                                                            getUrlKeepQuery(
                                                                `/article/${article.id}`
                                                            )
                                                        );
                                                    }}
                                                >
                                                    {article.title}
                                                </Text>
                                                {article.new && (
                                                    <NewTag
                                                        fontSize="12px"
                                                        span
                                                    >
                                                        New
                                                    </NewTag>
                                                )}
                                            </FlexGrid>
                                            <Text
                                                fontSize="0.875rem"
                                                color={COLORS.darkGray}
                                            >
                                                {article.date}
                                            </Text>
                                            <Text
                                                fontSize="0.875rem"
                                                color={COLORS.darkGray}
                                            >
                                                Likes {article.likes}
                                                <Text
                                                    span
                                                    color={COLORS.lightGray}
                                                >
                                                    {'  '}•{'  '}
                                                </Text>
                                                Dislikes {article.dislikes}
                                            </Text>
                                        </FlexGrid>
                                    </FlexGrid>
                                    <IconButton
                                        negative
                                        icon={<MoreIcon height="30" />}
                                    />
                                </BottomBorder>
                            ))}
                        </FlexGrid>
                        <Button negative m="0" p="2em 0em">
                            <Text fontSize="0.875rem">Show More</Text>
                        </Button>
                    </FlexGrid>
                </PageContent>
            </FlexGrid>
        </PageWrapper>
    );
};
