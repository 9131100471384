import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';
import { addUser } from '../../../store/store.actions';
import { GLOBAL_CONFIG } from '../../../config/config';

type Input = unknown;
type Output = any;

const mountFn = async (
    props: ICallbackParams<Input, Output>,
    dispatch: Dispatch<any>
) => {
    return null;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useDashboardHook = (): HookReturnType<Input, Output> => {
    const dispatch = useDispatch();
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: false,
        mountFn: (props: ICallbackParams<Input, Output>) =>
        mountFn(props, dispatch),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
