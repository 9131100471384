import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgPushNotification2: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 16}
        height={height || 19.5}
        viewBox="0 0 16 19.5"
    >
        <path
            className="icon-fill"
            data-name="Path 4787"
            d="M8 19.5a2.006 2.006 0 0 0 2-2H6a2.006 2.006 0 0 0 2 2Zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V1.5a1.5 1.5 0 0 0-3 0v.68C3.64 2.86 2 5.42 2 8.5v5l-2 2v1h16v-1Zm-2 1H4v-6C4 6.02 5.51 4 8 4s4 2.02 4 4.5Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgPushNotification2;
