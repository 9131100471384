import React, { useEffect, useState } from 'react';
import { Select } from './Select';
// import COUNTRY_LANGUAGE_MAP from '../assets/locales/country_default_language_mapping.json';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { setCountryAction } from '../store/store.actions';
import { getAvailableRoleIds, getRoleId, setCountry } from '../utils';
import { COUNTRY_LANGUAGE_MULTIPLE_MAP, ROLE_TAG_ID_MAP, STORAGE_ROLE_ID, TAG_COUNTRY_MAP } from '../common/constants';

const orgCountryList = _.keys(COUNTRY_LANGUAGE_MULTIPLE_MAP);

export const SelectCountry: React.FC = () => {
    const qParams = new URL(window.location.href).searchParams;
    const qCountry = qParams.get('country');
    let defaultCountry;
    const availableRoleIds = getAvailableRoleIds();
    let countryList = orgCountryList;

    const roleTagId = ROLE_TAG_ID_MAP[getRoleId()];
    if (roleTagId) {
        defaultCountry = qCountry || TAG_COUNTRY_MAP[roleTagId];
        if (availableRoleIds && availableRoleIds.length > 0) {
            countryList = availableRoleIds.map(roleId => TAG_COUNTRY_MAP[ROLE_TAG_ID_MAP[roleId]]);
        }
    } else {
        defaultCountry = qCountry || 'England/UK';
    }
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
    const dispatch = useDispatch();

    if (roleTagId && availableRoleIds && availableRoleIds.length > 0) {
        // Change role ID
        const countryIdx = countryList.indexOf(defaultCountry);
        if (countryIdx >= 0) {
            localStorage.setItem(STORAGE_ROLE_ID, availableRoleIds[countryIdx]);
        }
    }

    useEffect(() => {
        dispatch(setCountryAction(selectedCountry));
        setCountry(selectedCountry);

        if (defaultCountry !== selectedCountry) {
            const url = new URL(window.location.href);
            url.searchParams.set('country', selectedCountry);
            window.location.href = url.href;
        }
    }, [selectedCountry]);

    return (
        <Select
            value={selectedCountry}
            label="Country"
            options={countryList}
            disable={(!!roleTagId && (!availableRoleIds || availableRoleIds.length === 0))}
            onChange={(value: string) => setSelectedCountry(value)}
        />
    );
};
