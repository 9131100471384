import { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { CheckBox } from '../../../components/CheckBox';
import { FlexGrid } from '../../../components/FlexGrid';
import { Modal } from '../../../components/Modal';
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components/Table';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { ArrowDownIcon, RemoveCircleIcon } from '../../../assets/icons';
import { IconButton } from '../../../components/IconButton';

const Content = styled(FlexGrid)`
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: scroll;
    max-height: 450px;
`;

const Avatar = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 100%;
`;

interface UserType {
    picture: string;
    username: string;
    userID: string;
    suspensionReason: string;
    mobile: string;
    details: string;
}

export const ReactivateUsers = () => {
    const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);

    const users: UserType[] = [
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '12345',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '21345',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '31245',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '41235',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '51234',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '61234',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '71234',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '81234',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '91234',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '10123',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '11234',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
        {
            picture: 'https://picsum.photos/80/80',
            username: 'Andrea Smith',
            userID: '12543',
            suspensionReason: 'Acme Reason for Suspension',
            mobile: '+91 1234-567-89',
            details: 'Acme details goes here',
        },
    ];

    const [reactivateModal, setReactivateModal] = useState(false);
    const everyoneSelected = users.every((user) =>
        selectedUsers.find((el) => el.userID === user.userID)
    );

    return (
        <>
            {selectedUsers.length !== 0 && (
                <Modal
                    header="Reactivate Users"
                    open={reactivateModal}
                    onClose={() => setReactivateModal(false)}
                    content={
                        <FlexGrid
                            p="2em 1em"
                            direction="column"
                            width="100%"
                            gap="2em"
                        >
                            <Text color={COLORS.darkGray}>
                                Reactivating will allow this user to log in. Are
                                you sure?
                            </Text>
                            <FlexGrid direction="column" width="100%" gap="1em">
                                {selectedUsers.map((user) => (
                                    <FlexGrid
                                        width="100%"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <FlexGrid gap="1em" alignItems="center">
                                            <Avatar src={user.picture} />
                                            <FlexGrid
                                                direction="column"
                                                gap="0.3em"
                                            >
                                                <Text
                                                    fontSize="0.875rem"
                                                    color={COLORS.green}
                                                >
                                                    {user.username}
                                                </Text>
                                                <Text
                                                    fontSize="0.75rem"
                                                    color={COLORS.darkGray}
                                                >
                                                    User ID: {user.userID}
                                                </Text>
                                            </FlexGrid>
                                        </FlexGrid>
                                        <IconButton
                                            negative
                                            icon={<RemoveCircleIcon />}
                                        />
                                    </FlexGrid>
                                ))}
                            </FlexGrid>
                        </FlexGrid>
                    }
                    actions={<Button p="1em 1.5em">activate Users</Button>}
                />
            )}
            <Content>
                <Table>
                    <TableRow>
                        <TableHead>
                            <FlexGrid alignItems="center" gap="0.5em">
                                <CheckBox
                                    checked={everyoneSelected}
                                    onChange={() =>
                                        everyoneSelected
                                            ? setSelectedUsers([])
                                            : setSelectedUsers([...users])
                                    }
                                />
                                User Name
                                <IconButton negative icon={<ArrowDownIcon />} />
                            </FlexGrid>
                        </TableHead>
                        <TableHead>User ID</TableHead>
                        <TableHead>Reason for Suspension</TableHead>
                        <TableHead>Mobile Number</TableHead>
                        <TableHead>details</TableHead>
                    </TableRow>
                    {users.map((user) => (
                        <TableRow>
                            <TableCell>
                                <FlexGrid alignItems="center" gap="0.5em">
                                    <CheckBox
                                        checked={Boolean(
                                            selectedUsers.find(
                                                (el) =>
                                                    el.userID === user.userID
                                            )
                                        )}
                                        onChange={() => {
                                            if (
                                                !selectedUsers.find(
                                                    (el) =>
                                                        el.userID ===
                                                        user.userID
                                                )
                                            ) {
                                                setSelectedUsers([
                                                    ...selectedUsers,
                                                    user,
                                                ]);
                                            } else {
                                                const filtered =
                                                    selectedUsers.filter(
                                                        (el) =>
                                                            el.userID !==
                                                            user.userID
                                                    );
                                                setSelectedUsers(filtered);
                                            }
                                        }}
                                    />
                                    <Avatar src={user.picture} />
                                    <Text
                                        fontSize="0.875rem"
                                        color={COLORS.green}
                                    >
                                        {user.username}
                                    </Text>
                                </FlexGrid>
                            </TableCell>
                            <TableCell>{user.userID}</TableCell>
                            <TableCell>{user.suspensionReason}</TableCell>
                            <TableCell>{user.mobile}</TableCell>
                            <TableCell>{user.details}</TableCell>
                        </TableRow>
                    ))}
                </Table>
            </Content>
            <Button
                disabled={selectedUsers.length === 0}
                onClick={() => setReactivateModal(true)}
                m="0em 0em 0em auto"
                p="1em 3em"
            >
                <Text uppercase font="OS">
                    Reactivate Users
                </Text>
            </Button>
        </>
    );
};
