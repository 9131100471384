import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/variables';
import { FlexGrid } from './FlexGrid';
import { Info } from './Info';
import { Tag } from './Tag';

interface ButtonGroupProps {
    titles: string[];
    info?: { title: string; content: string };
    active?: string;
    // eslint-disable-next-line no-unused-vars
    onChange?: (val: string) => void;
    checked?: boolean[];
}

const Container = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
    padding-bottom: 0.5em;
    margin-top: 0.5em;
    justify-content: space-between;
    width: 100%;
    position: relative;
`;

const TagsContainer = styled(FlexGrid)`
    flex: 1;
    flex-wrap: wrap;
    gap: 0.5em;
`;

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
    titles,
    info,
    active,
    onChange,
    checked,
}) => {
    return (
        <Container>
            <TagsContainer>
                {titles.map((title, i) => (
                    <Tag
                        key={`button-group-tag-key-${i}`}
                        checked={checked && checked[i]}
                        onClick={() => onChange && onChange(title)}
                        active={active === title}
                        button
                        title={title}
                    />
                ))}
            </TagsContainer>
            {info && <Info info={info} />}
        </Container>
    );
};
