import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgArticles: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path data-name="Rectangle 2479" fill="none" d="M0 0h24v24H0z" />
        <g data-name="Group 14026">
            <path
                className="icon-fill"
                data-name="Path 4772"
                d="M19 5v14H5V5h14m0-2H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2Z"
                fill={color || '#333'}
            />
        </g>
        <path
            className="icon-fill"
            data-name="Path 4773"
            d="M14 17H7v-2h7Zm3-4H7v-2h10Zm0-4H7V7h10Z"
            fill={color || '#333'}
        />
    </svg>
);

export default SvgArticles;
