// import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

// import { GLOBAL_CONFIG } from '../../../config/config';
import { IPagination } from '../../../types';
// import { getMetaTag, getRoleId } from '../../../utils';
// import { ROLE_TAG_ID_MAP } from '../../../common/constants';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { ApiService } from '../../../services/Api.service';
import { getAssetIdsParams } from '../../../common';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const data = await ContentfulApiService.searchContent({
        contentType: 'article',
        page: props.input.pagination.page,
        limit: props.input.pagination.perPage,
        sortBy: props.input.pagination.sortBy,
    });

    ContentfulApiService.searchContent({
        contentType: 'articleFolder',
    }).then((res) => {
        data.articleFolder = res;
        props.updateData({ ...data });
    });

    console.log('article', data);

    const promisies = [];

    for (let i = 0; i < data.items.length; ++i) {
        const d = data.items[i];
        promisies.push(
            ApiService.getViews(
                d.sys.id,
                d.sys.space.sys.id,
                d.sys.environment.sys.id,
                d.sys.contentType.sys.id
            )
        );
    }

    // data.items.map((d) => {
    //     ApiService.getViews(
    //         d.sys.id,
    //         d.sys.space.sys.id,
    //         d.sys.environment.sys.id,
    //         d.sys.contentType.sys.id
    //     ).then((res) => {
    //         d.views = res.views;
    //         d.uniqueViews = res.uniqueViews;
    //         props.updateData({ ...data });
    //     });
    // });

    for (let i = 0; i < data.items.length; ++i) {
        const d = data.items[i];
        promisies.push(
            ApiService.getComments(
                d.sys.id,
                d.sys.space.sys.id,
                d.sys.environment.sys.id,
                d.sys.contentType.sys.id
            )
        );
    }

    // data.items.map((d) => {
    //     ApiService.getComments(
    //         d.sys.id,
    //         d.sys.space.sys.id,
    //         d.sys.environment.sys.id,
    //         d.sys.contentType.sys.id
    //     ).then((res) => {
    //         d.comments = res;
    //         props.updateData({ ...data });
    //     });
    // });

    Promise.all(promisies).then((results) => {
        const numItems = data.items.length;
        for (let i = 0; i < numItems; ++i) {
            const d = data.items[i];
            const res = results[i];
            d.views = res.views;
            d.uniqueViews = res.uniqueViews;
        }
        for (let i = numItems; i < results.length; ++i) {
            const d = data.items[i - numItems];
            const res = results[i];
            d.comments = res;
        }
        props.updateData({ ...data });
    });

    const assetsResponse = await ContentfulApiService.getAssets({
        ...getAssetIdsParams(
            ContentfulTransformService.getArticlesAssets(data.items)
        ),
    });
    data.assets = assetsResponse;
    props.updateData({ ...data });

    // ContentfulApiService.getAssets({
    //     ...getAssetIdsParams(
    //         ContentfulTransformService.getArticlesAssets(data.items)
    //     ),
    // }).then((res) => {
    //     data.assets = res;
    //     props.updateData({ ...data });
    // });

    return data;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useDashboardArticleHook = (
    pagination: IPagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
