import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { Text } from '../../../components/Text';
import { COLORS, COMMON } from '../../../styles/variables';

import { CheckIcon, DeleteIcon } from '../../../assets/icons';
import { PageWrapper } from '../../../components/PageWrapper';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { IdentifierDefinition } from './IdentifierDefinition';
import { VideoContent } from './VideoContent';
import { TargetGeography } from './TargetGeography';
import { TargetUser } from './TargetUser';
import { SendNotication } from './SendNotification';
import { Review } from './Review';
import { VideoType } from '../../../types';
import { useDropdownPopulateHook } from '../../../hooks/api/use-dropdown-populate-hook';
import { CreationService } from '../../../services/Creation.service';
// import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { GLOBAL_CONFIG } from '../../../config/config';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../store/store.actions';
import { AddQuiz } from './AddQuiz';

const SaveDraftBtn = styled(Button)`
    border-radius: 0;
    margin: 5px 0;
    padding: 0.2em 1em;
    border-left: 1px solid ${COLORS.lightGray};
    border-right: 1px solid ${COLORS.lightGray};
    font-family: 'OS Bold';
    font-size: 1rem;
`;

const PageContent = styled(FlexGrid)`
    background-color: ${COLORS.veryLightGray};
    flex: 1;
    padding: 2em;
    gap: 2em;
`;

const Card = styled(FlexGrid)`
    background-color: ${COLORS.white};
    box-shadow: ${COMMON.shadow};
    border-radius: 5px;
`;

const ProgressCard = styled(Card)`
    width: 300px;
    padding: 1em 2em;
`;

const FormCard = styled(Card)`
    flex: 1;
    min-height: 500px;
`;

const Head = styled(Text)`
    color: ${COLORS.darkGray};
    padding: 1em 0em 0.5em;
    width: 100%;
`;

interface StepNumberProps {
    active?: boolean;
}

const StepNumber = styled(FlexGrid)<StepNumberProps>`
    width: 30px;
    height: 30px;
    justify-content: center;
    border-radius: 100%;
    align-items: center;
    background-color: ${({ active }) =>
        active ? COLORS.green : COLORS.veryLightGray};
    color: ${({ active }) => (active ? COLORS.white : COLORS.darkGray)};
    font-family: ${({ active }) => (active ? 'AR Bold' : 'AR')};
`;

interface LocationState {
    data: VideoType;
    page: number;
    viewOnly?: boolean;
    quizEdit?: boolean;
}

export const CreateVideo: React.FC = () => {
    const [{ hookData: dropdownPopulate }] = useDropdownPopulateHook();
    const dispatch = useDispatch();

    // console.log('dropdownPopus data', dropdownPopulate);

    const defaultValue: VideoType = {
        raw: null,

        identifierDefinition: {
            name: '',
            appLanguages: [],
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
        },
        content: {
            isFromURL: false,
            source: null,
            thumbnail: null,
            isFeatured: false,
            playlists: [],
            tags: [],
            title: {},
            summary: {},
            description: {},
        },
        targetGeography: {
            regions: [],
            states: [],
            distributors: [],
            districts: [],
            pincode: null,
            rmAreas: [],
        },
        targetUser: {
            userTypes: [],
            spaceOfMechanics: [],
            connekt: false,
            mobile: null,
            PID: null,
            dealerSegments: [],
            dealerSubSegments: [],
            mechanicsSegments: [],
            mechanicsSubSegments: [],
        },
        sendNotification: {
            notificationType: [],
            icon: '',
            // headline: {},
            // bodyText: {},
            inAppNotificationContent: {
                bodyText: {},
            },
            pushNotificationContent: {
                headline: {},
                bodyText: {},
            },
            targetLink: {},
            isLinkInternal: true,
        },
    };

    const location = useLocation();
    const state = location.state as LocationState;
    const initVideoData = state?.data || defaultValue;
    const videoState = useState<VideoType>(initVideoData);
    const viewOnlyMode = state?.viewOnly;
    const quizEditMode = state?.quizEdit;
    const steps = [
        'Identifier Definition',
        'Content',
        'Target Geography',
        'Target User',
        'Add Quiz',
        'Send Notification',
        'Review',
    ];
    const [activeStep, setActiveStep] = useState<number>(
        quizEditMode ? 4 : viewOnlyMode ? steps.length - 1 : 0
    );

    const stepsComponent = [
        <IdentifierDefinition
            videoState={videoState}
            initVideoData={initVideoData}
            stepNavigator={setActiveStep}
        />,
        <VideoContent videoState={videoState} stepNavigator={setActiveStep} />,
        <TargetGeography
            dropdownPopulate={dropdownPopulate}
            videoState={videoState}
            stepNavigator={setActiveStep}
        />,
        <TargetUser
            dropdownPopulate={dropdownPopulate}
            videoState={videoState}
            stepNavigator={setActiveStep}
        />,
        <AddQuiz
            videoState={videoState}
            initVideoData={initVideoData}
            stepNavigator={setActiveStep}
        />,
        <SendNotication
            videoState={videoState}
            stepNavigator={setActiveStep}
        />,
        <Review
            mode={
                viewOnlyMode ? 'View' : !!videoState[0].raw ? 'Edit' : 'Create'
            }
            videoState={videoState}
            stepNavigator={setActiveStep}
            dropdownPopulate={dropdownPopulate}
        />,
    ];

    const percentage = (activeStep / (steps.length - 1)) * 100;

    const saveAsDraft = () => {
        if (GLOBAL_CONFIG.SAVE_AS_DRAFT_TO_LOCAL) {
            const s = JSON.stringify(videoState[0]);
            localStorage.setItem('fastscan-video-draft', s);
        } else {
            dispatch(
                updateModalAction({
                    open: true,
                    noAction: true,
                    title: 'Save as draft',
                    content: 'Saving content as draft...',
                })
            );

            CreationService.createUpdateVideo(
                !!videoState[0].raw,
                false,
                videoState[0]
            )
                .then((result) => {
                    videoState[0].raw = result;
                    dispatch(
                        updateModalAction({
                            open: true,
                            title: 'Save as draft',
                            content: 'Saved',
                        })
                    );
                })
                .catch((e) => {
                    dispatch(
                        updateModalAction({
                            open: true,
                            title: 'Error',
                            content: `${e}`,
                        })
                    );
                });
        }
    };

    const navigate = useNavigate();

    const onDelete = () => {
        // if (videoState[0].raw) {
        //     // todo: should delete related video media, thunmbnail and notification;
        //     ContentfulApiService.deleteContent(videoState[0].raw.sys.id).then(() => {
        //         videoState[0].raw = null;
        //     });
        // }

        if (GLOBAL_CONFIG.SAVE_AS_DRAFT_TO_LOCAL) {
            localStorage.setItem('fastscan-video-draft', '');
        }

        videoState[1]({ ...defaultValue, raw: videoState[0].raw });
        // setActiveStep(0);
        navigate(-1);
    };

    useEffect(() => {
        if (GLOBAL_CONFIG.SAVE_AS_DRAFT_TO_LOCAL) {
            const draftS = localStorage.getItem('fastscan-video-draft');
            if (draftS) {
                const draft = JSON.parse(draftS);
                videoState[1](draft);
            }
        }
    }, []);

    return (
        <PageWrapper>
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.5rem" uppercase={false}>
                        {viewOnlyMode ? 'View' : state?.data ? 'Edit' : 'Add New'}{' '}
                        Video
                    </Text>
                }
                actions={
                    <FlexGrid gap="1em" alignItems="center">
                        <Text fontSize="0.875rem" color={COLORS.darkGray}>
                            <Text fontSize="0.875rem" font="AR Bold" span>
                                ID: 
                            </Text>{`  ${videoState[0]?.raw?.sys?.id || "[Pending...]"}`}
                        </Text>
                        <SaveDraftBtn
                            negative
                            onClick={() => {
                                saveAsDraft();
                            }}
                        >
                            SAVE AS DRAFT
                        </SaveDraftBtn>
                        <Button
                            negative
                            onClick={() => {
                                onDelete();
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </FlexGrid>
                }
            />
            <PageContent width="100%" justifyContent="center">
                <ProgressCard direction="column">
                    <Head>{percentage.toFixed(2)}% Complete</Head>
                    <HorizontalLine
                        backgroundColor={COLORS.lightGray}
                        height="3px"
                        progressColor={COLORS.green}
                        progressPercentage={`${percentage}%`}
                    />
                    <FlexGrid direction="column" gap="1.5em" p="1.5em 0em">
                        {steps.map((step, i) => (
                            <FlexGrid
                                key={`create-video-grid-${i}`}
                                gap="1em"
                                alignItems="center"
                            >
                                {i < activeStep ? (
                                    <StepNumber active>
                                        <CheckIcon color={COLORS.white} />
                                    </StepNumber>
                                ) : i === activeStep ? (
                                    <StepNumber active>{i + 1}</StepNumber>
                                ) : (
                                    <StepNumber>{i + 1}</StepNumber>
                                )}
                                <Text
                                    font={i === activeStep ? 'AR Bold' : 'AR'}
                                    color={i < activeStep ? 'green' : 'black'}
                                >
                                    {step}
                                </Text>
                            </FlexGrid>
                        ))}
                    </FlexGrid>
                </ProgressCard>
                <FormCard>{stepsComponent[activeStep]}</FormCard>
            </PageContent>
        </PageWrapper>
    );
};
