import { useEffect } from 'react';
import styled from 'styled-components';
import { ChevronLeftIcon, ChevronRightIcon } from '../assets/icons';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';

interface TableProps {
    fixed?: boolean;
}

export const Table = styled.table<TableProps>`
    table-layout: ${({ fixed }) => (fixed ? 'fixed' : 'auto')};
    width: 100%;
`;

export const TableRow = styled.tr``;

interface TableHeadProps {
    padding?: string;
    borderBottomDisabled?: boolean;
}

export const TableHead = styled.th<TableHeadProps>`
    text-transform: uppercase;
    font-size: 0.625rem;
    color: ${COLORS.darkGray};
    text-align: left;
    padding: ${({ padding }) => padding ?? `2em 0em`};
    border-bottom: ${({ borderBottomDisabled }) => borderBottomDisabled ? 'none' : `1px solid ${COLORS.lightGray}`};
`;

interface TableCellProps {
    padding?: string;
    width?: string;
    verticalAlign?: string;
    borderBottomDisabled?: boolean;
}

export const TableCell = styled.td<TableCellProps>`
    width: ${({ width }) => width || 'auto'};
    padding: ${({ padding }) => padding ?? `1em 1em 1em 0em`};
    border-bottom: ${({ borderBottomDisabled }) => borderBottomDisabled ? 'none' : `1px solid ${COLORS.lightGray}`};
    font-size: 0.875rem;
    color: ${COLORS.darkGray};
    overflow: hidden;
    white-space: pre-wrap;
    vertical-align: ${({ verticalAlign }) => verticalAlign || 'center'};
`;

export const TableImg = styled.img`
    width: 122px;
    height: 64px;
`;

const Pagination = styled(FlexGrid)`
    align-self: flex-end;
    align-items: center;
    gap: 1em;
    padding: 1em 0em;
`;

const Pages = styled(FlexGrid)`
    border: 1px solid ${COLORS.lightGray};
    align-items: center;
`;

interface PageBtnProps {
    active?: boolean;
}

const PageBtn = styled(Button)<PageBtnProps>`
    border-radius: 0;
    padding: 0.2em 0.5em;
    margin: 5px 2px;
    background-color: ${({ active }) =>
        active ? COLORS.green : COLORS.transparent};
    color: ${({ active }) => (active ? COLORS.white : COLORS.darkGray)};
    font-family: 'AR';
    font-size: 1rem;
`;

interface TablePaginationProps {
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    length: number;
    perPage: number;
}

export const TablePagination: React.FC<TablePaginationProps> = ({
    page,
    setPage,
    length,
    perPage,
}) => {
    const currentPage = page + 1;

    const numberOfPages = Math.ceil(length / perPage);

    useEffect(() => {
        if (page * perPage > length) {
            setPage((p) => p - 1);
        }
    }, [length, page, perPage, setPage]);

    const firstPageButton =
        currentPage < 3
            ? 1
            : currentPage > numberOfPages - 2
            ? numberOfPages - 2
            : currentPage - 1;
    const secondPageButton =
        currentPage < 3
            ? 2
            : currentPage > numberOfPages - 2
            ? numberOfPages - 1
            : currentPage;
    const thirdPageButton =
        currentPage < 3
            ? 3
            : currentPage > numberOfPages - 2
            ? numberOfPages
            : currentPage + 1;

    return (
        <Pagination>
            <Text fontSize="0.875rem" color={COLORS.darkGray}>
                Page {currentPage} from {numberOfPages}
            </Text>
            <Pages>
                <Button
                    negative
                    disabled={currentPage === 1}
                    onClick={() => setPage((p) => p - 1)}
                >
                    <ChevronRightIcon
                        color={
                            currentPage === 1 ? COLORS.lightGray : COLORS.green
                        }
                    />
                </Button>
                {thirdPageButton > 3 && (
                    <>
                        <PageBtn onClick={() => setPage(0)}>1</PageBtn>
                        <Text>...</Text>
                    </>
                )}
                <PageBtn
                    active={currentPage === firstPageButton}
                    onClick={() => setPage(firstPageButton - 1)}
                >
                    {firstPageButton}
                </PageBtn>
                {numberOfPages > 1 && (
                    <PageBtn
                        active={currentPage === secondPageButton}
                        onClick={() => setPage(secondPageButton - 1)}
                    >
                        {secondPageButton}
                    </PageBtn>
                )}
                {numberOfPages > 2 && (
                    <PageBtn
                        active={currentPage === thirdPageButton}
                        onClick={() => setPage(thirdPageButton - 1)}
                    >
                        {thirdPageButton}
                    </PageBtn>
                )}
                {firstPageButton < numberOfPages - 2 && (
                    <>
                        <Text>...</Text>
                        <PageBtn onClick={() => setPage(numberOfPages - 1)}>
                            {numberOfPages}
                        </PageBtn>
                    </>
                )}
                <Button
                    negative
                    disabled={currentPage === numberOfPages}
                    onClick={() => setPage((p) => p + 1)}
                >
                    <ChevronLeftIcon
                        color={
                            currentPage === numberOfPages
                                ? COLORS.lightGray
                                : COLORS.green
                        }
                    />
                </Button>
            </Pages>
        </Pagination>
    );
};
