// import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import _ from 'lodash';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { IPagination } from '../../../types';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { DEFAULT_LANGUAGE_CODE } from '../../../common/constants';
// import { DEFAULT_LANGUAGE_CODE } from '../../../common/constants';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const quizzes = await ContentfulApiService.searchContent({
        contentType: 'quiz',
        page: props.input.pagination.page,
        limit: props.input.pagination.perPage,
        sortBy: props.input.pagination.sortBy,
    });

    const quizzesObj = ContentfulTransformService.getQuizzes(quizzes);
    const mappedItems = quizzesObj.mapped;

    for (let i = 0; i < mappedItems.length; ++i) {
        const quiz = mappedItems[i];
        if (quiz.linkedContent) {
            const linkedContentEntryId = quiz.linkedContent.sys.id;
            const response = await ContentfulApiService.getEntry({
                entryId: linkedContentEntryId,
            });
            if (response) {
                const contentType = response.sys.contentType.sys.id;
                const name =
                    response.fields.name[DEFAULT_LANGUAGE_CODE];
                quiz.linkedContentObj = {
                    raw: response,
                    contentType,
                    name,
                };
            }
        }
    }

    console.log('quizzes contentful data', quizzes);
    return quizzesObj;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useQuizHook = (
    pagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialData: {},
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
