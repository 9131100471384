// import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import _ from 'lodash';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { IPagination } from '../../../types';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { DEFAULT_LANGUAGE_CODE } from '../../../common/constants';
import { ApiService } from '../../../services/Api.service';
// import { DEFAULT_LANGUAGE_CODE } from '../../../common/constants';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const products = await ApiService.getScanningContestProducts();

    console.log('products data', products);
    return products;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useBrandSKUListHook = (
    pagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialData: {},
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
