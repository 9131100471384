import { createGlobalStyle } from 'styled-components';
import BN from '../assets/fonts/BebasNeue-Book.ttf';
import AR from '../assets/fonts/Arial.ttf';
import ARBold from '../assets/fonts/Arial-Bold.ttf';
import OS from '../assets/fonts/Oswald-Regular.ttf';
import OSBold from '../assets/fonts/Oswald-SemiBold.ttf';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'BN';
    src: url(${BN}) format('truetype')
  }

  @font-face {
    font-family: 'OS';
    src: url(${OS}) format('truetype')
  }

  @font-face {
    font-family: 'OS Bold';
    src: url(${OSBold}) format('truetype')
  }

  @font-face {
    font-family: 'AR';
    src: url(${AR}) format('truetype')
  }

  @font-face {
    font-family: 'AR Bold';
    src: url(${ARBold}) format('truetype')
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'AR';
    overflow-x: hidden;
  }

  * {
    scrollbar-width: 14px;
    scrollbar-color: #babac0 #fff;
  }

  /* total width */
  *::-webkit-scrollbar {
      background-color: #fff;
      width: 14px;
  }


  /* background of the scrollbar except button or resizer */
  *::-webkit-scrollbar-track {
      background-color: #fff;
  }

  /* scrollbar itself */
  *::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 14px;
      border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  body::-webkit-scrollbar-button {
      display:none;
  }
`;

export default GlobalStyle;
