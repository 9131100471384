import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgDate: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <defs>
            <clipPath id="date_svg__a">
                <path
                    data-name="Rectangle 87"
                    transform="translate(93 539)"
                    fill={color || '#139343'}
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
        <g
            data-name="ic working day"
            transform="translate(-93 -539)"
            clipPath="url(#date_svg__a)"
        >
            <g
                className="icon-fill"
                transform="translate(93 539)"
                fill={color || '#139343'}
            >
                <path
                    data-name="Path 113"
                    d="M19 2h-1V1a1 1 0 1 0-2 0v1H8V1a1 1 0 1 0-2 0v1H5a5.006 5.006 0 0 0-5 5v12a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5V7a5.006 5.006 0 0 0-5-5ZM2 7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v1H2Zm17 15H5a3 3 0 0 1-3-3v-9h20v9a3 3 0 0 1-3 3Z"
                />
                <circle
                    data-name="Ellipse 7"
                    cx={1.5}
                    cy={1.5}
                    r={1.5}
                    transform="translate(10.5 13.5)"
                />
                <circle
                    data-name="Ellipse 8"
                    cx={1.5}
                    cy={1.5}
                    r={1.5}
                    transform="translate(5.5 13.5)"
                />
                <circle
                    data-name="Ellipse 9"
                    cx={1.5}
                    cy={1.5}
                    r={1.5}
                    transform="translate(15.5 13.5)"
                />
            </g>
        </g>
    </svg>
);

export default SvgDate;
