import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgArrowRight: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 13.481}
        height={height || 13.937}
        viewBox="0 0 13.481 13.937"
    >
        <g
            className="icon-stroke"
            fill="none"
            stroke={color || '#009343'}
            strokeLinecap="round"
            strokeWidth={2}
        >
            <path data-name="Path 7" d="M1 6.968h10.446" />
            <path
                data-name="Path 8"
                d="m6.927 1.414 5.554 5.55-5.554 5.554"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default SvgArrowRight;
