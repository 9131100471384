import React, { MouseEvent, MouseEventHandler, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../styles/variables';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';

import { ChevronLeftIcon, ChevronUpIcon } from '../assets/icons';
import { IconButton } from './IconButton';
import { getUrlKeepQuery } from '../utils';

interface ListItemProps {
    icon?: any;
    title?: any;
    className?: string;
    line?: boolean;
    right?: any;
    bottom?: any;
    color?: string;
    onClick?: () => void;
    isAccordion?: boolean;
    accordionContent?: any;
    href?: string;
    uppercase?: boolean;
    p?: string;
    m?: string;
    contentPadding?: string;
    active?: boolean;
    middleFlexible?: boolean;
}

interface ContentProps {
    line?: boolean;
    open?: boolean;
    color?: string;
    p?: string;
}

const Title = styled(Text)<ListItemProps>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ color }) => color || 'auto'};
`;

const Content = styled(FlexGrid)<ContentProps>`
    padding: ${({ p }) => p || '1em'};
    border-bottom: ${({ line }) =>
        line ? `1px solid ${COLORS.lightGray}` : 'none'};
    width: 100%;
    justify-content: space-between;
    align-items: center;

    ${({ open }) =>
        !open &&
        css`
            &:hover .icon-stroke {
                stroke: ${COLORS.white};
            }

            &:hover .icon-fill {
                fill: ${COLORS.white};
            }
        `}

    &:hover ${Title}, &:hover .inner-title-text, 
    &:hover .list-title {
        color: ${({ open, color }) =>
            open ? color || COLORS.green : COLORS.white};
    }
`;

interface ContainerProps {
    line?: boolean;
    open?: boolean;
    m?: string;
    p?: string;
    active?: boolean;
}

const ListItemContainer = styled(FlexGrid)<ContainerProps>`
    flex-direction: column;
    width: 100%;
    transition: background-color 100ms ease-in;
    cursor: pointer;
    margin: ${({ m }) => m || '0'};
    padding: ${({ p }) => p || '0'};
    background-color: ${({ open, active }) =>
        open || active ? COLORS.veryLightGray : COLORS.transparent};
    color: ${({ open }) => (open ? COLORS.green : COLORS.black)};

    &:hover {
        background-color: ${({ open }) =>
            open ? COLORS.veryLightGray : COLORS.green};
    }

    &:hover .list-bottom * {
        color: ${({ open }) => (open ? COLORS.green : COLORS.white)};
    }

    &:hover ${Content} {
        border-bottom: ${({ line }) =>
            line ? `1px solid ${COLORS.green}` : 'none'};
        color: ${({ open }) => (open ? COLORS.green : COLORS.white)};
    }

    &:hover ${Content} ${Title} {
        color: ${({ open }) => (open ? COLORS.green : COLORS.white)};
    }
`;

const ListIcon = styled(FlexGrid)`
    min-width: 1.5em;
    justify-content: center;
`;

export const ListItem: React.FC<ListItemProps> = ({
    icon,
    title,
    className,
    line,
    right,
    bottom,
    color,
    onClick,
    isAccordion,
    accordionContent,
    href,
    uppercase,
    m,
    p,
    contentPadding,
    active,
    middleFlexible,
}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClick: MouseEventHandler<HTMLDivElement> = (
        event: MouseEvent<HTMLDivElement>
    ) => {
        event.stopPropagation();
        if (href) {
            navigate(getUrlKeepQuery(href));
        } else if (isAccordion) {
            setOpen((prev) => !prev);
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <ListItemContainer
            onClick={handleClick}
            className={className}
            color={color}
            open={open}
            m={m}
            p={p}
            active={active}
        >
            <Content line={line} open={open} color={color} p={contentPadding}>
                <FlexGrid
                    alignItems={bottom ? 'flex-start' : 'center'}
                    {...(middleFlexible ? { flex: '1' } : {})}
                >
                    {icon && <ListIcon>{icon}</ListIcon>}
                    <FlexGrid
                        gap="0.3em"
                        m="0em 0em 0em 1em"
                        direction="column"
                        {...(middleFlexible ? { flex: '1', width: '0' } : {})}
                    >
                        <div
                            style={
                                middleFlexible
                                    ? { width: '100%', overflow: 'hidden' }
                                    : {}
                            }
                        >
                            <Title uppercase={uppercase} color={color}>
                                {title}
                            </Title>
                        </div>
                        {bottom}
                    </FlexGrid>
                </FlexGrid>
                {right}
                {isAccordion && (
                    <IconButton
                        negative
                        icon={
                            open ? (
                                <ChevronUpIcon height="10" />
                            ) : (
                                <ChevronLeftIcon height="16" />
                            )
                        }
                    />
                )}
            </Content>
            {open && (
                <FlexGrid width="100%" direction="column">
                    {accordionContent}
                </FlexGrid>
            )}
        </ListItemContainer>
    );
};
