import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgDot: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 12}
        height={height || 12}
        viewBox="0 0 12 12"
    >
        <g
            className="icon-stroke"
            transform="translate(2 2)"
            fill="red"
            stroke={color || '#009343'}
            strokeWidth={2}
        >
            <circle cx={4} cy={4} r={4} stroke="none" />
            <circle cx={4} cy={4} r={5} fill="none" />
        </g>
    </svg>
);

export default SvgDot;
