import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgTime: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 22}
        height={height || 22}
        viewBox="0 0 22 22"
    >
        <g
            className="icon-stroke"
            transform="translate(-1 -1)"
            fill="none"
            stroke={color || '#139343'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <circle
                data-name="Ellipse 160"
                cx={10}
                cy={10}
                r={10}
                transform="translate(2 2)"
            />
            <path data-name="Path 4547" d="M12 6v6l4 2" />
        </g>
    </svg>
);

export default SvgTime;
