import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgRemoveCircle: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 20}
        height={height || 20}
        viewBox="0 0 20 20"
    >
        <path data-name="Path 4810" d="M0 0h20v20H0Z" fill="none" />
        <path
            className="icon-fill"
            data-name="Path 4811"
            d="M10 2a8 8 0 1 0 8 8 8 8 0 0 0-8-8Zm4 8.8H6V9.2h8Z"
            fill={color || '#f00023'}
        />
    </svg>
);

export default SvgRemoveCircle;
