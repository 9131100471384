import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { Info } from './Info';
import { Text } from './Text';

interface RadioProps {
    label?: string;
    checked?: boolean;
}

const RadioButton = styled(FlexGrid)<RadioProps>`
    width: 26px;
    height: 26px;
    border-radius: 100%;
    border: ${({ checked }) =>
        checked ? `5px solid ${COLORS.green}` : `1px solid ${COLORS.black}`};
`;

const Radio: React.FC<RadioProps> = ({ label, checked }) => {
    return (
        <FlexGrid gap="0.5em" alignItems="center">
            <RadioButton checked={checked} />
            <Text
                color={checked ? COLORS.black : COLORS.darkGray}
                font={checked ? 'AR Bold' : 'AR'}
            >
                {label}
            </Text>
        </FlexGrid>
    );
};

interface RadioGroupProps {
    options?: { label: string; value: string }[];
    label?: string;
    info?: { title: any; content: any };
    value?: any;
    // eslint-disable-next-line no-unused-vars
    onChange?: (val: string) => void;
    disabled?: boolean;
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
    label,
    options,
    info,
    value,
    onChange,
    disabled,
}) => {
    return (
        <FlexGrid
            gap="0.5em"
            width="100%"
            m="1em 0em"
            position="relative"
            direction="column"
        >
            <FlexGrid alignItems="center" gap="0.3em">
                <Text color={COLORS.darkGray} fontSize="0.75rem">
                    {label}
                </Text>
                {info && <Info info={info} />}
            </FlexGrid>
            <FlexGrid width="100%" gap="1.25em">
                {options &&
                    options.map((option, index) => (
                        <Button
                            key={`radio-option-${index}`}
                            onClick={() => onChange && onChange(option.value)}
                            negative
                            m="0"
                            p="0"
                            disableUnderline
                            disabled={disabled}
                        >
                            <Radio
                                label={option.label}
                                checked={value === option.value}
                            />
                        </Button>
                    ))}
            </FlexGrid>
        </FlexGrid>
    );
};
