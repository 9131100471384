import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
// import moment from 'moment';
import { Accordion } from '../../../components/Accordion';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import {
    BonusPointsIcon,
    DateIcon,
    PointsIcon,
    QuizCorrectOptionIcon,
    TimeIcon,
} from '../../../assets/icons';
import { QuizType, LookupValue } from '../../../types';
import { SuccessModal } from '../../../components/SuccessModal';
// import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { CreationService } from '../../../services/Creation.service';
import { getUrlKeepQuery } from '../../../utils';
import { DropDownPopulate } from '../../../services/Api.service';
import { SectionHeader } from '../../../components/SectionHeader';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../store/store.actions';

const EditBtn = styled(Button)`
    font-size: 15px;
    font-family: 'AR';
`;

const Label = styled(Text)`
    color: ${COLORS.darkGray};
    font-size: 0.75rem;
`;

const Value = styled(FlexGrid)`
    width: 50%;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 2em;
    min-width: 0;
`;

const Form = styled(FlexGrid)`
    max-width: 700px;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    mode?: 'Create' | 'Edit' | 'View';
    stepNavigator?: any;
    quizState: [QuizType, React.Dispatch<React.SetStateAction<QuizType>>];
    dropdownPopulate?: DropDownPopulate;
}

export const Review: React.FC<Props> = ({
    mode,
    stepNavigator,
    quizState,
    dropdownPopulate,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [quiz] = quizState;
    const [disableSubmit, setDisableSubmit] = useState(false);
    const { identifierDefinition, content, targetGeography, targetUser } = quiz;
    const location = useLocation() as any;

    const [sectorMapping, setSectorMapping] = useState(
        new Map<string, LookupValue>()
    );
    const [subSectorsMapping, setSubSectorsMapping] = useState(
        new Map<string, LookupValue>()
    );

    useEffect(() => {
        if (dropdownPopulate.sectors && dropdownPopulate.subSectors) {
            setSectorMapping(
                new Map(dropdownPopulate.sectors.map((elem) => [elem.id, elem]))
            );
            setSubSectorsMapping(
                new Map(
                    // @ts-ignore
                    Object.keys(dropdownPopulate.subSectors)
                        .map((sectorId) => {
                            return dropdownPopulate.subSectors[sectorId].map(
                                (elem) => [elem.id, elem]
                            );
                        })
                        .reduce((pre, cur) => pre.concat(cur), [])
                )
            );
        }
    }, [dropdownPopulate]);

    const createQuiz = () => {
        setDisableSubmit(true);
        const isUpdate = mode === 'Edit';
        CreationService.createUpdateQuiz(isUpdate, true, quiz)
            .then((result) => {
                setDisableSubmit(false);
                quiz.raw = result.response;
                setSubmitModalOpen(true);
            })
            .catch((e) => {
                console.log('=======false====', e);
                setDisableSubmit(false);
                dispatch(
                    updateModalAction({
                        open: true,
                        title: 'error',
                        content: `Failed to ${isUpdate ? 'update' : 'create'} quiz. ${e}. ${e?.config?.url ? `url: ${e?.config?.url}` : ''}`,
                    })
                );
            });
    };

    return (
        <FlexGrid width="100%" direction="column">
            <SuccessModal
                header={mode === 'Edit' ? 'Edit Quiz' : 'New Quiz'}
                open={submitModalOpen}
                onClose={() => {
                    setSubmitModalOpen(false);
                    navigate(getUrlKeepQuery('/communication/quizzes'), {
                        state: { page: location.state?.page },
                    });
                }}
                body={
                    mode === 'Edit'
                        ? 'The quiz have been updated successfully.'
                        : 'A new quiz have been submitted successfully.'
                }
            />
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Review
                    </Text>
                }
            />
            <Form direction="column" p="0.5em" width="100%">
                <Accordion
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Identifier Definition
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(0);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>Article Name</Label>
                                <Text>{identifierDefinition.name}</Text>
                            </Value>
                            <Value>
                                <Label>App Language</Label>
                                <Text>
                                    {identifierDefinition.appLanguages.join(
                                        ', '
                                    )}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Start Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>
                                        {identifierDefinition.startDate}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>Start Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>
                                        {identifierDefinition.startTime}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>{identifierDefinition.endDate}</Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>{identifierDefinition.endTime}</Text>
                                </FlexGrid>
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Content
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(1);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={identifierDefinition.appLanguages.map(
                        (language) => (
                            <>
                                <div
                                    style={{
                                        width: '100%',
                                        borderBottom: `1px solid ${COLORS.gray}`,
                                    }}
                                >
                                    <Text
                                        key={`video-text-${language}`}
                                        m="0.5em 0 0 1.1em"
                                        font="OS"
                                        fontSize="1.1em"
                                        fontWeight="10"
                                    >
                                        {language}
                                    </Text>
                                </div>
                                <FlexGrid
                                    key={`inapp-noti-flexgrid-${language}`}
                                    width="100%"
                                    wrap="wrap"
                                    direction="column"
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Value>
                                            <Label>Title</Label>
                                            <Text>
                                                {content.title[language] ||
                                                    content.title['English']}
                                            </Text>
                                        </Value>
                                        <Value>
                                            <Label>Bonus Points</Label>
                                            <FlexGrid
                                                alignItems="center"
                                                width="100%"
                                                gap="0.5em"
                                            >
                                                <BonusPointsIcon />
                                                <Text>
                                                    {content.bonusPoints || 0}
                                                </Text>
                                            </FlexGrid>
                                        </Value>
                                    </div>
                                    <Value>
                                        <Label>Description</Label>
                                        <Text>
                                            {content.description[language] ||
                                                content.description['English']}
                                        </Text>
                                    </Value>
                                    {content.questions.map(
                                        (question, index) => (
                                            <Accordion
                                                key={`quiz-question-${index}`}
                                                defaultOpen={false}
                                                info={{
                                                    title: 'Show or Hide Details',
                                                    content:
                                                        'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                                                }}
                                                title={
                                                    <SectionHeader
                                                        title={`QUESTION #${
                                                            index + 1
                                                        }`}
                                                        bg="#F5F5F5"
                                                        fontFamily="OS"
                                                        height="40px"
                                                        titleMargin="0 0 0 1em"
                                                    />
                                                }
                                                content={
                                                    <>
                                                        <Value>
                                                            <Label>
                                                                Question
                                                            </Label>
                                                            <Text>
                                                                {question
                                                                    .content
                                                                    .questionText[
                                                                    language
                                                                ] ||
                                                                    question
                                                                        .content
                                                                        .questionText[
                                                                        'English'
                                                                    ]}
                                                            </Text>
                                                        </Value>
                                                        <Value>
                                                            <Label>
                                                                Points
                                                            </Label>
                                                            <FlexGrid
                                                                alignItems="center"
                                                                width="100%"
                                                                gap="0.5em"
                                                            >
                                                                <PointsIcon />
                                                                <Text>
                                                                    {question
                                                                        .content
                                                                        .points ||
                                                                        0}
                                                                </Text>
                                                            </FlexGrid>
                                                        </Value>
                                                        {question.content.options.map(
                                                            (option, index) => (
                                                                <Value>
                                                                    <div
                                                                        style={{
                                                                            width: '100%',
                                                                            display:
                                                                                'flex',
                                                                            flexDirection:
                                                                                'row',
                                                                            alignItems:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        <Label>
                                                                            {`Answer ${
                                                                                index +
                                                                                1
                                                                            }`}
                                                                        </Label>
                                                                        {question
                                                                            .content
                                                                            .correctAnswerIndex ===
                                                                            index && (
                                                                            <QuizCorrectOptionIcon width="20" />
                                                                        )}
                                                                    </div>
                                                                    <Text>
                                                                        {option[
                                                                            language
                                                                        ] ||
                                                                            option[
                                                                                'English'
                                                                            ]}
                                                                    </Text>
                                                                </Value>
                                                            )
                                                        )}
                                                        <Value>
                                                            <Label>
                                                                Reason
                                                            </Label>
                                                            <Text>
                                                                {question
                                                                    .content
                                                                    .reason[
                                                                    language
                                                                ] ||
                                                                    question
                                                                        .content
                                                                        .reason[
                                                                        'English'
                                                                    ]}
                                                            </Text>
                                                        </Value>
                                                    </>
                                                }
                                            />
                                        )
                                    )}
                                </FlexGrid>
                            </>
                        )
                    )}
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target Geography
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(2);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                {targetGeography.regions.map(
                                    (region, index) => (
                                        <Text
                                            key={`inAppNotiTargetGeography-${index}`}
                                        >
                                            {region}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                {targetGeography.states.map((state, index) => (
                                    <Text key={`inAppNotiTargetState-${index}`}>
                                        {state}
                                    </Text>
                                ))}
                            </Value>
                            <Value>
                                <Label>
                                    Distributors (
                                    {targetGeography.distributors.length})
                                </Label>
                                {targetGeography.distributors.map(
                                    (distributor, index) => (
                                        <Text
                                            key={`inAppNotiDistributor-${index}`}
                                        >
                                            {distributor}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>District</Label>
                                {targetGeography.districts.map(
                                    (district, index) => (
                                        <Text
                                            key={`inAppNotiDistrict-${index}`}
                                        >
                                            {district}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Pincode</Label>
                                <Text>{targetGeography.pincode}</Text>
                            </Value>
                            <Value>
                                <Label>RM Area</Label>
                                {targetGeography.rmAreas.map(
                                    (rmArea, index) => (
                                        <Text key={`inAppNotiRmArea-${index}`}>
                                            {rmArea}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target User
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(3);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>User Type</Label>
                                <Text>{targetUser.userTypes.join(', ')}</Text>
                            </Value>
                            {targetUser.userTypes.includes('Mechanics') && (
                                <>
                                    <Value>
                                        <Label>Space of Mechanic</Label>
                                        <Text>
                                            {targetUser.spaceOfMechanics.join(
                                                ', '
                                            )}
                                        </Text>
                                    </Value>
                                    {/* <Value>
                                        <Label>Connekt</Label>
                                        <Text>
                                            {targetUser.connekt ? 'Yes' : 'No'}
                                        </Text>
                                    </Value> */}
                                </>
                            )}
                            {/* <Value>
                                <Label>Upload Mobile</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.mobile}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Upload PID</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.PID}
                                </Text>
                            </Value> */}
                            <Value>
                                <Label>Dealer/WS Segment</Label>
                                {targetUser.dealerSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`inAppDealerSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Dealer/WS Sub Segment (
                                    {targetUser.dealerSubSegments.length})
                                </Label>
                                {targetUser.dealerSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`inAppDealerSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Mechanics Segment</Label>
                                {targetUser.mechanicsSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`inAppMechanicsSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Mechanics Sub Segment (
                                    {targetUser.mechanicsSubSegments.length})
                                </Label>
                                {targetUser.mechanicsSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`inAppMechanicsSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() => {
                            if (mode === 'View') {
                                navigate(-1);
                            } else {
                                stepNavigator((prev: number) => prev - 1);
                            }
                        }}
                    />
                    {mode !== 'View' && (
                        <Button
                            onClick={createQuiz}
                            disabled={disableSubmit}
                            p="1em 2em"
                            title="Submit"
                        />
                    )}
                </Action>
            </Form>
        </FlexGrid>
    );
};
