import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexGrid } from '../../components/FlexGrid';
import { ListItem } from '../../components/ListItem';
import { PageHeader } from '../../components/PageHeader';
import { PageWrapper } from '../../components/PageWrapper';
import { SearchBar } from '../../components/SearchBar';
import { Text } from '../../components/Text';
import {
    VideoPlaylist2Icon,
    VideoPlaylistIcon,
    DeleteIcon,
    EditIcon,
    SortIcon,
    PlayIcon,
    MoreIcon,
    CheckIcon,
    CheckCircleBigIcon,
} from '../../assets/icons';
import { COLORS } from '../../styles/variables';
import { IconButton } from '../../components/IconButton';
import { Button } from '../../components/Button';
import { Tooltip } from '../../components/Tooltip';
import { Modal } from '../../components/Modal';
import { Input } from '../../components/Input';
import { SelectCountry } from '../../components/SelectCountry';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { ContentfulTransformService } from '../../services/ContentfulTransform.service';
import { useVideoPlaylistHook } from './hooks/use-video-playlist-hook';
import { appHistory } from '../../utils/history';
import { ContentfulApiService } from '../../services/ContentfulApi.service';
import {
    getUrlKeepQuery,
    getUrlStringKeepQuery,
    isStringNotRequireAndWhiteSpaces,
} from '../../utils';
import {
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_NAME_LENGTH,
} from '../../common/constants';
import { CreateEditModal } from '../../components/CreateEditModal';

const BottomBorder = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
    padding: 1em 0em;
`;

const ActionButton = styled(Button)`
    gap: 0.5em;
    padding: 0em 1.5em;
    border-left: 1px solid ${COLORS.lightGray};
    border-radius: 0;
    margin: 0.5em 0em;
    padding-right: 1em;

    &.first {
        border-left: none;
        padding-left: 1em;
        padding-right: 1.5em;
    }
`;

const ImageContainer = styled(FlexGrid)`
    width: 120px;
    height: 64px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    img {
        object-fit: cover;
        height: 100%;
    }
`;

const Length = styled(Text)`
    position: absolute;
    bottom: 0.2em;
    left: 0.2em;
    font-size: 0.75rem;
    background-color: ${COLORS.transparentBlack75};
    color: ${COLORS.white};
    padding: 0.3em;
    border-radius: 2px;
`;

const NewTag = styled(Text)`
    padding: 0.3em 0.5em;
    background-color: ${COLORS.darkRed};
    color: ${COLORS.white};
    font-family: 'AR';
    text-transform: uppercase;
    border-radius: 2px;
    margin-left: 0.5em;
`;

const BorderBottom = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
`;

const PageContent = styled(FlexGrid)`
    max-width: 1020px;
    width: 100%;
    padding: 2em 0em;
    gap: 1em;
`;

export const VideoPlaylist: React.FC = () => {
    const [apiUpdate, setApiUpdate] = useState(false);
    const [{ hookData: contentfulPlaylists }] = useVideoPlaylistHook({}, [
        apiUpdate,
    ]);
    const [sortBy, setSortBy] = useState('Latest');
    const [playlistName, setPlaylistName] = useState('');
    const [playlistDescription, setPlaylistDescription] = useState('');

    const [editModal, setEditModal] = useState(false);
    const [editNameValue, setEditNameValue] = useState('');
    const [editDescriptionValue, setDescriptionValue] = useState('');
    const [deleteModal, setDeleteModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [createEditSuccess, setCreateEditSuccess] = useState(null);
    const [createPlaylistDisabled, setCreatePlaylistDisabled] = useState(false);
    const [deleteSuccessModal, setDeleteSuccessModal] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [activePlaylist, setActivePlaylist] = useState(0);
    const params = useParams();
    const navigate = useNavigate();

    const openDetails = (video: any) => {
        window.location.pathname = `/video/${video.id}`;
    };

    const [videoPlaylistId, setVideoPlaylistId] = useState(
        params.videoPlaylistId
    );

    // const videos = playlists[activePlaylist] || [];

    const videos = ContentfulTransformService.getPlaylistVideos(
        contentfulPlaylists[activePlaylist] || {},
        contentfulPlaylists
    );

    const playlists =
        ContentfulTransformService.getPlaylist(contentfulPlaylists);

    useEffect(() => {
        const index = playlists.findIndex((p) => p.id === videoPlaylistId);
        if (index > -1) {
            setActivePlaylist(index);
        }

        if (!contentfulPlaylists.length) {
            // navigate(getUrlKeepQuery(`/communication/video-playlist`));
        } else {
            if (
                !contentfulPlaylists.find((p) => p.sys.id === videoPlaylistId)
            ) {
                navigate(
                    getUrlKeepQuery(
                        `/communication/video-playlist/${contentfulPlaylists[0].sys.id}`
                    )
                );
            }
        }
    }, [contentfulPlaylists]);

    useEffect(() => {
        const index = playlists.findIndex((p) => p.id === videoPlaylistId);
        if (index > -1) {
            setActivePlaylist(index);
            setEditNameValue(playlists[index]?.title);
            setDescriptionValue(playlists[index]?.description);
        }
    }, [videoPlaylistId]);

    useEffect(() => {
        setVideoPlaylistId(params.videoPlaylistId);
    }, [params.videoPlaylistId]);

    return (
        <PageWrapper>
            <CreateEditModal
                mode="create"
                open={createModal}
                onClose={() => {
                    setPlaylistName('');
                    setPlaylistDescription('');
                    setCreateModal(false);
                }}
                onSubmit={() => {
                    setCreatePlaylistDisabled(true);
                    ContentfulApiService.createContent('videoPlaylist', {
                        name: playlistName,
                        description: playlistDescription,
                    })
                        .then(() => {
                            setCreateEditSuccess(true);
                            setPlaylistName('');
                            setPlaylistDescription('');
                            setApiUpdate(!apiUpdate);
                        })
                        .finally(() => {
                            setCreatePlaylistDisabled(false);
                        });
                }}
                setSuccess={setCreateEditSuccess}
                success={createEditSuccess}
                title="Create New Playlist"
                disabled={
                    createPlaylistDisabled ||
                    playlistName.trim() === '' ||
                    isStringNotRequireAndWhiteSpaces(playlistDescription)
                }
                inputComponents={[
                    <Input
                        maxLength={LIMIT_NAME_LENGTH}
                        label="Playlist Name"
                        value={playlistName}
                        onChange={(val) => setPlaylistName(val)}
                    />,
                    <Input
                        maxLength={LIMIT_DESCRIPTION_LENGTH}
                        placeholder="Enter"
                        multiline
                        height="88px"
                        label="Description"
                        value={playlistDescription}
                        onChange={(val) => setPlaylistDescription(val)}
                    />,
                ]}
            />
           <CreateEditModal
                mode="edit"
                open={editModal}
                onClose={() => {
                    setEditNameValue('');
                    setDescriptionValue('');
                    setEditModal(false);
                }}
                onSubmit={async () => {
                    try {
                        setDisableSubmit(true);
                        const response = await ContentfulApiService.updateContentFields(
                            playlists[activePlaylist].id,
                            contentfulPlaylists[activePlaylist],
                            {
                                name: editNameValue,
                                description: editDescriptionValue,
                            }
                        );
                        await ContentfulApiService.publish(response.sys.id);
                        console.log('Update video playlist published');
                        setCreateEditSuccess(true);
                    } catch (error) {
                        console.error('Failed to update article folder', error);
                    } finally {
                        setDisableSubmit(false);
                        setApiUpdate(!apiUpdate);
                    }
                }}
                setSuccess={setCreateEditSuccess}
                success={createEditSuccess}
                title="Edit Playlist"
                disabled={
                    disableSubmit ||
                    editNameValue.trim() === '' ||
                    isStringNotRequireAndWhiteSpaces(editDescriptionValue)
                }
                inputComponents={[
                    <Input
                        maxLength={LIMIT_NAME_LENGTH}
                        label="Playlist Name"
                        value={editNameValue}
                        onChange={(val) => setEditNameValue(val)}
                    />,
                    <Input
                        maxLength={LIMIT_DESCRIPTION_LENGTH}
                        placeholder="Enter"
                        multiline
                        height="88px"
                        label="Description"
                        value={editDescriptionValue}
                        onChange={(val) => setDescriptionValue(val)}
                    />,
                ]}
            />
            {deleteSuccessModal && (
                <Modal
                    header="Delete Successful"
                    content={
                        <FlexGrid
                            direction="row"
                            width="100%"
                            gap="1em"
                            p="2em 0em"
                            alignItems="center"
                        >
                            <CheckCircleBigIcon />

                            <Text color={COLORS.darkGray}>
                                Playlist has been delelted successfully
                            </Text>
                        </FlexGrid>
                    }
                    actions={
                        <FlexGrid alignItems="center">
                            <Button
                                p="1.3em 2.5em"
                                onClick={() => {
                                    setDeleteSuccessModal(false);
                                }}
                            >
                                DONE
                            </Button>
                        </FlexGrid>
                    }
                    open={deleteSuccessModal}
                    onClose={() => setDeleteSuccessModal(!deleteSuccessModal)}
                />
            )}
            <Modal
                open={deleteModal}
                header="Delete Playlist"
                onClose={() => setDeleteModal(false)}
                content={
                    <FlexGrid
                        direction="column"
                        width="100%"
                        gap="2em"
                        p="2em 0em"
                    >
                        <Text color={COLORS.darkGray}>
                            Are you sure you want to delete Playlist?
                        </Text>
                        <Text color={COLORS.darkGray}>
                            <Text color={COLORS.darkGray} span font="AR Bold">
                                Note:{' '}
                            </Text>
                            Deleting playlists is a permanent action and cannot
                            be undone.
                        </Text>
                    </FlexGrid>
                }
                actions={
                    <FlexGrid alignItems="center">
                        <Button
                            p="1.3em 2.5em"
                            onClick={() => {
                                ContentfulApiService.deleteContent(
                                    videoPlaylistId
                                )
                                    .then(() => {
                                        setApiUpdate(!apiUpdate);
                                        setDeleteSuccessModal(true);
                                    })
                                    .finally(() => {
                                        setDeleteModal(false);
                                    });
                            }}
                        >
                            DELETE
                        </Button>
                        <Button
                            negative
                            p="0.5em 1em"
                            onClick={() => {
                                setDeleteModal(false);
                            }}
                        >
                            CANCEL
                        </Button>
                    </FlexGrid>
                }
            />
            <PageHeader
                back
                actions={
                    <FlexGrid gap="1.5em">
                        <SearchBar placeholder="Search banners, videos, articles here…" />
                        <SelectCountry />
                    </FlexGrid>
                }
            />
            <FlexGrid width="100%" justifyContent="center">
                <PageContent>
                    <FlexGrid direction="column" width="30%" gap="1em">
                        <Text font="OS" fontSize="2.25rem" uppercase>
                            VIDEO PLAYLIST
                        </Text>
                        <FlexGrid direction="column" width="100%">
                            {playlists.map((playlist, i) => (
                                <ListItem
                                    middleFlexible
                                    key={i}
                                    active={videoPlaylistId === playlist.id}
                                    onClick={() => {
                                        setVideoPlaylistId(playlist.id);
                                        appHistory.push(
                                            getUrlStringKeepQuery(
                                                `/communication/video-playlist/${playlists[i].id}`
                                            )
                                        );
                                    }}
                                    title={playlist.title}
                                    icon={<VideoPlaylist2Icon height="16" />}
                                    color={COLORS.green}
                                    bottom={
                                        <FlexGrid
                                            gap="0.2em"
                                            alignItems="center"
                                        >
                                            <Text fontSize="0.75rem">
                                                {playlist.total} Videos
                                            </Text>
                                        </FlexGrid>
                                    }
                                />
                            ))}
                            <ListItem
                                onClick={() => setCreateModal(true)}
                                title="Create New Playlist"
                                icon={<VideoPlaylistIcon height="16" />}
                                color={COLORS.green}
                            />
                        </FlexGrid>
                    </FlexGrid>
                    <FlexGrid flex="1" direction="column">
                        <BottomBorder
                            width="100%"
                            justifyContent="space-between"
                        >
                            <FlexGrid direction="column" width="70%" gap="1em">
                                <Text fontSize="1.5rem">
                                    {playlists[activePlaylist]?.title}
                                </Text>
                                <Text
                                    workBreakAll
                                    fontSize="0.875rem"
                                    color={COLORS.darkGray}
                                >
                                    {playlists[activePlaylist]?.description}
                                </Text>
                            </FlexGrid>
                            <FlexGrid gap="1.5em">
                                <Tooltip title="Edit Playlist">
                                    <IconButton
                                        disabled={!playlists.length}
                                        onClick={() => setEditModal(true)}
                                        negative
                                        icon={
                                            <EditIcon
                                                color={
                                                    !playlists.length
                                                        ? COLORS.gray
                                                        : COLORS.green
                                                }
                                            />
                                        }
                                    />
                                </Tooltip>
                                <Tooltip title="Delete Playlist">
                                    <IconButton
                                        disabled={!playlists.length}
                                        onClick={() => setDeleteModal(true)}
                                        negative
                                        icon={
                                            <DeleteIcon
                                                color={
                                                    !playlists.length
                                                        ? COLORS.gray
                                                        : COLORS.red
                                                }
                                            />
                                        }
                                    />
                                </Tooltip>
                            </FlexGrid>
                        </BottomBorder>
                        <FlexGrid
                            width="100%"
                            justifyContent="space-between"
                            p="1em 0em"
                        >
                            <Text fontSize="1.125rem">Video List</Text>
                            <FlexGrid>
                                <ActionButton className="first" negative>
                                    <Tooltip title="Sort by">
                                        <SortIcon />
                                    </Tooltip>
                                    <Text
                                        onClick={() => setSortBy('Latest')}
                                        fontSize="1rem"
                                        font="AR"
                                    >
                                        Latest
                                    </Text>
                                </ActionButton>
                                <ActionButton
                                    negative
                                    onClick={() => {
                                        if (videos.length) {
                                            window.location.pathname = `/video/${videos[0].id}`;
                                        }
                                    }}
                                >
                                    <PlayIcon />
                                    <Text fontSize="1rem" font="AR">
                                        Play All
                                    </Text>
                                </ActionButton>
                            </FlexGrid>
                        </FlexGrid>
                        <FlexGrid flex="1" direction="column" width="100%">
                            {videos.map((video, key) => (
                                <BottomBorder
                                    key={key}
                                    justifyContent="space-between"
                                    width="100%"
                                    alignItems="center"
                                >
                                    <FlexGrid flex="1" gap="1em">
                                        <ImageContainer>
                                            <img
                                                style={{ cursor: 'pointer' }}
                                                src={video.image}
                                                alt="video thumbnail"
                                                onClick={() =>
                                                    openDetails(video)
                                                }
                                            />
                                            {/* <Length>{video.length}</Length> */}
                                        </ImageContainer>
                                        <FlexGrid
                                            direction="column"
                                            width="100%"
                                            gap="0.5em"
                                        >
                                            <Text
                                                font="AR Bold"
                                                color={COLORS.green}
                                            >
                                                <span
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        openDetails(video)
                                                    }
                                                >
                                                    {video.title}
                                                </span>
                                                {video.new && (
                                                    <NewTag
                                                        fontSize="12px"
                                                        span
                                                    >
                                                        New
                                                    </NewTag>
                                                )}
                                            </Text>
                                            <Text
                                                fontSize="0.875rem"
                                                color={COLORS.darkGray}
                                            >
                                                {video.date}
                                            </Text>
                                            <Text
                                                fontSize="0.875rem"
                                                color={COLORS.darkGray}
                                            >
                                                Views {video.views}
                                                <Text
                                                    span
                                                    color={COLORS.lightGray}
                                                >
                                                    {'  '}•{'  '}
                                                </Text>
                                                Likes {video.likes}
                                            </Text>
                                        </FlexGrid>
                                    </FlexGrid>
                                    <IconButton
                                        negative
                                        icon={<MoreIcon height="30" />}
                                    />
                                </BottomBorder>
                            ))}
                        </FlexGrid>
                        <Button negative m="0" p="2em 0em">
                            <Text fontSize="0.875rem">Show More</Text>
                        </Button>
                    </FlexGrid>
                </PageContent>
            </FlexGrid>
        </PageWrapper>
    );
};
