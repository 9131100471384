import styled from 'styled-components';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';
import { CheckIcon } from '../assets/icons';
import { COLORS } from '../styles/variables';

const CheckBoxInput = styled(FlexGrid)`
    align-items: center;
    justify-content: center;
    border: 1px solid ${COLORS.black};
    padding: 3px;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 3px;
    margin-right: 0.5em;
`;

const CheckBoxContainer = styled(FlexGrid)`
    cursor: pointer;
    align-items: center;
    gap: 0.3em;
`;

interface CheckBoxProps {
    label?: string;
    checked?: boolean;
    onChange?: () => void;
    labelColor?: string;
}

export const CheckBox: React.FC<CheckBoxProps> = ({
    label,
    checked,
    onChange,
    labelColor,
}) => {
    return (
        <CheckBoxContainer onClick={onChange}>
            <CheckBoxInput>
                {checked && <CheckIcon height="12" />}
            </CheckBoxInput>
            <Text color={labelColor || COLORS.darkGray}>{label}</Text>
        </CheckBoxContainer>
    );
};
