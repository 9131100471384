import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { COLORS, COMMON } from '../styles/variables';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';
import { Info } from './Info';

interface InputContainerProps {
    className?: string;
    info?: { title: any; content: any };
    label?: string;
    type?: 'fileUpload' | 'textEditor' | 'textArea';
    flat?: boolean;
    m?: string;
    p?: string;
    onClick?: () => void;
    ref?: React.MutableRefObject<HTMLInputElement>;
    containerRef?: React.MutableRefObject<HTMLInputElement>;
    focused?: boolean;
    border?: boolean;
    height?: string;
    disableInfoClick?: string;
    transform?: string;
    onClickInfoDetails?: (event: any) => void;
    disabled?: boolean;
}

const Container = styled(FlexGrid)<InputContainerProps>`
    width: 100%;
    flex-direction: column;
    margin: 0.8em 0em;
    position: relative;
    margin: ${({ m }) => m || '0'};
    padding: ${({ p }) => p || '0'};
    transform: ${({ transform, flat }) => transform || 'none'};
`;

const InputField = styled(FlexGrid)<InputContainerProps>`
    box-shadow: ${({ type, flat }) =>
        flat || type === 'fileUpload' ? 'none' : COMMON.shadow};
    border-radius: 3px;
    border: ${({ type, focused, border }) => {
        if (focused) {
            return `1px solid ${COLORS.green}`;
        }
        if (type === 'fileUpload') {
            return `2px dashed ${COLORS.lightGray}`;
        }
        if (border) {
            return `1px solid ${COLORS.lightGray}`;
        }
        return 'none';
    }};
    font-size: 0.8rem;
    padding: ${({ type }) => (type === 'textEditor' ? '0em' : '1em')};
    height: ${({ type, height }) => {
        if (height) return height;
        switch (type) {
            case 'textArea':
                return '100px';
            case 'textEditor':
                return '230px';
            default:
                return '40px';
        }
    }};
    width: 100%;
    margin-top: 0.3em;
    align-items: center;

    input {
        flex: 1;
        height: 100%;
        border: none;
    }

    input:focus {
        outline: none;
    }

    textarea {
        flex: 1;
        height: 100%;
        border: none;
        resize: none;
    }

    textarea:focus {
        outline: none;
    }

    &:focus-within {
        border: 1px solid ${COLORS.green};
    }
`;

export const InputContainer: React.FC<InputContainerProps> = ({
    className,
    children,
    label,
    info,
    type,
    flat,
    p,
    m,
    onClick,
    ref,
    containerRef,
    focused,
    border,
    height,
    disableInfoClick = false,
    transform,
    onClickInfoDetails,
    disabled,
}) => {

    return (
        <Container
            ref={containerRef}
            className={className}
            p={p}
            m={m}
            transform={transform}
            disabled={disabled}
        >
            <FlexGrid
                width="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                <Text color={COLORS.darkGray} fontSize="0.75rem">
                    {label}
                </Text>
                {info && (
                    <Info
                        info={info}
                        onClickDetails={(event) => {
                            if (disableInfoClick) {
                                event.stopPropagation();
                            } else {
                                onClickInfoDetails && onClickInfoDetails(event);
                            }
                        }}
                    />
                )}
            </FlexGrid>
            <InputField
                focused={focused}
                flat={flat}
                type={type}
                border={border}
                height={height}
                onClick={disabled ? undefined : onClick}
                disabled={disabled}
            >
                {children}
            </InputField>
        </Container>
    );
};
