import React, { MouseEvent, MouseEventHandler } from 'react';
import styled from 'styled-components';
import { FlexGrid } from './FlexGrid';
import { ArrowRightIcon, ArrowLeftIcon,CheckIcon } from '../assets/icons';
import { COLORS } from '../styles/variables';

interface ButtonProps {
    onClick?: () => void;
    title?: string;
    icon?: 'next' | 'back' | 'check'| any;
    negative?: boolean;
    className?: string;
    m?: string;
    p?: string;
    iconSlot?: 'start' | 'end';
    disableUnderline?: boolean;
    width?: string;
    height?: string;
    bg?: string;
    disabled?: boolean;
    iconHeight?: string;
}

interface IconProps {
    back?: boolean;
    start?: boolean;
}

const ButtonIcon = styled(({ start, back, ...props }) => (
    <FlexGrid {...props}></FlexGrid>
))<IconProps>`
    margin: ${({ back, start }) =>
        back || start ? '0em 0.8em 0em 0em' : '0em 0em 0em 0.8em'};
`;

interface StyledButtonProps {
    negative?: boolean;
    bg?: string;
    m?: string;
    p?: string;
    width?: string;
    height?: string;
    disableUnderline?: boolean;
    disabled?: boolean;
}

const StyledButton = styled(FlexGrid)<StyledButtonProps>`
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: ${({ negative, disabled }) => (negative ? (disabled ? COLORS.gray : COLORS.green) : COLORS.white)};
    background-color: ${({ negative, bg, disabled }) => {
        if (negative) return COLORS.transparent;
        if (disabled) return COLORS.gray;
        if (bg) return bg;
        return COLORS.green;
    }};
    padding: ${({ p }) => p || '0.5em'};
    margin: ${({ m }) => m || '5px'};
    border-radius: 100px;
    font-family: 'AR Bold';
    cursor: pointer;
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};

    &:hover {
        text-decoration: ${({ negative, disableUnderline }) =>
            negative && !disableUnderline ? 'underline' : 'none'};
    }
`;

export const Button: React.FC<ButtonProps> = ({
    className,
    icon,
    onClick,
    children,
    title,
    negative,
    p,
    m,
    iconSlot,
    disableUnderline,
    width,
    height,
    bg,
    disabled,
    iconHeight,
}) => {
    const renderIcon = () => {
        if (!icon) return null;
        if (icon === 'next')
            return (
                <ButtonIcon start={iconSlot === 'start'}>
                    <ArrowRightIcon
                        color={
                            negative && !disabled ? COLORS.green : COLORS.white
                        }
                        height={iconHeight || '15'}
                    />
                </ButtonIcon>
            );
        if (icon === 'back')
            return (
                <ButtonIcon start={iconSlot === 'start'} back>
                    <ArrowLeftIcon
                        color={
                            negative && !disabled ? COLORS.green : COLORS.white
                        }
                        height={iconHeight || '15'}
                    />
                </ButtonIcon>
            );
            if (icon === 'check')
            return (
                <ButtonIcon start={iconSlot === 'start'}>
                    <CheckIcon
                        color={
                            negative && !disabled ? COLORS.green : COLORS.white
                        }
                        height={iconHeight || '15'}
                    />
                </ButtonIcon>
            );
        return <ButtonIcon start={iconSlot === 'start'}>{icon}</ButtonIcon>;
    };

    const buttonHandler: MouseEventHandler<HTMLDivElement> = (
        event: MouseEvent<HTMLDivElement>
    ) => {
        event.stopPropagation();
        if (onClick) {
            onClick();
        }
    };

    return (
        <StyledButton
            m={m}
            p={p}
            className={className}
            onClick={disabled ? undefined : buttonHandler}
            negative={negative}
            disableUnderline={disableUnderline}
            width={width}
            bg={bg}
            height={height}
            disabled={disabled}
        >
            {icon &&
                (icon === 'back' || (iconSlot && iconSlot === 'start')) &&
                renderIcon()}
            {title || children}
            {icon &&
                icon !== 'back' &&
                (icon === 'next' || !iconSlot || iconSlot === 'end') &&
                renderIcon()}
        </StyledButton>
    );
};
