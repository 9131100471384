import React, { useEffect, useState } from 'react';
import { InAppNotification2Icon, LinkIcon } from '../assets/icons';
import { RadioGroup } from './RadioGroup';
import { Input } from './Input';
import { INTERNAL_PAGE_LINK, INTERNAL_PAGE_LINK_REVERSED, LIMIT_DEFAULT_TEXT_LENGTH } from '../common/constants';
// import { type } from 'os';
import { Select } from './Select';
import _ from 'lodash';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';

interface TargetLinkValueProps {
    isLinkInternal: boolean;
    linkSource: string;
}

interface TargetLinkProps {
    value: string;
    isLinkInternal?: boolean;
    title?: string;
    // eslint-disable-next-line no-unused-vars
    onChange: (value: TargetLinkValueProps) => void;
}

export const TargetLink: React.FC<TargetLinkProps> = ({
    title,
    value,
    isLinkInternal: isInternal = true,
    onChange,
}) => {
    const [isLinkInternal, setIsLinkInternal] = useState(isInternal);
    const [linkSource1, setLinkSource1] = useState('');
    const [linkSource2, setLinkSource2] = useState('');

    useEffect(() => {
        if (isLinkInternal) {
            setLinkSource1(value);
        } else {
            setLinkSource2(value);
        }
    }, [isLinkInternal, value]);

    return (
        <>
            <RadioGroup
                label={title || 'Link'}
                info={{
                    title: 'Link Internal',
                    content:
                        'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                }}
                options={[
                    { label: 'INTERNAL', value: 'INTERNAL' },
                    { label: 'EXTERNAL', value: 'EXTERNAL' },
                ]}
                value={isLinkInternal ? 'INTERNAL' : 'EXTERNAL'}
                onChange={(val) => {
                    setIsLinkInternal(val === 'INTERNAL');
                    onChange({
                        isLinkInternal,
                        linkSource:
                            val === 'INTERNAL' ? linkSource1 : linkSource2,
                    });
                }}
            />
            {isLinkInternal ? (
                <Select
                    form
                    label="Link Source"
                    placeholder="Select an internal page"
                    info={{
                        title: 'Link Internal',
                        content: 'Link source to internal content',
                    }}
                    options={_.keys(INTERNAL_PAGE_LINK).map((x) => x)}
                    renderValue={() => (
                        <Text color="#000000">
                            {INTERNAL_PAGE_LINK_REVERSED[linkSource1] ||
                                linkSource1}
                        </Text>
                    )}
                    value={
                        INTERNAL_PAGE_LINK_REVERSED[linkSource1] || linkSource1
                    }
                    onChange={(value: any) => {
                        const deepLinkingUrl = INTERNAL_PAGE_LINK[value];
                        if (deepLinkingUrl) {
                            setLinkSource1(deepLinkingUrl);
                            onChange({
                                isLinkInternal,
                                linkSource: deepLinkingUrl,
                            });
                        }
                    }}
                    renderOption={(option: string) => (
                        <FlexGrid width="100%" alignItems="center" gap="1em">
                            <InAppNotification2Icon />
                            <Text>{option}</Text>
                        </FlexGrid>
                    )}
                />
            ) : (
                // <Input
                //     label="Link Source"
                //     info={{
                //         title: 'Link Internal',
                //         content: 'Link source to internal content',
                //     }}
                //     icon={<InAppNotification2Icon />}
                //     maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                //     iconPosition="start"
                //     value={linkSource1}
                //     onChange={(val) => {
                //         setLinkSource1(val);
                //         onChange({
                //             isLinkInternal,
                //             linkSource: val,
                //         });
                //     }}
                // />
                <Input
                    label="Link Source"
                    info={{
                        title: 'Link External',
                        content: 'Link source to external content',
                    }}
                    icon={<LinkIcon />}
                    iconPosition="start"
                    value={linkSource2}
                    maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                    onChange={(val) => {
                        setLinkSource2(val);
                        onChange({
                            isLinkInternal,
                            linkSource: val,
                        });
                    }}
                />
            )}
        </>
    );
};
