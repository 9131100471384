import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/variables';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';

interface ToastProps {
    title: string;
    open: boolean;
    onClose: () => void;
}

interface ContainerProps {
    open: boolean;
}

const ToastContainer = styled(FlexGrid)<ContainerProps>`
    display: ${({ open }) => (open ? 'flex' : 'none')};
    position: fixed;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: flex-end;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 2em;
`;

const ToastBody = styled(FlexGrid)`
    padding: 0.5em 1em;
    background-color: ${COLORS.transparentBlack75};
    border-radius: 5px;
`;

export const Toast: React.FC<ToastProps> = ({ title, open, onClose }) => {
    useEffect(() => {
        if (open) {
            setTimeout(() => onClose(), 1000);
        }
    }, [open, onClose]);

    return (
        <ToastContainer open={open}>
            <ToastBody>
                <Text color={COLORS.white} fontSize="0.9em">
                    {title}
                </Text>
            </ToastBody>
        </ToastContainer>
    );
};
