import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import { GLOBAL_CONFIG } from '../../../config/config';
import _ from 'lodash';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';

type Input = {
    pagination: {
        page: number;
        perPage: number;
    };
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const pg = props.input.pagination;

    const data = await ContentfulApiService.searchContent({
        contentType: 'articleFolder',
        limit: pg.perPage,
        page: pg.page || 1,
        orderByCreated: true,
    });

    const assets = await ContentfulApiService.getAllAssets();

    data.assets = assets;

    data.items.map(async (d, i) => {
        const entries = await ContentfulApiService.searchContent({
            contentType: 'article',
            params: {
                'fields.folders.sys.id[in]': d.sys.id,
            },
        });
        data.items[i] = {
            ...d,
            total: entries.total,
            articles: entries,
        };
        props.updateData({ ...data });
    });

    return data;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useDashboardArticleFolderHook = (
    pagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
