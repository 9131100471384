import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';
import { CheckIcon, RemoveCircleIcon } from '../assets/icons';

interface TagProps {
    title?: string;
    className?: string;
    removeBtn?: boolean;
    button?: boolean;
    active?: boolean;
    // eslint-disable-next-line no-unused-vars
    onRemove?: (value: string) => void;
    onClick?: () => void;
    checked?: boolean;
}

const TagContainer = styled(FlexGrid)<TagProps>`
    background-color: ${({ active, checked }) =>
        active || checked ? COLORS.green : COLORS.veryLightGray};
    border-radius: 50px;
    padding: ${({ removeBtn }) =>
        removeBtn ? '0.25em 0.25em 0.25em 0.5em' : '0.4em 0.75em'};
    gap: 0.25em;
    align-items: center;
`;

export const Tag: React.FC<TagProps> = ({
    title,
    className,
    removeBtn,
    button,
    active,
    onRemove,
    onClick,
    checked,
}) => {
    if (button) {
        return (
            <Button onClick={onClick} m="0" p="0">
                <TagContainer
                    active={active}
                    checked={checked}
                    removeBtn={removeBtn}
                    className={className}
                >
                    <Text
                        fontSize="0.625rem"
                        uppercase
                        font="AR Bold"
                        color={
                            active || checked ? COLORS.white : COLORS.darkGray
                        }
                    >
                        {title}
                    </Text>
                    {removeBtn && (
                        <Button
                            onClick={() => onRemove && onRemove(title || '')}
                            m="0"
                            p="0"
                            negative
                        >
                            <RemoveCircleIcon height="20" />
                        </Button>
                    )}
                    {checked && (
                        <FlexGrid p="0 0 0 0.2rem">
                            <CheckIcon height="10" color={COLORS.white} />
                        </FlexGrid>
                    )}
                </TagContainer>
            </Button>
        );
    }

    return (
        <TagContainer removeBtn={removeBtn} className={className}>
            <Text
                fontSize="0.625rem"
                uppercase
                font="AR Bold"
                color={COLORS.green}
            >
                {title}
            </Text>
            {removeBtn && (
                <Button
                    onClick={() => onRemove && onRemove(title || '')}
                    m="0"
                    p="0"
                    negative
                >
                    <RemoveCircleIcon height="20" />
                </Button>
            )}
        </TagContainer>
    );
};
