import styled from 'styled-components';
import { ArrowDownIcon } from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { IconButton } from '../../../components/IconButton';
import {
    Table,
    TableCell,
    TableHead,
    TableRow,
} from '../../../components/Table';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';

const Content = styled(FlexGrid)`
    width: 100%;
    flex: 1;
`;

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};
    padding: 0.5em 1em;

    &:first-of-type {
        border-left: none;
        padding-left: 0;
    }
`;

export const UserLimit = () => {
    return (
        <>
            <Content>
                <Table>
                    <TableRow>
                        <TableHead> </TableHead>
                        <TableHead>
                            <FlexGrid alignItems="center" gap="0.8em">
                                Dealer/Retailer
                                <IconButton negative icon={<ArrowDownIcon />} />
                            </FlexGrid>
                        </TableHead>
                        <TableHead>Workshop</TableHead>
                        <TableHead>Mechanic</TableHead>
                        <TableHead>Actions</TableHead>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Text fontSize="0.875rem" color={COLORS.green}>
                                Daily Scan Limit
                            </Text>
                        </TableCell>
                        <TableCell>50</TableCell>
                        <TableCell>30</TableCell>
                        <TableCell>25</TableCell>
                        <TableCell>
                            <FlexGrid alignItems="center">
                                <TableActionBtn negative>Edit</TableActionBtn>
                                <TableActionBtn negative>Delete</TableActionBtn>
                            </FlexGrid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Text fontSize="0.875rem" color={COLORS.green}>
                                Annual Scan Limit
                            </Text>
                        </TableCell>
                        <TableCell>50</TableCell>
                        <TableCell>3500</TableCell>
                        <TableCell>1500</TableCell>
                        <TableCell>
                            <FlexGrid alignItems="center">
                                <TableActionBtn negative>Edit</TableActionBtn>
                                <TableActionBtn negative>Delete</TableActionBtn>
                            </FlexGrid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Text fontSize="0.875rem" color={COLORS.green}>
                                Daily Earning Limit
                            </Text>
                        </TableCell>
                        <TableCell>2500</TableCell>
                        <TableCell>2000</TableCell>
                        <TableCell>1500</TableCell>
                        <TableCell>
                            <FlexGrid alignItems="center">
                                <TableActionBtn negative>Edit</TableActionBtn>
                                <TableActionBtn negative>Delete</TableActionBtn>
                            </FlexGrid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Text fontSize="0.875rem" color={COLORS.green}>
                                Annual Earning Limit
                            </Text>
                        </TableCell>
                        <TableCell>125000</TableCell>
                        <TableCell>10000</TableCell>
                        <TableCell>50000</TableCell>
                        <TableCell>
                            <FlexGrid alignItems="center">
                                <TableActionBtn negative>Edit</TableActionBtn>
                                <TableActionBtn negative>Delete</TableActionBtn>
                            </FlexGrid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Text fontSize="0.875rem" color={COLORS.green}>
                                Acme Earning Limit
                            </Text>
                        </TableCell>
                        <TableCell>2500</TableCell>
                        <TableCell>2000</TableCell>
                        <TableCell>1500</TableCell>
                        <TableCell>
                            <FlexGrid alignItems="center">
                                <TableActionBtn negative>Edit</TableActionBtn>
                                <TableActionBtn negative>Delete</TableActionBtn>
                            </FlexGrid>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Text fontSize="0.875rem" color={COLORS.green}>
                                Acme Earning Limit
                            </Text>
                        </TableCell>
                        <TableCell>125000</TableCell>
                        <TableCell>10000</TableCell>
                        <TableCell>50000</TableCell>
                        <TableCell>
                            <FlexGrid alignItems="center">
                                <TableActionBtn negative>Edit</TableActionBtn>
                                <TableActionBtn negative>Delete</TableActionBtn>
                            </FlexGrid>
                        </TableCell>
                    </TableRow>
                </Table>
            </Content>

            <Button m="0em 0em 0em auto" p="1em 3em">
                <Text font="OS">SUBMIT</Text>
            </Button>
        </>
    );
};
