import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgBanners: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path data-name="Path 4776" d="M0 0h24v24H0Z" fill="none" />
        <path
            className="icon-fill"
            data-name="Path 4777"
            d="M19 5v14H5V5h14m0-2H5a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V5a2.006 2.006 0 0 0-2-2Zm-4.86 8.86-3 3.87L9 13.14 6 17h12l-3.86-5.14Z"
            fill={color || '#333'}
        />
    </svg>
);

export default SvgBanners;
