import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
    // LIMIT_DEFAULT_TEXT_LENGTH,
    // LIMIT_DESCRIPTION_LENGTH,
    // LIMIT_HEADLINE_LENGTH,
    // LIMIT_NAME_LENGTH,
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_HEADLINE_LENGTH,
    NUM_OPTIONS_QUIZ,
} from '../../../common/constants';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { FlexGrid } from '../../../components/FlexGrid';
import { Input } from '../../../components/Input';
// import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
import { PointsPickerInput } from '../../../components/PointsPickerInput';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { QuizType } from '../../../types';
import {
    composeDate,
    ContentStatus,
    genNewQuestion,
    getContentStatus,
    isRequireOrWhiteSpaces,
    isStringRequiredMissing,
} from '../../../utils';
import {
    BonusPointsIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    MinusIcon,
    PlusIcon,
    PointsIcon,
} from '../../../assets/icons';
import { SectionHeader } from '../../../components/SectionHeader';
import { IconButton } from '../../../components/IconButton';
import { QuizOptionsInput } from '../../../components/QuizOptionsInput';
import { updateModalAction } from '../../../store/store.actions';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    max-width: 500px;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    stepNavigator?: any;
    quizState: [QuizType, React.Dispatch<React.SetStateAction<QuizType>>];
    initQuizData: QuizType;
}

export const QuizContent: React.FC<Props> = ({ stepNavigator, quizState, initQuizData }) => {
    const dispatch = useDispatch();
    const [quiz, setQuiz] = quizState;
    const { content } = quiz;
    const { appLanguages } = quiz.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);
    const [questionsCount, setQuestionsCount] = useState(
        content.questions.length
    );
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
    // console.log('==activeQuestionIndex==', activeQuestionIndex);

    useEffect(() => {
        const newQuiz = { ...quiz };
        const numQuestions = newQuiz.content.questions.length;
        if (numQuestions <= 0) {
            newQuiz.content.questions = [genNewQuestion(appLanguages)];
        }
        newQuiz.identifierDefinition.appLanguages.forEach((language) => {
            if (!newQuiz.content.title[language]) {
                newQuiz.content.title[language] = '';
            }
            if (!newQuiz.content.description[language]) {
                newQuiz.content.description[language] = '';
            }
            for (let i = 0; i < numQuestions; ++i) {
                const question = newQuiz.content.questions[i];
                if (!question.content.questionText[language]) {
                    question.content.questionText[language] = '';
                }
                for (let j = 0; j < NUM_OPTIONS_QUIZ; ++j) {
                    if (!question.content.options[j]) {
                        question.content.options[j] = {};
                    }
                    if (!question.content.options[j][language]) {
                        question.content.options[j][language] = '';
                    }
                }
                if (!question.content.reason[language]) {
                    question.content.reason[language] = '';
                }
            }
        });
        console.log('=====newQuiz====', newQuiz);
        setQuiz(newQuiz);
        // setOptionItems();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(content.headline[language] && content.bodyText[language])
            language === activeLanguage
    );

    const dataIncomplete =
        isRequireOrWhiteSpaces(content.title) ||
        isRequireOrWhiteSpaces(content.description) ||
        content.questions.length <= 0; // ||
    // content.questions.reduce(
    //     (prev, question) =>
    //         isRequireOrWhiteSpaces(question.content.questionText) ||
    //         isRequireOrWhiteSpaces(question.content.reason) ||
    //         isRequireOrWhiteSpacesArray(question.content.options) ||
    //         question.content.points === undefined ||
    //         question.content.correctAnswerIndex === undefined,
    //     false
    // );

    // console.log('==content.questions==', content.questions);
    // console.log('==quiz.status==', quiz.status)
    const quizStatus = getContentStatus(
        quiz,
        composeDate(
            initQuizData?.identifierDefinition.startDate,
            initQuizData?.identifierDefinition.startTime
        )?.toDate(),
        composeDate(
            initQuizData?.identifierDefinition.endDate,
            initQuizData?.identifierDefinition.endDate
        )?.toDate()
    );
    const isQuizPublished = quizStatus == ContentStatus.LIVE || quizStatus == ContentStatus.EXPIRED;

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Content
                    </Text>
                }
            />
            <Form>
                <ButtonGroup
                    titles={appLanguages}
                    info={{
                        title: 'Multiple Languages',
                        content:
                            'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                    }}
                    active={activeLanguage}
                    onChange={setActiveLanguage}
                    checked={checkedLanguage}
                />
                <Input
                    label="Title"
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    value={content.title[activeLanguage]}
                    onChange={(val) =>
                        setQuiz((state) => {
                            const newState = { ...state };
                            newState.content.title[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Input
                    label="Description"
                    maxLength={LIMIT_DESCRIPTION_LENGTH}
                    value={content.description[activeLanguage]}
                    onChange={(val) =>
                        setQuiz((state) => {
                            const newState = { ...state };
                            newState.content.description[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <PointsPickerInput
                    label="Bonus points"
                    icon={<BonusPointsIcon />}
                    info={{
                        title: 'Bonus points',
                        content:
                            'You can add bonus points goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                    }}
                    value={content.bonusPoints}
                    onChange={(val) =>
                        setQuiz((state) => {
                            const newState = { ...state };
                            newState.content.bonusPoints = val;
                            return newState;
                        })
                    }
                    disabled={isQuizPublished}
                />
                <SectionHeader
                    title={`QUESTION #${activeQuestionIndex + 1}`}
                    bg="#F5F5F5"
                    fontFamily="OS"
                    height="40px"
                    titleMargin="0 0 0 1em"
                    right={
                        <FlexGrid alignItems="center">
                            <FlexGrid alignItems="center">
                                {questionsCount > 1 && (
                                    <IconButton
                                        negative
                                        p="0"
                                        m="0em 1em"
                                        icon={<MinusIcon color={isQuizPublished ? COLORS.gray : undefined} />}
                                        disabled={isQuizPublished}
                                        onClick={() => {
                                            setQuiz((state) => {
                                                const newState = { ...state };
                                                newState.content.questions.splice(
                                                    activeQuestionIndex,
                                                    1
                                                );
                                                return newState;
                                            });
                                            const prevQuestionCount =
                                                questionsCount;
                                            setQuestionsCount(
                                                prevQuestionCount - 1
                                            );
                                            if (
                                                activeQuestionIndex >=
                                                prevQuestionCount - 1
                                            ) {
                                                setActiveQuestionIndex(
                                                    prevQuestionCount - 2
                                                );
                                            }
                                        }}
                                    />
                                )}
                                {activeQuestionIndex === questionsCount - 1 && (
                                    <IconButton
                                        negative
                                        p="0"
                                        m="0em 1em"
                                        icon={<PlusIcon color={isQuizPublished ? COLORS.gray : undefined} />}
                                        disabled={isQuizPublished}
                                        onClick={() => {
                                            setQuiz((state) => {
                                                const newState = { ...state };
                                                newState.content.questions.push(
                                                    genNewQuestion(appLanguages)
                                                );
                                                return newState;
                                            });
                                            const prevQuestionCount =
                                                questionsCount;
                                            setQuestionsCount(
                                                questionsCount + 1
                                            );
                                            setActiveQuestionIndex(
                                                prevQuestionCount
                                            );
                                        }}
                                    />
                                )}
                                <Button
                                    p="0"
                                    m="0em 1em"
                                    negative
                                    onClick={() => {
                                        setActiveQuestionIndex(
                                            Math.max(0, activeQuestionIndex - 1)
                                        );
                                    }}
                                    disabled={activeQuestionIndex < 1}
                                >
                                    <ChevronRightIcon
                                        color={
                                            activeQuestionIndex < 1
                                                ? COLORS.gray
                                                : undefined
                                        }
                                    />
                                </Button>
                                <Button
                                    p="0"
                                    m="0em 1em"
                                    negative
                                    onClick={() => {
                                        setActiveQuestionIndex(
                                            Math.min(
                                                questionsCount - 1,
                                                activeQuestionIndex + 1
                                            )
                                        );
                                    }}
                                    disabled={
                                        activeQuestionIndex + 1 >=
                                        questionsCount
                                    }
                                >
                                    <ChevronLeftIcon
                                        color={
                                            activeQuestionIndex + 1 >=
                                            questionsCount
                                                ? COLORS.gray
                                                : undefined
                                        }
                                    />
                                </Button>
                            </FlexGrid>
                        </FlexGrid>
                    }
                />
                <FlexGrid
                    direction="column"
                    flex="1"
                    width="100%"
                    p="1em 2em 1em 0em"
                >
                    <PointsPickerInput
                        label="Points per question"
                        icon={<PointsIcon />}
                        info={{
                            title: 'Points per points',
                            content:
                                'You can add points per question goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                        }}
                        value={
                            content.questions[activeQuestionIndex].content
                                .points
                        }
                        disabled={isQuizPublished}
                        onChange={(val) =>
                            setQuiz((state) => {
                                const newState = { ...state };
                                const question =
                                    newState.content.questions[
                                        activeQuestionIndex
                                    ];
                                question.content.points = val;
                                return newState;
                            })
                        }
                    />
                    <Input
                        placeholder="Enter Your Question"
                        maxLength={LIMIT_DESCRIPTION_LENGTH}
                        value={
                            content.questions[activeQuestionIndex].content
                                .questionText[activeLanguage]
                        }
                        onChange={(val) =>
                            setQuiz((state) => {
                                const newState = { ...state };
                                const question =
                                    newState.content.questions[
                                        activeQuestionIndex
                                    ];
                                question.content.questionText[activeLanguage] =
                                    val;
                                return newState;
                            })
                        }
                        disabled={isQuizPublished}
                    />
                    <QuizOptionsInput
                        reason={
                            content.questions[activeQuestionIndex].content
                                .reason[activeLanguage]
                        }
                        correctAnswerIndex={
                            content.questions[activeQuestionIndex].content
                                .correctAnswerIndex
                        }
                        onSelected={(index) => {
                            setQuiz((state) => {
                                const newState = { ...state };
                                const question =
                                    newState.content.questions[
                                        activeQuestionIndex
                                    ];
                                question.content.correctAnswerIndex = index;
                                return newState;
                            });
                        }}
                        onReasonChanged={(val) =>
                            setQuiz((state) => {
                                const newState = { ...state };
                                const question =
                                    newState.content.questions[
                                        activeQuestionIndex
                                    ];
                                question.content.reason[activeLanguage] = val;
                                return newState;
                            })
                        }
                        onOrderChanged={(optionIndexes, selectedIndex) =>
                            setQuiz((state) => {
                                const newState = { ...state };
                                const question =
                                    newState.content.questions[
                                        activeQuestionIndex
                                    ];
                                const newOptions = new Array(
                                    question.content.options.length
                                );
                                for (let i = 0; i < optionIndexes.length; ++i) {
                                    const newOptionIndex = optionIndexes[i];
                                    newOptions[i] = {
                                        ...question.content.options[
                                            newOptionIndex
                                        ],
                                    };
                                }
                                question.content.options = newOptions;
                                question.content.correctAnswerIndex =
                                    selectedIndex;
                                return newState;
                            })
                        }
                        onChange={(val, optionIndex) =>
                            setQuiz((state) => {
                                const newState = { ...state };
                                const question =
                                    newState.content.questions[
                                        activeQuestionIndex
                                    ];
                                // console.log('===questions==', newState.content.questions[0].content.options === newState.content.questions[1].content.options);
                                // console.log('===question==', question);
                                question.content.options[optionIndex][
                                    activeLanguage
                                ] = val;
                                return newState;
                            })
                        }
                        optionItems={
                            content.questions[activeQuestionIndex].content
                                .options &&
                            content.questions[activeQuestionIndex].content
                                .options.length === NUM_OPTIONS_QUIZ
                                ? [
                                      {
                                          optionValue:
                                              content.questions[
                                                  activeQuestionIndex
                                              ].content.options[0][
                                                  activeLanguage
                                              ],
                                          placeHolder: 'Enter Answer',
                                          optionIndex: 0,
                                      },
                                      {
                                          optionValue:
                                              content.questions[
                                                  activeQuestionIndex
                                              ].content.options[1][
                                                  activeLanguage
                                              ],
                                          placeHolder: 'Enter Answer',
                                          optionIndex: 1,
                                      },
                                      {
                                          optionValue:
                                              content.questions[
                                                  activeQuestionIndex
                                              ].content.options[2][
                                                  activeLanguage
                                              ],
                                          placeHolder: 'Enter Answer',
                                          optionIndex: 2,
                                      },
                                      {
                                          optionValue:
                                              content.questions[
                                                  activeQuestionIndex
                                              ].content.options[3][
                                                  activeLanguage
                                              ],
                                          placeHolder: 'Enter Answer',
                                          optionIndex: 3,
                                      },
                                  ]
                                : []
                        }
                        disabled={isQuizPublished}
                    />
                </FlexGrid>
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() => {
                            // Check multi-language fields are filled
                            for (const language of appLanguages) {
                                const questions = content.questions;
                                for (let i = 0; i < questions.length; ++i) {
                                    const question = questions[i];
                                    if (
                                        isStringRequiredMissing(
                                            question.content.questionText[
                                                language
                                            ]
                                        )
                                    ) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content: `The title of question #${
                                                    i + 1
                                                } in ${language} is missing`,
                                            })
                                        );
                                        return;
                                    }

                                    if (
                                        isStringRequiredMissing(
                                            question.content.reason[language]
                                        )
                                    ) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content: `The correct answer reason of question #${
                                                    i + 1
                                                } in ${language} is missing`,
                                            })
                                        );
                                        return;
                                    }

                                    for (
                                        let j = 0;
                                        j < question.content.options.length;
                                        ++j
                                    ) {
                                        const option =
                                            question.content.options[j];
                                        if (
                                            isStringRequiredMissing(
                                                option[language]
                                            )
                                        ) {
                                            dispatch(
                                                updateModalAction({
                                                    open: true,
                                                    title: 'error',
                                                    content: `The option ${
                                                        j + 1
                                                    } text of question #${
                                                        i + 1
                                                    } in ${language} is missing`,
                                                })
                                            );
                                            return;
                                        }
                                    }

                                    if (question.content.points <= 0) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content: `Please assign points to question #${
                                                    i + 1
                                                }.`,
                                            })
                                        );
                                        return;
                                    }

                                    if (
                                        question.content.correctAnswerIndex ===
                                        undefined
                                    ) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content: `Please select a correct option for question #${
                                                    i + 1
                                                }.`,
                                            })
                                        );
                                        return;
                                    }
                                }
                            }

                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
