import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { ButtonGroup } from '../../../components/ButtonGroup';
import { FileUploader } from '../../../components/FileUploader';
import { FlexGrid } from '../../../components/FlexGrid';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
// import { RadioGroup } from '../../../components/RadioGroup';
// import { Select } from '../../../components/Select';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { ImageIcon /*, LinkIcon*/ } from '../../../assets/icons';
import { BannerType } from '../../../types';
import { AddTags } from '../../../components/AddTags';
import { TargetLink } from '../../../components/TargetLink';
import {
    checkImageSize,
    // getImageSize,
    getImageSizeBySource,
    getTagsIntersection,
    isNotRequireAndWhiteSpaces,
    isStringRequiredMissing,
} from '../../../utils';
import {
    IMAGE_SIZE_MIN_HEIGHT,
    IMAGE_SIZE_MIN_WIDTH,
    LIMIT_DEFAULT_TEXT_LENGTH,
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_HEADLINE_LENGTH,
    EXTERNAL_LINK_SOURCE_PATERN,
    INTERNAL_LINK_SOURCE_PATERN,
} from '../../../common/constants';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../store/store.actions';

const PreviewImage = styled(FlexGrid)`
    position: relative;
    min-height: 240px;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;

    img {
        width: 100%;
        object-fit: cover;
    }
`;

const LinkText = styled(Text)`
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    max-width: 500px;
    width: 100%;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;
const Buttontext = styled(Button)`
    flex-shrink: 0;
`;

interface Props {
    stepNavigator?: any;
    bannerState: [BannerType, React.Dispatch<React.SetStateAction<BannerType>>];
}

export const BannerContent: React.FC<Props> = ({
    stepNavigator,
    bannerState,
}) => {
    const dispatch = useDispatch();
    const [banner, setBanner] = bannerState;
    const { content } = banner;
    const [showPreview, setShowPreview] = useState(false);
    const { appLanguages } = banner.identifierDefinition;
    const [activeLanguage, setActiveLanguage] = useState(appLanguages[0]);
    const [imageSizeOk, setImageSizeOk] = useState(false);

    useEffect(() => {
        const newBanner = { ...banner };
        newBanner.identifierDefinition.appLanguages.forEach((language) => {
            if (!newBanner.content.headline[language]) {
                newBanner.content.headline[language] = '';
            }
            if (!newBanner.content.description[language]) {
                newBanner.content.description[language] = '';
            }
            if (!newBanner.content.actionText[language]) {
                newBanner.content.actionText[language] = '';
            }
            if (!newBanner.content.linkSource[language]) {
                newBanner.content.linkSource[language] = '';
            }
        });
        setBanner(newBanner);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkedLanguage = appLanguages.map(
        (language) =>
            // Boolean(
            //     content.headline[language] &&
            //         content.description[language] &&
            //         content.actionText[language]
            // )
            language === activeLanguage
    );

    const dataIncomplete =
        !content.image ||
        !imageSizeOk ||
        isNotRequireAndWhiteSpaces(content.headline) ||
        isNotRequireAndWhiteSpaces(content.description) ||
        isNotRequireAndWhiteSpaces(content.actionText);

    useEffect(() => {
        if (content.oldImage) {
            setShowPreview(true);
        }
    }, []);

    useEffect(() => {
        if (content.image) {
            getImageSizeBySource(content.image.preview).then((imageSize) => {
                if (!checkImageSize(imageSize[0], imageSize[1])) {
                    setImageSizeOk(false);
                    dispatch(
                        updateModalAction({
                            open: true,
                            title: 'error',
                            content: `image size limit: width > ${IMAGE_SIZE_MIN_WIDTH} height > ${IMAGE_SIZE_MIN_HEIGHT}`,
                        })
                    );
                } else {
                    setImageSizeOk(true);
                }
            });
        }
    }, [content.image]);

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Content
                    </Text>
                }
            />
            <Form>
                <FileUploader
                    fileType="image/*"
                    label="Upload Image"
                    info={{
                        title: 'Upload Image',
                        content:
                            'Upload Image short desc goes here. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.',
                    }}
                    value={content.image}
                    onChange={(val) =>
                        setBanner({
                            ...banner,
                            content: {
                                ...banner.content,
                                image: val,
                                oldImage: false,
                            },
                        })
                    }
                />
                {content.image && !showPreview && (
                    <>
                        <FlexGrid width="100%" justifyContent="flex-end">
                            <Button
                                onClick={() => setShowPreview(true)}
                                negative
                                title="Show image"
                            />
                        </FlexGrid>
                        <HorizontalLine
                            m="0"
                            height="1px"
                            backgroundColor={COLORS.lightGray}
                        />
                    </>
                )}
                {content.image && showPreview && (
                    <>
                        <PreviewImage>
                            <img src={content.image.preview} alt="banner" />
                        </PreviewImage>
                        <FlexGrid
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                            gap="1em"
                        >
                            <FlexGrid width="100%" gap="1em">
                                <ImageIcon color={COLORS.gray} />
                                <FlexGrid direction="column" gap="0.2em">
                                    <LinkText color={COLORS.green}>
                                        {content.image.name}
                                    </LinkText>
                                    <Text>
                                        {(content.image.size / 1e3).toFixed(2)}{' '}
                                        kb
                                    </Text>
                                </FlexGrid>
                            </FlexGrid>
                            <Buttontext
                                onClick={() => setShowPreview(false)}
                                negative
                                title="Hide image"
                            />
                        </FlexGrid>

                        <AddTags
                            value={content.tags}
                            onChange={(val) =>
                                setBanner({
                                    ...banner,
                                    content: {
                                        ...banner.content,
                                        tags: getTagsIntersection(
                                            content.tags || [],
                                            val
                                                .split(' ')
                                                .filter((el: string) => el)
                                        ),
                                    },
                                })
                            }
                        />
                    </>
                )}
                <ButtonGroup
                    titles={appLanguages}
                    info={{
                        title: 'Multiple Languages',
                        content:
                            'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                    }}
                    active={activeLanguage}
                    onChange={setActiveLanguage}
                    checked={checkedLanguage}
                />
                <Input
                    label="Headline"
                    value={content.headline[activeLanguage]}
                    maxLength={LIMIT_HEADLINE_LENGTH}
                    onChange={(val) =>
                        setBanner((state) => {
                            const newState = { ...state };
                            newState.content.headline[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Input
                    label="Description"
                    multiline
                    height="250px"
                    placeholder="Enter"
                    rows={10}
                    maxLength={LIMIT_DESCRIPTION_LENGTH}
                    value={content.description[activeLanguage]}
                    onChange={(val) =>
                        setBanner((state) => {
                            const newState = { ...state };
                            newState.content.description[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <Input
                    label="Text to Display (Call to Action)"
                    info={{
                        title: 'Text to Display',
                        content: 'Short descriptions goes here',
                    }}
                    value={content.actionText[activeLanguage]}
                    maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                    onChange={(val) =>
                        setBanner((state) => {
                            const newState = { ...state };
                            newState.content.actionText[activeLanguage] = val;
                            return newState;
                        })
                    }
                />
                <HorizontalLine
                    backgroundColor={COLORS.lightGray}
                    height="1px"
                />
                <TargetLink
                    value={content.linkSource[activeLanguage] || ''}
                    isLinkInternal={content.isLinkInternal}
                    onChange={(val) =>
                        setBanner((state) => {
                            const newState = { ...state };
                            newState.content.isLinkInternal =
                                val.isLinkInternal;
                            newState.content.linkSource[activeLanguage] =
                                val.linkSource;
                            return newState;
                        })
                    }
                />
                {/* <RadioGroup
                    label="Link"
                    info={{
                        title: 'Link Internal',
                        content:
                            'Short descriptions goes here, lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
                    }}
                    options={[
                        { label: 'INTERNAL', value: 'INTERNAL' },
                        { label: 'EXTERNAL', value: 'EXTERNAL' },
                    ]}
                    value={
                        content.isLinkInternal
                            ? 'INTERNAL'
                            : content.isLinkInternal === false
                            ? 'EXTERNAL'
                            : null
                    }
                    onChange={(val: string) =>
                        setBanner({
                            ...banner,
                            content: {
                                ...banner.content,
                                isLinkInternal: val === 'INTERNAL',
                            },
                        })
                    }
                />
                {content.isLinkInternal ? (
                    <Select
                        form
                        label="Link Source"
                        info={{
                            title: '',
                            content: '',
                        }}
                        options={['Acme Link Source Option 1']}
                        value={content.linkSource}
                        onChange={(val) =>
                            setBanner({
                                ...banner,
                                content: {
                                    ...banner.content,
                                    linkSource: val,
                                },
                            })
                        }
                    />
                ) : content.isLinkInternal === false ? (
                    <Input
                        label="Link Source"
                        info={{
                            title: '',
                            content: '',
                        }}
                        icon={<LinkIcon />}
                        iconPosition="start"
                        value={content.linkSource}
                        onChange={(val) =>
                            setBanner({
                                ...banner,
                                content: {
                                    ...banner.content,
                                    linkSource: val,
                                },
                            })
                        }
                    />
                ) : null} */}
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() => {
                            if (
                                content.linkSource &&
                                content.linkSource[activeLanguage]
                            ) {
                                if (content.isLinkInternal) {
                                    if (
                                        !content.linkSource[
                                            activeLanguage
                                        ].match(INTERNAL_LINK_SOURCE_PATERN)
                                    ) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content:
                                                    'Please start the URL with fastscan:// for linking an internal page',
                                            })
                                        );
                                        return;
                                    }
                                } else {
                                    if (
                                        !content.linkSource[
                                            activeLanguage
                                        ].match(EXTERNAL_LINK_SOURCE_PATERN)
                                    ) {
                                        dispatch(
                                            updateModalAction({
                                                open: true,
                                                title: 'error',
                                                content:
                                                    'Please start the URL with https:// for a secure and valid connection ',
                                            })
                                        );
                                        return;
                                    }
                                }
                            }

                            // Check multi-language fields are filled
                            for (const language of appLanguages) {
                                if (
                                    isStringRequiredMissing(
                                        content.headline[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Headline in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.description[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Description in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        content.actionText[language]
                                    )
                                ) {
                                    dispatch(
                                        updateModalAction({
                                            open: true,
                                            title: 'error',
                                            content: `Text to Display (Call to Action) in ${language} is missing`,
                                        })
                                    );
                                    return;
                                }
                            }
                            stepNavigator((prev: number) => prev + 1);
                        }}
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
