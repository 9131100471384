import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgDashboard: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 20}
        height={height || 22}
        viewBox="0 0 20 22"
    >
        <g
            className="icon-stroke"
            fill="none"
            stroke={color || '#b82b5c'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <path
                data-name="Path 4727"
                d="m1 8 9-7 9 7v11a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2Z"
            />
            <path data-name="Path 4728" d="M7 21V11h6v10" />
        </g>
    </svg>
);

export default SvgDashboard;
