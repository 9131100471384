import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgChevronDoubleLeft: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 13.41}
        height={height || 12}
        viewBox="0 0 13.41 12"
    >
        <g className="icon-fill" fill={color || '#b5b5c3'}>
            <path
                data-name="Path 4844"
                d="m12 12 1.41-1.41L8.83 6l4.58-4.59L12 0 6 6Z"
            />
            <path
                data-name="Path 4844"
                d="m6 12 1.41-1.41L2.83 6l4.58-4.59L6 0 0 6Z"
            />
        </g>
    </svg>
);

export default SvgChevronDoubleLeft;
