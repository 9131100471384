import { resolve } from 'node:path/win32';
import { useEffect, useState } from 'react';

export interface ICallbackParams<T, O = any> {
    isLoading: boolean;
    isFinishedOnce: boolean;
    error: string | undefined;
    hookData: O;
    input: T;
    exception?: Error;
    updateArray?: () => void;
    updateData?: (data: any) => void;
}

export type CallbackFunctionType = (props: ICallbackParams<any>) => any;

interface IHookProps {
    initialData?: any;
    initialIsLoading?: boolean;
    skipInitialApiCallOnEmptyInputs?: boolean;
    initialInput?: Record<any, any> | string | number | undefined;
    mountFn: CallbackFunctionType;
    errorFn: CallbackFunctionType;
    unmountFn: CallbackFunctionType;
    changeList?: any[];
}

type GenStateType<T> = [T, (f: T) => void];

export type HookReturnType<I, O = any> = [
    {
        isLoading: boolean;
        error: string | undefined;
        isFinishedOnce: boolean;
        hookData: O;
    },
    GenStateType<I>[1]
];

export function useApiHookWrapper(props: IHookProps): HookReturnType<any> {
    const {
        initialIsLoading = false,
        initialInput,
        initialData,
        skipInitialApiCallOnEmptyInputs = false,
        mountFn,
        unmountFn,
        errorFn,
    } = props;
    // initial states
    const [isLoading, setIsLoading]: GenStateType<boolean> =
        useState(initialIsLoading);
    const [isFinishedOnce, setIsFinishedOnce]: GenStateType<boolean> = useState(
        false as boolean
    );
    const [error, setError]: GenStateType<string> = useState();
    const [hookData, setHookData]: GenStateType<any> = useState(initialData);
    const [input, setInput]: GenStateType<typeof initialInput> =
        useState(initialInput);

    const changeList = props.changeList || []

    useEffect(() => {
        if (
            skipInitialApiCallOnEmptyInputs &&
            (!input || Object.keys(input).length === 0)
        ) {
            setIsLoading(false);
            return () => {};
        }

        const isObsolete = false;

        const fetchData = async () => {
            setError(undefined);
            setIsLoading(true);

            let res; // undefined

            const updateArray = () => {
                setHookData([...res])
            }
            const updateData = (data: any) => {
                setHookData(data)
            }

            try {
                res = await mountFn({
                    isLoading,
                    isFinishedOnce,
                    error,
                    hookData,
                    input,
                    updateArray,
                    updateData
                });
            } catch (e: any) {
                if (!isObsolete) {
                    const errorMsg = e?.response?.data?.message || e.message;

                    errorFn &&
                        (await errorFn({
                            isLoading,
                            isFinishedOnce,
                            error: errorMsg,
                            hookData,
                            input,
                            exception: e,
                        }));
                    setError(errorMsg);
                }
            }

            if (!isObsolete) {
                if (res) {
                    setIsFinishedOnce(true);
                    setHookData(res);
                }

                setIsLoading(false);
            }
        };

        fetchData();

        // will run on unmount, example cancellation of promises
        return async () => {
            unmountFn &&
                (await unmountFn({
                    isLoading,
                    isFinishedOnce,
                    error,
                    hookData,
                    input,
                }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [input, ...changeList]);

    return [{ isLoading, error, isFinishedOnce, hookData }, setInput];
}
