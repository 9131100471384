import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/variables';
import { FlexGrid } from './FlexGrid';

interface TooltipProps {
    title: string;
}

const TooltipBody = styled(FlexGrid)`
    position: absolute;
    top: -2.3rem;
    background-color: ${COLORS.black};
    transform: translateX(-50%);
    left: 50%;
    color: ${COLORS.white};
    font-size: 12px;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    margin-right: auto;
    margin-left: auto;
    white-space: nowrap;
    display: none;
    font-family: 'AR';
    text-transform: none;
`;

const Pointer = styled(FlexGrid)`
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border: 5px solid ${COLORS.transparent};
    border-top: 5px solid ${COLORS.black};
    border-bottom: none;
`;

const Container = styled.div`
    position: relative;

    &:hover > ${TooltipBody} {
        display: flex;
    }
`;

export const Tooltip: React.FC<TooltipProps> = ({ title, children }) => {
    return (
        <Container>
            <TooltipBody>
                {title}
                <Pointer />
            </TooltipBody>
            {children}
        </Container>
    );
};
