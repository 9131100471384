import { useState } from 'react';
import { CopyIcon } from '../assets/icons';
import { IconButton } from './IconButton';
import { Toast } from './Toast';

interface CopyBtnProps {
    text: string;
}

export const CopyButton: React.FC<CopyBtnProps> = ({ text }) => {
    const [successToast, setSuccessToast] = useState(false);
    return (
        <>
            <IconButton
                onClick={() => {
                    navigator.clipboard.writeText(text);
                    setSuccessToast(true);
                }}
                negative
                icon={<CopyIcon />}
            />
            <Toast
                title="Copied!"
                open={successToast}
                onClose={() => setSuccessToast(false)}
            />
        </>
    );
};
