import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgFile: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <defs>
            <clipPath id="file_svg__a">
                <path
                    className="icon-fill"
                    data-name="Rectangle 2316"
                    transform="translate(33 453)"
                    fill={color || '#009343'}
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
        <g transform="translate(-33 -453)" clipPath="url(#file_svg__a)">
            <path
                className="icon-fill"
                data-name="Path 4648"
                d="M50 467a1 1 0 0 1-1 1h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 1 1Zm-4 3h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2Zm9-6.515V472a5.006 5.006 0 0 1-5 5H40a5.006 5.006 0 0 1-5-5v-14a5.006 5.006 0 0 1 5-5h4.515a6.958 6.958 0 0 1 4.95 2.05l3.484 3.486A6.951 6.951 0 0 1 55 463.485Zm-6.949-7.021a5.01 5.01 0 0 0-1.051-.78V460a1 1 0 0 0 1 1h4.316a4.983 4.983 0 0 0-.781-1.05ZM53 463.485c0-.165-.032-.323-.047-.485H48a3 3 0 0 1-3-3v-4.953c-.162-.015-.321-.047-.485-.047H40a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3Z"
                fill={color || '#009343'}
            />
        </g>
    </svg>
);

export default SvgFile;
