import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Button';
import { FlexGrid } from '../../../../components/FlexGrid';
import { Input } from '../../../../components/Input';
import { Modal } from '../../../../components/Modal';
import { CheckCircleBigIcon, ImageIcon } from '../../../../assets/icons';
import { Text } from '../../../../components/Text';
import { COLORS } from '../../../../styles/variables';
import { IMAGE_SIZE_MIN_HEIGHT, IMAGE_SIZE_MIN_WIDTH, LIMIT_DESCRIPTION_LENGTH, LIMIT_HEADLINE_LENGTH, LIMIT_NAME_LENGTH } from '../../../../common/constants';
import { checkImageSize, getCountry, getImageSizeBySource, getLanguageListByCountry, getTagsIntersection, isNotRequireAndWhiteSpaces } from '../../../../utils';
import { Select } from '../../../../components/Select';
import { LearningCardSectionType } from '../../../../types';
import { Tag } from '../../../../components/Tag';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { FileUploader } from '../../../../components/FileUploader';
import { HorizontalLine } from '../../../../components/HorizontalLine';
import { AddTags } from '../../../../components/AddTags';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../../store/store.actions';

// 1 Theme -> (n) Sections

// 1 Section -> (n) Learning cards

// 1 Learning Card -> (n) Learning Card Slides

// Theme definition:
//   - Upload Image
//   - App Language
//   - Headline
//   - Description
//   - Add Tags

// Section definition:
//   - App Language
//   - Upload Image
//   - Headline
//   - Description
//   - Link to Theme (Drop Down) - Error if languages dont match
//   - Add Tags (Common for both)

// Learning Card:
//   - Identifier Definition
//     - Learning Card Name
//     - App Language
//     - Start Date + Time
//     - End Date + Time
//   - Learning Card Definition
//     - Learning Card Headline
//     - Learning Card Description
//     - Add Tags (Optional)
//     - Section (Option to link to Section - similar to Playlists or Folders) (Error if languages dont match)
//     - Slides
//       - Upload Image (Future - Link Video)
//       - Headline
//       - Description
//       - Add More (+ - like with Scanning contests to add more slides to the learning cards)
//   - Target Geography
//   - Target User
//   - Add Quiz
//   - Send Notification (Notification should guide user to the Theme on clicking)
//   - Review

interface LearningCardSectionModalProps {
    open: boolean;
    onConfirm?: (data: LearningCardSectionType) => void;
    onClose?: () => void;
    title: string;
    success: boolean | null;
    setSuccess: (v: boolean | null) => void;
    errorMsg: string | undefined;
    setErrorMsg: (v: string | undefined) => void;
    disabled?: boolean;
    successContentText: string;
    params?: any;
}

const ActionButton = styled(Button)`
    font-family: 'OS';
    font-size: 1rem;
`;

const BorderBottom = styled(FlexGrid)`
    padding-bottom: 2.5em;
    border-bottom: 1px solid ${COLORS.lightGray};
    gap: 1em;
`;

const PreviewImage = styled(FlexGrid)`
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
    img {
        width: 100%;
        object-fit: cover;
    }
`;

const LinkText = styled(Text)`
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PreviewBtn = styled(Button)`
    flex-shrink: 0;
`;

export const LearningCardSectionModal: React.FC<LearningCardSectionModalProps> = ({
    open,
    onClose,
    onConfirm,
    success = null,
    setSuccess,
    errorMsg = undefined,
    setErrorMsg,
    successContentText,
    title,
    disabled,
    params,
}) => {
    const defaultValue: LearningCardSectionType = {
        name: '',
        appLanguages: [],
        content: {
            image: null,
            tags: [],
            headline: {},
            description: {},
            referencedTheme: undefined,
            prevReferencedTheme: undefined,
        },
    };

    const dispatch = useDispatch();
    const [learningCardSection, setLearningCardSection] =
        useState<LearningCardSectionType>(defaultValue);
    const [submitted, setSubmitted] = useState(false);

    const [availableThemesMap, setAvailableThemesMap] = useState({});
    const [availableThemesIdMap, setAvailableThemesIdMap] = useState({});
    const [selectedThemeName, setSelectedThemeName] = useState('');
    const [activeLanguage, setActiveLanguage] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [imageSizeOk, setImageSizeOk] = useState(false);

    useEffect(() => {
        const newLearningCardSection = { ...learningCardSection };
        newLearningCardSection.appLanguages.forEach((language) => {
            if (!newLearningCardSection.content.headline[language]) {
                newLearningCardSection.content.headline[language] = '';
            }
            if (!newLearningCardSection.content.description[language]) {
                newLearningCardSection.content.description[language] = '';
            }
        });
        setLearningCardSection(newLearningCardSection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningCardSection.appLanguages]);

    const dataIncomplete =
        disabled ||
        !learningCardSection.content.image ||
        !imageSizeOk ||
        learningCardSection.name?.trim() === '' ||
        learningCardSection.appLanguages?.length <= 0 ||
        isNotRequireAndWhiteSpaces(learningCardSection.content.headline) ||
        isNotRequireAndWhiteSpaces(learningCardSection.content.description);

    // console.log('==dataIncomplete==', dataIncomplete, learningCardSection.appLanguages?.length, disabled);

    const checkedLanguage = learningCardSection.appLanguages?.map(
        (language) =>
            language === activeLanguage
    );

    useEffect(() => {
        if (learningCardSection.content.oldImage) {
            setShowPreview(true);
        }
    }, []);

    useEffect(() => {
        if (learningCardSection.content.image) {
            getImageSizeBySource(learningCardSection.content.image.preview).then((imageSize) => {
                if (!checkImageSize(imageSize[0], imageSize[1])) {
                    setImageSizeOk(false);
                    dispatch(
                        updateModalAction({
                            open: true,
                            title: 'error',
                            content: `image size limit: width > ${IMAGE_SIZE_MIN_WIDTH} height > ${IMAGE_SIZE_MIN_HEIGHT}`,
                        })
                    );
                } else {
                    setImageSizeOk(true);
                }
            });
        }
    }, [learningCardSection.content.image]);

    const getAvailableThemeNames = useCallback(() => {
        console.log('==Object.keys(availableThemesMap)==', Object.keys(availableThemesMap));
        return Object.keys(availableThemesMap).map((themeName) => {
            console.log('==availableThemesMap==', themeName);
            return themeName;
        })
    }, [availableThemesMap]);

    const reset = () => {
        if (onClose) {
            onClose();
        }
        resetParams();
    };

    const resetParams = () => {
        setLearningCardSection(defaultValue);
        setSuccess(null);
        setErrorMsg(undefined);
        setSubmitted(false);
        setSelectedThemeName('');
        setActiveLanguage('');
        setAvailableThemesMap({});
        setAvailableThemesIdMap({});
    };

    useEffect(() => {
        if (!open || submitted) {
            return;
        }
        if (!params || !params.section) {
            resetParams();
        } else {
            setLearningCardSection(params.section);
            setActiveLanguage(params.section.appLanguages[0]);
        }
        const themesData = params?.themesData ?? [];
        const newThemesMap = {};
        const newThemesIdMap = {};
        for (let i = 0; i < themesData.length; ++i) {
            const themeData = themesData[i];
            newThemesMap[themeData.name] = themeData;
            newThemesIdMap[themeData.id] = themeData;
        }
        // console.log('==newThemesMap==', newThemesMap);
        // console.log('==newThemesIdMap==', newThemesIdMap);
        setAvailableThemesMap(newThemesMap);
        setAvailableThemesIdMap(newThemesIdMap);
        if (params.section?.content.referencedTheme?.contentfulRawData?.sys?.id) {
            const sysId = params.section.content.referencedTheme?.contentfulRawData?.sys?.id;
            if (newThemesIdMap[sysId]) {
                setSelectedThemeName(newThemesIdMap[sysId].name);
            }
        } else {
            setSelectedThemeName('');
        }
    }, [params]);

    const successContent = (
        <FlexGrid
            p="1em 0em 1em 0em"
            alignItems="center"
            width="100%"
            gap="0.5em"
            m="0em 0em 2em 0em"
        >
            <CheckCircleBigIcon height="36" />
            <Text color={COLORS.darkGray}>{successContentText}</Text>
        </FlexGrid>
    );

    const inputContent = (
        <BorderBottom direction="column" width="100%" p="1em 0em">
            <Input
                label="Learning Card Section Name"
                value={learningCardSection.name}
                maxLength={LIMIT_NAME_LENGTH}
                onChange={(val) =>
                    setLearningCardSection({
                        ...learningCardSection,
                        name: val,
                    })
                }
                placeholder="Enter"
            />
            <FileUploader
                fileType="image/*"
                label="Upload Image"
                value={learningCardSection.content.image}
                onChange={(val) =>
                    setLearningCardSection({
                        ...learningCardSection,
                        content: {
                            ...learningCardSection.content,
                            image: val,
                            oldImage: false,
                        },
                    })
                }
            />
            {learningCardSection.content.image && !showPreview && (
                <>
                    <FlexGrid width="100%" justifyContent="flex-end">
                        <Button
                            onClick={() => setShowPreview(true)}
                            negative
                            title="Show image"
                        />
                    </FlexGrid>
                    <HorizontalLine
                        m="0"
                        height="1px"
                        backgroundColor={COLORS.lightGray}
                    />
                </>
            )}
            {learningCardSection.content.image && showPreview && (
                <>
                    <PreviewImage>
                        <img src={learningCardSection.content.image.preview} alt="learningcard-section" />
                    </PreviewImage>
                    <FlexGrid
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        gap="1em"
                    >
                        <FlexGrid width="100%" gap="1em">
                            <ImageIcon color={COLORS.gray} />
                            <FlexGrid direction="column" gap="0.2em">
                                <LinkText color={COLORS.green}>
                                    {learningCardSection.content.image.name}
                                </LinkText>
                                <Text>
                                    {(learningCardSection.content.image.size / 1e3).toFixed(2)}{' '}
                                    kb
                                </Text>
                            </FlexGrid>
                        </FlexGrid>
                        <PreviewBtn
                            onClick={() => setShowPreview(false)}
                            negative
                            title="Hide image"
                        />
                    </FlexGrid>
                </>
            )}
             {learningCardSection.content.image && <AddTags
                value={learningCardSection.content.tags}
                onChange={(val) =>
                    setLearningCardSection({
                        ...learningCardSection,
                        content: {
                            ...learningCardSection.content,
                            tags: getTagsIntersection(
                                learningCardSection.content.tags || [],
                                val
                                    .split(' ')
                                    .filter((el: string) => el)
                            ),
                        },
                    })
                }
            />}
            <Select
                form
                label="Link to Module"
                placeholder="Select"
                valueColor={COLORS.black}
                options={getAvailableThemeNames()}
                value={selectedThemeName}
                onChange={(value: string) => {
                    setSelectedThemeName(value);
                    let prevReferencedTheme;
                    if (learningCardSection.content.referencedTheme?.contentfulRawData?.sys?.id) {
                        prevReferencedTheme = availableThemesIdMap[learningCardSection.content.referencedTheme?.contentfulRawData?.sys?.id];
                    }
                    setLearningCardSection({
                        ...learningCardSection,
                        content: {
                            ...learningCardSection.content,
                            referencedTheme: availableThemesMap[value],
                            prevReferencedTheme,
                        },
                    })
                }}
            />
            <Select
                form
                label="App Language"
                placeholder="Select"
                options={getLanguageListByCountry(getCountry())}
                renderValue={() => '- Select -'}
                value={learningCardSection.appLanguages}
                onChange={(value: any) => {
                    if (!learningCardSection.appLanguages.includes(value)) {
                        const newLanguages = [
                            ...learningCardSection.appLanguages,
                            value,
                        ];
                        setLearningCardSection({
                            ...learningCardSection,
                            appLanguages: newLanguages,
                        });
                        setActiveLanguage(newLanguages[0]);
                    }
                }}
            />
            <FlexGrid gap="1em">
                {learningCardSection.appLanguages.map((language, index) => (
                    <Tag
                        key={`learningcard-section-flexgrid-lang-${index}`}
                        title={language}
                        removeBtn
                        onRemove={(value: string) => {
                            const newLanguages = learningCardSection.appLanguages.filter(
                                (el) => el !== value
                            );
                            if (newLanguages.length > 0 && newLanguages[0] !== activeLanguage) {
                                setActiveLanguage(newLanguages[0]);
                            }
                            setLearningCardSection({
                                ...learningCardSection,
                                appLanguages: newLanguages,
                            });
                        }
                        }
                    />
                ))}
            </FlexGrid>
            <ButtonGroup
                titles={learningCardSection.appLanguages}
                active={activeLanguage}
                onChange={setActiveLanguage}
                checked={checkedLanguage}
            />
            <Input
                disabled={activeLanguage.trim() === ''}
                label="Headline"
                value={learningCardSection.content.headline[activeLanguage] ?? ''}
                maxLength={LIMIT_HEADLINE_LENGTH}
                onChange={(val) =>
                    setLearningCardSection((state) => {
                        const newState = { ...state };
                        newState.content.headline[activeLanguage] = val;
                        return newState;
                    })
                }
            />
            <Input
                disabled={activeLanguage.trim() === ''}
                label="Description"
                multiline
                height="250px"
                placeholder="Enter"
                rows={10}
                value={learningCardSection.content.description[activeLanguage] ?? ''}
                maxLength={LIMIT_DESCRIPTION_LENGTH}
                onChange={(val) =>
                    setLearningCardSection((state) => {
                        const newState = { ...state };
                        newState.content.description[activeLanguage] = val;
                        return newState;
                    })
                }
            />
            {errorMsg && (
                <Text link fontSize="0.875rem" color={COLORS.red}>
                    {errorMsg}
                </Text>
            )}
        </BorderBottom>
    );

    const inputActions = (
        <FlexGrid alignItems="center">
            <ActionButton
                disabled={dataIncomplete}
                onClick={() => {
                    setSubmitted(true);
                    onConfirm && onConfirm(learningCardSection);
                }}
                p="0.6em 2em"
            >
                {params.section ? 'Update' : 'Add'} section
            </ActionButton>
            <ActionButton onClick={reset} negative>
                Cancel
            </ActionButton>
        </FlexGrid>
    );

    const successActions = (
        <ActionButton onClick={reset} p="0.6em 2.5em" m="0 0 2em 0">
            Done
        </ActionButton>
    );

    return (
        <Modal
            open={open}
            header={title}
            onClose={reset}
            content={success ? successContent : inputContent}
            actions={success ? successActions : inputActions}
            maxWidth='60%'
        />
    );
};
