import { AppAction, ModalState, User } from './store.types';

export enum ACTIONS {
    ADD_USER = 'Add',
    SET_COUNTRY = 'SET_COUNTRY',
    SET_LANGUAGE = 'SET_LANGUAGE',
    SET_CONTENTFUL_TOKEN = 'SET_CONTENTFUL_TOKEN',
    SET_MODAL = 'SET_MODAL',
}

export function addUser(user: User): AppAction<User> {
    return {
        type: ACTIONS.ADD_USER,
        payload: user,
    };
}

export function setCountryAction(name: string): AppAction<string> {
    return {
        type: ACTIONS.SET_COUNTRY,
        payload: name
    }
}

export function setLanguageAction(name: string): AppAction<string> {
    return {
        type: ACTIONS.SET_LANGUAGE,
        payload: name
    }
}

export function seContentfulTokenAction(token: string): AppAction<string> {
    return {
        type: ACTIONS.SET_CONTENTFUL_TOKEN,
        payload: token
    }
}

export function updateModalAction(modal: ModalState): AppAction<ModalState> {
    return {
        type: ACTIONS.SET_MODAL,
        payload: modal
    }
}