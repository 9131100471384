import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgCheckCircleBig: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 36}
        height={height || 36}
        viewBox="0 0 36 36"
    >
        <g
            className="icon-stroke"
            data-name="Ellipse 18"
            transform="translate(3 3)"
            fill="#009343"
            stroke={color || '#fff'}
        >
            <circle cx={15} cy={15} r={15} stroke="none" />
            <circle cx={15} cy={15} r={14.5} fill="none" />
        </g>
        <path data-name="Rectangle 81" fill="none" d="M0 0h36v36H0z" />
        <path
            className="icon-stroke"
            d="m10.518 19.22 3.77 3.951 11.2-11.2"
            fill="none"
            stroke={color || '#fff'}
            strokeWidth={3}
        />
    </svg>
);

export default SvgCheckCircleBig;
