import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgDelete: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 20}
        height={height || 22}
        viewBox="0 0 20 22"
    >
        <g
            className="icon-fill"
            fill="none"
            stroke={color || '#f00023'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <path data-name="Path 4780" d="M1 5h18" />
            <path
                data-name="Path 4781"
                d="M17 5v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5m3 0V3a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
            />
        </g>
    </svg>
);

export default SvgDelete;
