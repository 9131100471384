import React from 'react';
import styled from 'styled-components';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { PageWrapper } from '../../../components/PageWrapper';
import { SearchBar } from '../../../components/SearchBar';
import { LearningCardSectionTable } from './components/LearningCardSectionTable';
import { SelectCountry } from '../../../components/SelectCountry';

const PageContent = styled(FlexGrid)`
    max-width: 1200px;
    width: 100%;
    padding: 2em 0em;
    flex-direction: column;
`;

export const LearningCardSections: React.FC = () => {
    return (
        <PageWrapper>
            <PageHeader
                back
                actions={
                    <FlexGrid gap="1.5em">
                        <SearchBar placeholder="Search banners, videos, articles here…" />
                        <SelectCountry />
                    </FlexGrid>
                }
            />
            <FlexGrid width="100%" justifyContent="center">
                <PageContent>
                    <LearningCardSectionTable />
                </PageContent>
            </FlexGrid>
        </PageWrapper>
    );
};
