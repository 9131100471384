/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { InputContainer } from './InputContainer';
import { Text } from './Text';
import { RemoveCircleIcon } from '../assets/icons';

interface FileUploaderProps {
    label?: string;
    info?: { title: string; content: string };
    value?: any;
    // eslint-disable-next-line no-unused-vars
    onChange?: (val: any) => void;
    fileType?: string;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
    info,
    label,
    onChange,
    value,
    fileType,
}) => {
    const onDrop = useCallback(
        (acceptedFiles: any) => {
            if (onChange) {
                onChange(
                    Object.assign(acceptedFiles[0], {
                        preview: URL.createObjectURL(acceptedFiles[0]),
                    })
                );
            }
        },
        [onChange]
    );

    return (
        <FlexGrid direction="column" width="100%" gap="1em">
            <Dropzone onDrop={onDrop} multiple={false} accept={fileType}>
                {({ getRootProps }) => (
                    <FlexGrid
                        {...getRootProps()}
                        direction="column"
                        width="100%"
                    >
                        <InputContainer
                            type="fileUpload"
                            info={info}
                            label={label}
                            onClickInfoDetails={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            {value?.name ? (
                                <FlexGrid width="100%">
                                    <Text color={COLORS.green}>
                                        {value.name}
                                    </Text>
                                    <Button
                                        onClick={() =>
                                            onChange && onChange(null)
                                        }
                                        negative
                                        m="0 0 0 auto"
                                        p="0"
                                    >
                                        <RemoveCircleIcon />
                                    </Button>
                                </FlexGrid>
                            ) : (
                                <Text>
                                    <Text link>Upload a file</Text> or drag and
                                    drop
                                </Text>
                            )}
                        </InputContainer>
                    </FlexGrid>
                )}
            </Dropzone>
        </FlexGrid>
    );
};
