import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import 'moment-timezone';
import { FlexGrid } from '../../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../../components/Table';
import { Text } from '../../../../components/Text';
import { COLORS } from '../../../../styles/variables';
import { ArrowDownIcon, PlusIcon } from '../../../../assets/icons';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { IconButton } from '../../../../components/IconButton';
import { useLearningCardThemeHook } from '../hooks/use-learning-card-theme-hook';
import { Pagination } from '../../../../components/Pagination';
import { sortFunc } from '../../../../common';
import { LearningCardThemeModal } from './LearningCardThemeModal';
import { CreationService } from '../../../../services/Creation.service';
import { PLACEHOLDER_IMAGE } from '../../../../common/constants';
import {
    LearningCardThemeItemToEditModel,
    isStringRequiredMissing,
    roundDecimals,
} from '../../../../utils';
import { ContentfulApiService } from '../../../../services/ContentfulApi.service';
import { updateModalAction } from '../../../../store/store.actions';
import { useDispatch } from 'react-redux';
import { ApiService } from '../../../../services/Api.service';

const NewItemBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    font-size: 1rem;
`;

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

const ImageContainer = styled(FlexGrid)`
    width: 100px;
    height: 64px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    img {
        object-fit: cover;
        height: 100%;
    }
`;

export const LearningCardThemeTable: React.FC = () => {
    const dispatch = useDispatch();
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const perPage = 5;
    const DEFAULT_SORT = {
        name: 0,
        languages: 0,
        status: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);

    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);

    const [{ isLoading, hookData: themesData }] = useLearningCardThemeHook(
        {
            // page,
            // perPage,
            // sortBy: `${sort.date ? '' : '-'}fields.brandName`,
        },
        [update]
    );

    const [createEditModal, setCreateEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [createEditSuccess, setCreateEditSuccess] = useState(null);
    const [createEditThemeDisabled, setCreateEditThemeDisabled] =
        useState(false);

    const [detailsModal, setDetailsModal] = useState(false);

    const [errorMsg, setErrorMsg] = useState(undefined);

    const [themesDashboardStats, setThemesDashboardStats] = useState({
        totalThemes: 0,
        themesActive: 0,
        themesExpired: 0,
        pointsDisbursed: 0,
        totalParticipants: 0,
    });

    useEffect(() => {
        ApiService.getLearningCardThemesDashboardStats()
            .then((response) => {
                // {
                //     "totalThemes": 4,
                //     "themesActive": 4,
                //     "themesExpired": 0,
                //     "pointsDisbursed": 50,
                //     "totalParticipants": 2
                // }
                const data = response.data;
                setThemesDashboardStats(data);
                console.log('==getThemesDashboardStats==', data);
            })
            .catch((err) =>
                console.error(
                    '===Failed to fetch learning card themes dashboard stats===',
                    err
                )
            );
    }, []);

    let themes = sortFunc(sort, themesData ?? []);

    const total = themes.length;
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    themes = themes.slice(startIndex, endIndex);

    console.log('==themes==', themes);
    console.log('==current==', current);

    return (
        <>
            {!isLoading && (
                <>
                    <FlexGrid
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text font="OS" fontSize="2.25rem" uppercase>
                            Learning card module
                        </Text>
                        <NewItemBtn
                            negative
                            title="New Learning Card Module"
                            icon={<PlusIcon />}
                            iconSlot="start"
                            onClick={() => {
                                setCurrent(null);
                                setCreateEditModal(true);
                            }}
                        />
                    </FlexGrid>
                    <LearningCardThemeModal
                        title={current ? 'Edit a module' : 'Add new module'}
                        onClose={() => {
                            if (createEditSuccess) {
                                setUpdate(!update);
                            }
                            setCreateEditModal(false);
                        }}
                        open={createEditModal}
                        success={createEditSuccess}
                        setSuccess={setCreateEditSuccess}
                        errorMsg={errorMsg}
                        setErrorMsg={setErrorMsg}
                        successContentText={
                            current
                                ? 'The module has been updated successfully.'
                                : 'New module has been added successfully.'
                        }
                        disabled={createEditThemeDisabled}
                        params={LearningCardThemeItemToEditModel(current)}
                        onConfirm={async (learningCardTheme) => {
                            setErrorMsg(undefined);
                            // Check multi-language fields are filled
                            for (const language of learningCardTheme.appLanguages) {
                                if (
                                    isStringRequiredMissing(
                                        learningCardTheme.content.headline[
                                            language
                                        ]
                                    )
                                ) {
                                    setErrorMsg(
                                        `Headline in ${language} is missing`
                                    );
                                    return;
                                }
                                if (
                                    isStringRequiredMissing(
                                        learningCardTheme.content.description[
                                            language
                                        ]
                                    )
                                ) {
                                    setErrorMsg(
                                        `Description in ${language} is missing`
                                    );
                                    return;
                                }
                            }

                            setCreateEditThemeDisabled(true);
                            const isUpdate = !!current;

                            console.log(
                                '===learningCardTheme==',
                                learningCardTheme
                            );

                            try {
                                const contentfulResult =
                                    await CreationService.createUpdateLearningCardTheme(
                                        isUpdate,
                                        true,
                                        learningCardTheme
                                    );
                                console.log(
                                    '==contentfulResult==',
                                    contentfulResult
                                );
                                const entryId = contentfulResult?.sys?.id;
                                if (entryId) {
                                    await ApiService.updateLearningCardTheme(
                                        entryId
                                    );
                                    setCreateEditThemeDisabled(false);
                                    setCreateEditSuccess(true);
                                } else {
                                    console.error(
                                        '==CreationService.createUpdateLearningCardTheme==, no contentfulResult.sys.id exists'
                                    );
                                }
                            } catch (err) {
                                console.error(
                                    '==CreationService.createUpdateLearningCardTheme==',
                                    err
                                );
                                dispatch(
                                    updateModalAction({
                                        open: true,
                                        title: 'error',
                                        content: `Failed to ${
                                            isUpdate ? 'update' : 'create'
                                        } learning card module. ${err}. ${
                                            err?.config?.url
                                                ? `url: ${err?.config?.url}`
                                                : ''
                                        }`,
                                    })
                                );
                            } finally {
                                setCreateEditThemeDisabled(false);
                            }
                        }}
                    />
                    <Modal
                        open={deleteModal}
                        header="Delete a module"
                        onClose={() => setDeleteModal(false)}
                        content={
                            <FlexGrid
                                direction="column"
                                width="100%"
                                gap="2em"
                                p="2em 0em"
                            >
                                <Text color={COLORS.darkGray}>
                                    Are you sure you want to delete this module?
                                </Text>
                                <Text color={COLORS.darkGray}>
                                    <Text
                                        color={COLORS.darkGray}
                                        span
                                        font="AR Bold"
                                    >
                                        Note:{' '}
                                    </Text>
                                    Deleting a module is a permanent action and
                                    cannot be undone.
                                </Text>
                            </FlexGrid>
                        }
                        actions={
                            <FlexGrid alignItems="center">
                                <Button
                                    onClick={() => {
                                        ContentfulApiService.deleteContent(
                                            current.id
                                        )
                                            .then(() => {
                                                setUpdate(!update);
                                                if (total % perPage === 1) {
                                                    setPage(
                                                        Math.max(page - 1, 1)
                                                    );
                                                }
                                            })
                                            .catch(() => {
                                                alert(
                                                    'Cannot delete published'
                                                );
                                            });
                                        setDeleteModal(false);
                                    }}
                                    p="1.3em 2.5em"
                                >
                                    DELETE
                                </Button>
                                <Button
                                    onClick={() => setDeleteModal(false)}
                                    negative
                                    p="0.5em 1em"
                                >
                                    CANCEL
                                </Button>
                            </FlexGrid>
                        }
                    />
                    {current && (
                        <Modal
                            open={detailsModal}
                            header={`${current.name} details`}
                            onClose={() => setDetailsModal(false)}
                            content={
                                <FlexGrid direction="column">
                                    <Table>
                                        <TableRow>
                                            <TableHead padding="2em 0em 0em 0em">
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Number
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead padding="2em 0em 0em 0em">
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Section
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead padding="2em 0em 0em 0em">
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Starting Learners
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead padding="2em 0em 0em 0em">
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Completed Learners
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead padding="2em 0em 0em 0em">
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    % Completion
                                                </FlexGrid>
                                            </TableHead>
                                        </TableRow>
                                        {current.statsData &&
                                        current.statsData.length > 0 ? (
                                            current.statsData.map(
                                                (statData, index) => (
                                                    <TableRow
                                                        key={`stat-detail-${index}`}
                                                    >
                                                        <TableCell
                                                            verticalAlign="top"
                                                            width="250px"
                                                        >
                                                            <Text
                                                                fontSize="0.875rem"
                                                                workBreakAll
                                                            >
                                                                {index + 1}
                                                            </Text>
                                                        </TableCell>
                                                        <TableCell
                                                            verticalAlign="top"
                                                            width="250px"
                                                        >
                                                            <Text
                                                                fontSize="0.875rem"
                                                                workBreakAll
                                                            >
                                                                {statData.section ??
                                                                    ''}
                                                            </Text>
                                                        </TableCell>
                                                        <TableCell
                                                            verticalAlign="top"
                                                            width="250px"
                                                        >
                                                            <Text
                                                                fontSize="0.875rem"
                                                                workBreakAll
                                                            >
                                                                {statData.startingUsers ??
                                                                    0}
                                                            </Text>
                                                        </TableCell>
                                                        <TableCell
                                                            verticalAlign="top"
                                                            width="250px"
                                                        >
                                                            <Text
                                                                fontSize="0.875rem"
                                                                workBreakAll
                                                            >
                                                                {statData.completedUsers ??
                                                                    0}
                                                            </Text>
                                                        </TableCell>
                                                        <TableCell
                                                            verticalAlign="top"
                                                            width="250px"
                                                        >
                                                            <Text
                                                                fontSize="0.875rem"
                                                                workBreakAll
                                                            >
                                                                {`${roundDecimals(
                                                                    statData.percentCompletion ??
                                                                        0,
                                                                    2
                                                                )}%`}
                                                            </Text>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )
                                        ) : (
                                            <TableRow
                                                key={`stat-detail-empty`}
                                            >
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {''}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {''}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {''}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {''}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {''}
                                                    </Text>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Table>
                                </FlexGrid>
                            }
                        />
                    )}
                    <Table>
                        <TableRow>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Modules
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Learners (Participants)
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Active Modules
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Expired Modules
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Point Disbursed
                                </FlexGrid>
                            </TableHead>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {themesDashboardStats.totalThemes}
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {themesDashboardStats.totalParticipants}
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {themesDashboardStats.themesActive}
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {themesDashboardStats.themesExpired}
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {`${themesDashboardStats.pointsDisbursed} pts`}
                                </Text>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Table>
                        <TableRow>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Name
                                    {sort.name === 0 && (
                                        <IconButton
                                            negative
                                            transform="rotate(-90deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Languages
                                    {sort.languages === 0 && (
                                        <IconButton
                                            negative
                                            transform="rotate(-90deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    languages: 1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.languages === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    languages: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.languages === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    languages: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Status
                                    {sort.status === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    status: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.status === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    status: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.status === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    status: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                        {themes.map((theme, key) => {
                            const url = theme.image;
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        <FlexGrid gap="1em" alignItems="center">
                                            <ImageContainer>
                                                <img
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    src={
                                                        url
                                                            ? `https:${url}`
                                                            : PLACEHOLDER_IMAGE
                                                    }
                                                    alt="module thumbnail"
                                                />
                                            </ImageContainer>
                                            <Text
                                                link
                                                fontSize="0.875rem"
                                                color={COLORS.green}
                                            >
                                                {theme?.name}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        {theme?.language?.join(', ')}
                                    </TableCell>
                                    <TableCell>{theme?.status}</TableCell>
                                    <TableCell>
                                        <FlexGrid alignItems="center">
                                            <TableActionBtn
                                                onClick={async () => {
                                                    setDetailsModal(true);
                                                    const entryId = theme.id;
                                                    const statsResponse =
                                                        await ApiService.getSingleLearningCardThemeStats(
                                                            entryId
                                                        );
                                                    const statsData =
                                                        statsResponse?.data;
                                                    // [
                                                    //     {
                                                    //         "entryId": "4psD7Clw1vj5kuBUCX7m2F",
                                                    //         "section": "Test Section 1 M 2",
                                                    //         "startingUsers": 2,
                                                    //         "completedUsers": 0,
                                                    //         "percentCompletion": null
                                                    //     }
                                                    // ]
                                                    // console.log('==statsData==', statsData);
                                                    theme.statsData =
                                                        statsData ?? [];
                                                    setCurrent(theme);
                                                }}
                                                negative
                                            >
                                                Stats
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={() => {
                                                    setCurrent(theme);
                                                    setCreateEditModal(true);
                                                }}
                                                negative
                                            >
                                                Edit
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={() => {
                                                    const sections =
                                                        theme.sections;
                                                    if (
                                                        sections &&
                                                        sections.length > 0
                                                    ) {
                                                        dispatch(
                                                            updateModalAction({
                                                                open: true,
                                                                title: 'error',
                                                                content: `You can't delete this module because it has ${
                                                                    sections.length
                                                                } ${
                                                                    sections.length ===
                                                                    1
                                                                        ? 'section'
                                                                        : 'sections'
                                                                }.`,
                                                            })
                                                        );
                                                        return;
                                                    }
                                                    setCurrent(theme);
                                                    setDeleteModal(true);
                                                }}
                                                negative
                                            >
                                                Remove
                                            </TableActionBtn>
                                        </FlexGrid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </Table>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        total={total}
                        setPage={(v) => {
                            setPage(v);
                        }}
                    />
                </>
            )}
        </>
    );
};
