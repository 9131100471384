import { contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import { GLOBAL_CONFIG } from '../../../config/config';
import { ApiService } from '../../../services/Api.service';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';

type Input = any;
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const entryId = props.input.id;
    const spaceId = props.input.spaceId || GLOBAL_CONFIG.CONTENTFUL_SPACE_ID;
    const envId = props.input.envId || GLOBAL_CONFIG.environment;
    const contentType = props.input.contentType || 'video';
    const video = await ContentfulApiService.getEntry({
        contentType: 'video',
        entryId,
    });
    const viewsData = await ApiService.getViews(entryId, spaceId, envId, contentType);
    video.views = viewsData.views;
    video.uniqueViews = viewsData.uniqueViews;
    video.comments = [];

    ApiService.getComments(entryId, spaceId, envId, contentType).then((res) => {
        props.updateData({ ...video, comments: res });
    });
    console.log('video hook', video);
    return video;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useVideoHook = (
    id: string,
    spaceId: string,
    envId: string,
    contentType: string,
    changeList = []
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: true,
        initialData: { views: 0, comments: [] },
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: {
                id,
                spaceId,
                envId,
                contentType,
            } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
