import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import { GLOBAL_CONFIG } from '../../../config/config';
import _ from 'lodash';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { ApiService } from '../../../services/Api.service';

type Input = {
    pagination: any;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const pushNotifications = await ContentfulApiService.searchContent({
        contentType: 'pushNotification',
        limit: props.input.pagination.perPage,
        page: props.input.pagination.page,
        sortBy: props.input.pagination.sortBy,
    });

    pushNotifications.items.map((d) => {
        ApiService.getViews(
            d.sys.id,
            d.sys.space.sys.id,
            d.sys.environment.sys.id,
            d.sys.contentType.sys.id
        ).then((res) => {
            d.views = res.views;
            d.uniqueViews = res.uniqueViews;
            props.updateData({ ...pushNotifications });
        });
    });

    console.log('pushNotifications contentful data', pushNotifications);
    return pushNotifications;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const usePushNotificationHook = (
    pagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialData: {},
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
