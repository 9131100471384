import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../hooks/use-api-hook-wrapper';

import { ApiService, DropDownPopulate } from '../../services/Api.service';

type Input = string;
type Output = DropDownPopulate;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const dropdown = await ApiService.getDropdown()
    return dropdown;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useDropdownPopulateHook = (): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: true,
        initialData: {},
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
