/* eslint-disable no-unused-vars */
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { COLORS, COMMON } from '../styles/variables';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';
import { ChevronDownIcon,ChevronUpIcon } from '../assets/icons';
import { InputContainer } from './InputContainer';
import { Button } from './Button';
import useClickOutside from '../utils/useClickOutside';
import { CheckBox } from './CheckBox';

const SelectContainer = styled(FlexGrid)`
    border-radius: 50px;
    padding: 0.3em 1.5em 0.3em 2em;
    gap: 0.5em;
    align-items: center;
    justify-content: space-between;
    min-width: 150px;
    background-color: ${COLORS.veryLightGray};
    height: 2em;
    gap: 0.5em;
    cursor: pointer;
    z-index: 2;
`;

const FormSelectValue = styled(Text)`
    flex: 1;
`;

interface OptionProps {
    open: boolean;
    form?: boolean;
}

const OptionsContainer = styled(FlexGrid) <OptionProps>`
    position: absolute;
    border-radius: 5px;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    top: ${({ form }) => (form ? '110%' : '0%')};
    border-top-left-radius: ${({ form }) => !form && '1em'};
    border-top-right-radius: ${({ form }) => !form && '1em'};
    padding-top: ${({ form }) => !form && '2em'};
    z-index: 1;
    width: 100%;
    flex-direction: column;
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.lightGray};
    box-shadow: ${COMMON.shadow};
`;

const Option = styled(FlexGrid)`
    width: 100%;
    padding: 0.5em 1em;
    border-bottom: 1px solid ${COLORS.veryLightGray};
    cursor: pointer;

    &:hover {
        background-color: ${COLORS.green};
        color: ${COLORS.white};

        .icon-stroke {
            stroke: ${COLORS.white};
        }

        .icon-fill {
            fill: ${COLORS.white};
        }
    }

    &:hover * {
        color: ${COLORS.white};
    }
`;

interface SelectProps {
    value?: any;
    options?: any[];
    onChange?: (e: any) => void;
    label?: string;
    form?: boolean;
    placeholder?: string;
    info?: { title: any; content: any };
    renderValue?: (value: string) => any;
    renderOption?: (val: any) => any;
    multiple?: boolean;
    optionsBottom?: any;
    m?: string;
    p?: string;
    disable?: boolean;
    valueColor?: string;
}

export const Select: React.FC<SelectProps> = ({
    label,
    value,
    options,
    onChange,
    form,
    placeholder,
    info,
    renderValue,
    renderOption,
    multiple,
    optionsBottom,
    disable,
    valueColor,
    m,
    p,
}) => {
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [temporarySelected, setTemporarySelected] = useState<any[]>(value);
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const close = useCallback(() => {
        setOptionsOpen(false);
        if (multiple && onChange) {
            onChange(temporarySelected);
        }
    }, [multiple, onChange, temporarySelected]);
    useClickOutside(ref, close);

    const selectInterface = form ? (
        <InputContainer
            info={info}
            label={label}
            onClick={() => !disable && setOptionsOpen((prev) => !prev)}
            focused={optionsOpen}
            border
            m={m}
            p={p}
        >
            <FormSelectValue color={value ? (valueColor ?? COLORS.darkGray) : COLORS.gray}>
                {renderValue && value
                    ? renderValue(value)
                    : value || placeholder || 'SELECT'}
            </FormSelectValue>
            {!!multiple && value?.length > 1 && (
                <div style={{ marginRight: '5px' }}>+{value?.length - 1} </div>
            )}
            <Button
                m="0"
                p="0"
                negative
                onClick={() => !disable && setOptionsOpen((prev) => !prev)}
            >
                {
                    !optionsOpen ? <ChevronDownIcon height="8" /> : <ChevronUpIcon height="8" />
                }
            </Button>
        </InputContainer>
    ) : (
        <SelectContainer
            m={m}
            onClick={() => !disable && setOptionsOpen((prev) => !prev)}
        >
            <Text
                fontSize="0.75rem"
                uppercase
                font="AR Bold"
                color={COLORS.green}
            >
                {value || label}
            </Text>
            {optionsOpen && (
                <div style={{ transform: 'rotate(180deg' }}>
                    <ChevronDownIcon height="8" />
                </div>
            )}
            {!optionsOpen && <ChevronDownIcon height="8" />}
        </SelectContainer>
    );

    const selectOption = (selectedValue: any) => {
        if (multiple) {
            if (
                _.find(temporarySelected, selectedValue) ||
                temporarySelected.includes(selectedValue)
            ) {
                setTemporarySelected(
                    temporarySelected.filter(
                        (el) => !_.isEqual(el, selectedValue)
                    )
                );
            } else {
                // setTemporarySelected([...temporarySelected, selectedValue]);
                setTemporarySelected(
                    options.filter(
                        (op) =>
                            !!temporarySelected.find((t) => _.isEqual(t, op)) ||
                            _.isEqual(op, selectedValue)
                    )
                );
            }
        } else {
            if (onChange) {
                onChange(selectedValue);
            }
            setOptionsOpen(false);
        }
    };

    return (
        <FlexGrid
            width={form ? '100%' : 'auto'}
            ref={ref}
            position="relative"
            direction="column"
            m={m}
            p={p}
        >
            {selectInterface}
            {!options || options.length === 0 ? (
                <OptionsContainer form={form} open={optionsOpen}>
                    <Text p="0.5em 1em" color={COLORS.gray}>
                        - no options available -
                    </Text>
                    {optionsBottom}
                </OptionsContainer>
            ) : (
                <OptionsContainer form={form} open={optionsOpen}>
                    {options.map((option: any, key) => (
                        // remove onchange check
                        <Option
                            key={key}
                            alignItems="center"
                            onClick={() => selectOption(option)}
                        >
                            {multiple && (
                                <CheckBox
                                    checked={
                                        _.find(temporarySelected, option) ||
                                        temporarySelected.includes(option)
                                    }
                                    onChange={() => selectOption(option)}
                                />
                            )}
                            <Text>
                                {renderOption ? renderOption(option) : option}
                            </Text>
                        </Option>
                    ))}
                    {multiple && (
                        <FlexGrid
                            width="100%"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {optionsBottom}
                            <Button
                                negative
                                title="Save"
                                onClick={() => {
                                    if (onChange) {
                                        onChange(temporarySelected);
                                    }
                                    setOptionsOpen(false);
                                }}
                            />
                        </FlexGrid>
                    )}
                </OptionsContainer>
            )}
        </FlexGrid>
    );
};
