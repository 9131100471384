import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { DEFAULT_LANGUAGE_CODE, LANGUAGE_CONTENTFUL_MAP_REVERSE } from '../common/constants';
import {
    cfDefaultGet,
    cfGet,
    cfLanGet,
    getDefaultTimezone,
    getMapValue,
} from '../utils';
const lan = DEFAULT_LANGUAGE_CODE;

export class ContentfulTransformService {
    static putDate = (dt: string, tm: string) => {
        if (!dt) {
            return null;
        }
        // console.log('==`${dt} ${tm}`, "DD MMMM YYYY HH:mm"', `${dt} ${tm}`, "DD MMMM YYYY HH:mm", getDefaultTimezone())
        return moment.tz(
            `${dt} ${tm}`,
            'DD MMMM YYYY HH:mm',
            getDefaultTimezone()
        );
    };

    static putLink = (id: string, linkType = 'Asset') => {
        return {
            sys: {
                id,
                linkType,
                type: 'Link',
            },
        };
    };

    static getLinkAsset(assets, item, fieldName: string) {
        const asset = assets?.items.find((a) =>
            item.fields[fieldName] && item.fields[fieldName][lan]
                ? a.sys.id === item.fields[fieldName][lan].sys.id
                : false
        );
        return asset;
    }

    static getLink(assets, item, fieldName: string) {
        const asset = ContentfulTransformService.getLinkAsset(
            assets,
            item,
            fieldName
        );

        return asset?.fields.file ? asset?.fields.file[lan].url : '';
    }

    static getLinkDetails(asset) {
        return {
            name: cfDefaultGet(asset, 'fields.file')?.fileName,
            size: cfDefaultGet(asset, 'fields.file')?.details?.size,
        };
    }

    static getCommonFields(p: any) {
        return {
            contentfulRawData: p,

            regions: p.fields.region ? p.fields.region[lan] : [],
            stateprovinces: p.fields.stateprovince
                ? p.fields.stateprovince[lan]
                : [],
            distributors: p.fields.distributors
                ? p.fields.distributors[lan]
                : [],
            district: p.fields.district ? p.fields.district[lan] : [],
            pincode: (p.fields.pincode || {})[lan],

            targetUsers: p.fields.userType ? p.fields.userType[lan] : [],
            spaceOfMechanics: p.fields.spaceOfMechanic
                ? p.fields.spaceOfMechanic[lan]
                : [],
            connekt: !!(p.fields.connekt || {})[lan],
            mobile: (p.fields.uploadMobile || {})[lan],
            pid: (p.fields.uploadPid || {})[lan],
            dealerSegments: p.fields.dealerWsSegment
                ? p.fields.dealerWsSegment[lan]
                : [],
            dealerSubSegments: p.fields.dealerWsSubSegment
                ? p.fields.dealerWsSubSegment[lan]
                : [],
            mechanicsSegments: p.fields.mechanicsSegment
                ? p.fields.mechanicsSegment[lan]
                : [],
            mechanicsSubSegments: p.fields.mechanicsSubSegment
                ? p.fields.mechanicsSubSegment[lan]
                : [],
        };
    }

    static getArticleFolders(items: any[]) {
        if (!items) {
            return [];
        }

        // todo: sort should happen in server endpoint
        const copyed = _.cloneDeep(items);

        const sortedPlaylist = copyed;

        const mappedItems = sortedPlaylist.map((p, index) => {
            const createdAt = moment.tz(p.sys.createdAt, getDefaultTimezone());
            const daysNumber = moment
                .tz(getDefaultTimezone())
                .diff(createdAt, 'days');

            return {
                contentfulRawData: p,

                id: p.sys.id,
                name: cfDefaultGet(p.fields, 'name'),
                language: cfDefaultGet(p.fields, 'language') || [],
                startDate: moment.tz(
                    cfDefaultGet(p.fields, 'startDate') || p.sys.createdAt,
                    getDefaultTimezone()
                ),
                tag: cfDefaultGet(p.fields, 'tag'),
                title: cfDefaultGet(p.fields, 'name'),
                date: `${daysNumber} days ago`,
                createdAt,
                views: p.views,
                uniqueViews: p.uniqueViews,

                total: p.total,
                articles: p.articles,
            };
        });
        return mappedItems;
    }

    static getArticles(assets, items: any[], articleFolder: any[]) {
        if (!items) {
            return [];
        }
        // const sortedPlaylist = items.sort((x, y) => {
        //     const xd = moment.tz(x.sys.createdAt, getDefaultTimezone());
        //     const yd = moment.tz(y.sys.createdAt, getDefaultTimezone());
        //     return yd.valueOf() - xd.valueOf();
        // });
        const sortedPlaylist = items;

        const mappedItems = sortedPlaylist.map((p, index) => {
            const createdAt = moment.tz(p.sys.createdAt, getDefaultTimezone());
            const daysNumber = moment
                .tz(getDefaultTimezone())
                .diff(createdAt, 'days');

            let folders = (
                (p.fields.folders && p.fields.folders[lan]) ||
                []
            ).map((fd) => {
                return (
                    // sometime backend return {folders: {en-US: [null, null]}}, not sure why
                    articleFolder &&
                    articleFolder.find((af) => af.sys.id === fd?.sys?.id)
                );
            });
            folders = folders.filter((spl) => !!spl);
            folders = ContentfulTransformService.getArticleFolders(folders);
            const quiz = p.fields.quiz && p.fields.quiz[lan];

            return {
                id: p.sys.id,
                name: cfDefaultGet(p, 'fields.name') || '',
                tags: cfDefaultGet(p.fields, 'tags') || [],
                headline: cfLanGet(p, 'fields.headline'),
                description: cfLanGet(p, 'fields.description'),
                lanHeadline: p.fields.headline,
                lanDescription: p.fields.description,
                linkSource: cfLanGet(p, 'fields.linkSource'),
                lanLinkSource: p.fields.linkSource,
                lanCallToActionText: p.fields.callToActionText,
                language: cfDefaultGet(p.fields, 'language') || [],
                startDate:
                    cfDefaultGet(p.fields, 'startDate') &&
                    moment.tz(
                        cfDefaultGet(p.fields, 'startDate'),
                        getDefaultTimezone()
                    ),
                endDate:
                    cfDefaultGet(p.fields, 'endDate') &&
                    moment.tz(
                        cfDefaultGet(p.fields, 'endDate'),
                        getDefaultTimezone()
                    ),

                featured:
                    p.fields.featured && cfDefaultGet(p.fields, 'featured'),
                folders,

                status:
                    cfDefaultGet(p.fields, 'status') ||
                    (p.sys.publishedVersion ? 'Published' : 'Unpublish'),
                title: cfLanGet(p, 'fields.headline'),
                image: ContentfulTransformService.getLink(
                    assets,
                    p,
                    'articleImage'
                ),
                imageAsset: ContentfulTransformService.getLinkAsset(
                    assets,
                    p,
                    'articleImage'
                ),
                date: `${daysNumber} days ago`,
                createdAt,
                views: p.views,
                uniqueViews: p.uniqueViews,

                likes:
                    (p.fields.likes && cfDefaultGet(p.fields, 'likes')) || '0',
                dislikes:
                    (p.fields.dislikes && cfDefaultGet(p.fields, 'dislikes')) ||
                    '0',
                new: index === 0,

                comments: p.comments || [],
                quiz,

                ...ContentfulTransformService.getCommonFields(p),
            };
        });
        return mappedItems;
    }

    static getBanners(assets, items: any[]) {
        const sortedPlaylist = items;

        const mappedItems = sortedPlaylist.map((p, index) => {
            const createdAt = moment.tz(p.sys.createdAt, getDefaultTimezone());
            const daysNumber = moment
                .tz(getDefaultTimezone())
                .diff(createdAt, 'days');

            console.log('banner data', p);
            return {
                id: p.sys.id,
                tags: p.fields.tags ? p.fields.tags[lan] : [],
                name: (p.fields.name || {})[lan] || '',
                lanHeadline: p.fields.headline,
                lanDescription: p.fields.description,
                lanCallToActionText: p.fields.callToActionText,
                language: p.fields.language ? p.fields.language[lan] : [],
                isLinkInternal: p.fields.linkType && p.fields.linkType[lan],
                lanLinkSource: p.fields.linkSource,
                linkSource: p.fields.linkSource && p.fields.linkSource[lan],
                startDate:
                    cfDefaultGet(p.fields, 'startDate') &&
                    moment.tz(p.fields.startDate[lan], getDefaultTimezone()),
                endDate:
                    cfDefaultGet(p.fields, 'endDate') &&
                    moment.tz(p.fields.endDate[lan], getDefaultTimezone()),
                title: cfLanGet(p, 'fields.headline'),
                image: ContentfulTransformService.getLink(
                    assets,
                    p,
                    'bannerImage'
                ),
                status:
                    (p.fields.status && p.fields.status[lan]) ||
                    (p.sys.publishedVersion ? 'Active' : 'InActive'),
                imageAsset: ContentfulTransformService.getLinkAsset(
                    assets,
                    p,
                    'bannerImage'
                ),
                date: `${daysNumber} days ago`,
                createdAt,
                views: p.views,
                uniqueViews: p.uniqueViews,

                ...ContentfulTransformService.getCommonFields(p),
            };
        });
        return mappedItems;
    }

    static getBannersAssets(videos: any[]) {
        let result = [];
        videos.forEach((p) => {
            const vd = cfDefaultGet(p.fields, `bannerImage`);
            if (vd) {
                result.push(vd.sys.id);
            }
        });
        return result;
    }

    static getArticlesAssets(articles: any[]) {
        let result = [];
        articles.forEach((p) => {
            const vd = cfDefaultGet(p.fields, `articleImage`);
            if (vd) {
                result.push(vd.sys.id);
            }
        });
        return result;
    }

    static getVideosAssets(videos: any[]) {
        let result = [];
        videos.forEach((p) => {
            const vd = cfDefaultGet(p.fields, `videoMedia`);
            const id = cfDefaultGet(p.fields, `videoThumbnail`);
            if (vd) {
                result.push(vd.sys.id);
            }
            if (id) {
                result.push(id.sys.id);
            }
        });
        return result;
    }

    static getVideos(assets, videos: any[], playlist: any[]) {
        const sortedPlaylist = videos;

        const mappedVideos = sortedPlaylist.map((p, index) => {
            const createdAt = moment.tz(p.sys.createdAt, getDefaultTimezone());
            // const daysNumber = moment.tz(getDefaultTimezone()).diff(createdAt, 'days');
            const startDateFormateed = moment
                .tz(createdAt, getDefaultTimezone())
                .format('MMM DD, YYYY');

            const pls = (p.fields.playlists && p.fields.playlists[lan]) || [];
            const pls2 = pls
                .map((pl) =>
                    (playlist || []).find((pl2) => pl2.sys.id === pl.sys.id)
                )
                .filter((r) => !!r);
            const tranPls = ContentfulTransformService.getPlaylist(pls2);
            const link =
                !!(p.fields.videoFromUrl || {})[lan] &&
                (p.fields.videoFromUrl || {})[lan] !== 'No'
                    ? (p.fields.videoFromUrl || {})[lan]
                    : ContentfulTransformService.getLink(
                          assets,
                          p,
                          'videoMedia'
                      );
            let image = ContentfulTransformService.getLink(
                assets,
                p,
                'videoThumbnail'
            );
            const quiz = p.fields.quiz && p.fields.quiz[lan];
            if (
                !cfDefaultGet(p, 'fields.videoThumbnail') &&
                link?.trim()?.match(/^https:\/\/youtu.be/)
            ) {
                const id = link?.trim()?.match(/^https:\/\/youtu.be\/(.*)$/)[1];
                image = `http://img.youtube.com/vi/${id}/0.jpg`;
            }
            if (
                !cfDefaultGet(p, 'fields.videoThumbnail') &&
                link?.trim()?.match(/^https:\/\/www.youtube.com/)
            ) {
                const id = link
                    ?.trim()
                    ?.match(/^https:\/\/www.youtube.com\/watch\?v=(.*)$/)[1];
                image = `http://img.youtube.com/vi/${id}/0.jpg`;
            }

            return {
                id: p.sys.id,
                spaceId: p.sys.space.sys.id,
                envId: p.sys.environment.sys.id,
                contentType: p.sys.contentType.sys.id,
                name: cfDefaultGet(p.fields, 'name') || '',
                language: cfDefaultGet(p.fields, 'language') || [],
                startDate:
                    cfDefaultGet(p.fields, 'startDate') &&
                    moment.tz(
                        cfDefaultGet(p.fields, 'startDate'),
                        getDefaultTimezone()
                    ),
                endDate:
                    cfDefaultGet(p.fields, 'endDate') &&
                    moment.tz(
                        cfDefaultGet(p.fields, 'endDate'),
                        getDefaultTimezone()
                    ),
                fromUrl: !!cfDefaultGet(p.fields, 'videoFromUrl'),
                title: cfLanGet(p.fields, 'videoTitle'),
                lanTitle: p.fields.videoTitle,
                lanSummary: p.fields.videoSummary,
                lanDescription: p.fields.description,
                description: cfGet(p.fields, 'description'),
                link,
                linkAsset: ContentfulTransformService.getLinkAsset(
                    assets,
                    p,
                    'videoMedia'
                ),
                image,
                imageAsset: ContentfulTransformService.getLinkAsset(
                    assets,
                    p,
                    'videoThumbnail'
                ),
                // date: `${daysNumber} days ago`,
                date: startDateFormateed,
                createdAt,
                views: p.views,
                uniqueViews: p.uniqueViews,
                featured: cfDefaultGet(p.fields, 'featured'),
                tags: cfDefaultGet(p, 'fields.tags') || [],
                playlist: tranPls[0]?.title || '', // todo: delete it
                playlists: tranPls,
                quiz,
                ...ContentfulTransformService.getCommonFields(p),

                status: p.sys.publishedVersion ? 'Published' : 'Unpublish',
                likes: `${
                    p.fields?.likes ? cfDefaultGet(p.fields, 'likes') : 0
                }`,
                dislikes: `${
                    p.fields?.dislikes ? cfDefaultGet(p.fields, 'dislikes') : 0
                }`,
                length: `${cfDefaultGet(p.fields, 'videoDuration')}`,
                new: index === 0,
            };
        });
        return mappedVideos;
    }

    static getPlaylistVideos(playlist, playlists) {
        const playList = playlist.playList || [];
        const assets = playlist.assets;

        return ContentfulTransformService.getVideos(
            assets,
            playList,
            playlists
        );
    }

    static getPlaylist(playlist) {
        return playlist.map((p) => ({
            contentfulRawData: p,

            id: p.sys.id,
            title: p.fields.name[DEFAULT_LANGUAGE_CODE],
            description: p.fields.description[DEFAULT_LANGUAGE_CODE],
            language: p.fields.language ? p.fields.language[lan] : [],
            total: (p.playList || []).length, // todo: change to null if not assigned playList
        }));
    }

    static getInAppNotifications(contentful) {
        const mapped = ((contentful.items as any[]) || []).map((p) => {
            const startDateFormateed = moment
                .tz(cfDefaultGet(p, 'fields.startDate'), getDefaultTimezone())
                .format('MMM DD, YYYY');

            const startDate =
                cfDefaultGet(p, 'fields.startDate') &&
                moment.tz(
                    cfDefaultGet(p, 'fields.startDate'),
                    getDefaultTimezone()
                );
            const endDate =
                cfDefaultGet(p, 'fields.endDate') &&
                moment.tz(
                    cfDefaultGet(p, 'fields.endDate'),
                    getDefaultTimezone()
                );

            return {
                id: p.sys.id,
                // headline: cfLanGet(p, 'fields.headline'),
                bodyText: cfLanGet(p, 'fields.description'),
                targetLink: cfLanGet(p, 'fields.TargetLink'),
                date: startDateFormateed,
                status:
                    (p.fields.status && p.fields.status[lan]) ||
                    (p.sys.publishedVersion ? 'Published' : 'Unpublish'),

                icon: (p.fields.icon || {})[lan],
                // lanHeadline: p.fields.headline,
                isLinkInternal: p.fields.linkType && p.fields.linkType[lan],
                lanBodyText: p.fields.description,
                lanTargetLink: p.fields.TargetLink,
                name: cfDefaultGet(p, 'fields.name') || '',
                language: cfDefaultGet(p, 'fields.language') || [],
                startDate,
                endDate,
                views: p.views,
                uniqueViews: p.uniqueViews,

                ...ContentfulTransformService.getCommonFields(p),
            };
        });

        return {
            raw: contentful,
            mapped,
        };
    }

    static getPushNotifications(contentful) {
        const mapped = ((contentful.items as any[]) || []).map((p) => {
            const startDate =
                cfDefaultGet(p, 'fields.startDate') &&
                moment.tz(
                    cfDefaultGet(p, 'fields.startDate'),
                    getDefaultTimezone()
                );
            const endDate =
                cfDefaultGet(p, 'fields.endDate') &&
                moment.tz(
                    cfDefaultGet(p, 'fields.endDate'),
                    getDefaultTimezone()
                );

            return {
                id: p.sys.id,
                previewText: cfLanGet(p, 'fields.headline'),
                notificationText: cfLanGet(p, 'fields.description'),
                type: (p.fields.type || {})[lan],
                targetUser: ((p.fields.userType || {})[lan] || [])[0],
                targetLink: cfLanGet(p, 'fields.TargetLink'),
                // above is table show required

                lanPreviewText: p.fields.headline,
                lanPotificationText: p.fields.description,
                isLinkInternal: p.fields.linkType && p.fields.linkType[lan],
                lanTargetLink: p.fields.TargetLink,
                name: cfDefaultGet(p, 'fields.name') || '',
                language: cfDefaultGet(p, 'fields.language') || [],
                startDate,
                endDate,

                views: p.views,
                uniqueViews: p.uniqueViews,

                ...ContentfulTransformService.getCommonFields(p),
            };
        });

        return {
            raw: contentful,
            mapped,
        };
    }

    static transformQuiz(contentfulQuiz) {
        const p = contentfulQuiz;

        const startDateFormateed = moment
            .tz(cfDefaultGet(p, 'fields.startDate'), getDefaultTimezone())
            .format('MMM DD, YYYY');
        const startDate =
            cfDefaultGet(p, 'fields.startDate') &&
            moment.tz(
                cfDefaultGet(p, 'fields.startDate'),
                getDefaultTimezone()
            );
        const endDate =
            cfDefaultGet(p, 'fields.endDate') &&
            moment.tz(cfDefaultGet(p, 'fields.endDate'), getDefaultTimezone());

        return {
            id: p.sys.id,
            title: cfLanGet(p, 'fields.title'),
            description: cfLanGet(p, 'fields.description'),
            type: (p.fields.type || {})[lan],
            targetUser: ((p.fields.userType || {})[lan] || [])[0],
            date: startDateFormateed,
            status:
                (p.fields.status && p.fields.status[lan]) ||
                (p.sys.publishedVersion ? 'Published' : 'Unpublish'),
            // above is table show required

            lanTitle: p.fields.title,
            lanDescription: p.fields.description,
            questions: p.fields.questions,
            bonusPoints: cfDefaultGet(p, 'fields.bonusPoints') || 0,
            totalPoints: cfDefaultGet(p, 'fields.totalPoints') || 0,
            linkedContent: cfDefaultGet(p, 'fields.linkedContent'),
            linkedContentObj: undefined,
            name: cfDefaultGet(p, 'fields.name') || '',
            language: cfDefaultGet(p, 'fields.language') || [],
            startDate,
            endDate,

            ...ContentfulTransformService.getCommonFields(p),
        };
    }

    // Quizzes
    static getQuizzes(contentful) {
        const mapped = ((contentful.items as any[]) || []).map(
            ContentfulTransformService.transformQuiz
        );

        return {
            raw: contentful,
            mapped,
        };
    }

    static transformScanningContest(contentfulScanningContest) {
        const p = contentfulScanningContest;

        const startDateFormateed = moment
            .tz(cfDefaultGet(p, 'fields.startDate'), getDefaultTimezone())
            .format('MMM DD, YYYY');
        const startDate =
            cfDefaultGet(p, 'fields.startDate') &&
            moment.tz(
                cfDefaultGet(p, 'fields.startDate'),
                getDefaultTimezone()
            );
        const endDate =
            cfDefaultGet(p, 'fields.endDate') &&
            moment.tz(cfDefaultGet(p, 'fields.endDate'), getDefaultTimezone());

        return {
            id: p.sys.id,
            title: cfLanGet(p, 'fields.title'),
            description: cfLanGet(p, 'fields.description'),
            type: (p.fields.type || {})[lan],
            targetUser: ((p.fields.userType || {})[lan] || [])[0],
            date: startDateFormateed,
            status:
                (p.fields.status && p.fields.status[lan]) ||
                (p.sys.publishedVersion ? 'Published' : 'Unpublish'),
            // above is table show required

            lanHeadline: p.fields.headline,
            lanDescription: p.fields.description,
            schemes: p.fields.schemes,
            totalPoints: cfDefaultGet(p, 'fields.totalPoints') || 0,
            multiplierPoints: cfDefaultGet(p, 'fields.multiplierPoints') || 0,
            applicableBrands: cfDefaultGet(p.fields, 'applicableBrand') || [],
            applicableSKUs: cfDefaultGet(p.fields, 'applicableSKU') || [],

            name: cfDefaultGet(p, 'fields.name') || '',
            language: cfDefaultGet(p, 'fields.language') || [],
            startDate,
            endDate,

            ...ContentfulTransformService.getCommonFields(p),
        };
    }
    

    // ScanningContests
    static getScanningContests(contentful) {
        const mapped = ((contentful.items as any[]) || []).map(
            ContentfulTransformService.transformScanningContest
        );

        return {
            raw: contentful,
            mapped,
        };
    }

    // Learning card
    // Learning card themes
    // For learningCardTheme, learningCardSection, learningCardSlides
    static getLearningCardThemeAssets(learningCardThemeItems: any[]) {
        let result = [];
        learningCardThemeItems.forEach((p) => {
            const vd = cfDefaultGet(p.fields, `learningCardThemeImage`);
            if (vd) {
                result.push(vd.sys.id);
            }
        });
        return result;
    }

    static transformLearningCardTheme(assets, contentful) {
        const p = contentful;
        let image, imageAsset;
        if (assets) {
            image = ContentfulTransformService.getLink(
                assets,
                p,
                'learningCardThemeImage'
            );
            imageAsset = ContentfulTransformService.getLinkAsset(
                assets,
                p,
                'learningCardThemeImage'
            );
        }
        return {
            contentfulRawData: p,
            id: p.sys.id,
            name: cfDefaultGet(p, 'fields.name') || '',
            language: cfDefaultGet(p, 'fields.language') || [],
            tags: cfDefaultGet(p.fields, 'tags') || [],
            headline: cfLanGet(p, 'fields.headline'),
            description: cfLanGet(p, 'fields.description'),
            lanHeadline: p.fields.headline,
            lanDescription: p.fields.description,
            sections: cfDefaultGet(p, 'fields.sections') || [],
            image,
            imageAsset,
            status:
                (p.fields.status && p.fields.status[lan]) ||
                (p.sys.publishedVersion ? 'Published' : 'Unpublish'),
        };
    }

    static getLearningCardThemes(assets, contentful) {
        const items = (contentful.items as any[]) ?? [];
        if (items.length <= 0) {
            return items;
        }

        const mappedItems = items.map((p, index) => {
            return ContentfulTransformService.transformLearningCardTheme(assets, p);
        });
        return mappedItems;
    }

    static getLearningCardSectionAssets(learningCardSectionItems: any[]) {
        let result = [];
        learningCardSectionItems.forEach((p) => {
            const vd = cfDefaultGet(p.fields, `learningCardSectionImage`);
            if (vd) {
                result.push(vd.sys.id);
            }
        });
        return result;
    }

    static transformLearningCardSection(assets, contentful) {
        const p = contentful;
        let image, imageAsset;
        if (assets) {
            image = ContentfulTransformService.getLink(
                assets,
                p,
                'learningCardSectionImage'
            );
            imageAsset = ContentfulTransformService.getLinkAsset(
                assets,
                p,
                'learningCardSectionImage'
            );
        }
        return {
            contentfulRawData: p,
            id: p.sys.id,
            name: cfDefaultGet(p, 'fields.name') || '',
            language: cfDefaultGet(p, 'fields.language') || [],
            tags: cfDefaultGet(p.fields, 'tags') || [],
            headline: cfLanGet(p, 'fields.headline'),
            description: cfLanGet(p, 'fields.description'),
            lanHeadline: p.fields.headline,
            lanDescription: p.fields.description,
            cards: cfDefaultGet(p, 'fields.cards') || [],
            referencedTheme: cfDefaultGet(p, 'fields.referencedTheme'),
            image,
            imageAsset,
            status:
                (p.fields.status && p.fields.status[lan]) ||
                (p.sys.publishedVersion ? 'Published' : 'Unpublish'),
        };
    }

    static getLearningCardSections(assets, contentful) {
        const items = (contentful.items as any[]) ?? [];
        if (items.length <= 0) {
            return items;
        }

        const mappedItems = items.map((p, index) => {
            return ContentfulTransformService.transformLearningCardSection(assets, p);
        });
        return mappedItems;
    }

    static getLearningCardSlideAssets(learningCardSlideItems: any[]) {
        let result = [];
        learningCardSlideItems.forEach((p) => {
            const vd = cfDefaultGet(p.fields, `learningCardSlideImage`);
            if (vd) {
                result.push(vd.sys.id);
            }
        });
        return result;
    }

    static getLearningCardSlides(assets, contentful) {
        console.log('==getLearningCardSlides==', contentful);
        const items = (contentful as any[]) ?? [];
        if (items.length <= 0) {
            return items;
        }

        const getLanField = (prop) => {
            const result: any = {};
            _.map(prop, (v, k) => {
                result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
            });
            return result;
        };

        const mappedItems = items.map((p, index) => {
            const videoUrl = cfDefaultGet(p, 'fields.videoFromUrl');
            const image = ContentfulTransformService.getLink(
                assets,
                p,
                'learningCardSlideImage'
            );
            const imageAsset = ContentfulTransformService.getLinkAsset(
                assets,
                p,
                'learningCardSlideImage'
            );

            return {
                contentfulRawData: p,
                id: p.sys.id,
                content: {
                    headline: getLanField(p.fields.headline),
                    description: getLanField(p.fields.description),
                    learningCard: cfDefaultGet(p, 'fields.learningCard'),
                    oldImage: !!image,
                    image: {
                        preview: image,
                        name: ContentfulTransformService.getLinkDetails(imageAsset)
                            .name,
                        size: ContentfulTransformService.getLinkDetails(imageAsset)
                            .size,
                    },
                    isFromVideo: !!videoUrl,
                    videoUrl,
                },
                status:
                    (p.fields.status && p.fields.status[lan]) ||
                    (p.sys.publishedVersion ? 'Published' : 'Unpublish'),
            };
        });
        return mappedItems;
    }

    static transformLearningCard(contentfulLearningCard) {
        const p = contentfulLearningCard;

        const startDateFormateed = moment
            .tz(cfDefaultGet(p, 'fields.startDate'), getDefaultTimezone())
            .format('MMM DD, YYYY');
        const startDate =
            cfDefaultGet(p, 'fields.startDate') &&
            moment.tz(
                cfDefaultGet(p, 'fields.startDate'),
                getDefaultTimezone()
            );
        const endDate =
            cfDefaultGet(p, 'fields.endDate') &&
            moment.tz(cfDefaultGet(p, 'fields.endDate'), getDefaultTimezone());

        const quiz = p.fields.quiz && p.fields.quiz[lan];

        return {
            id: p.sys.id,
            headline: cfLanGet(p, 'fields.headline'),
            description: cfLanGet(p, 'fields.description'),
            type: (p.fields.type || {})[lan],
            targetUser: ((p.fields.userType || {})[lan] || [])[0],
            date: startDateFormateed,
            status:
                (p.fields.status && p.fields.status[lan]) ||
                (p.sys.publishedVersion ? 'Published' : 'Unpublish'),
            // above is table show required
            lanHeadline: p.fields.headline,
            lanDescription: p.fields.description,
            slides: p.fields.slides,
            referencedSection: cfDefaultGet(p, 'fields.referencedSection'),
            tags: cfDefaultGet(p.fields, 'tags') || [],
            name: cfDefaultGet(p, 'fields.name') || '',
            language: cfDefaultGet(p, 'fields.language') || [],
            startDate,
            endDate,

            quiz,

            ...ContentfulTransformService.getCommonFields(p),
        };
    }

    
    static getLearningCards(contentful) {
        const mapped = ((contentful.items as any[]) || []).map(
            ContentfulTransformService.transformLearningCard
        );

        return {
            raw: contentful,
            mapped,
        };
    }

    static transformRegisterContest(contentfulRegisterContest) {
        const p = contentfulRegisterContest;
    
        const startDateFormateed = moment.tz(
            cfDefaultGet(p, 'fields.startDate'), getDefaultTimezone()
        ).format('MMM DD, YYYY');
        const startDate =
            cfDefaultGet(p, 'fields.startDate') &&
            moment.tz(cfDefaultGet(p, 'fields.startDate'), getDefaultTimezone());
        const endDate =
            cfDefaultGet(p, 'fields.endDate') &&
            moment.tz(cfDefaultGet(p, 'fields.endDate'), getDefaultTimezone());
    
        return {
            id: p.sys.id,
            name: cfDefaultGet(p, 'fields.name') || '',
            language: cfDefaultGet(p, 'fields.language') || [],
            startDate,
            endDate,
            headline: cfLanGet(p, 'fields.headline'),
            lanHeadline: p.fields.headline,
            pointsForAchievement: cfDefaultGet(p, 'fields.pointsForAchievement') || 0,
            targetedUsers: cfDefaultGet(p, 'fields.targetedUsers') || 0,
            reachedUsers: cfDefaultGet(p, 'fields.reachedUsers') || 0,
            targetUser: ((p.fields.userType || {})[lan] || [])[0],
            date: startDateFormateed,
            status:
                (p.fields.status && p.fields.status[lan]) ||
                (p.sys.publishedVersion ? 'Published' : 'Unpublish'),
            // above is table show required    
            ...ContentfulTransformService.getCommonFields(p),
        };
    }

    static getRegisterContests(contentful) {
        const mapped = ((contentful.items as any[]) || []).map(ContentfulTransformService.transformRegisterContest);
    
        return {
            raw: contentful,
            mapped,
        };
    }

    static getContents(
        assets,
        contentful: any,
        videoPlaylist: any[],
        articleFolder: any[]
    ) {
        const items = contentful?.items || [];

        return items
            .map((item) => {
                let v: any = { type: 'unkown' };
                switch (item.sys.contentType.sys.id) {
                    case 'video':
                        v = ContentfulTransformService.getVideos(
                            assets,
                            [item],
                            videoPlaylist
                        )[0];
                        v.type = 'Videos';
                        break;
                    case 'videoPlaylist':
                        v = ContentfulTransformService.getPlaylist([item])[0];
                        v.type = 'Video playlist';
                        break;
                    case 'inAppNotification':
                        v = ContentfulTransformService.getInAppNotifications({
                            items: [item],
                        }).mapped[0];
                        v.type = 'In-app notification';
                        break;
                    case 'pushNotification':
                        v = ContentfulTransformService.getPushNotifications({
                            items: [item],
                        }).mapped[0];
                        v.type = 'Push notification';
                        break;
                    case 'banner':
                        v = ContentfulTransformService.getBanners(assets, [
                            item,
                        ])[0];
                        v.type = 'Banners';
                        break;
                    case 'article':
                        v = ContentfulTransformService.getArticles(
                            assets,
                            [item],
                            []
                        )[0];
                        v.type = 'Articles';
                        break;
                    case 'articleFolder':
                        v = ContentfulTransformService.getArticleFolders([
                            item,
                        ])[0];
                        v.type = 'Article Folder';
                        break;
                    case 'quiz':
                        v = ContentfulTransformService.getQuizzes({
                            items: [item],
                        }).mapped[0];
                        v.type = 'Quiz';
                        break;
                }
                return v;
            })
            .filter((v) => v.type !== 'unkown');
    }
}
