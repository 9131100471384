import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgNewArticle: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 19}
        height={height || 19}
        viewBox="0 0 19 19"
    >
        <g className="icon-fill" fill={color || '#009343'}>
            <path
                data-name="Path 4763"
                d="M15 10a1 1 0 0 0-1 1v5.22a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h5a1 1 0 0 0 0-2H2a2.006 2.006 0 0 0-2 2v12a2.006 2.006 0 0 0 2 2h12a2.006 2.006 0 0 0 2-2v-6a1 1 0 0 0-1-1Z"
            />
            <path
                data-name="Path 4764"
                d="M18.02 3H16V.98a.982.982 0 0 0-.98-.98h-.03a.984.984 0 0 0-.99.98V3h-2.01a1 1 0 0 0-.99.98v.03a.986.986 0 0 0 .99.99H14v2.01a.977.977 0 0 0 .99.98h.03a.982.982 0 0 0 .98-.98V5h2.02a.982.982 0 0 0 .98-.98v-.04a.982.982 0 0 0-.98-.98Z"
            />
            <path
                data-name="Path 4765"
                d="M11 7H5a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2Z"
            />
            <path
                data-name="Path 4766"
                d="M11 10H5a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2Z"
            />
            <path
                data-name="Path 4767"
                d="M11 13H5a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2Z"
            />
        </g>
    </svg>
);

export default SvgNewArticle;
