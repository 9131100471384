import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { InfoIcon, CloseIcon } from '../assets/icons';
import { FlexGrid } from './FlexGrid';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { Text } from './Text';
import useClickOutside from '../utils/useClickOutside';

interface InfoContainerProps {
    open?: boolean;
    width?: string;
}

const InfoContainer = styled(FlexGrid)<InfoContainerProps>`
    display: ${({ open }) => (open ? 'flex' : 'none')};
    position: absolute;
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.white};
    width: ${({ width }) => width || '400px'};
    padding: 0.5em 1em;
    flex-direction: column;
    border-radius: 10px;
    left: calc(100% + 18px);
    top: -10px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3), 0 0 2px rgba(0, 0, 0, 0.2);
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        right: 100%;
        top: 9px;
        border: 8px solid ${COLORS.transparent};
        border-left: 0;
        border-right: 10px solid ${COLORS.white};
        filter: drop-shadow(-1px 0 0px rgba(0, 0, 0, 0.3));
        border-radius: 0 0 0 20px;
    }
`;

interface InfoDetailsProp {
    open: boolean;
    setInfoOpen: React.Dispatch<React.SetStateAction<boolean>>;
    info: any;
    width?: string;
    onClick?: (event: any) => void;
}

export const InfoDetails: React.FC<InfoDetailsProp> = ({
    open,
    setInfoOpen,
    info,
    width,
    onClick
}) => (
    <InfoContainer width={width} open={open} onClick={onClick}>
        <FlexGrid width="100%" justifyContent="space-between" m="0 0 1em 0">
            <Text color={COLORS.black} font="AR Bold">
                {info.title}
            </Text>
            <Button
                negative
                m="5px 0 0 0"
                p="0"
                onClick={() => setInfoOpen((p) => !p)}
            >
                <CloseIcon color={COLORS.green} height="10" />
            </Button>
        </FlexGrid>
        <Text
            lineHeight="1.5"
            font="AR"
            color={COLORS.darkGray}
            fontSize="0.875rem"
        >
            {info.content}
        </Text>
    </InfoContainer>
);

interface InfoProps {
    info: any;
    onClickDetails?: (event: any) => void;
}

export const Info: React.FC<InfoProps> = ({ info, onClickDetails }) => {
    const [infoOpen, setInfoOpen] = useState(false);

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const close = useCallback(() => setInfoOpen(false), []);
    useClickOutside(ref, close);

    return (
        <FlexGrid ref={ref}>
            <Button negative p="0" m="0" onClick={() => setInfoOpen((p) => !p)}>
                <InfoIcon height="18" />
            </Button>
            <InfoDetails
                open={infoOpen}
                setInfoOpen={setInfoOpen}
                info={info}
                onClick={onClickDetails}
            />
        </FlexGrid>
    );
};
