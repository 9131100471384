import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgAlertCircle: React.FC<Props> = ({ width, height, color }) => (
    <svg
        data-name="Group 14969"
        xmlns="http://www.w3.org/2000/svg"
        width={width || 30}
        height={height || 30}
        viewBox="0 0 30 30"
    >
        <path data-name="Rectangle 428" fill="none" d="M0 0h30v30H0z" />
        <path
            className="icon-fill"
            data-name="Path 393"
            d="M15.007 28a12.451 12.451 0 0 0 5.018-1.024 13.328 13.328 0 0 0 6.958-6.958 12.866 12.866 0 0 0-.007-10.043A13.4 13.4 0 0 0 24.16 5.82a13.077 13.077 0 0 0-4.148-2.81A12.593 12.593 0 0 0 14.994 2a12.545 12.545 0 0 0-5.011 1.01 13.29 13.29 0 0 0-6.965 6.965 12.866 12.866 0 0 0 .007 10.043 13.39 13.39 0 0 0 6.958 6.958A12.437 12.437 0 0 0 15.007 28Zm0-10.745a1 1 0 0 1-1.124-1.111l-.174-6.61a1.163 1.163 0 0 1 .341-.89 1.4 1.4 0 0 1 1.867.007 1.217 1.217 0 0 1 .361.9l-.187 6.6q-.027 1.101-1.084 1.101Zm0 4.309a1.517 1.517 0 0 1-1.057-.4 1.354 1.354 0 0 1 0-2 1.565 1.565 0 0 1 2.1-.012 1.3 1.3 0 0 1 .442 1 1.286 1.286 0 0 1-.448 1.01 1.522 1.522 0 0 1-1.037.401Z"
            fill={color || 'red'}
        />
    </svg>
);

export default SvgAlertCircle;
