import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgMinus: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path data-name="Path 4845" d="M0 0h24v24H0Z" fill="none" />
        <path
            className="icon-fill"
            data-name="Path 4846"
            d="M 19 13 h -16 v -2 h 16 v 2 Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgMinus;
