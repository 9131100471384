import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgWatchLater: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 30}
        height={height || 30}
        viewBox="0 0 30 30"
    >
        <path data-name="Rectangle 431" fill="none" d="M0 0h30v30H0z" />
        <path
            className="icon-fill"
            data-name="Path 386"
            d="M15.007 28a12.451 12.451 0 0 0 5.018-1.024 13.328 13.328 0 0 0 6.958-6.958 12.866 12.866 0 0 0-.007-10.043A13.4 13.4 0 0 0 24.16 5.82a13.077 13.077 0 0 0-4.148-2.81A12.593 12.593 0 0 0 14.994 2a12.545 12.545 0 0 0-5.011 1.01 13.29 13.29 0 0 0-6.965 6.965 12.866 12.866 0 0 0 .007 10.043 13.39 13.39 0 0 0 6.958 6.958A12.437 12.437 0 0 0 15.007 28Zm-3.479-12.07h3.466a.681.681 0 0 0 .482-.187.614.614 0 0 0 .2-.468v-4.5a.635.635 0 0 0-.2-.475.668.668 0 0 0-.482-.194.663.663 0 0 0-.669.669v3.84h-2.8a.667.667 0 0 0-.465 1.137.648.648 0 0 0 .468.178Zm3.479 6.062a6.678 6.678 0 0 1-2.7-.555 7.272 7.272 0 0 1-2.235-1.512 7.039 7.039 0 0 1-1.512-2.228 6.917 6.917 0 0 1 0-5.4 7.221 7.221 0 0 1 1.509-2.235 7.084 7.084 0 0 1 2.228-1.519 6.917 6.917 0 0 1 5.4 0 7.063 7.063 0 0 1 2.235 1.519 7.222 7.222 0 0 1 1.512 2.235 6.917 6.917 0 0 1 0 5.4 7.039 7.039 0 0 1-1.512 2.228 7.3 7.3 0 0 1-2.228 1.512 6.632 6.632 0 0 1-2.697.554Z"
            fill={color || '#636363'}
        />
    </svg>
);

export default SvgWatchLater;
