import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import 'moment-timezone';
import { getDefaultTimezone } from '.';

const REQUEST_RATE_LIMIT = 100; // api request rate limit
let global_dt = moment.utc();
let global_rrl_count = 0;


export const rrlSchedule = async () => {
    global_rrl_count++;

    const now = moment.tz(getDefaultTimezone());
    const timeDiff = now.diff(global_dt, 'milliseconds');
    if (timeDiff < REQUEST_RATE_LIMIT * global_rrl_count) {
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve(null);
            }, REQUEST_RATE_LIMIT * global_rrl_count - timeDiff);
        });
    }

    global_dt = moment.tz(getDefaultTimezone());
    global_rrl_count--;
};

// It's for contentful preview api
export const axiosRequest = async (options: AxiosRequestConfig) => {
    const headers: any = {
        'Content-Type': 'text/plain',
    };

    // const apiToken = sessionStorage.getItem('apiToken');
    // if (apiToken) {
    //     headers.Authorization = `Bearer ${apiToken}`;
    // }

    headers.Authorization = `Bearer ${"RAt9KOOvcc1rDDGzCiT0XD07C-VzvfiG-R0_A5MLc2Y"}`;

    const axiosClient = axios.create({
        timeout: 10000,
        headers,
    });

    await rrlSchedule();

    return axiosClient(options);
};

export const contentfulApiRequest = async (options: AxiosRequestConfig) => {
    const headers: any = {
        'Content-Type': 'text/plain',
    };

    if (sessionStorage.getItem('contentfulToken')) {
        headers.Authorization = `Bearer ${sessionStorage.getItem('contentfulToken')}`;
    }

    const axiosClient = axios.create({
        timeout: 10000,
        headers,
    });

    await rrlSchedule();

    return axiosClient(options);
};
