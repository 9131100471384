import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { DateTimePicker } from '../../../components/DateTimePicker';
import { FlexGrid } from '../../../components/FlexGrid';
import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Select } from '../../../components/Select';
import { Tag } from '../../../components/Tag';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { ScanningContestType } from '../../../types';
import {
    checkIfAutoChangeEndtime,
    composeDate,
    ContentStatus,
    getContentStatus,
    getCountry,
    getDefaultTimezone,
    getLanguageListByCountry,
} from '../../../utils';
import { LIMIT_NAME_LENGTH } from '../../../common/constants';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    min-width: 500px;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    stepNavigator?: any;
    scanningContestState: [
        ScanningContestType,
        React.Dispatch<React.SetStateAction<ScanningContestType>>
    ];
    initScanningContestData: ScanningContestType;
}

export const IdentifierDefinition: React.FC<Props> = ({
    stepNavigator,
    scanningContestState,
    initScanningContestData,
}) => {
    const navigate = useNavigate();
    const [scanningContest, setScanningContest] = scanningContestState;
    const { identifierDefinition } = scanningContest;

    const { appLanguages, name, startDate, startTime, endDate, endTime } =
        identifierDefinition;

    const dataIncomplete =
        appLanguages.length === 0 ||
        name.trim() === '' ||
        !startDate ||
        !startTime;

    useEffect(() => {
        if (checkIfAutoChangeEndtime(startDate, startTime, endDate, endTime)) {
            setScanningContest({
                ...scanningContest,
                identifierDefinition: {
                    ...scanningContest.identifierDefinition,
                    endTime: startTime,
                },
            });
        }
    }, [startTime, startDate, endDate, endTime]);

    const scanningContestStatus = getContentStatus(
        initScanningContestData,
        composeDate(
            initScanningContestData?.identifierDefinition.startDate,
            initScanningContestData?.identifierDefinition.startTime
        )?.toDate(),
        composeDate(
            initScanningContestData?.identifierDefinition.endDate,
            initScanningContestData?.identifierDefinition.endDate
        )?.toDate()
    );

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Identifier Definition
                    </Text>
                }
            />
            <Form>
                <Input
                    label="Scanning Contest Name"
                    value={identifierDefinition.name}
                    maxLength={LIMIT_NAME_LENGTH}
                    onChange={(val) =>
                        setScanningContest({
                            ...scanningContest,
                            identifierDefinition: {
                                ...scanningContest.identifierDefinition,
                                name: val,
                            },
                        })
                    }
                    placeholder="Enter"
                />
                <Select
                    form
                    label="App Language"
                    placeholder="Select"
                    info={{
                        title: 'Application language',
                        content:
                            'User can add multiple language, short desc goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                    }}
                    options={getLanguageListByCountry(getCountry())}
                    renderValue={() => '- Select -'}
                    value={identifierDefinition.appLanguages}
                    onChange={(value: any) => {
                        if (
                            !identifierDefinition.appLanguages.includes(value)
                        ) {
                            const newLanguages = [
                                ...identifierDefinition.appLanguages,
                                value,
                            ];
                            setScanningContest({
                                ...scanningContest,
                                identifierDefinition: {
                                    ...scanningContest.identifierDefinition,
                                    appLanguages: newLanguages,
                                },
                            });
                        }
                    }}
                />
                <FlexGrid gap="1em">
                    {identifierDefinition.appLanguages.map(
                        (language, index) => (
                            <Tag
                                key={`scanningContest-flexgrid-lang-${index}`}
                                title={language}
                                removeBtn
                                onRemove={(value: string) =>
                                    setScanningContest({
                                        ...scanningContest,
                                        identifierDefinition: {
                                            ...scanningContest.identifierDefinition,
                                            appLanguages:
                                                identifierDefinition.appLanguages.filter(
                                                    (el) => el !== value
                                                ),
                                        },
                                    })
                                }
                            />
                        )
                    )}
                </FlexGrid>
                <DateTimePicker
                    label="Start"
                    info={{
                        title: 'Select Date and Time',
                        content:
                            'You can add start-end date, short desc goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                    }}
                    value={{
                        date: identifierDefinition.startDate,
                        time: identifierDefinition.startTime,
                    }}
                    minDate={moment.tz(getDefaultTimezone()).toDate()}
                    maxDate={
                        identifierDefinition.endDate
                            ? composeDate(
                                  identifierDefinition.endDate,
                                  identifierDefinition.endTime || '00:00'
                              ).toDate()
                            : null
                    }
                    onChange={(op: string, newValue: any) => {
                        switch (op) {
                            case 'date':
                                setScanningContest({
                                    ...scanningContest,
                                    identifierDefinition: {
                                        ...scanningContest.identifierDefinition,
                                        startDate: newValue
                                            ? moment
                                                  .tz(
                                                      newValue,
                                                      getDefaultTimezone()
                                                  )
                                                  .format('D MMMM YYYY')
                                            : null,
                                    },
                                });
                                break;
                            case 'time':
                                setScanningContest({
                                    ...scanningContest,
                                    identifierDefinition: {
                                        ...scanningContest.identifierDefinition,
                                        startTime: newValue,
                                    },
                                });
                                break;
                            case 'reset':
                                setScanningContest({
                                    ...scanningContest,
                                    identifierDefinition: {
                                        ...scanningContest.identifierDefinition,
                                        startDate: null,
                                        startTime: null,
                                    },
                                });
                                break;
                            default:
                                break;
                        }
                    }}
                    disabled={
                        scanningContestStatus === ContentStatus.LIVE ||
                        scanningContestStatus === ContentStatus.EXPIRED
                    }
                />
                <DateTimePicker
                    label="End"
                    info={{
                        title: 'Select Date and Time',
                        content:
                            'You can add start-end date, short desc goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                    }}
                    value={{
                        date: identifierDefinition.endDate,
                        time: identifierDefinition.endTime,
                    }}
                    minDate={
                        identifierDefinition.startDate
                            ? composeDate(
                                  identifierDefinition.startDate,
                                  identifierDefinition.startTime || '00:00'
                              ).toDate()
                            : moment.tz(getDefaultTimezone()).toDate()
                    }
                    onChange={(op: string, newValue: any) => {
                        switch (op) {
                            case 'date':
                                setScanningContest({
                                    ...scanningContest,
                                    identifierDefinition: {
                                        ...scanningContest.identifierDefinition,
                                        endDate: newValue
                                            ? moment
                                                  .tz(
                                                      newValue,
                                                      getDefaultTimezone()
                                                  )
                                                  .format('D MMMM YYYY')
                                            : null,
                                    },
                                });
                                break;
                            case 'time':
                                setScanningContest({
                                    ...scanningContest,
                                    identifierDefinition: {
                                        ...scanningContest.identifierDefinition,
                                        endTime: newValue,
                                    },
                                });
                                break;
                            case 'reset':
                                setScanningContest({
                                    ...scanningContest,
                                    identifierDefinition: {
                                        ...scanningContest.identifierDefinition,
                                        endDate: null,
                                        endTime: null,
                                    },
                                });
                                break;
                            default:
                                break;
                        }
                    }}
                />
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() => navigate(-1)}
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() =>
                            stepNavigator((prev: number) => prev + 1)
                        }
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
