import React from 'react';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';

interface ActionIconProps {
    text?: string;
    icon: any;
    click?: () => void
}

export const ActionIcon: React.FC<ActionIconProps> = ({ text, icon, click }) => {
    return (
        <FlexGrid alignItems="center" gap="0.3rem">
            <Button negative m="0" p="0" onClick={click}>
                {icon}
            </Button>
            {text && (
                <Text m="0 0.5em 0 0" fontSize="0.8rem" color={COLORS.darkGray}>
                    {text}
                </Text>
            )}
        </FlexGrid>
    );
};
