import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const AccountMultiple: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path
            className="icon-fill"
            data-name="Path 4769"
            d="M19 17v2H7v-2s0-4 6-4 6 4 6 4m-3-9a3 3 0 10-3 3 3 3 0 003-3m3.2 5.06A5.6 5.6 0 0121 17v2h3v-2s0-3.45-4.8-3.94M18 5a2.91 2.91 0 00-.89.14 5 5 0 010 5.72A2.91 2.91 0 0018 11a3 3 0 000-6M8 10H5V7H3v3H0v2h3v3h2v-3h3z"
            fill={color || '#009343'}
        />
    </svg>
);

export default AccountMultiple;