import React from 'react';
import styled from 'styled-components';
import { FlexGrid } from './FlexGrid';
import { Header } from './Header';
import { Navigation } from './Navigation';

const Wrapper = styled(FlexGrid)`
    min-height: 100vh;
    width: 100vw;
    flex-direction: column;
`;

const Content = styled(FlexGrid)`
    flex: 1;
    width: 100vw;
    position: relative;
    padding: 0em 0em 0em 3.75em;
    flex-direction: column;
`;

export const PageWrapper: React.FC = ({ children }) => {
    return (
        <Wrapper>
            <Header />
            <Navigation />
            <Content>{children}</Content>
        </Wrapper>
    );
};
