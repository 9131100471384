export const COLORS = {
    red: '#f00023',
    veryLightGray: '#f2f2f2',
    darkRed: '#c90d5c',
    lightGray: '#dddddd',
    blue: '#6a8db6',
    white: '#ffffff',
    darkGray: '#636363',
    black: '#000000',
    green: '#009343',
    transparent: 'transparent',
    transparentWhite: 'rgb(255,255,255,.5)',
    transparentBlack75: 'rgb(0,0,0,.75)',
    transparentBlack50: 'rgb(0,0,0,.5)',
    gray: '#AFAFAF',
    lightGreen: '#86CCA6',
    veryLightGreen: '#E5F4EC',
    vvLightGray: '#F9FAFB',
};

export const COMMON = {
    shadow: `0px 1px 6px ${COLORS.lightGray}`,
    verticalShadow: `0px 3px 6px ${COLORS.lightGray}`,
};
