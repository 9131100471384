import React, { useRef } from 'react';
import { COLORS } from '../styles/variables';
import { FlexGrid } from './FlexGrid';
import { Input } from './Input';
import { Text } from './Text';

interface Props {
    value?: string[];
    // eslint-disable-next-line no-unused-vars
    onChange?: (val: string) => void;
}

export const AddTags: React.FC<Props> = ({ value, onChange }) => {
    const cInputRef = useRef({ handleMultipleChange: null });

    return (
        <FlexGrid width="100%" direction="column" gap="0.5em">
            <Input
                label="Add Tags"
                multiple
                value={value}
                onChange={onChange}
                onBlur={() => {
                    cInputRef.current.handleMultipleChange()
                }}
                cRef={cInputRef.current}
            />
            <Text fontSize="0.875rem" color={COLORS.gray}>
                Enter space after each tag
            </Text>
        </FlexGrid>
    );
};
