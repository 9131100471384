import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import { GLOBAL_CONFIG } from '../../../config/config';
import _ from 'lodash';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { getMetaTag } from '../../../utils';
import { IPagination } from '../../../types';

type Input = { pagination: IPagination };
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    // const { data } = await contentfulApiRequest({
    //     url: `${GLOBAL_CONFIG.CONTENTFUL_API_PATH}/spaces/${
    //         GLOBAL_CONFIG.CONTENTFUL_SPACE_ID
    //     }/environments/${
    //         GLOBAL_CONFIG.environment
    //     }/entries?content_type=videoPlaylist&metadata.tags.sys.id[in]=${getMetaTag()}`,
    //     method: 'GET',
    // });

    const includeParams = { limit: props.input?.pagination?.perPage, page: 1 };
    const data = await ContentfulApiService.searchContent({
        contentType: 'videoPlaylist',
        limit: includeParams.limit,
        page: includeParams.page
    });

    const assets = await ContentfulApiService.getAllAssets();

    // todo: move assets to global store
    const ids = data.items.map((item) => item.sys.id);
    const stringIds = _.join(ids, ',');

    const videoData = data.items.map((item) => item);

    videoData.map((item) => {
        const getPlayListData = async (data) => {
            const playList = await ContentfulApiService.searchContent({
                contentType: 'video',
                params: {
                    'fields.playlists.sys.id': data.sys.id,
                    include: 10,
                },
            });
            item.playList = playList.items;
            item.assets = assets;
            props.updateArray();
            console.log('Playlist : ', playList);
        };
        getPlayListData(item);

        // console.log('item: ', item);
        return item;
    });

    console.log('Video playlist data: ', videoData);
    return videoData;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useVideoPlaylistHook = (
    pagination?: IPagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialData: [],
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
