import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/variables';
import useClickOutside from '../utils/useClickOutside';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { Input } from './Input';
import { Text } from './Text';
import {
    ChevronDoubleRightIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronLeftIcon,
} from '../assets/icons';
import { IconButton } from './IconButton';
import { InputContainer } from './InputContainer';
import { LookupValue } from '../types';

interface OptionsContainerProps {
    focused?: boolean;
}

const OptionsContainer = styled(FlexGrid)<OptionsContainerProps>`
    position: relative;
    border: 1px solid ${COLORS.lightGray};
    border-radius: 5px;
    display: ${({ focused }) => (focused ? 'flex' : 'none')};
    height: 100%;
    min-width: 350px;
    width: 100%;
    flex-direction: column;
`;

const Container = styled(FlexGrid)`
    &:focus-within > ${OptionsContainer} {
        display: flex;
    }
`;

const Options = styled(FlexGrid)`
    width: 100%;
    flex-direction: column;
    height: 230px;
    overflow-y: scroll;
    background-color: ${COLORS.white};
    border-radius: 5px;
`;

interface OptionProps {
    selected?: boolean;
}

const Option = styled(FlexGrid)<OptionProps>`
    width: 100%;
    border-bottom: 1px solid ${COLORS.veryLightGray};
    background-color: ${({ selected }) =>
        selected ? COLORS.green : COLORS.white};
`;

const OptionHeader = styled(Option)`
    padding: 0.6em;
`;

const Selected = styled(FlexGrid)`
    position: absolute;
    left: calc(100% + 0.5em);
    height: 100%;
    gap: 0.5em;
`;

const EditBtn = styled(Button)`
    text-transform: none;
    font-size: 15px;
    font-family: 'AR';
`;

interface ActionButtonsProps {
    active?: boolean;
}

const ActionButtons = styled(IconButton)<ActionButtonsProps>`
    cursor: ${({ active }) => (active ? 'pointer' : 'unset')};
    background-color: ${({ active }) =>
        active ? COLORS.veryLightGray : COLORS.vvLightGray};

    .icon-fill {
        fill: ${({ active }) => (active ? COLORS.gray : COLORS.lightGray)};
    }
    .icon-stroke {
        stroke: ${({ active }) => (active ? COLORS.gray : COLORS.lightGray)};
    }

    &:hover {
        background-color: ${({ active }) =>
            active ? COLORS.green : COLORS.vvLightGray};

        .icon-fill {
            fill: ${({ active }) => (active ? COLORS.white : COLORS.lightGray)};
        }
        .icon-stroke {
            stroke: ${({ active }) =>
                active ? COLORS.white : COLORS.lightGray};
        }
    }
`;

interface SearchSelectProps {
    label?: string;
    innerLabel?: string;
    info?: { title: string; content: string };
    options?: LookupValue[];
    value?: string[];
    // eslint-disable-next-line no-unused-vars
    onChange?: (val: string[]) => void;
    renderOption?: (val: LookupValue) => string;
    disabled?: boolean;
}

const findValue = (v: string, options: LookupValue[]) => {
    return options.find((o) => o.id === v);
};

export const SearchSelect: React.FC<SearchSelectProps> = ({
    label,
    innerLabel,
    info,
    options,
    value,
    onChange,
    renderOption = (v: LookupValue) => `${v.name}(${v.code})`,
    disabled,
}) => {
    const [focused, setFocused] = useState(false);
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const close = useCallback(() => setFocused(false), []);
    useClickOutside(ref, close);

    const [search, setSearch] = useState('');

    const [markedAvailable, setMarkedAvailable] = useState<LookupValue[]>([]);
    const [markedSelected, setMarkedSelected] = useState<LookupValue[]>([]);
    const [selectedItems, setSelectedItems] = useState<LookupValue[]>([]);

    useEffect(() => {
        const tmpSelectedItems = (value || [])
            .map((v) => findValue(v, options))
            .filter((v) => !!v);
        setSelectedItems(tmpSelectedItems);
    }, [options, value]);

    const availableOptions =
        options?.filter(
            (option) =>
                !selectedItems.includes(option) &&
                option.name.toLowerCase().includes(search.toLowerCase())
        ) || [];

    const markItem = (item: LookupValue, direction: 'right' | 'left') => {
        switch (direction) {
            case 'right':
                if (markedSelected.includes(item)) {
                    setMarkedSelected(
                        markedSelected.filter((el) => el !== item)
                    );
                } else {
                    setMarkedSelected([...markedSelected, item]);
                }
                break;
            case 'left':
                if (markedAvailable.includes(item)) {
                    setMarkedAvailable(
                        markedAvailable.filter((el) => el !== item)
                    );
                } else {
                    setMarkedAvailable([...markedAvailable, item]);
                }
                break;
            default:
                break;
        }
    };

    const moveMarked = (direction: 'right' | 'left') => {
        switch (direction) {
            case 'right':
                setSelectedItems([...selectedItems, ...markedAvailable]);
                setMarkedAvailable([]);
                break;
            case 'left':
                setSelectedItems(
                    selectedItems.filter((el) => !markedSelected.includes(el))
                );
                setMarkedSelected([]);
                break;
            default:
                break;
        }
    };

    const moveAll = (direction: 'right' | 'left') => {
        switch (direction) {
            case 'right':
                setSelectedItems(options || []);
                setMarkedSelected([]);
                break;
            case 'left':
                setSelectedItems([]);
                setMarkedAvailable([]);
                break;
            default:
                break;
        }
    };

    return (
        <Container width="100%" direction="column" ref={ref}>
            {focused || !value || value.length === 0 ? (
                <FlexGrid width="100%" onClick={() => setFocused(true)}>
                    <Input
                        label={label}
                        placeholder="Search"
                        info={info}
                        value={search}
                        onChange={(val) => setSearch(val)}
                    />
                </FlexGrid>
            ) : (
                <InputContainer label={label} info={info} border>
                    <FlexGrid
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Text>
                            Selected {innerLabel ?? label} ({value.length})
                        </Text>
                        <EditBtn
                            onClick={() => setFocused(true)}
                            negative
                            m="0"
                            p="0"
                            disabled={disabled}
                        >
                            Edit
                        </EditBtn>
                    </FlexGrid>
                </InputContainer>
            )}
            {options && (
                <OptionsContainer focused={focused}>
                    <OptionHeader>
                        <Text font="AR Bold" fontSize="0.875rem">
                            Available {innerLabel ?? label}{' '}
                            <Text
                                color={COLORS.darkGray}
                                fontSize="0.875rem"
                                span
                            >
                                - Showing {options.length}
                            </Text>
                        </Text>
                    </OptionHeader>
                    <Options>
                        {availableOptions.map((option, index) => {
                            const selected = markedAvailable.includes(option);
                            return (
                                <Option key={`searchOption-${index}`} width="100%" selected={selected}>
                                    <Button
                                        m="0"
                                        p="0.6em"
                                        height="100%"
                                        disableUnderline
                                        width="100%"
                                        negative
                                        onClick={() => markItem(option, 'left')}
                                    >
                                        <Text
                                            fontSize="0.875rem"
                                            color={
                                                selected
                                                    ? COLORS.white
                                                    : COLORS.black
                                            }
                                        >
                                            {renderOption(option)}
                                        </Text>
                                    </Button>
                                </Option>
                            );
                        })}
                    </Options>
                    <Selected>
                        <FlexGrid
                            height="100%"
                            direction="column"
                            justifyContent="center"
                            gap="0.5em"
                        >
                            <ActionButtons
                                active={availableOptions.length !== 0}
                                icon={<ChevronDoubleRightIcon height="12" />}
                                bg={COLORS.veryLightGray}
                                width="40px"
                                height="40px"
                                onClick={() => moveAll('right')}
                            />
                            <ActionButtons
                                active={availableOptions.length !== 0}
                                icon={<ChevronLeftIcon height="12" />}
                                bg={COLORS.veryLightGray}
                                width="40px"
                                height="40px"
                                onClick={() => moveMarked('right')}
                            />
                            <ActionButtons
                                active={selectedItems.length !== 0}
                                icon={<ChevronRightIcon height="12" />}
                                bg={
                                    selectedItems.length === 0
                                        ? COLORS.vvLightGray
                                        : COLORS.veryLightGray
                                }
                                width="40px"
                                height="40px"
                                onClick={() => moveMarked('left')}
                                disabled={selectedItems.length === 0}
                            />
                            <ActionButtons
                                active={selectedItems.length !== 0}
                                icon={<ChevronDoubleLeftIcon height="12" />}
                                bg={
                                    selectedItems.length === 0
                                        ? COLORS.vvLightGray
                                        : COLORS.veryLightGray
                                }
                                width="40px"
                                height="40px"
                                onClick={() => moveAll('left')}
                                disabled={selectedItems.length === 0}
                            />
                        </FlexGrid>
                        <OptionsContainer focused={focused}>
                            <OptionHeader
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Text font="AR Bold" fontSize="0.875rem">
                                    Selected {innerLabel ?? label}{' '}
                                    <Text
                                        color={COLORS.darkGray}
                                        fontSize="0.875rem"
                                        span
                                    >
                                        - Showing {selectedItems.length}
                                    </Text>
                                </Text>
                                {(
                                    <Button
                                        negative
                                        icon="check"
                                        onClick={() => {
                                            if (onChange) {
                                                onChange(
                                                    selectedItems.map(
                                                        (v) => v.id
                                                    )
                                                );
                                            }
                                            setFocused(false);
                                        }}
                                    />
                                )}
                            </OptionHeader>
                            <Options>
                                {selectedItems.map((option) => {
                                    const selected =
                                        markedSelected.includes(option);
                                    return (
                                        <Option
                                            width="100%"
                                            selected={selected}
                                        >
                                            <Button
                                                m="0"
                                                p="0.6em"
                                                height="100%"
                                                disableUnderline
                                                width="100%"
                                                negative
                                                onClick={() =>
                                                    markItem(option, 'right')
                                                }
                                            >
                                                <Text
                                                    fontSize="0.875rem"
                                                    color={
                                                        selected
                                                            ? COLORS.white
                                                            : COLORS.black
                                                    }
                                                >
                                                    {renderOption(option)}
                                                </Text>
                                            </Button>
                                        </Option>
                                    );
                                })}
                            </Options>
                        </OptionsContainer>
                    </Selected>
                </OptionsContainer>
            )}
        </Container>
    );
};
