import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgBell: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 20}
        height={height || 21.996}
        viewBox="0 0 20 21.996"
    >
        <g
            className="icon-stroke"
            fill="none"
            stroke={color || '#fff'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <path
                data-name="Path 4735"
                d="M16 7A6 6 0 1 0 4 7c0 7-3 9-3 9h18s-3-2-3-9"
            />
            <path data-name="Path 4736" d="M11.73 20a2 2 0 0 1-3.46 0" />
        </g>
    </svg>
);

export default SvgBell;
