import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../styles/variables';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';

const Container = styled(FlexGrid)`
    width: 100%;
    padding: 1em 2em;
    height: 75px;
    border-bottom: 1px solid ${COLORS.lightGray};
    align-items: center;
    z-index: 1;
`;

interface PageHeaderProps {
    title?: string | any;
    actions?: any;
    back?: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
    title,
    actions,
    back,
}) => {
    const navigate = useNavigate();
    return (
        <Container justifyContent="space-between">
            {back ? (
                <Button
                    onClick={() => navigate(-1)}
                    negative
                    title="back"
                    icon="back"
                    iconSlot="start"
                />
            ) : (
                <Text font="OS Bold" fontSize="1.75rem" uppercase>
                    {title}
                </Text>
            )}
            {actions}
        </Container>
    );
};
