import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgQuiz: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 22.299}
        height={height || 22.299}
        viewBox="0 0 22.299 22.299"
    >
        <g
            id="Group_15661"
            data-name="Group 15661"
            transform="translate(-74.35 -615.85)"
        >
            <g id="Group_15565" data-name="Group 15565">
                <path
                    id="iconmonstr-star-7"
                    d="M11,1.833A9.166,9.166,0,1,1,1.833,11,9.177,9.177,0,0,1,11,1.833ZM11,0A11,11,0,1,0,22,11,11,11,0,0,0,11,0Z"
                    transform="translate(74.5 616)"
                    fill={color || '#fff'}
                    stroke="#fff"
                    strokeWidth="0.3"
                />
                <path
                    id="Path_4887"
                    data-name="Path 4887"
                    d="M15.22,17.39,12,15.45,8.78,17.39a.5.5,0,0,1-.75-.54l.85-3.66L6.05,10.74a.505.505,0,0,1,.29-.88l3.74-.32,1.46-3.45a.5.5,0,0,1,.92,0l1.46,3.44,3.74.32a.5.5,0,0,1,.28.88l-2.83,2.45.85,3.67a.5.5,0,0,1-.74.54Z"
                    transform="translate(73.82 615.018)"
                    fill={color || '#fff'}
                />
            </g>
        </g>
    </svg>
);

export default SvgQuiz;
