import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';
import { ApiService } from '../../../services/Api.service';
import { GLOBAL_CONFIG } from '../../../config/config';
import { STORAGE_ROLES, STORAGE_ROLE_ID } from '../../../common/constants';
import axios from 'axios';

type Input = {
    email?: string;
    password?: string;
    contentfulToken?: string;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const contentfulToken =
        props.input.contentfulToken ||
        sessionStorage.getItem('contentfulToken');

    // Super admin test token
    const { data } = await axios({
        url: `${GLOBAL_CONFIG.CONTENTFUL_API_PATH}/users/me`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${contentfulToken}`,
        },
    });

    const response = await axios({
        url: `${GLOBAL_CONFIG.CONTENTFUL_API_PATH}/spaces/${GLOBAL_CONFIG.CONTENTFUL_SPACE_ID}/space_members?limit=100`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${contentfulToken}`,
        },
    });
    const rolesData = response.data;

    // filter by sysid
    const item = rolesData.items.filter(
        (role) => role.sys.user.sys.id === data.sys.id
    );

    // eslint-disable-next-line prefer-destructuring
    data.roles = item[0];
    console.log('get roles', data.roles);

    // todo: must set before every contentful data api
    localStorage.setItem(STORAGE_ROLES, JSON.stringify(data.roles.roles.map(role => role?.sys?.id)));
    // The first role is the default one
    localStorage.setItem(STORAGE_ROLE_ID, data.roles.roles[0]?.sys?.id);
    // get tag id from Roles Mapping
    // tagId not used?
    // data.tagId = GLOBAL_CONFIG.ROLE_DATA[item[0].roles[0]?.sys?.id]?.tagId;

    return {
        profile: data,
    };
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useMe = (
    contentfulToken?: string,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: false,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { contentfulToken } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
