import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { Accordion } from '../../../components/Accordion';
import { COLORS } from '../../../styles/variables';

import {
    NewFolderArticleIcon,
    NewArticleIcon,
    NewBannerIcon,
    InAppNotificationIcon,
    PushNotificationIcon,
    NewVideoIcon,
    ArticleFolderIcon,
    MoreIcon,
    VideoPlaylist2Icon,
    VideoPlaylistIcon,
    QuizIcon,
} from '../../../assets/icons';

import { ListItem } from '../../../components/ListItem';
import { Tag } from '../../../components/Tag';
import { FlexGrid } from '../../../components/FlexGrid';
import { Text } from '../../../components/Text';

import { useDashboardArticleFolderHook } from '../hooks/use-article-folder-hook';
import { useVideoPlaylistHook } from '../../VideoPlaylist/hooks/use-video-playlist-hook';
import { DEFAULT_LANGUAGE_CODE } from '../../../common/constants';
import { getMapValue, getUrlKeepQuery } from '../../../utils';

interface SideBarProp {
    newPlaylistOpen: () => void;
    newFolderOpen: () => void;
}

const SideBarContainer = styled(FlexGrid)`
    border-left: 1px solid ${COLORS.lightGray};
    flex-direction: column;
    height: 100%;
    padding: 1em 0em;
    width: 350px;
    flex-shrink: 0;
    background-color: ${COLORS.white};
`;

const AccordionItem = styled(ListItem)`
    padding: 0em 1.5em;

    &:hover .icon-fill {
        fill: ${COLORS.white};
    }

    &:hover .icon-stroke {
        stroke: ${COLORS.white};
    }
`;

export const SideBar: React.FC<SideBarProp> = ({
    newPlaylistOpen,
    newFolderOpen,
}) => {
    const navigate = useNavigate();
    const [{ hookData: articleFolderData }] = useDashboardArticleFolderHook(
        {  },
        []
    );
    const [{ hookData: videoPlaylist }] = useVideoPlaylistHook({ });

    const articleFolders = articleFolderData?.items || [];
    const assets = articleFolderData?.assets || [];

    const createNewList = [
        {
            title: 'New Folder Article',
            icon: <NewFolderArticleIcon height="20" />,
            onClick: newFolderOpen,
        },
        {
            title: 'New Article',
            icon: <NewArticleIcon height="20" />,
            href: '/communication/articles/create',
        },
        {
            title: 'New Banner',
            icon: <NewBannerIcon height="20" />,
            href: '/communication/banners/create',
        },
        {
            title: 'New Quiz',
            icon: <QuizIcon color={COLORS.green} />,
            href: '/communication/quizzes/create',
        },
        {
            title: 'In-app Notification',
            icon: <InAppNotificationIcon height="20" />,
            href: '/communication/in-app-notifications/create',
        },
        {
            title: 'Push Notification',
            icon: <PushNotificationIcon height="20" />,
            href: '/communication/push-notifications/create',
        },
        {
            title: 'Video Playlist',
            icon: <VideoPlaylistIcon height="20" />,
            onClick: newPlaylistOpen,
        },
        {
            title: 'New Video',
            icon: <NewVideoIcon height="20" />,
            href: '/communication/videos/create',
        },
    ];

    console.log('dashboard videoplaylist', videoPlaylist);

    const handelClick = ({ href, onClick }: any) => {
        if (href) {
            navigate(getUrlKeepQuery(href));
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <SideBarContainer>
            <Accordion
                title="Create New"
                content={
                    <FlexGrid width="100%" direction="column">
                        {createNewList.map((newList, key) => (
                            <AccordionItem
                                key={key}
                                onClick={() => handelClick(newList)}
                                line
                                title={newList.title}
                                icon={newList.icon}
                            />
                        ))}
                    </FlexGrid>
                }
            />
            <Accordion
                title={`Article Folder (${(articleFolders || []).length})`}
                content={
                    <FlexGrid width="100%" direction="column">
                        {articleFolders?.map((folder) => {
                            const tags = folder?.fields?.tags || [];
                            // if (!tags.length) {
                            //     tags.push(
                            //         getMapValue(
                            //             folder?.fields?.name,
                            //             DEFAULT_LANGUAGE_CODE,
                            //             ''
                            //         )
                            //     );
                            // }

                            return (
                                <AccordionItem
                                    middleFlexible
                                    key={uuidv4()}
                                    // href={`/communication/article-folder/${folder.sys.id}`}
                                    onClick={() => {
                                        console.log('folder', folder.sys.id);
                                        navigate(
                                            getUrlKeepQuery(
                                                `/communication/article-folder/${folder.sys.id}`
                                            )
                                        );
                                    }}
                                    line
                                    title={
                                        folder?.fields?.name &&
                                        folder?.fields?.name[DEFAULT_LANGUAGE_CODE]
                                    }
                                    icon={<ArticleFolderIcon />}
                                    right={<MoreIcon />}
                                    color={COLORS.green}
                                    bottom={
                                        <FlexGrid
                                            gap="0.2em"
                                            alignItems="center"
                                        >
                                            {(tags || []).map((tag) => (
                                                <Tag
                                                    key={uuidv4()}
                                                    title={tag}
                                                />
                                            ))}
                                            <Text color={COLORS.lightGray}>
                                                •
                                            </Text>
                                            <Text fontSize="0.75rem">
                                                {folder?.total} Articles
                                            </Text>
                                        </FlexGrid>
                                    }
                                />
                            );
                        })}
                    </FlexGrid>
                }
            />
            <Accordion
                title={`Video Playlist (${(videoPlaylist || []).length})`}
                content={
                    <FlexGrid width="100%" direction="column">
                        {videoPlaylist?.map((playlist) => (
                            <AccordionItem
                                key={uuidv4()}
                                href={`/communication/video-playlist/${playlist.sys.id}`}
                                line
                                title={playlist?.fields.name[DEFAULT_LANGUAGE_CODE]}
                                icon={<VideoPlaylist2Icon />}
                                right={<MoreIcon />}
                                color={COLORS.green}
                                middleFlexible
                                bottom={
                                    <FlexGrid gap="0.2em" alignItems="center">
                                        <Text fontSize="0.75rem">
                                            {(playlist?.playList || []).length}{' '}
                                            Videos
                                        </Text>
                                    </FlexGrid>
                                }
                            />
                        ))}
                    </FlexGrid>
                }
            />
        </SideBarContainer>
    );
};
