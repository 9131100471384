import React, { useRef } from 'react';
import styled from 'styled-components';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';
import { InputContainer } from './InputContainer';
import SvgChevronDown from '../assets/icons/chevron-down';
import { MAX_POINTS } from '../common/constants';
import { COLORS } from '../styles/variables';

const InputValue = styled(Text)`
    flex: 1;
    display: flex;
    justify-content: space-between;

    .react-time-picker__wrapper {
        border: none;
    }
`;

const InputElement = styled.input`
    width: ${({ width }) => width || 'auto'};
    padding-top: 0px;
    padding-bottom: 0px;
`;

interface InputProps {
    label: string;
    value: any;
    icon: any;
    width?: string;
    maxPoints?: number;
    // eslint-disable-next-line no-unused-vars
    onChange?: (e: number) => void;
    placeholder?: string;
    explicitPlaceHolder?: string;
    transform?: string;
    info?: { title: any; content: any };
    disabled?: boolean;
}

export const PointsPickerInput: React.FC<InputProps> = ({
    label,
    value,
    icon,
    width,
    maxPoints,
    onChange,
    placeholder,
    explicitPlaceHolder,
    transform,
    info,
    disabled,
}) => {
    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

    const indecrement = (increment: boolean) => {
        const v = value || 0;
        let nextValue = Math.min(
            MAX_POINTS,
            Math.max(increment ? v + 1 : v - 1, 0)
        );
        onChange && onChange(nextValue);
    };

    const maxPointsVal = maxPoints ?? MAX_POINTS;

    const handleChange = (op: string, val: string) => {
        switch (op) {
            case 'add':
                if (onChange && Number.isInteger(+val)) {
                    onChange(Math.min(+val, maxPointsVal));
                }
                break;
            case 'remove':
                if (onChange && Array.isArray(value)) {
                    const valueString = value
                        .filter((el) => el !== val)
                        .join(' ');
                    if (Number.isInteger(+valueString)) {
                        onChange(Math.min(+valueString, maxPointsVal));
                    } else {
                        onChange(0);
                    }
                }
                break;
            default:
                break;
        }
    };

    return (
        <InputContainer
            // ref={ref}
            containerRef={ref}
            info={info}
            label={label}
            border
            transform={transform}
        >
            <FlexGrid m="0em 1em 0em 0em">{icon}</FlexGrid>
            <InputValue>
                <FlexGrid width="100%">
                    <InputElement
                        type="text"
                        width={width}
                        value={value}
                        onChange={(e) => handleChange('add', e.target.value)}
                        placeholder={placeholder || 'Enter'}
                        disabled={disabled}
                    />
                    {explicitPlaceHolder && (
                        <Text m="0 0.5rem 0 0" fontSize="0.875rem" color={COLORS.gray}>
                            {explicitPlaceHolder}
                        </Text>
                    )}
                </FlexGrid>

                <div style={{ height: '20px' }}>
                    <div
                        style={{
                            display: 'flex',
                            marginBottom: '-3px',
                            transform: 'rotate(180deg)',
                            cursor: 'pointer',
                        }}
                        onClick={
                            disabled
                                ? undefined
                                : () => {
                                      indecrement(true);
                                  }
                        }
                    >
                        <SvgChevronDown
                            color={disabled ? COLORS.white : COLORS.green}
                            width={'10px'}
                            height={'10px'}
                        ></SvgChevronDown>
                    </div>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={
                            disabled
                                ? undefined
                                : () => {
                                      indecrement(false);
                                  }
                        }
                    >
                        <SvgChevronDown
                            color={disabled ? COLORS.white : COLORS.green}
                            width={'10px'}
                            height={'10px'}
                        ></SvgChevronDown>
                    </div>
                </div>
            </InputValue>
        </InputContainer>
    );
};
