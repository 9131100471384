import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';
import { Modal } from './Modal';
import { CheckCircleBigIcon } from '../assets/icons';
import { Text } from './Text';
import { COLORS } from '../styles/variables';

interface CreateEditModalProps {
    open: boolean;
    onSubmit?: () => void;
    onClose?: () => void;
    title: string;
    success: boolean | null;
    disabled?: boolean;
    nameLabel?: string;
    setSuccess: (v: boolean | null) => void;
    inputComponents: React.ReactElement[];
    mode: 'create' | 'edit';
}

const ActionButton = styled(Button)`
    font-family: 'OS';
    font-size: 1rem;
`;

const BorderBottom = styled(FlexGrid)`
    padding-bottom: 2.5em;
    border-bottom: 1px solid ${COLORS.lightGray};
    gap: 1em;
`;

export const CreateEditModal: React.FC<CreateEditModalProps> = ({
    open,
    onClose,
    onSubmit,
    success = null,
    setSuccess,
    title,
    disabled,
    inputComponents,
    mode,
}) => {
    const dataIncomplete = disabled;

    const reset = () => {
        if (onClose) {
            onClose();
            setSuccess(null);
        }
    };

    const successContent = (
        <FlexGrid
            p="1em 0em 1em 0em"
            alignItems="center"
            width="100%"
            gap="0.5em"
            m="0em 0em 2em 0em"
        >
            <CheckCircleBigIcon height="36" />
            <Text color={COLORS.darkGray}>
                {title.replace('Create ', '').replace('Edit ', '')} has been {mode === 'create' ? 'added' : 'updated'} successfully.
            </Text>
        </FlexGrid>
    );

    const inputContent = (
        <BorderBottom direction="column" width="100%" p="1em 0em">
            {inputComponents.map((component, index) => (
                React.cloneElement(component, { key: index })  // Clone each custom component with a unique key
            ))}
        </BorderBottom>
    );

    const inputActions = (
        <FlexGrid alignItems="center">
            <ActionButton
                disabled={dataIncomplete}
                onClick={onSubmit}
                p="0.6em 2em"
            >
                {mode === 'create' ? 'Create' : 'Save'}
            </ActionButton>
            <ActionButton onClick={reset} negative>
                Cancel
            </ActionButton>
        </FlexGrid>
    );

    const successActions = (
        <ActionButton onClick={reset} p="0.6em 2.5em" m="0 0 2em 0">
            Done
        </ActionButton>
    );

    return (
        <Modal
            open={open}
            header={title}
            onClose={reset}
            content={success ? successContent : inputContent}
            actions={success ? successActions : inputActions}
        />
    );
};
