import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { FlexGrid } from '../../components/FlexGrid';
import { PageWrapper } from '../../components/PageWrapper';
import { Tag } from '../../components/Tag';
import { Text } from '../../components/Text';
import { COLORS } from '../../styles/variables';

import {
    ThumbsUpIcon,
    ThumbsDownIcon,
    CommentIcon,
    LoveIcon,
    ShareIcon,
    DownloadIcon,
    WatchLaterIcon,
    SortIcon,
} from '../../assets/icons';
import { ActionIcon } from '../../components/ActionIcon';
import { Button } from '../../components/Button';
import { Tooltip } from '../../components/Tooltip';
import { PageHeader } from '../../components/PageHeader';
import { SearchBar } from '../../components/SearchBar';
import { SideBar } from './components/SideBar';
import { Input } from '../../components/Input';
import { SelectCountry } from '../../components/SelectCountry';
import { useArticleDetailHook } from '../Dashboard/hooks/use-article-detail-hook';
import { ContentfulTransformService } from '../../services/ContentfulTransform.service';
import { getDefaultTimezone } from '../../utils';

const PageContent = styled(FlexGrid)`
    max-width: 1200px;
    width: 100%;
    padding: 2em 0em;
    gap: 2em;
`;

const ArticleContainer = styled(FlexGrid)`
    min-width: 0;
`;

const ArticleImage = styled(FlexGrid)`
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden;

    img {
        height: 100%;
        object-fit: cover;
    }
`;

const ArticleHeader = styled(FlexGrid)`
    flex-direction: column;
    padding: 1em;
    width: 100%;
`;

const CommentSection = styled(FlexGrid)`
    width: 100%;
    flex-direction: column;
`;

const Avatar = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 100%;
`;

interface LocationState {
    articleId: string;
}

export const Article: React.FC = () => {
    const location = useLocation();
    const state = location.state as LocationState;

    const { articleId } = useParams();
    const [id, setId] = useState(null);

    const [{ isLoading, hookData }] = useArticleDetailHook(id || articleId, [
        id,
    ]);
    const [folders, setFolders] = useState([]);


    let article = null;
    if (hookData?.sys) {
        article = ContentfulTransformService.getArticles(
            hookData.assets,
            [hookData],
            folders
        )[0];
    }

    // useEffect(() => {
    //     // it will raise two video flicker error if miss `!video`
    //     if (hookData?.sys && !article) {
    //         setArticle(
    //             ContentfulTransformService.getArticles(
    //                 hookData.assets,
    //                 [hookData],
    //                 folders
    //             )[0]
    //         );
    //     }
    // }, [hookData]);

    // console.log('article deatails', hookData?.comments);

    return (
        <PageWrapper>
            <PageHeader
                back
                actions={
                    <FlexGrid gap="1.5em">
                        <SearchBar placeholder="Search banners, videos, articles here…" />
                        <SelectCountry />
                    </FlexGrid>
                }
            />
            <FlexGrid width="100%" justifyContent="center">
                <PageContent>
                    <ArticleContainer flex="1" direction="column">
                        <ArticleImage>
                            <img
                                src={`https:${article?.image}`}
                                alt="temp video"
                            />
                        </ArticleImage>
                        <ArticleHeader>
                            <Text
                                m="0 0 1em 0"
                                font="AR Bold"
                                fontSize="1.5rem"
                            >
                                {article?.title}
                            </Text>
                            <FlexGrid
                                m="0 0 1em 0"
                                width="100%"
                                line
                                p="0em 0em 1em 0em"
                            >
                                <Text
                                    fontSize="0.875rem"
                                    color={COLORS.darkGray}
                                >
                                    {article?.description}
                                </Text>
                            </FlexGrid>
                            <FlexGrid gap="0.2em" wrap={'wrap'}>
                                {(article?.tags || []).map((tag: string) => (
                                    <Tag key={uuidv4()} title={tag} />
                                ))}
                            </FlexGrid>
                            <FlexGrid
                                width="100%"
                                gap="4rem"
                                alignItems="flex-end"
                            >
                                <Text
                                    fontSize="0.875rem"
                                    color={COLORS.darkGray}
                                >
                                    Views {article?.views || 0}
                                    <Text span color={COLORS.lightGray}>
                                        {'  '}•{'  '}
                                    </Text>
                                    {moment.tz(article?.endDate, getDefaultTimezone()).fromNow()}
                                </Text>
                                <FlexGrid gap="0.5em">
                                    <ActionIcon
                                        icon={<ThumbsUpIcon height="30" />}
                                        text={article?.likes}
                                    />
                                    <ActionIcon
                                        icon={<ThumbsDownIcon height="30" />}
                                        text={article?.dislikes}
                                    />
                                    {/* <ActionIcon
                                        icon={<CommentIcon height="30" />}
                                        text="1.2k"
                                    /> */}
                                    <ActionIcon
                                        icon={<LoveIcon height="30" />}
                                    />
                                    {/* <ActionIcon
                                        icon={<ShareIcon height="30" />}
                                    /> */}
                                    <ActionIcon
                                        icon={<WatchLaterIcon height="30" />}
                                    />
                                    <ActionIcon
                                        icon={<DownloadIcon height="30" />}
                                    />
                                </FlexGrid>
                            </FlexGrid>
                        </ArticleHeader>
                        <CommentSection>
                            <FlexGrid
                                width="100%"
                                justifyContent="space-between"
                                alignItems="center"
                                bg={COLORS.veryLightGray}
                                p="0.5em 1em"
                            >
                                <Text color={COLORS.darkGray}>
                                    {(article?.comments || []).length} Comments
                                </Text>
                                <Tooltip title="Sort by">
                                    <Button
                                        icon={<SortIcon height="30" />}
                                        iconSlot="start"
                                        negative
                                    >
                                        <Text
                                            color={COLORS.green}
                                            fontSize="0.875rem"
                                        >
                                            Latest
                                        </Text>
                                    </Button>
                                </Tooltip>
                            </FlexGrid>
                            <Input
                                m="0.5em 0em"
                                placeholder="Post a comment..."
                            />
                            <FlexGrid width="100%" direction="column">
                                {(article?.comments || []).map((comment, key) => (
                                    <FlexGrid
                                        key={key}
                                        justifyContent="space-between"
                                        width="100%"
                                        line
                                        p="1em 0em"
                                    >
                                        <FlexGrid gap="1em">
                                            <Avatar
                                                src={comment.author.image}
                                            />
                                            <FlexGrid
                                                gap="0.3em"
                                                direction="column"
                                            >
                                                <Text font="AR Bold">
                                                    {comment.author.name}
                                                </Text>
                                                <Text
                                                    fontSize="0.875rem"
                                                    color={COLORS.darkGray}
                                                >
                                                    {comment.content}
                                                </Text>
                                                <FlexGrid m="0.5em 0 0 0">
                                                    <Text
                                                        fontSize="0.875rem"
                                                        color={COLORS.darkGray}
                                                    >
                                                        Like {comment.likes}
                                                        <Text
                                                            span
                                                            color={
                                                                COLORS.lightGray
                                                            }
                                                        >
                                                            {'  '}•{'  '}
                                                        </Text>
                                                        Dislike{' '}
                                                        {comment.dislikes}
                                                    </Text>
                                                </FlexGrid>
                                            </FlexGrid>
                                        </FlexGrid>
                                        <Text
                                            fontSize="0.875rem"
                                            color={COLORS.darkGray}
                                        >
                                            {comment.date}
                                        </Text>
                                    </FlexGrid>
                                ))}
                                {/* <Button negative>
                                    <Text color={COLORS.green}>Show more</Text>
                                </Button> */}
                            </FlexGrid>
                        </CommentSection>
                    </ArticleContainer>
                    <SideBar
                        setArticleId={(id) => {
                            setId(id);
                        }}
                        setFolder={(fd) => {
                            setFolders([fd])
                        }}
                    />
                </PageContent>
            </FlexGrid>
        </PageWrapper>
    );
};
