import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { SectionHeader } from '../../../components/SectionHeader';
import { COLORS } from '../../../styles/variables';
import {
    BannersIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
} from '../../../assets/icons';
import { BannerTable } from '../../Banners/components/BannerTable';

import { useDashboardBannerHook } from '../hooks/use-banner-hook';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { useDashboardAssetsHook } from '../hooks/use-assets-hook';
import { getUrlKeepQuery } from '../../../utils';

const Container = styled(FlexGrid)`
    flex-direction: column;
    padding: 2em 0em;
    border-bottom: 1px solid ${COLORS.lightGray};
    width: 100%;
    gap: 2em;
    min-width: 0;
    min-height: 0;
`;

const SeeAllBtn = styled(Button)`
    font-family: 'OS';
    font-size: 1rem;
`;

export const Banners: React.FC = () => {
    const [{ isLoading, hookData }] = useDashboardBannerHook({
        perPage: 10,
        page: 1,
    });
    const [pos, setPos] = useState(0);

    // const [{ hookData: assetsHookData }] = useDashboardAssetsHook();
    // const assets = assetsHookData?.items;

    // let items = hookData?.items || [];
    // items = items.slice(pos, pos + 5);

    // const banners = ContentfulTransformService.getBanners(
    //     assetsHookData,
    //     items
    // );

    const navigate = useNavigate();

    const indecrement = (increment: boolean) => {
        if (hookData.items.length <= 5) {
            return;
        }

        if (increment) {
            if (pos < hookData.items.length - 5) {
                setPos(pos + 1);
            }
        } else {
            if (pos !== 0) {
                setPos(pos - 1);
            }
        }
    };

    return (
        <Container>
            {!isLoading && !!hookData?.items?.length && (
                <SectionHeader
                    title="BANNERS"
                    icon={<BannersIcon />}
                    right={
                        <FlexGrid alignItems="center">
                            <SeeAllBtn
                                p="0"
                                m="0em 0.5em"
                                negative
                                onClick={() =>
                                    navigate(
                                        getUrlKeepQuery(
                                            '/communication/banners/'
                                        )
                                    )
                                }
                            >
                                SEE ALL
                            </SeeAllBtn>
                            <FlexGrid alignItems="center">
                                <Button
                                    p="0"
                                    m="0em 1em"
                                    negative
                                    onClick={() => {
                                        indecrement(false);
                                    }}
                                >
                                    <ChevronRightIcon
                                        color={pos === 0 ? 'gray' : 'green'}
                                    />
                                </Button>
                                <Button
                                    p="0"
                                    m="0em 1em"
                                    negative
                                    onClick={() => {
                                        indecrement(true);
                                    }}
                                >
                                    <ChevronLeftIcon
                                        color={
                                            pos + 5 >= hookData?.items?.length
                                                ? 'gray'
                                                : 'green'
                                        }
                                    />
                                </Button>
                            </FlexGrid>
                        </FlexGrid>
                    }
                />
            )}
            {!isLoading && !!hookData?.items?.length && (
                <BannerTable dashboard pos={pos} />
            )}
        </Container>
    );
};
