import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgCommunication: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 20}
        height={height || 20}
        viewBox="0 0 20 20"
    >
        <g
            className="icon-stroke"
            fill="none"
            stroke={color || '#009343'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <path data-name="Rectangle 2256" d="M1 1h7v7H1z" />
            <path data-name="Rectangle 2257" d="M12 1h7v7h-7z" />
            <path data-name="Rectangle 2258" d="M12 12h7v7h-7z" />
            <path data-name="Rectangle 2259" d="M1 12h7v7H1z" />
        </g>
    </svg>
);

export default SvgCommunication;
