import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FlexGrid } from '../../../components/FlexGrid';
import { FolderIcon, PlusIcon, MoreIcon } from '../../../assets/icons';
import { Text } from '../../../components/Text';
import { IconButton } from '../../../components/IconButton';
import { COLORS } from '../../../styles/variables';
import { ListItem } from '../../../components/ListItem';
import { Button } from '../../../components/Button';
import { useVideoPlaylistHook } from '../../VideoPlaylist/hooks/use-video-playlist-hook';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { useDashboardArticleFolderHook } from '../../Dashboard/hooks/use-article-folder-hook';
import { useParams } from 'react-router-dom';
import { cfDefaultGet } from '../../../utils';
import _ from 'lodash';
import { appHistory } from '../../../utils/history';
import { useShowMore } from '../../../hooks/use-show-more';

const ImageContainer = styled(FlexGrid)`
    width: 120px;
    height: 64px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    img {
        object-fit: cover;
        height: 100%;
    }
`;

const BorderBottom = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
`;

export const SideBar: React.FC<{
    setArticleId: (id: string) => void;
    setFolder: (folder: any) => void;
}> = ({ setArticleId, setFolder }) => {
    const [articlePage, setArticlePage] = useState(1);
    const [folderPage, setFolderPage] = useState(1);
    const pageSize = 5;

    const [{ hookData }] = useDashboardArticleFolderHook(
        {
            perPage: 1000,
            page: folderPage,
        },
        []
    );

    const articleFolders = ContentfulTransformService.getArticleFolders(
        hookData?.items
    );

    const [list, setList] = useState(0);
    const params = useParams();
    const articleId = params.articleId;

    const [showMoreArticles, setShowMoreArticles] = useState({
        data: [],
        hasShowMore: true,
        showMoreSize: 5,
    });

    const [newArticlesDataCome, setNewArticlesDataCome] = useState(true);
    const [newFoldersDataCome, setFoldersDataCome] = useState(true);

    const { data: playlists, hasShowMore: hasFoldersShowmore } = useShowMore(
        folderPage,
        pageSize,
        hookData?.total,
        newFoldersDataCome
            ? articleFolders.slice(
                  (folderPage - 1) * pageSize,
                  folderPage * pageSize
              )
            : []
    );

    const currentArticles = ContentfulTransformService.getArticles(
        hookData?.assets,
        (_.get(articleFolders, `[${list}].articles.items`) || []).slice(
            (articlePage - 1) * pageSize,
            articlePage * pageSize
        ),
        hookData?.items
    );

    const { data: articles, hasShowMore: hasArticlesShowmore } = useShowMore(
        articlePage,
        pageSize,
        _.get(articleFolders, `[${list}].articles.items`)?.length || 0,
        newArticlesDataCome ? currentArticles : []
    );

    useEffect(() => {
        setNewArticlesDataCome(true);
    }, [articlePage]);
    useEffect(() => {
        setFoldersDataCome(true);
    }, [folderPage]);

    useEffect(() => {
        if (articleId) {
            const folder = articleFolders.findIndex((af) => {
                return !!(af.articles?.items || []).find((article) => {
                    return article.sys.id === articleId;
                });
            });
            if (folder > -1) {
                setList(folder);
            }
        }
    }, [articleId, hookData]);

    const current = articleFolders[list];
    // console.log('artcle page', playlists, articles);

    const goArticle = (ac) => {
        setArticleId(ac.id);
        appHistory.push(`${ac.id}`);
    };

    return (
        <FlexGrid direction="column" flex="auto 0 300px" width="380px">
            <FlexGrid
                width="100%"
                justifyContent="space-between"
                alignItems="center"
            >
                <FlexGrid gap="1em" alignItems="center" p="0em 0em 1em">
                    <FolderIcon height="14" />
                    <Text font="AR Bold">{current?.name}</Text>
                </FlexGrid>
                <IconButton icon={PlusIcon} negative />
            </FlexGrid>
            <FlexGrid width="100%" direction="column">
                {articles.map((aritcle, key) => (
                    <BorderBottom
                        key={key}
                        justifyContent="space-between"
                        width="100%"
                        alignItems="center"
                        p="1em"
                    >
                        <FlexGrid flex="1" gap="1em">
                            <ImageContainer>
                                <img
                                    src={aritcle.image}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => goArticle(aritcle)}
                                    alt="aritcle"
                                />
                            </ImageContainer>
                            <FlexGrid
                                direction="column"
                                width="100%"
                                gap="0.5em"
                            >
                                <Text
                                    font="AR Bold"
                                    color={COLORS.green}
                                    link
                                    onClick={() => goArticle(aritcle)}
                                >
                                    {aritcle.title}
                                </Text>
                                <Text
                                    fontSize="0.875rem"
                                    color={COLORS.darkGray}
                                >
                                    Likes {aritcle.likes}
                                    <Text span color={COLORS.lightGray}>
                                        {'  '}•{'  '}
                                    </Text>
                                    {aritcle.date}
                                </Text>
                            </FlexGrid>
                        </FlexGrid>
                    </BorderBottom>
                ))}

                {hasArticlesShowmore && (
                    <Button negative>
                        <Text
                            color={COLORS.green}
                            link
                            onClick={() => {
                                setNewArticlesDataCome(false);
                                setArticlePage(articlePage + 1);
                            }}
                        >
                            Show more
                        </Text>
                    </Button>
                )}
            </FlexGrid>
            <FlexGrid direction="column" width="100%" p="2em 0em" gap="1em">
                <Text font="AR Bold" uppercase>
                    Other Playlist ({hookData?.total})
                </Text>
                <FlexGrid direction="column" width="100%">
                    {playlists.map((playlist, index) => (
                        <ListItem
                            key={index}
                            icon={<FolderIcon height="14" />}
                            title={playlist.title}
                            color={COLORS.green}
                            onClick={() => {
                                setList(index);
                                setFolder(
                                    articleFolders[index].contentfulRawData
                                );
                            }}
                            bottom={
                                <Text
                                    fontSize="0.875rem"
                                    color={COLORS.darkGray}
                                >
                                    {playlist.total} articles
                                </Text>
                            }
                            right={<IconButton negative icon={MoreIcon} />}
                        />
                    ))}
                    {hasFoldersShowmore && (
                        <Button negative>
                            <Text
                                color={COLORS.green}
                                link
                                onClick={() => {
                                    setFoldersDataCome(false);
                                    setFolderPage(folderPage + 1);
                                }}
                            >
                                Show more
                            </Text>
                        </Button>
                    )}
                </FlexGrid>
            </FlexGrid>
        </FlexGrid>
    );
};
