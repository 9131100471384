import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgPushNotification: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 19.94}
        height={height || 19.5}
        viewBox="0 0 19.94 19.5"
    >
        <path
            className="icon-fill"
            d="M9.97 19.5a2.006 2.006 0 0 0 2-2h-4a2.006 2.006 0 0 0 2 2Zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V1.5a1.5 1.5 0 0 0-3 0v.68c-2.86.68-4.5 3.24-4.5 6.32v5l-2 2v1h16v-1Zm-2 1h-8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5ZM5.55 1.58 4.12.15A10.425 10.425 0 0 0 0 8h2a8.446 8.446 0 0 1 3.55-6.42ZM17.94 8h2A10.489 10.489 0 0 0 15.82.15L14.4 1.58A8.5 8.5 0 0 1 17.94 8Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgPushNotification;
