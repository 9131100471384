import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgNewVideo: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 18}
        height={height || 18}
        viewBox="0 0 18 18"
    >
        <path
            className="icon-fill"
            d="M7 6.04v5.92a.5.5 0 0 0 .81.39l3.7-2.96a.5.5 0 0 0 0-.78l-3.7-2.96a.5.5 0 0 0-.81.39ZM16 0H2a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2.006 2.006 0 0 0 2-2V2a2.006 2.006 0 0 0-2-2Zm-1 16H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgNewVideo;
