import _, { isArray } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import {
    COUNTRY_SHORT_TAG_MAP,
    COUNTRY_TAG_MAP,
    IMAGE_SIZE_MIN_HEIGHT,
    IMAGE_SIZE_MIN_WIDTH,
    LANGUAGE,
    DEFAULT_LANGUAGE_CODE,
    LANGUAGE_CONTENTFUL_MAP,
    LANGUAGE_CONTENTFUL_MAP_REVERSE,
    NUM_OPTIONS_QUIZ,
    ROLE_TAG_ID_MAP,
    STORAGE_ROLES,
    STORAGE_ROLE_ID,
    COUNTRY_DEFAULT_TIMEZONE,
    COUNTRY_LANGUAGE_MULTIPLE_MAP,
} from '../common/constants';
import { ContentfulApiService } from '../services/ContentfulApi.service';
import { ContentfulTransformService } from '../services/ContentfulTransform.service';
import { LearningCardSlide, QuizQuestion, ScanningContestScheme } from '../types';
import { getAssetIdsParams } from '../common';

export function convertDateToTzDate(val: Date) {
    return moment(new Date(val.getTime() - val.getTimezoneOffset() * 60000))
        .tz(getDefaultTimezone())
        .toDate();
}

/**
 * store date
 * @param key the key
 * @param obj the json object
 */
export const saveData = (key: string, obj: any) => {
    return localStorage.setItem(key, JSON.stringify(obj));
};

/**
 * get data
 * @param key
 */
export function getData<T>(key: string): any {
    const data = localStorage.getItem(key);
    if (!data) {
        return undefined;
    }
    return JSON.parse(data || '{}');
}

export const getLocalArray = (key: string) => {
    const data = getData(key);
    if (isArray(data)) {
        return data;
    }
    return [];
};

export const clearLocalArray = (key: string) => {
    saveData(key, []);
};

export const toggleLocalArrayItem = (key: string, element: any) => {
    let data = getData(key);
    if (isArray(data)) {
        const fI = data.findIndex((el) => el === element);
        if (fI >= 0) {
            data = data.filter((v) => v !== element);
        } else {
            data.push(element);
        }
    } else {
        data = [element];
    }

    saveData(key, data);
};

export const removeLocalArrayItemByIndex = (key: string, index: number) => {
    let data = getData(key);
    if (isArray(data)) {
        data = data.filter((v, id) => id !== index);
        saveData(key, data);
    }
};

export const addLocalArrayItem = (
    key: string,
    element: any,
    reverse = true,
    limit = 50,
    unique = false
) => {
    let data = getData(key);
    if (isArray(data)) {
        if (unique) {
            data = data.filter((d) => d !== element);
        }
        if (reverse) {
            data = [element].concat(data);
        } else {
            data.push(element);
        }
    } else {
        data = [element];
    }

    if (limit > -1) {
        data = data.slice(0, limit);
    }

    saveData(key, data);
};

export const removeLocalArrayItem = (key: string, element: any) => {
    let data = getData(key);
    if (isArray(data)) {
        data = data.filter((d) => d !== element);
    } else {
        data = [];
    }

    saveData(key, data);
};

export const getRoleId = () => {
    return localStorage.getItem(STORAGE_ROLE_ID);
};

export const getAvailableRoleIds = () => {
    return JSON.parse(localStorage.getItem(STORAGE_ROLES));
};

export const getMetaTag = () => {
    const roleId = getRoleId();
    if (roleId && ROLE_TAG_ID_MAP[roleId]) {
        // console.log("tag is1", ROLE_TAG_ID_MAP[roleId])
        return ROLE_TAG_ID_MAP[roleId];
    }
    // console.log("tag is2", COUNTRY_TAG_MAP[getCountry()])
    return COUNTRY_TAG_MAP[getCountry()];
};

export const getCountryTag = () => {
    return COUNTRY_SHORT_TAG_MAP[getCountry()];
};

export const getDefaultTimezone = () => {
    return (
        COUNTRY_DEFAULT_TIMEZONE[getCountry()] ??
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );
};

export const readMediaBuffer = (file: File): Promise<ArrayBuffer> => {
    return new Promise<any>((resolve, reject) => {
        const fr = new FileReader();
        fr.readAsArrayBuffer(file);
        fr.onload = function (e) {
            console.log('arrayBuffer', fr.result);
            resolve(fr.result as ArrayBuffer);
        };
    });
};

export const checkImageSize = (width: number, height: number) => {
    if (width < IMAGE_SIZE_MIN_WIDTH || height < IMAGE_SIZE_MIN_HEIGHT) {
        return false;
    }
    return true;
};

export function getImageSize(file) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            const image = new Image();
            image.onload = () => {
                resolve([image.width, image.height]);
            };
            image.src = evt.target.result as string;
        };
        reader.readAsDataURL(file);
    });
}

export function getImageSizeBySource(src) {
    return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => {
            resolve([image.width, image.height]);
        };
        image.src = src as string;
    });
}

export const getMapValue = (v: any, field: string, fallback: any) => {
    return (v && v[field]) || fallback;
};

let language = LANGUAGE;
let country = 'England/UK';
export const setLanguage = (v) => {
    language = v;
};
export const getLanguage = () => {
    return language;
};
export const getLanguageListByCountry = (country) => {
    const defaultLanguages = COUNTRY_LANGUAGE_MULTIPLE_MAP[country];
    const eng = 'English';
    if (defaultLanguages && !defaultLanguages.includes(eng)) {
        return [...defaultLanguages, eng];
    }
    return [eng];
};
export const setCountry = (v) => {
    country = v;
};
export const getCountry = () => {
    return country;
};
export const cfDefaultGet = (v: any, field: string) => {
    return _.get(v, `${field}[${LANGUAGE_CONTENTFUL_MAP.English}]`);
};

export const cfGet = (v: any, field: string) => {
    return _.get(v, `${field}[${LANGUAGE_CONTENTFUL_MAP[getLanguage()]}]`);
};

export const cfLanGet = (v: any, field: string) => {
    const lv = _.get(v, field);
    if (v && lv) {
        const keys = _.keys(lv);
        const key1 = LANGUAGE_CONTENTFUL_MAP[getLanguage()];
        const key2 = LANGUAGE_CONTENTFUL_MAP.English;
        const key3 = keys[0];

        if (keys.includes(key1)) {
            return lv[key1];
        }
        if (keys.includes(key2)) {
            return lv[key2];
        }
        if (key3) {
            return _.get(v, `${field}[${key3}]`);
        }
        return undefined;
    }
    return undefined;
};

export const getMapOrEmpty = (key: string, v: any) => {
    return v ? { [key]: v } : {};
};

export const composeDate = (dt: string, tm: string) => {
    if (!dt || !tm) {
        return undefined;
    }
    const sDates = dt.split(' ');
    return moment.tz(
        `${sDates[1]}/${sDates[0]}/${sDates[2]} ${tm}`,
        getDefaultTimezone()
    );
};

export const getTime = (tm: string) => {
    const v = tm;
    const splited = v.split(':');
    const h = Number(splited[0]);
    const m = Number(splited[1]);

    return h * 60 + m;
};

export const checkIfAutoChangeEndtime = (
    startDate: string,
    startTime: string,
    endDate: string,
    endTime: string
) => {
    console.log('startDate', startDate, startTime, endDate, endTime);
    return (
        startDate &&
        startTime &&
        endDate &&
        ((endTime &&
            composeDate(startDate, startTime).toDate().valueOf() >
                composeDate(endDate, endTime).toDate().valueOf()) ||
            (!endTime &&
                composeDate(startDate, startTime).toDate().valueOf() >
                    composeDate(endDate, '00:00').toDate().valueOf()))
    );
};

export const getTagsIntersection = (coll1: any[], coll2: any[]) => {
    const append = [];
    let newColl2 = coll2;
    if (
        coll2
            .slice(0, coll2.length - 1)
            .find((x) => x === coll2[coll2.length - 1])
    ) {
        newColl2 = coll2.slice(0, coll2.length - 1);
    }
    for (const item2 of newColl2) {
        const found = coll1.find((item1) => item1 === item2);
        if (!found) {
            append.push(item2);
        }
    }
    if (append.length) {
        return [...coll1, ...append];
    }
    return newColl2;
};

export const isRequireOrWhiteSpaces = (obj: object) => {
    for (const key of Object.keys(obj)) {
        if (!obj[key] || obj[key].match(/^\s+$/)) {
            return true;
        }
    }
    return false;
};

export const isRequireOrWhiteSpacesArray = (
    obj: { [key: string]: string }[]
) => {
    for (const elem of obj) {
        for (const key of Object.keys(elem)) {
            if (!elem[key] || elem[key].match(/^\s+$/)) {
                return true;
            }
        }
    }
    return false;
};

export const isNotRequireAndWhiteSpaces = (obj: object) => {
    for (const key of Object.keys(obj)) {
        if (obj[key] && obj[key].match(/^\s+$/)) {
            return true;
        }
    }
    return false;
};

export const isStringRequiredMissing = (str: string) => {
    return !str || !!str.match(/^\s+$/);
};

export const isStringNotRequireAndWhiteSpaces = (s: string) => {
    return s && !!s.match(/^\s+$/);
};

export const getUrlKeepQuery = (to: string) => {
    const url = new URL(window.location.href);
    url.pathname = to;

    return url;
};

export const getUrlStringKeepQuery = (to: string) => {
    const url = new URL(window.location.href);
    url.pathname = to;

    return url.href;
};

export const getYoutubePosterUrl = (link: string) => {
    let image = '';
    if (link?.trim()?.match(/^https:\/\/youtu.be/)) {
        const id = link?.trim()?.match(/^https:\/\/youtu.be\/(.*)$/)[1];
        image = `http://img.youtube.com/vi/${id}/0.jpg`;
    }
    if (link?.trim()?.match(/^https:\/\/www.youtube.com/)) {
        const id = link
            ?.trim()
            ?.match(/^https:\/\/www.youtube.com\/watch\?v=(.*)$/)[1];
        image = `http://img.youtube.com/vi/${id}/0.jpg`;
    }

    return image;
};

export const genNewQuestion = (appLanguages: string[]) => {
    const question: QuizQuestion = {
        content: {
            questionText: {},
            options: [],
            reason: {},
            points: 0,
            // referencedQuiz: QuizType;
            correctAnswerIndex: undefined,
        },
    };
    appLanguages.forEach((language) => {
        if (!question.content.questionText[language]) {
            question.content.questionText[language] = '';
        }
        for (let j = 0; j < NUM_OPTIONS_QUIZ; ++j) {
            if (!question.content.options[j]) {
                question.content.options[j] = {};
            }
            if (!question.content.options[j][language]) {
                question.content.options[j][language] = '';
            }
        }
        if (!question.content.reason[language]) {
            question.content.reason[language] = '';
        }
    });
    return question;
};

export const genNewSchemeOffer = () => {
    const schemeOffer: ScanningContestScheme = {
        content: {
            schemeType: '',
            targetScans: 0,
            awardedPoints: 0,
        },
    };
    return schemeOffer;
};

export const getQuizQuestions = async (questions: any[]) => {
    const quizQuestions = [];
    const quizQuestionsOptions = [];
    for (let i = 0; i < questions.length; ++i) {
        const question = questions[i];
        const questionEntryId = question.sys.id;
        try {
            const quizQuestion = await ContentfulApiService.getEntry({
                contentType: 'quizQuestion',
                entryId: questionEntryId,
            });
            const quizQuestionOptions = [];
            quizQuestions.push(quizQuestion);
            const { options } = quizQuestion.fields;
            const languages = Object.keys(options);
            for (const langCode of languages) {
                const optionItems = options[langCode];
                for (let j = 0; j < optionItems.length; ++j) {
                    const option = optionItems[j];
                    if (!quizQuestionOptions[j]) {
                        quizQuestionOptions[j] = {};
                    }
                    quizQuestionOptions[j][
                        LANGUAGE_CONTENTFUL_MAP_REVERSE[langCode]
                    ] = option;
                }
            }
            quizQuestionsOptions.push(quizQuestionOptions);
        } catch (err) {
            console.error(
                `Failed to get quiz question for entry ${questionEntryId}`
            );
        }
    }
    return {
        quizQuestions,
        quizQuestionsOptions,
    };
};

export const getScanningContestSchemes = async (schemes: any[]) => {
    const scanningContestSchemes = [];
    for (let i = 0; i < schemes.length; ++i) {
        const scheme = schemes[i];
        const schemeEntryId = scheme.sys.id;
        try {
            const scanningContestScheme = await ContentfulApiService.getEntry({
                contentType: 'scanningContestScheme',
                entryId: schemeEntryId,
            });
            scanningContestSchemes.push(scanningContestScheme);
        } catch (err) {
            console.error(
                `Failed to get scanning contest scheme for entry ${schemeEntryId}`
            );
        }
    }
    return {
        scanningContestSchemes,
    };
};

export const getRegisterContest = async (schemeEntryId: string) => {
    let registerContestScheme;
    try {
        registerContestScheme = await ContentfulApiService.getEntry({
            contentType: 'registrationContest',
            entryId: schemeEntryId,
        });
    } catch (err) {
        console.error(
            `Failed to get registerContest scheme for entry ${schemeEntryId}`
        );
    }
    return registerContestScheme;
};

export const VideoItemToEditModel = (content: any) => {
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };

    const video = content;

    const toReturn = {
        raw: video.contentfulRawData,
        status: video.status,

        identifierDefinition: {
            name: video.name,
            appLanguages: video.language,
            startDate:
                video.startDate && video.startDate.format('DD MMMM YYYY'),
            startTime: video.startDate && video.startDate.format('HH:mm'),
            endDate: video.endDate && video.endDate.format('DD MMMM YYYY'),
            endTime: video.endDate && video.endDate.format('HH:mm'),
        },
        content: {
            isFromURL: video.fromUrl,
            // source: video.link,
            thumbnail: video.image,
            isFeatured: video.featured,
            playlists: video.playlists,
            tags: video.tags,
            title: getLanField(video.lanTitle),
            summary: getLanField(video.lanSummary),
            description: getLanField(video.lanDescription),
            oldImage: !!video.image,
            oldSource: !!video.link,
            image: {
                preview: video.image,
                name: ContentfulTransformService.getLinkDetails(
                    video.imageAsset
                ).name,
                size: ContentfulTransformService.getLinkDetails(
                    video.imageAsset
                ).size,
            },
            source: video.fromUrl
                ? video.link
                : video.link
                ? {
                      preview: video.link,
                      name: ContentfulTransformService.getLinkDetails(
                          video.linkAsset
                      ).name,
                      size: ContentfulTransformService.getLinkDetails(
                          video.linkAsset
                      ).size,
                  }
                : null,
        },
        targetGeography: {
            regions: video.regions,
            states: video.stateprovinces,
            distributors: video.distributors,
            districts: video.district,
            pincode: video.pincode,
            rmAreas: ['A', 'B'],
        },
        targetUser: {
            userTypes: video.targetUsers,
            spaceOfMechanics: video.spaceOfMechanics,
            connekt: video.connekt,
            mobile: video.mobile,
            PID: video.pid,
            dealerSegments: video.dealerSegments,
            dealerSubSegments: video.dealerSubSegments,
            mechanicsSegments: video.mechanicsSegments,
            mechanicsSubSegments: video.mechanicsSubSegments,
        },
        sendNotification: {
            notificationType: [],
            icon: '',
            // headline: {},
            inAppNotificationContent: {
                bodyText: {},
            },
            pushNotificationContent: {
                headline: {},
                bodyText: {},
            },
            targetLink: {},
        },
        quiz: undefined,
    };

    return toReturn;
};

export const ArticleItemToEditModel = (content: any) => {
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };

    const item = content;

    const toReturn = {
        raw: item.contentfulRawData,
        status: item.status,

        identifierDefinition: {
            name: item.name,
            appLanguages: item.language,
            startDate: item.startDate && item.startDate.format('DD MMMM YYYY'),
            startTime: item.startDate && item.startDate.format('HH:mm'),
            endDate: item.endDate && item.endDate.format('DD MMMM YYYY'),
            endTime: item.endDate && item.endDate.format('HH:mm'),
        },
        content: {
            headline: getLanField(item.lanHeadline),
            description: getLanField(item.lanDescription),
            actionText: getLanField(item.lanCallToActionText),

            folders: JSON.parse(JSON.stringify(item.folders)),

            oldImage: !!item.image,
            image: {
                preview: item.image,
                name: ContentfulTransformService.getLinkDetails(item.imageAsset)
                    .name,
                size: ContentfulTransformService.getLinkDetails(item.imageAsset)
                    .size,
            },
            linkSource: getLanField(item.lanLinkSource),
            tags: item.tags || [],
            isFeatured: item.featured,
        },
        targetGeography: {
            regions: item.regions,
            states: item.stateprovinces,
            distributors: item.distributors,
            districts: item.district,
            pincode: item.pincode,
            rmAreas: ['A', 'B'],
        },
        targetUser: {
            userTypes: item.targetUsers,
            spaceOfMechanics: item.spaceOfMechanics,
            connekt: item.connekt,
            mobile: item.mobile,
            PID: item.pid,
            dealerSegments: item.dealerSegments,
            dealerSubSegments: item.dealerSubSegments,
            mechanicsSegments: item.mechanicsSegments,
            mechanicsSubSegments: item.mechanicsSubSegments,
        },
        sendNotification: {
            notificationType: [],
            icon: '',
            inAppNotificationContent: {
                bodyText: {},
            },
            pushNotificationContent: {
                headline: {},
                bodyText: {},
            },
            // name: {},
            targetLink: {},
        },
        quiz: undefined,
    };
    return toReturn;
};

export const QuizItemToEditModel = async (content: any) => {
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };

    const questions = content.questions[DEFAULT_LANGUAGE_CODE];
    const { quizQuestions, quizQuestionsOptions } = await getQuizQuestions(
        questions
    );

    // console.log('==content==', content);
    // console.log('==quizQuestions==', quizQuestions);
    // console.log('==quizQuestionsOptions==', quizQuestionsOptions);
    const toReturn = {
        raw: content.contentfulRawData,
        status: content.status,

        identifierDefinition: {
            name: content.name,
            appLanguages: content.language,
            startDate:
                content.startDate && content.startDate.format('DD MMMM YYYY'),
            startTime: content.startDate && content.startDate.format('HH:mm'),
            endDate: content.endDate && content.endDate.format('DD MMMM YYYY'),
            endTime: content.endDate && content.endDate.format('HH:mm'),
        },
        content: {
            title: getLanField(content.lanTitle),
            description: getLanField(content.lanDescription),
            questions: quizQuestions.map((quizQuestion, index) => ({
                raw: quizQuestion,
                content: {
                    questionText: getLanField(quizQuestion.fields.questionText),
                    correctAnswerIndex:
                        quizQuestion.fields.correctAnswerIndex[
                            DEFAULT_LANGUAGE_CODE
                        ],
                    options: quizQuestionsOptions[index],
                    points: quizQuestion.fields.points[DEFAULT_LANGUAGE_CODE],
                    reason: getLanField(quizQuestion.fields.reason),
                    // options: { [key: string]: string }[];
                    // reason: { [key: string]: string };
                    // points: number;
                    // // referencedQuiz: QuizType;
                    // correctAnswerIndex: number;
                },
            })),
            bonusPoints: content.bonusPoints,
            totalPoints: content.totalPoints,
        },
        targetGeography: {
            regions: content.regions,
            states: content.stateprovinces,
            distributors: content.distributors,
            districts: content.district,
            pincode: content.pincode,
            rmAreas: ['A', 'B'],
        },
        targetUser: {
            userTypes: content.targetUsers,
            spaceOfMechanics: content.spaceOfMechanics,
            connekt: content.connekt,
            mobile: content.mobile,
            PID: content.pid,
            dealerSegments: content.dealerSegments,
            dealerSubSegments: content.dealerSubSegments,
            mechanicsSegments: content.mechanicsSegments,
            mechanicsSubSegments: content.mechanicsSubSegments,
        },
    };

    console.log('quizzes table item', toReturn);

    return toReturn;
};

export const ScanningContestItemToEditModel = async (content: any) => {
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };
    console.log(
        content.schemes[DEFAULT_LANGUAGE_CODE],
        DEFAULT_LANGUAGE_CODE,
        'console.log(content);'
    );
    const schemes = content.schemes[DEFAULT_LANGUAGE_CODE];
    const { scanningContestSchemes } = await getScanningContestSchemes(schemes);

    console.log('====scanningContestSchemes===', scanningContestSchemes);

    const toReturn = {
        raw: content.contentfulRawData,
        status: content.status,

        identifierDefinition: {
            name: content.name,
            appLanguages: content.language,
            startDate:
                content.startDate && content.startDate.format('DD MMMM YYYY'),
            startTime: content.startDate && content.startDate.format('HH:mm'),
            endDate: content.endDate && content.endDate.format('DD MMMM YYYY'),
            endTime: content.endDate && content.endDate.format('HH:mm'),
        },
        content: {
            headline: getLanField(content.lanHeadline),
            description: getLanField(content.lanDescription),
            schemes: scanningContestSchemes.map((scheme, index) => ({
                raw: scheme,
                content: {
                    schemeType: scheme.fields.schemeType[DEFAULT_LANGUAGE_CODE],
                    targetScans:
                        scheme.fields.targetScans[DEFAULT_LANGUAGE_CODE],
                    awardedPoints:
                        scheme.fields.awardedPoints[DEFAULT_LANGUAGE_CODE],
                },
            })),
            multiplierPoints: content.multiplierPoints,
            totalPoints: content.totalPoints,
            applicableBrands: content.applicableBrands,
            applicableSKUs: content.applicableSKUs,
        },
        targetGeography: {
            regions: content.regions,
            states: content.stateprovinces,
            distributors: content.distributors,
            districts: content.district,
            pincode: content.pincode,
            rmAreas: ['A', 'B'],
        },
        targetUser: {
            userTypes: content.targetUsers,
            spaceOfMechanics: content.spaceOfMechanics,
            connekt: content.connekt,
            mobile: content.mobile,
            PID: content.pid,
            dealerSegments: content.dealerSegments,
            dealerSubSegments: content.dealerSubSegments,
            mechanicsSegments: content.mechanicsSegments,
            mechanicsSubSegments: content.mechanicsSubSegments,
        },
        sendNotification: {
            notificationType: [],
            icon: '',
            inAppNotificationContent: {
                bodyText: {},
            },
            pushNotificationContent: {
                headline: {},
                bodyText: {},
            },
            // name: {},
            targetLink: {},
        },
        scanningContest: undefined,
    };
    return toReturn;
};

export const RegistrationContestItemToEditModel = async (content: any) => {
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };
    console.log(content, 'RegistrationContestItemToEditModel');
    const { scanningContestSchemes } = await getRegisterContest(content.id);

    console.log('====scanningContestSchemes===', scanningContestSchemes);

    const toReturn = {
        raw: content.contentfulRawData,
        status: content.status,

        identifierDefinition: {
            name: content.name,
            appLanguages: content.language,
            startDate:
                content.startDate && content.startDate.format('DD MMMM YYYY'),
            startTime: content.startDate && content.startDate.format('HH:mm'),
            endDate: content.endDate && content.endDate.format('DD MMMM YYYY'),
            endTime: content.endDate && content.endDate.format('HH:mm'),
        },
        content: {
            headline: getLanField(content.lanHeadline),
            pointsForAchievement: content.pointsForAchievement,
            targetedUsers: content.targetedUsers,
            reachedUsers: content.reachedUsers,
        },
        targetGeography: {
            regions: content.regions,
            states: content.stateprovinces,
            distributors: content.distributors,
            districts: content.district,
            pincode: content.pincode,
            rmAreas: ['A', 'B'],
        },
        targetUser: {
            userTypes: content.targetUsers,
            spaceOfMechanics: content.spaceOfMechanics,
            connekt: content.connekt,
            mobile: content.mobile,
            PID: content.pid,
            dealerSegments: content.dealerSegments,
            dealerSubSegments: content.dealerSubSegments,
            mechanicsSegments: content.mechanicsSegments,
            mechanicsSubSegments: content.mechanicsSubSegments,
        },
        sendNotification: {
            notificationType: [],
            icon: '',
            inAppNotificationContent: {
                bodyText: {},
            },
            pushNotificationContent: {
                headline: {},
                bodyText: {},
            },
            // name: {},
            targetLink: {},
        },
        scanningContest: undefined,
    };
    return toReturn;
};

// Learning card
export const LearningCardThemeItemToEditModel = (content: any) => {
    if (!content) {
        return undefined;
    }
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };

    const toReturn = {
        raw: content.contentfulRawData,
        status: content.status,

        name: content.name,
        appLanguages: content.language,
        content: {
            headline: getLanField(content.lanHeadline),
            description: getLanField(content.lanDescription),
            oldImage: !!content.image,
            image: {
                preview: content.image,
                name: ContentfulTransformService.getLinkDetails(content.imageAsset)
                    .name,
                size: ContentfulTransformService.getLinkDetails(content.imageAsset)
                    .size,
            },
            tags: content.tags || [],
            sections: [], // TODO
        },
    };
    return toReturn;
};

export const LearningCardSectionItemToEditModel = (content: any) => {
    if (!content) {
        return undefined;
    }
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };

    console.log('=====content===', content);

    const toReturn = {
        raw: content.contentfulRawData,
        status: content.status,

        name: content.name,
        appLanguages: content.language,
        content: {
            headline: getLanField(content.lanHeadline),
            description: getLanField(content.lanDescription),
            oldImage: !!content.image,
            image: {
                preview: content.image,
                name: ContentfulTransformService.getLinkDetails(content.imageAsset)
                    .name,
                size: ContentfulTransformService.getLinkDetails(content.imageAsset)
                    .size,
            },
            tags: content.tags || [],
            referencedTheme: content.referencedTheme,
            prevReferencedTheme: content.referencedTheme,
        },
    };
    return toReturn;
};

export const genNewLearningCardSlide = (appLanguages: string[]) => {
    const learningCardSlide: LearningCardSlide = {
        content: {
            image: null,
            oldImage: false,
            headline: {},
            description: {},
            isFromVideo: false,
        },
    };
    appLanguages.forEach((language) => {
        if (!learningCardSlide.content.headline[language]) {
            learningCardSlide.content.headline[language] = '';
        }
        if (!learningCardSlide.content.description[language]) {
            learningCardSlide.content.description[language] = '';
        }
    });
    return learningCardSlide;
};

export const getLearningCardSlides = async (slides: any[]) => {
    const learningCardSlides = [];
    for (let i = 0; i < slides.length; ++i) {
        const slide = slides[i];
        const slideEntryId = slide.sys.id;
        try {
            const learningCardSlide = await ContentfulApiService.getEntry({
                contentType: 'learningCardSlide',
                entryId: slideEntryId,
            });
            learningCardSlides.push(learningCardSlide);
        } catch (err) {
            console.error(
                `Failed to get learning card slide for entry ${slideEntryId}`
            );
        }
    }
    return {
        learningCardSlides,
    };
};

const transformLearningCardSlides = async (content: any) => {
    console.log('==transformLearningCardSlides==', content);
    const assetsResponse = await ContentfulApiService.getAssets({
        ...getAssetIdsParams(
            ContentfulTransformService.getLearningCardSlideAssets(content)
        ),
    });
    console.log('==transformLearningCardSlides=assetsResponse=', assetsResponse);

    const learningCardSlidesObj = ContentfulTransformService.getLearningCardSlides(
        assetsResponse,
        content,
    );
    console.log('==transformLearningCardSlides=learningCardSlidesObj=', learningCardSlidesObj);
    return learningCardSlidesObj;
};

export const LearningCardItemToEditModel = async (content: any) => {
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };

    const slides = content.slides[DEFAULT_LANGUAGE_CODE];
    const { learningCardSlides } = await getLearningCardSlides(
        slides
    );

    console.log('====LearningCardItemToEditModel=content===', content);
    console.log('====learningCardSlides===', learningCardSlides);

    const toReturn = {
        raw: content.contentfulRawData,
        status: content.status,

        identifierDefinition: {
            name: content.name,
            appLanguages: content.language,
            startDate: content.startDate && content.startDate.format('DD MMMM YYYY'),
            startTime: content.startDate && content.startDate.format('HH:mm'),
            endDate: content.endDate && content.endDate.format('DD MMMM YYYY'),
            endTime: content.endDate && content.endDate.format('HH:mm'),
        },
        content: {
            headline: getLanField(content.lanHeadline),
            description: getLanField(content.lanDescription),
            tags: content.tags || [],
            referencedSection: content.referencedSection,
            prevReferencedSection: content.referencedSection,
            // slides: learningCardSlides.map((slide, index) => ({
            //     raw: slide,
            //     content: {
            //         headline: slide.fields.headline,
            //         description: slide.fields.description,
            //         image: {
            //             preview: slide.image,
            //             name: ContentfulTransformService.getLinkDetails(
            //                 slide.imageAsset
            //             ).name,
            //             size: ContentfulTransformService.getLinkDetails(
            //                 slide.imageAsset
            //             ).size,
            //         },
            //     },
            // })),
            slides: await transformLearningCardSlides(learningCardSlides),
        },
        targetGeography: {
            regions: content.regions,
            states: content.stateprovinces,
            distributors: content.distributors,
            districts: content.district,
            pincode: content.pincode,
            rmAreas: ['A', 'B'],
        },
        targetUser: {
            userTypes: content.targetUsers,
            spaceOfMechanics: content.spaceOfMechanics,
            connekt: content.connekt,
            mobile: content.mobile,
            PID: content.pid,
            dealerSegments: content.dealerSegments,
            dealerSubSegments: content.dealerSubSegments,
            mechanicsSegments: content.mechanicsSegments,
            mechanicsSubSegments: content.mechanicsSubSegments,
        },
        sendNotification: {
            notificationType: [],
            icon: '',
            inAppNotificationContent: {
                bodyText: {},
            },
            pushNotificationContent: {
                headline: {},
                bodyText: {},
            },
            // name: {},
            targetLink: {},
        },
        scanningContest: undefined,
        quiz: undefined,
    };
    return toReturn;
};

export enum ContentStatus {
    NOT_EXISTS,
    DRAFT,
    YET_STARTED,
    LIVE,
    EXPIRED,
}

export const getContentStatus = (content, startDate, endDate) => {
    if (!content) {
        return ContentStatus.NOT_EXISTS;
    }

    if (content.status !== 'Published') {
        return ContentStatus.DRAFT;
    }

    if (!startDate) {
        return ContentStatus.YET_STARTED;
    }

    const defaultTimezone = getDefaultTimezone();
    const now = moment.tz(defaultTimezone).toDate();
    if (now < startDate) {
        return ContentStatus.YET_STARTED;
    }
    if (!endDate || now <= endDate) {
        return ContentStatus.LIVE;
    }
    return ContentStatus.EXPIRED;
};

export const getSubSectorsByMapping = (sectors, subSectorsMapping) => {
    if (sectors && subSectorsMapping) {
        let subSectors = [];
        for (const sectorId of sectors) {
            subSectors = subSectors.concat(subSectorsMapping[sectorId] || []);
        }
        return subSectors;
    }
    return [];
};

export const roundDecimals = (num: number, decimals: number): number | string => {
    const factor = Math.pow(10, decimals);
    const rounded = Math.round((num + Number.EPSILON) * factor) / factor;
    const parts = String(rounded).split(".");
    if (parts.length < 2 || parts[1].length < decimals) {
        return rounded;
    }
    return rounded.toFixed(decimals);
}

export const arrayElementsEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (const element of arr1) {
        if (arr2.indexOf(element) === -1) {
            return false;
        }
    }
    return true;
}

export const trimUrlByRetainParams = (url: string, retainParams: string[]): string => {
    try {
        // Parse the input URL
        const parsedUrl = new URL(url);

        // Create a new URLSearchParams object to hold the retained parameters
        const retainedSearchParams = new URLSearchParams();

        // Iterate over the parameters to retain
        retainParams.forEach(param => {
            const paramValue = parsedUrl.searchParams.get(param);
            if (paramValue !== null) {
                retainedSearchParams.set(param, paramValue);
            }
        });

        // Set the retained parameters back to the URL
        parsedUrl.search = retainedSearchParams.toString();

        // Return the modified URL as a string
        return parsedUrl.toString();
    } catch (error) {
        // In case of an invalid URL, keep it as is
        return url;
    }
}
