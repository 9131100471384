import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Button';
import { FlexGrid } from '../../../../components/FlexGrid';
import { Input } from '../../../../components/Input';
import { PageHeader } from '../../../../components/PageHeader';
import { SearchSelect } from '../../../../components/SearchSelect';
import { Text } from '../../../../components/Text';
import { COLORS } from '../../../../styles/variables';
import { DownloadTemplate } from '../../../../components/DownloadTemplate';
import { PlusIcon } from '../../../../assets/icons';
import { LearningCardType } from '../../../../types';
import { DropDownPopulate } from '../../../../services/Api.service';

const Form = styled(FlexGrid)`
    padding: 1em 2em;
    min-width: 500px;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    dropdownPopulate?: DropDownPopulate;
    stepNavigator?: any;
    learningCardState: [
        LearningCardType,
        React.Dispatch<React.SetStateAction<LearningCardType>>
    ];
}

export const TargetGeography: React.FC<Props> = ({
    dropdownPopulate,
    stepNavigator,
    learningCardState,
}) => {
    const [learningCard, setLearningCard] = learningCardState;
    const { targetGeography } = learningCard;

    // TODO
    const hideTargetGeography = true;

    const dataIncomplete = !targetGeography.regions?.length;

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Target Geography
                    </Text>
                }
            />
            <Form>
                {!hideTargetGeography && <SearchSelect
                    value={targetGeography.regions}
                    onChange={(val) =>
                        setLearningCard({
                            ...learningCard,
                            targetGeography: {
                                ...learningCard.targetGeography,
                                regions: val,
                            },
                        })
                    }
                    options={dropdownPopulate.regions || []}
                    label="Region"
                    info={{
                        title: 'Region',
                        content:
                            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor.',
                    }}
                />}
                {/* <SearchSelect
                    value={targetGeography.states}
                    onChange={(val) =>
                        setLearningCard({
                            ...learningCard,
                            targetGeography: {
                                ...learningCard.targetGeography,
                                states: val,
                            },
                        })
                    }
                    label="State/Provinces"
                    info={{
                        title: '',
                        content: '',
                    }}
                    options={(dropdownPopulate.provinces || []).map(r => r.name)}
                />
                <SearchSelect
                    value={targetGeography.distributors}
                    onChange={(val) =>
                        setLearningCard({
                            ...learningCard,
                            targetGeography: {
                                ...learningCard.targetGeography,
                                distributors: val,
                            },
                        })
                    }
                    label="Distributors"
                    info={{
                        title: '',
                        content: '',
                    }}
                    options={(dropdownPopulate.distributors || []).map(r => r.name)}
                />
                <SearchSelect
                    value={targetGeography.districts}
                    onChange={(val) =>
                        setLearningCard({
                            ...learningCard,
                            targetGeography: {
                                ...learningCard.targetGeography,
                                districts: val,
                            },
                        })
                    }
                    label="District"
                    info={{
                        title: 'District',
                        content:
                            'United States has more 250 District, just start typing to find which distributors will you pick.',
                    }}
                    options={(dropdownPopulate.districts || []).map(r => r.name)}
                />
                <Input
                    value={targetGeography.pincode?.toString()}
                    onChange={(val) =>
                        setLearningCard({
                            ...learningCard,
                            targetGeography: {
                                ...learningCard.targetGeography,
                                pincode: Number(val),
                            },
                        })
                    }
                    type="number"
                    label="Pincode"
                    info={{
                        title: '',
                        content: '',
                    }}
                    icon={<PlusIcon />}
                    iconPosition="end"
                />
                <DownloadTemplate
                    title="Download Excel Template"
                    buttonText="Upload Pincode"
                />
                <SearchSelect
                    value={targetGeography.rmAreas}
                    onChange={(val) =>
                        setLearningCard({
                            ...learningCard,
                            targetGeography: {
                                ...learningCard.targetGeography,
                                rmAreas: val,
                            },
                        })
                    }
                    label="RM Area"
                    info={{
                        title: 'RM Area',
                        content:
                            'United States has more 250 RM area, just start typing to find which RM Area will you pick.',
                    }}
                    options={[
                        'A',
                        'B',
                        'C',
                        'D',
                        'E',
                        'F',
                        'G',
                        'H',
                        'I',
                        'J',
                        'K',
                        'L',
                        'M',
                        'N',
                    ]}
                /> */}
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() =>
                            stepNavigator((prev: number) => prev - 1)
                        }
                    />
                    <Button
                        disabled={!hideTargetGeography && dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() =>
                            stepNavigator((prev: number) => prev + 1)
                        }
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
