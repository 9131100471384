import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const Points: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 26}
        height={height || 26}
        viewBox="0 0 26 26"
    >
        <path
            id="Path_4862"
            data-name="Path 4862"
            d="M14.657,28.857a12.451,12.451,0,0,0,5.018-1.024,13.328,13.328,0,0,0,6.958-6.958,12.866,12.866,0,0,0-.007-10.043A13.4,13.4,0,0,0,23.81,6.678a13.077,13.077,0,0,0-4.148-2.81,12.593,12.593,0,0,0-5.018-1.01,12.545,12.545,0,0,0-5.011,1.01,13.29,13.29,0,0,0-6.965,6.965,12.866,12.866,0,0,0,.007,10.043,13.39,13.39,0,0,0,6.958,6.958A12.437,12.437,0,0,0,14.657,28.857ZM9.291,20.668V16.587h4.8v5.754H11.031a1.812,1.812,0,0,1-1.271-.428A1.6,1.6,0,0,1,9.291,20.668Zm5.928,1.673V16.587H20v4.081a1.627,1.627,0,0,1-.455,1.244,1.762,1.762,0,0,1-1.258.428ZM8.395,14.847v-1.5a.992.992,0,0,1,.314-.81,1.29,1.29,0,0,1,.85-.261H10.95a1.835,1.835,0,0,1-.589-1.405,2.054,2.054,0,0,1,.656-1.566,2.242,2.242,0,0,1,1.592-.616,2.146,2.146,0,0,1,1.251.375,2.085,2.085,0,0,1,.783,1.071,2.113,2.113,0,0,1,2.047-1.445,2.242,2.242,0,0,1,1.592.616,2.054,2.054,0,0,1,.656,1.566,1.835,1.835,0,0,1-.589,1.405h1.405a1.279,1.279,0,0,1,.863.261,1.015,1.015,0,0,1,.3.81v1.5a.937.937,0,0,1-.288.769,1.274,1.274,0,0,1-.823.234h-4.59V12.278h.99a1.671,1.671,0,0,0,1.178-.395,1.24,1.24,0,0,0,.428-.943,1.016,1.016,0,0,0-.348-.81,1.263,1.263,0,0,0-.856-.3,1.323,1.323,0,0,0-.99.415,1.567,1.567,0,0,0-.4,1.137v.9H14.1v-.9a1.567,1.567,0,0,0-.4-1.137,1.323,1.323,0,0,0-.99-.415,1.251,1.251,0,0,0-.87.3,1.035,1.035,0,0,0-.335.81,1.248,1.248,0,0,0,.422.943,1.666,1.666,0,0,0,1.184.395h.99v3.573H9.505a1.274,1.274,0,0,1-.823-.234A.937.937,0,0,1,8.395,14.847Z"
            transform="translate(-1.65 -2.857)"
            fill={color || '#cca500'}
        />
    </svg>
);

export default Points;
