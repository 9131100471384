import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgClose: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || '20'}
        height={height || '20'}
        viewBox="0 0 12.068 12.068"
    >
        <g
            id="Group_119"
            data-name="Group 119"
            transform="translate(-328.966 -2666.17)"
        >
            <path
                id="Path_53"
                data-name="Path 53"
                d="M-931.425,1639.584l9.239,9.239"
                transform="translate(1261.806 1028)"
                fill="none"
                stroke={color || '#666'}
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                id="Path_54"
                data-name="Path 54"
                d="M-931.425,1639.584l9.239,9.239"
                transform="translate(-1309.204 1745.398) rotate(-90)"
                fill="none"
                stroke={color || '#666'}
                strokeLinecap="round"
                strokeWidth="2"
            />
        </g>
    </svg>
);

export default SvgClose;
