import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../styles/variables';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';
import { CloseIcon } from '../assets/icons';
import { Button } from './Button';

interface ModalProps {
    header?: string;
    content?: any;
    open?: boolean;
    onClose?: () => void;
    actions?: any;
    width?: string;
    maxWidth?: string;
}

const ModalScreen = styled(FlexGrid)<ModalProps>`
    display: ${({ open }) => (open ? 'flex' : 'none')};
    background-color: ${COLORS.transparentBlack50};
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 4;
`;

const ModalCard = styled(FlexGrid)<ModalProps>`
    background-color: ${COLORS.white};
    border-radius: 10px;
    min-width: ${({ width }) => width || '500px'};
    max-width: ${({ maxWidth }) => maxWidth || '90%'}; // Defaulting to 90% if not provided
    max-height: 80vh;
    flex-direction: column;
    transition: height 100ms ease-in;
    overflow-y: auto;
`;

const BorderBottom = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
`;

export const Modal: React.FC<ModalProps> = ({
    header,
    content,
    open,
    onClose,
    actions,
    width,
    maxWidth,
}) => {
    return (
        <ModalScreen open={open}>
            <ModalCard width={width} maxWidth={maxWidth}>
                <BorderBottom
                    gap="0.5em"
                    width="100%"
                    justifyContent="space-between"
                    p="1em 0.7em 1em 2em"
                >
                    <Text font="OS" fontSize="2.25rem" uppercase>
                        {header}
                    </Text>
                    <Button onClick={onClose} negative>
                        <CloseIcon height="20px" />
                    </Button>
                </BorderBottom>
                <FlexGrid width="100%" p="0em 2em">
                    {content}
                </FlexGrid>
                <FlexGrid width="100%" p="1em 2em">
                    {actions}
                </FlexGrid>
            </ModalCard>
        </ModalScreen>
    );
};
