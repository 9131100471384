import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const QuizIncorrectOption: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 36}
        height={height || 36}
        viewBox="0 0 36 36"
    >
        <g
            id="Group_15661"
            data-name="Group 15661"
            transform="translate(-822 -617)"
        >
            <g
                id="Component_14_3496"
                data-name="Component 14 – 3496"
                transform="translate(822 617)"
            >
                <g
                    id="Ellipse_18"
                    data-name="Ellipse 18"
                    transform="translate(3 3)"
                    fill="#009343"
                    stroke="#fff"
                    strokeWidth="1"
                >
                    <circle cx="15" cy="15" r="15" stroke="none" />
                    <circle cx="15" cy="15" r="14.5" fill="none" />
                </g>
                <rect
                    id="Rectangle_81"
                    data-name="Rectangle 81"
                    width="36"
                    height="36"
                    fill="none"
                />
                <path
                    id="Tick"
                    d="M-1195.1,61.573l3.77,3.951,11.2-11.2"
                    transform="translate(1205.618 -42.353)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="3"
                />
                <g id="Component_14_2791" data-name="Component 14 – 2791">
                    <g
                        id="Ellipse_18-2"
                        data-name="Ellipse 18"
                        transform="translate(3 3)"
                        fill={color || "#d3d3d6"}
                        stroke="#fff"
                        strokeWidth="1"
                    >
                        <circle cx="15" cy="15" r="15" stroke="none" />
                        <circle cx="15" cy="15" r="14.5" fill="none" />
                    </g>
                    <rect
                        id="Rectangle_81-2"
                        data-name="Rectangle 81"
                        width="36"
                        height="36"
                        fill="none"
                    />
                    <path
                        id="Tick-2"
                        data-name="Tick"
                        d="M-1195.1,61.573l3.77,3.951,11.2-11.2"
                        transform="translate(1205.618 -42.353)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="3"
                    />
                </g>
            </g>
        </g>
    </svg>
);

export default QuizIncorrectOption;
