import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgCopy: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path data-name="Path 4819" d="M0 0h24v24H0Z" fill="none" />
        <path
            className="icon-fill"
            data-name="Path 4820"
            d="M16 1H4a2.006 2.006 0 0 0-2 2v14h2V3h12Zm3 4H8a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h11a2.006 2.006 0 0 0 2-2V7a2.006 2.006 0 0 0-2-2Zm0 16H8V7h11Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgCopy;
