import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgChevronDoubleRight: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 13.41}
        height={height || 12}
        viewBox="0 0 13.41 12"
    >
        <g className="icon-fill" fill={color || '#b5b5c3'}>
            <path
                data-name="Path 4844"
                d="M1.41 0 0 1.41 4.58 6 0 10.59 1.41 12l6-6Z"
            />
            <path
                data-name="Path 4844"
                d="M7.41 0 6 1.41 10.58 6 6 10.59 7.41 12l6-6Z"
            />
        </g>
    </svg>
);

export default SvgChevronDoubleRight;
