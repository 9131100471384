import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexGrid } from '../../../components/FlexGrid';
import { VideoPlaylist2Icon, PlusIcon, MoreIcon } from '../../../assets/icons';
import { Text } from '../../../components/Text';
import { IconButton } from '../../../components/IconButton';
import { COLORS } from '../../../styles/variables';
import { ListItem } from '../../../components/ListItem';
import { Button } from '../../../components/Button';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { appHistory } from '../../../utils/history';
import { useParams } from 'react-router-dom';
import { useVideoPlaylistHook } from '../../VideoPlaylist/hooks/use-video-playlist-hook';

const ImageContainer = styled(FlexGrid)`
    width: 85px;
    height: 64px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    img {
        object-fit: cover;
        height: 100%;
    }
`;

const Length = styled(Text)`
    position: absolute;
    bottom: 0.2em;
    left: 0.2em;
    font-size: 0.75rem;
    background-color: ${COLORS.transparentBlack75};
    color: ${COLORS.white};
    padding: 0.3em;
    border-radius: 2px;
`;

const BorderBottom = styled(ListItem)`
    border-bottom: 1px solid ${COLORS.lightGray};
`;

const Title = styled(Text)`
    white-space: normal;
`;

interface SidebarProps {
    setPlaylists?: (playlists: any[]) => void;
    setVideos?: (videos: any[]) => void;
    setVideo?: (video) => void;
}

export const SideBar: React.FC<SidebarProps> = (props) => {
    const [activeVideo, setActiveVideo] = useState(0);
    // todo: use sys.id in state setting
    const [activePlaylist, setActivePlaylist] = useState(0);

    const [{ hookData: contentfulPlaylists }] = useVideoPlaylistHook({
        perPage: 10,
    });

    const videos = ContentfulTransformService.getPlaylistVideos(
        contentfulPlaylists[activePlaylist] || {},
        contentfulPlaylists
    );

    const playlists =
        ContentfulTransformService.getPlaylist(contentfulPlaylists);

    const params = useParams();
    const videoId = params.videoId;

    useEffect(() => {
        if (contentfulPlaylists.length) {
            const allVideos = contentfulPlaylists.map((pl) =>
                ContentfulTransformService.getPlaylistVideos(
                    pl,
                    contentfulPlaylists
                )
            );
            console.log('allVideos', contentfulPlaylists, allVideos);
            const vIndex = allVideos.findIndex((vs) => {
                const fVideo = vs.find((v) => v.id === videoId);
                props.setVideo(fVideo);
                return !!fVideo;
            });

            console.log('active playlist', videoId, vIndex);
            setActivePlaylist(vIndex);
        }

        const videoIndex = videos.findIndex((v) => v.id === videoId);
        if (videoIndex > -1) {
            setActiveVideo(videoIndex);
        }
    }, [videoId, contentfulPlaylists]);

    useEffect(() => {
        if (contentfulPlaylists.length) {
            props.setPlaylists(contentfulPlaylists);
        }
    }, [contentfulPlaylists]);

    return (
        <FlexGrid direction="column" flex="auto 0 300px" width="380px">
            <FlexGrid
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                p="0em 0em 1em"
            >
                <FlexGrid gap="1em" alignItems="center">
                    <VideoPlaylist2Icon height="14" />
                    <Text font="AR Bold">
                        {playlists[activePlaylist]?.title || ''}
                    </Text>
                </FlexGrid>
                <IconButton icon={<PlusIcon />} negative />
            </FlexGrid>
            <FlexGrid width="100%" direction="column">
                {videos.map((video, i) => (
                    <BorderBottom
                        active={activeVideo === i}
                        title={
                            <Title
                                className="list-title"
                                color={
                                    activeVideo === i ? COLORS.black : 'inherit'
                                }
                                font="AR Bold"
                            >
                                {video.title}
                            </Title>
                        }
                        icon={
                            <ImageContainer>
                                <img src={video.image} alt="video thumbnail" />
                                <Length>{video.length}</Length>
                            </ImageContainer>
                        }
                        color={COLORS.green}
                        onClick={() => {
                            setActiveVideo(i);
                            // href={`/video/${videos[i].sys.id}`}
                            props.setVideo && props.setVideo(video);
                            appHistory.push(`/video/${videos[i].id}`);
                        }}
                        bottom={
                            <FlexGrid
                                className="list-bottom"
                                gap="0.2em"
                                alignItems="center"
                            >
                                <Text
                                    fontSize="0.875rem"
                                    color={COLORS.darkGray}
                                >
                                    Views {video.views}
                                    <Text span color={COLORS.lightGray}>
                                        {'  '}•{'  '}
                                    </Text>
                                    {video.date}
                                </Text>
                            </FlexGrid>
                        }
                    />
                ))}
                <Button negative>
                    <Text color={COLORS.green}>Show more</Text>
                </Button>
            </FlexGrid>
            <FlexGrid direction="column" width="100%" p="2em 0em" gap="1em">
                <Text font="AR Bold" uppercase>
                    Other Playlist ({playlists.length})
                </Text>
                <FlexGrid direction="column" width="100%">
                    {playlists.map((playlist, index) => (
                        <ListItem
                            onClick={() => {
                                setActivePlaylist(index);
                            }}
                            icon={<VideoPlaylist2Icon height="14" />}
                            title={playlist.title}
                            color={COLORS.green}
                            bottom={
                                <Text
                                    fontSize="0.875rem"
                                    color={COLORS.darkGray}
                                >
                                    {playlist.total} videos
                                </Text>
                            }
                            right={<IconButton negative icon={MoreIcon} />}
                        />
                    ))}
                    <Button negative>
                        <Text color={COLORS.green}>Show more</Text>
                    </Button>
                </FlexGrid>
            </FlexGrid>
        </FlexGrid>
    );
};
