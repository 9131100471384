import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import { FlexGrid } from '../../components/FlexGrid';
import { PageHeader } from '../../components/PageHeader';
import { PageWrapper } from '../../components/PageWrapper';
import { SearchBar } from '../../components/SearchBar';
import { Text } from '../../components/Text';
import { RegistrationContestsTable } from './components/RegistrationContestsTable';
import { PlusIcon } from '../../assets/icons';
import { SelectCountry } from '../../components/SelectCountry';
import { getUrlKeepQuery } from '../../utils';

const NewScanningContestBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    font-size: 1rem;
`;

const PageContent = styled(FlexGrid)`
    max-width: 1200px;
    width: 100%;
    padding: 2em 0em;
    flex-direction: column;
`;

export const RegistrationContests: React.FC = () => {
    const navigate = useNavigate();
    return (
        <PageWrapper>
            <PageHeader
                back
                actions={
                    <FlexGrid gap="1.5em">
                        <SearchBar placeholder="Search banners, videos, articles here…" />
                        <SelectCountry />
                    </FlexGrid>
                }
            />
            <FlexGrid width="100%" justifyContent="center">
                <PageContent>
                    <FlexGrid
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text font="OS" fontSize="2.25rem" uppercase>
                            Registration Contest
                        </Text>
                        <NewScanningContestBtn
                            negative
                            title="New Registration Contest"
                            icon={<PlusIcon />}
                            iconSlot="start"
                            onClick={() =>
                                navigate(
                                    getUrlKeepQuery(
                                        '/scheme-offers/registration-contests/create'
                                    )
                                )
                            }
                        />
                    </FlexGrid>
                    <RegistrationContestsTable />
                </PageContent>
            </FlexGrid>
        </PageWrapper>
    );
};
