import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import { GLOBAL_CONFIG } from '../../../config/config';
import _ from 'lodash';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { PaginationType } from '../../../components/Pagination';
import { IPagination } from '../../../types';
import { ApiService } from '../../../services/Api.service';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const inAppNotifications = await ContentfulApiService.searchContent({
        contentType: 'inAppNotification',
        page: props.input.pagination.page,
        limit: props.input.pagination.perPage,
        sortBy: props.input.pagination.sortBy
    });

    // const { data: inAppNotifications } = await axiosRequest({
    //     url: `${GLOBAL_CONFIG.BASE_API_PATH}/spaces/${GLOBAL_CONFIG.CONTENTFUL_SPACE_ID}/environments/${GLOBAL_CONFIG.environment}/entries?content_type=inAppNotification&metadata.tags.sys.id[in]=uk&limit=5`,
    //     method: 'GET',
    // });

    inAppNotifications.items.map((d) => {
        ApiService.getViews(
            d.sys.id,
            d.sys.space.sys.id,
            d.sys.environment.sys.id,
            d.sys.contentType.sys.id
        ).then((res) => {
            d.views = res.views;
            d.uniqueViews = res.uniqueViews;
            props.updateData({ ...inAppNotifications });
        });
    });

    console.log('inAppNotifications contentful data', inAppNotifications);
    return inAppNotifications;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useInAppNotificationHook = (
    pagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialData: {},
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
