import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgFilter: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 22}
        height={height || 20}
        viewBox="0 0 22 20"
    >
        <path
            className="icon-stroke"
            d="M21 1H1l8 9.46V17l4 2v-8.54Z"
            fill="none"
            stroke={color || '#333'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        />
    </svg>
);

export default SvgFilter;
