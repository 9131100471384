import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import 'moment-timezone';
import { FlexGrid } from '../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../components/Table';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { ArrowDownIcon, PlusIcon } from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/Modal';
import { IconButton } from '../../../components/IconButton';
import { useBrandSKUListHook } from '../hooks/use-brand-sku-list-hook';
import { Pagination } from '../../../components/Pagination';
import { sortFunc } from '../../../common';
import { ProductModal } from './ProductModal';
import { ApiService } from '../../../services/Api.service';
import { Input } from '../../../components/Input';
import { LIMIT_DEFAULT_TEXT_LENGTH } from '../../../common/constants';

const NewProductBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    font-size: 1rem;
`;

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

export const BrandSKUListsTable: React.FC = () => {
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const perPage = 5;
    const DEFAULT_SORT = {
        productName: 0,
        skuCode: 0,
        brandName: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);

    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);

    const [{ isLoading, hookData: productsData }] = useBrandSKUListHook(
        {
            // page,
            // perPage,
            // sortBy: `${sort.date ? '' : '-'}fields.brandName`,
        },
        [update]
    );

    console.log('==productsData==', productsData);
    console.log('==current==', current);

    const [createEditModal, setCreateEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [createEditSuccess, setCreateEditSuccess] = useState(null);
    const [createEditProductDisabled, setCreateEditProductDisabled] =
        useState(false);

    const [productNameSearchTxt, setProductNameSearchTxt] = useState('');
    const [skuCodeSearchTxt, setSKUCodeSearchTxt] = useState('');
    const [brandNameSearchTxt, setBrandNameSearchTxt] = useState('');
    const [errorMsg, setErrorMsg] = useState(undefined);
    // const [products, setProducts] = useState([]);

    // useEffect(() => {

    // }, [productNameSearchTxt, skuCodeSearchTxt, brandNameSearchTxt]);

    const filterData = (
        productsDataArr,
        productNameFilter,
        skuCodeFilter,
        brandNameFilter
    ) => {
        return productsDataArr.filter((product) => {
            return (
                (!productNameFilter ||
                    productNameFilter.trim() === '' ||
                    product.productName.includes(productNameFilter)) &&
                (!skuCodeFilter ||
                    skuCodeFilter.trim() === '' ||
                    product.skuCode.includes(skuCodeFilter)) &&
                (!brandNameFilter ||
                    brandNameFilter.trim() === '' ||
                    product.brandName.includes(brandNameFilter))
            );
        });
    };

    let products = sortFunc(sort, filterData(productsData.data ?? [], productNameSearchTxt, skuCodeSearchTxt, brandNameSearchTxt));

    const total = products.length;
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    products = products.slice(startIndex, endIndex);

    return (
        <>
            {!isLoading && (
                <>
                    <FlexGrid
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text font="OS" fontSize="2.25rem" uppercase>
                            Brand & SKU List
                        </Text>
                        <NewProductBtn
                            negative
                            title="New Product"
                            icon={<PlusIcon />}
                            iconSlot="start"
                            onClick={() => {
                                setCurrent(null);
                                setCreateEditModal(true);
                            }}
                        />
                    </FlexGrid>
                    <ProductModal
                        title={current ? 'Edit a product' : 'Add new product'}
                        onClose={() => {
                            if (createEditSuccess) {
                                setUpdate(!update);
                            }
                            setCreateEditModal(false);
                        }}
                        open={createEditModal}
                        success={createEditSuccess}
                        setSuccess={setCreateEditSuccess}
                        errorMsg={errorMsg}
                        setErrorMsg={setErrorMsg}
                        successContentText={
                            current
                                ? 'The product has been updated successfully.'
                                : 'New product has been added successfully.'
                        }
                        disabled={createEditProductDisabled}
                        params={
                            current
                                ? {
                                      brandName: current.brandName,
                                      skuCode: current.skuCode,
                                      productName: current.productName,
                                  }
                                : undefined
                        }
                        onConfirm={({ brandName, skuCode, productName }) => {
                            setCreateEditProductDisabled(true);
                            if (current) {
                                // updateScanningContestProduct
                                ApiService.updateScanningContestProduct(
                                    current.id,
                                    {
                                        productName,
                                        skuCode,
                                        brandName,
                                    }
                                )
                                    .then(() => {
                                        setCreateEditProductDisabled(false);
                                        setCreateEditSuccess(true);
                                    })
                                    .catch((err) => {
                                        if (err.response?.status === 400 || err.response?.data?.error) {
                                            const message = err.response?.data?.message;
                                            if (message === 'api.product_sku_already_exists') {
                                                setErrorMsg("The SKU Code already exists.");        
                                            } else {
                                                setErrorMsg(message);
                                            }
                                        } else {
                                            setErrorMsg(err.message ?? 'Unknown internal server error.');
                                        }
                                    })
                                    .finally(() => {
                                        setCreateEditProductDisabled(false);
                                    });
                            } else {
                                ApiService.addScanningContestProduct({
                                    productName,
                                    skuCode,
                                    brandName,
                                })
                                    .then(() => {
                                        setCreateEditProductDisabled(false);
                                        setCreateEditSuccess(true);
                                    })
                                    .catch((err) => {
                                        if (err.response?.status === 400 || err.response?.data?.error) {
                                            const message = err.response?.data?.message;
                                            if (message === 'api.product_sku_already_exists') {
                                                setErrorMsg("The SKU code already exists.");        
                                            } else {
                                                setErrorMsg(message);
                                            }
                                        } else {
                                            setErrorMsg(err.message ?? 'Unknown internal server error.');
                                        }
                                    })
                                    .finally(() => {
                                        setCreateEditProductDisabled(false);
                                    });
                            }
                        }}
                    />
                    <Modal
                        open={deleteModal}
                        header="Delete a product"
                        onClose={() => setDeleteModal(false)}
                        content={
                            <FlexGrid
                                direction="column"
                                width="100%"
                                gap="2em"
                                p="2em 0em"
                            >
                                <Text color={COLORS.darkGray}>
                                    Are you sure you want to delete this
                                    product?
                                </Text>
                                <Text color={COLORS.darkGray}>
                                    <Text
                                        color={COLORS.darkGray}
                                        span
                                        font="AR Bold"
                                    >
                                        Note:{' '}
                                    </Text>
                                    Deleting a product is a permanent action and
                                    cannot be undone.
                                </Text>
                            </FlexGrid>
                        }
                        actions={
                            <FlexGrid alignItems="center">
                                <Button
                                    onClick={() => {
                                        ApiService.deleteScanningContestProduct(
                                            current.id
                                        )
                                            .then(() => {
                                                setUpdate(!update);
                                                setCurrent(null);
                                                if (total % perPage === 1) {
                                                    setPage(
                                                        Math.max(page - 1, 1)
                                                    );
                                                }
                                            })
                                            .catch(() => {
                                                alert(
                                                    'Cannot delete published'
                                                );
                                            });
                                        setDeleteModal(false);
                                    }}
                                    p="1.3em 2.5em"
                                >
                                    DELETE
                                </Button>
                                <Button
                                    onClick={() => setDeleteModal(false)}
                                    negative
                                    p="0.5em 1em"
                                >
                                    CANCEL
                                </Button>
                            </FlexGrid>
                        }
                    />
                    <Table>
                        <TableRow>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Product Name
                                    {sort.productName === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    productName: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.productName === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    productName: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.productName === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    productName: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    SKU Code
                                    {sort.skuCode === 0 && (
                                        <IconButton
                                            negative
                                            transform="rotate(-90deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    skuCode: 1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.skuCode === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    skuCode: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.skuCode === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    skuCode: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Brand Name
                                    {sort.brandName === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    brandName: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.brandName === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    brandName: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.brandName === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    brandName: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Input
                                    maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                                    placeholder="Search by product name"
                                    value={productNameSearchTxt}
                                    onChange={(val) => setProductNameSearchTxt(val)}
                                />
                            </TableCell>
                            <TableCell>
                                <Input
                                    maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                                    placeholder="Search by SKU Code"
                                    value={skuCodeSearchTxt}
                                    onChange={(val) => setSKUCodeSearchTxt(val)}
                                />
                            </TableCell>
                            <TableCell>
                                <Input
                                    maxLength={LIMIT_DEFAULT_TEXT_LENGTH}
                                    placeholder="Search by brand name"
                                    value={brandNameSearchTxt}
                                    onChange={(val) => setBrandNameSearchTxt(val)}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {products.map((product, key) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        <Text
                                            link
                                            fontSize="0.875rem"
                                            color={COLORS.green}
                                        >
                                            {product?.productName}
                                        </Text>
                                    </TableCell>
                                    <TableCell>{product?.skuCode}</TableCell>
                                    <TableCell>{product?.brandName}</TableCell>
                                    <TableCell>
                                        <FlexGrid alignItems="center">
                                            <TableActionBtn
                                                onClick={() => {
                                                    setCurrent(product);
                                                    setCreateEditModal(true);
                                                }}
                                                negative
                                            >
                                                Edit
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={() => {
                                                    setCurrent(product);
                                                    setDeleteModal(true);
                                                }}
                                                negative
                                            >
                                                Remove
                                            </TableActionBtn>
                                        </FlexGrid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </Table>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        total={total}
                        setPage={(v) => {
                            setPage(v);
                        }}
                    />
                </>
            )}
        </>
    );
};
