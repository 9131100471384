import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgTargetScans: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || '26'}
        height={height || '26'}
        viewBox="0 0 26 26"
    >
        <g
            id="Group_15868"
            data-name="Group 15868"
            transform="translate(-865 -489)"
        >
            <path
                id="Path_384"
                data-name="Path 384"
                d="M14.657,28.848a12.451,12.451,0,0,0,5.018-1.024,13.328,13.328,0,0,0,6.958-6.958,12.866,12.866,0,0,0-.007-10.043A13.4,13.4,0,0,0,23.81,6.668a13.077,13.077,0,0,0-4.148-2.81,12.593,12.593,0,0,0-5.018-1.01,12.545,12.545,0,0,0-5.011,1.01,13.29,13.29,0,0,0-6.965,6.965,12.866,12.866,0,0,0,.007,10.043,13.39,13.39,0,0,0,6.958,6.958A12.437,12.437,0,0,0,14.657,28.848Z"
                transform="translate(863.35 486.152)"
                fill={color || '#009343'}
            />
            <path
                id="Path_808"
                data-name="Path 808"
                d="M30.43,3.073l2.3-2.3h0A2.707,2.707,0,0,1,34.938.018a7.138,7.138,0,0,1,3.016,1.053,16.869,16.869,0,0,1,3.413,2.712A16.869,16.869,0,0,1,44.08,7.2a7.139,7.139,0,0,1,1.051,3.016,2.707,2.707,0,0,1-.757,2.209h0l-2.3,2.3A3.972,3.972,0,0,1,39.4,16a5.869,5.869,0,0,1-3.126-.883,16.762,16.762,0,0,1-3.475-2.758h0A16.758,16.758,0,0,1,30.036,8.88a5.866,5.866,0,0,1-.883-3.125A3.97,3.97,0,0,1,30.43,3.073M43.351,11.4a1.627,1.627,0,0,0,.34-1.539,6.653,6.653,0,0,0-1.043-2.314A17.544,17.544,0,0,0,40.354,4.8,17.533,17.533,0,0,0,37.608,2.5,6.657,6.657,0,0,0,35.293,1.46a1.629,1.629,0,0,0-1.541.34,1.63,1.63,0,0,0-.35,1.549,6.707,6.707,0,0,0,1.046,2.312,17.54,17.54,0,0,0,2.294,2.746h0A17.546,17.546,0,0,0,39.489,10.7,6.708,6.708,0,0,0,41.8,11.746a1.629,1.629,0,0,0,1.549-.35M31.492,8.527a17,17,0,0,0,2.327,2.8,17.058,17.058,0,0,0,2.807,2.33,4.952,4.952,0,0,0,2.427.883,2.618,2.618,0,0,0,2-.849l.576-.576a6.324,6.324,0,0,1-1.213-.354l-.827.827a.531.531,0,0,1-.383.16.51.51,0,0,1-.383-.154.553.553,0,0,1,0-.772l.555-.554c-.081-.044-.161-.086-.242-.133q-.47-.284-.921-.6l-.718.718a.546.546,0,0,1-.931-.383.521.521,0,0,1,.16-.389l.6-.6q-.6-.479-1.158-1.013l-.677.677a.518.518,0,0,1-.38.157.551.551,0,0,1-.545-.545.511.511,0,0,1,.154-.384h0L35.4,9.1q-.538-.55-1.022-1.148l-.593.593a.527.527,0,0,1-.386.163.517.517,0,0,1-.38-.157.539.539,0,0,1,0-.772h0l.7-.7c-.247-.348-.483-.7-.7-1.069-.015-.026-.028-.051-.042-.077l-.518.518a.526.526,0,0,1-.386.157.53.53,0,0,1-.539-.54.532.532,0,0,1,.159-.383h0l.775-.775a6.542,6.542,0,0,1-.428-1.394l-.577.577a2.609,2.609,0,0,0-.846,2,4.977,4.977,0,0,0,.883,2.426"
                transform="translate(840.801 493.949)"
                fill="#fff"
            />
        </g>
    </svg>
);

export default SvgTargetScans;
