/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion } from '../../../components/Accordion';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { PageHeader } from '../../../components/PageHeader';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import {
    DateIcon,
    TimeIcon,
    ImageIcon,
    BonusPointsIcon,
    PointsIcon,
    QuizCorrectOptionIcon,
} from '../../../assets/icons';
import { Tag } from '../../../components/Tag';
import { Modal } from '../../../components/Modal';
import { ArticleType, LookupValue } from '../../../types';
import { notificationIcons } from '../../Videos/components/SendNotification';
import { SuccessModal } from '../../../components/SuccessModal';
import { HorizontalLine } from '../../../components/HorizontalLine';
// import { CreateArticle } from './CreateArticle';
// import { ContentfulApiService } from '../../../services/ContentfulApi.service';
// import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { getUrlKeepQuery } from '../../../utils';
import { CreationService } from '../../../services/Creation.service';
import { DropDownPopulate } from '../../../services/Api.service';
import { SectionHeader } from '../../../components/SectionHeader';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../store/store.actions';
// import { lang } from 'moment';

const EditBtn = styled(Button)`
    font-size: 15px;
    font-family: 'AR';
`;

const Label = styled(Text)`
    color: ${COLORS.darkGray};
    font-size: 0.75rem;
`;

const Value = styled(FlexGrid)`
    width: 50%;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em 2em;
    min-width: 0;
`;

const Form = styled(FlexGrid)`
    max-width: 700px;
    margin-top: 0.5em;
`;

const ImageContainer = styled(FlexGrid)`
    width: 100%;
    max-height: 60vh;
    overflow: hidden;
`;

const PreviewImg = styled.img`
    width: 100%;
    object-fit: cover;
`;

const ModalContent = styled(FlexGrid)`
    gap: 1em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    mode?: 'Create' | 'Edit' | 'View';
    stepNavigator: any;
    articleState: [
        ArticleType,
        React.Dispatch<React.SetStateAction<ArticleType>>
    ];
    dropdownPopulate?: DropDownPopulate;
}

export const Review: React.FC<Props> = ({
    stepNavigator,
    articleState,
    mode,
    dropdownPopulate,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [submitModalOpen, setSubmitModalOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [article] = articleState;
    const {
        identifierDefinition,
        content,
        targetGeography,
        targetUser,
        sendNotification,
        quiz,
    } = article;

    const location = useLocation() as any;

    const [sectorMapping, setSectorMapping] = useState(
        new Map<string, LookupValue>()
    );
    const [subSectorsMapping, setSubSectorsMapping] = useState(
        new Map<string, LookupValue>()
    );

    const isInAppNotification = sendNotification.notificationType.includes(
        'In-App Notifications'
    );

    const isPushNotification =
        sendNotification.notificationType.includes('Push notifications');

    useEffect(() => {
        if (dropdownPopulate.sectors && dropdownPopulate.subSectors) {
            setSectorMapping(
                new Map(dropdownPopulate.sectors.map((elem) => [elem.id, elem]))
            );
            setSubSectorsMapping(
                new Map(
                    // @ts-ignore
                    Object.keys(dropdownPopulate.subSectors)
                        .map((sectorId) => {
                            return dropdownPopulate.subSectors[sectorId].map(
                                (elem) => [elem.id, elem]
                            );
                        })
                        .reduce((pre, cur) => pre.concat(cur), [])
                )
            );
        }
    }, [dropdownPopulate]);

    const createArticle = () => {
        setDisableSubmit(true);
        const isUpdate = mode === 'Edit';
        CreationService.createUpdateArticle(isUpdate, true, article)
            .then((result) => {
                setDisableSubmit(false);
                article.raw = result;
                setSubmitModalOpen(true);
            })
            .catch((e) => {
                console.error('===Failed to create update article===', e);
                setDisableSubmit(false);
                dispatch(
                    updateModalAction({
                        open: true,
                        title: 'error',
                        content: `Failed to ${isUpdate ? 'update' : 'create'} article. ${e}. ${e?.config?.url ? `url: ${e?.config?.url}` : ''}`,
                    })
                );
            });
    };

    return (
        <FlexGrid width="100%" direction="column">
            {content.image && (
                <Modal
                    header="Image Preview"
                    open={previewModalOpen}
                    onClose={() => setPreviewModalOpen(false)}
                    actions={
                        <FlexGrid width="100%" direction="column" gap="1em">
                            <HorizontalLine
                                height="1px"
                                backgroundColor={COLORS.lightGray}
                                m="0em"
                            />
                            <Button
                                onClick={() => setPreviewModalOpen(false)}
                                p="1.3em 3em"
                            >
                                Done
                            </Button>
                        </FlexGrid>
                    }
                    content={
                        <ModalContent
                            width="700px"
                            direction="column"
                            p="1em 0em"
                        >
                            <ImageContainer>
                                <PreviewImg
                                    src={content.image.preview}
                                    alt="preview"
                                />
                            </ImageContainer>
                            <FlexGrid gap="0.5em" p="0.5em 0em">
                                <ImageIcon />
                                <FlexGrid direction="column">
                                    <Text color={COLORS.green}>
                                        {content.image.name}
                                    </Text>
                                    <Text color={COLORS.darkGray}>
                                        {(content.image.size / 1e3).toFixed(2)}{' '}
                                        kb
                                    </Text>
                                </FlexGrid>
                            </FlexGrid>
                        </ModalContent>
                    }
                />
            )}

            <SuccessModal
                header={mode === 'Edit' ? 'Edit Article' : 'Create New Article'}
                open={submitModalOpen}
                onClose={() => {
                    setSubmitModalOpen(false);
                    navigate(getUrlKeepQuery('/communication/articles'), {
                        state: { page: location.state?.page },
                    });
                }}
                body={
                    mode === 'Edit'
                        ? 'Article has been updated successfully.'
                        : 'New article have been submitted successfully.'
                }
            />
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Review
                    </Text>
                }
            />
            <Form direction="column" p="0.5em" width="100%">
                <Accordion
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Identifier Definition
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(0);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>Article Name</Label>
                                <Text>{identifierDefinition.name}</Text>
                            </Value>
                            <Value>
                                <Label>App Language</Label>
                                <Text>
                                    {identifierDefinition.appLanguages.join(
                                        ', '
                                    )}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Start Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>
                                        {identifierDefinition.startDate}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>Start Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>
                                        {identifierDefinition.startTime}
                                    </Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Date</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <DateIcon />
                                    <Text>{identifierDefinition.endDate}</Text>
                                </FlexGrid>
                            </Value>
                            <Value>
                                <Label>End Time</Label>
                                <FlexGrid
                                    alignItems="center"
                                    width="100%"
                                    gap="0.5em"
                                >
                                    <TimeIcon />
                                    <Text>{identifierDefinition.endTime}</Text>
                                </FlexGrid>
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Content
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(1);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <>
                            <FlexGrid width="100%" wrap="wrap">
                                <Value>
                                    <Label>Image</Label>
                                    {content.image && (
                                        <>
                                            <Text color={COLORS.green}>
                                                {content.image.name}{' '}
                                                <Text
                                                    onClick={() =>
                                                        setPreviewModalOpen(
                                                            true
                                                        )
                                                    }
                                                    underline
                                                    link
                                                >
                                                    View
                                                </Text>
                                            </Text>
                                            <Label>
                                                {(
                                                    content.image.size / 1e3
                                                ).toFixed(2)}{' '}
                                                kb
                                            </Label>
                                        </>
                                    )}
                                </Value>
                                <Value>
                                    <Label>Image Tags</Label>
                                    <FlexGrid gap="0.5em" wrap="wrap">
                                        {content.tags.map((tag, index) => (
                                            <Tag
                                                key={`articleTags-${index}`}
                                                title={tag}
                                            />
                                        ))}
                                    </FlexGrid>
                                </Value>
                                <Value>
                                    <Label>Featured</Label>
                                    <Text>
                                        {content.isFeatured ? 'Yes' : 'No'}
                                    </Text>
                                </Value>
                            </FlexGrid>
                            {identifierDefinition.appLanguages.map(
                                (language) => (
                                    <>
                                        <div
                                            style={{
                                                width: '100%',
                                                borderBottom: `1px solid ${COLORS.gray}`,
                                            }}
                                        >
                                            <Text
                                                key={`video-text-${language}`}
                                                m="0.5em 0 0 1.1em"
                                                font="OS"
                                                fontSize="1.1em"
                                                fontWeight="10"
                                            >
                                                {language}
                                            </Text>
                                        </div>
                                        <FlexGrid
                                            key={`article-flexgrid1-${language}`}
                                            width="100%"
                                            wrap="wrap"
                                        >
                                            <Value>
                                                <Label>Headline</Label>
                                                <Text>
                                                    {
                                                        content.headline[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Description</Label>
                                                <Text>
                                                    {
                                                        content.description[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Text to Display</Label>
                                                <Text>
                                                    {
                                                        content.actionText[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Link Source</Label>
                                                <Text color={COLORS.green}>
                                                    {
                                                        content.linkSource[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>
                                        </FlexGrid>
                                    </>
                                )
                            )}
                        </>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target Geography
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(2);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                {targetGeography.regions.map(
                                    (region, index) => (
                                        <Text
                                            key={`articleTargetGeography-${index}`}
                                        >
                                            {region}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                {targetGeography.states.map((state, index) => (
                                    <Text key={`articleTargetState-${index}`}>
                                        {state}
                                    </Text>
                                ))}
                            </Value>
                            <Value>
                                <Label>
                                    Distributors (
                                    {targetGeography.distributors.length})
                                </Label>
                                {targetGeography.distributors.map(
                                    (distributor, index) => (
                                        <Text
                                            key={`articleDistributors-${index}`}
                                        >
                                            {distributor}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>District</Label>
                                {targetGeography.districts.map(
                                    (district, index) => (
                                        <Text key={`articleDistricts-${index}`}>
                                            {district}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Pincode</Label>
                                <Text>{targetGeography.pincode}</Text>
                            </Value>
                            <Value>
                                <Label>RM Area</Label>
                                {targetGeography.rmAreas.map(
                                    (rmArea, index) => (
                                        <Text key={`articleRmAreas-${index}`}>
                                            {rmArea}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Target User
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(3);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={
                        <FlexGrid width="100%" wrap="wrap">
                            <Value>
                                <Label>User Type</Label>
                                <Text>{targetUser.userTypes.join(', ')}</Text>
                            </Value>
                            {targetUser.userTypes.includes('Mechanics') && (
                                <>
                                    <Value>
                                        <Label>Space of Mechanic</Label>
                                        <Text>
                                            {targetUser.spaceOfMechanics.join(
                                                ', '
                                            )}
                                        </Text>
                                    </Value>
                                    {/* <Value>
                                        <Label>Connekt</Label>
                                        <Text>
                                            {targetUser.connekt ? 'Yes' : 'No'}
                                        </Text>
                                    </Value> */}
                                </>
                            )}
                            {/* <Value>
                                <Label>Upload Mobile</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.mobile}
                                </Text>
                            </Value>
                            <Value>
                                <Label>Upload PID</Label>
                                <Text color={COLORS.green}>
                                    {targetUser.PID}
                                </Text>
                            </Value> */}
                            <Value>
                                <Label>Dealer/WS Segment</Label>
                                {targetUser.dealerSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`articleDealerSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Dealer/WS Sub Segment (
                                    {targetUser.dealerSubSegments.length})
                                </Label>
                                {targetUser.dealerSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`articleDealerSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>Mechanics Segment</Label>
                                {targetUser.mechanicsSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`articleMechanicsSegments-${index}`}
                                        >
                                            {sectorMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                            <Value>
                                <Label>
                                    Mechanics Sub Segment (
                                    {targetUser.mechanicsSubSegments.length})
                                </Label>
                                {targetUser.mechanicsSubSegments.map(
                                    (segmentId, index) => (
                                        <Text
                                            key={`articleMechanicsSubSegments-${index}`}
                                        >
                                            {subSectorsMapping.get(segmentId)
                                                ?.name ?? segmentId}
                                        </Text>
                                    )
                                )}
                            </Value>
                        </FlexGrid>
                    }
                />
                {!!quiz && !quiz.markDeleted && (
                    <Accordion
                        defaultOpen={false}
                        info={{
                            title: 'Show or Hide Details',
                            content:
                                'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                        }}
                        title={
                            <FlexGrid
                                flex="1"
                                justifyContent="space-between"
                                alignItems="center"
                                m="0 0.5em 0 0"
                            >
                                <Text font="OS Bold" uppercase>
                                    Add Quiz
                                </Text>
                                {mode !== 'View' && (
                                    <EditBtn
                                        m="0"
                                        p="0"
                                        negative
                                        onClick={() => {
                                            stepNavigator(4);
                                        }}
                                    >
                                        Edit
                                    </EditBtn>
                                )}
                            </FlexGrid>
                        }
                        content={identifierDefinition.appLanguages.map(
                            (language) => (
                                <>
                                    <div
                                        style={{
                                            width: '100%',
                                            borderBottom: `1px solid ${COLORS.gray}`,
                                        }}
                                    >
                                        <Text
                                            key={`video-text-${language}`}
                                            m="0.5em 0 0 1.1em"
                                            font="OS"
                                            fontSize="1.1em"
                                            fontWeight="10"
                                        >
                                            {language}
                                        </Text>
                                    </div>
                                    <FlexGrid
                                        key={`inapp-noti-flexgrid-${language}`}
                                        width="100%"
                                        wrap="wrap"
                                        direction="column"
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Value>
                                                <Label>Title</Label>
                                                <Text>
                                                    {quiz.content.title[language] ||
                                                        quiz.content.title['English']}
                                                </Text>
                                            </Value>
                                            <Value>
                                                <Label>Bonus Points</Label>
                                                <FlexGrid
                                                    alignItems="center"
                                                    width="100%"
                                                    gap="0.5em"
                                                >
                                                    <BonusPointsIcon />
                                                    <Text>
                                                        {quiz.content.bonusPoints || 0}
                                                    </Text>
                                                </FlexGrid>
                                            </Value>
                                        </div>
                                        <Value>
                                            <Label>Description</Label>
                                            <Text>
                                                {content.description[
                                                    language
                                                ] ||
                                                    content.description[
                                                        'English'
                                                    ]}
                                            </Text>
                                        </Value>
                                        {quiz.content.questions.map(
                                            (question, index) => (
                                                <Accordion
                                                    key={`quiz-question-${index}`}
                                                    defaultOpen={false}
                                                    info={{
                                                        title: 'Show or Hide Details',
                                                        content:
                                                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                                                    }}
                                                    title={
                                                        <SectionHeader
                                                            title={`QUESTION #${
                                                                index + 1
                                                            }`}
                                                            bg="#F5F5F5"
                                                            fontFamily="OS"
                                                            height="40px"
                                                            titleMargin="0 0 0 1em"
                                                        />
                                                    }
                                                    content={
                                                        <>
                                                            <Value>
                                                                <Label>
                                                                    Question
                                                                </Label>
                                                                <Text>
                                                                    {question
                                                                        .content
                                                                        .questionText[
                                                                        language
                                                                    ] ||
                                                                        question
                                                                            .content
                                                                            .questionText[
                                                                            'English'
                                                                        ]}
                                                                </Text>
                                                            </Value>
                                                            <Value>
                                                                <Label>
                                                                    Points
                                                                </Label>
                                                                <FlexGrid
                                                                    alignItems="center"
                                                                    width="100%"
                                                                    gap="0.5em"
                                                                >
                                                                    <PointsIcon />
                                                                    <Text>
                                                                        {question
                                                                            .content
                                                                            .points ||
                                                                            0}
                                                                    </Text>
                                                                </FlexGrid>
                                                            </Value>
                                                            {question.content.options.map(
                                                                (
                                                                    option,
                                                                    index
                                                                ) => (
                                                                    <Value>
                                                                        <div
                                                                            style={{
                                                                                width: '100%',
                                                                                display:
                                                                                    'flex',
                                                                                flexDirection:
                                                                                    'row',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            <Label>
                                                                                {`Answer ${
                                                                                    index +
                                                                                    1
                                                                                }`}
                                                                            </Label>
                                                                            {question
                                                                                .content
                                                                                .correctAnswerIndex ===
                                                                                index && (
                                                                                <QuizCorrectOptionIcon width="20" />
                                                                            )}
                                                                        </div>
                                                                        <Text>
                                                                            {option[
                                                                                language
                                                                            ] ||
                                                                                option[
                                                                                    'English'
                                                                                ]}
                                                                        </Text>
                                                                    </Value>
                                                                )
                                                            )}
                                                            <Value>
                                                                <Label>
                                                                    Reason
                                                                </Label>
                                                                <Text>
                                                                    {question
                                                                        .content
                                                                        .reason[
                                                                        language
                                                                    ] ||
                                                                        question
                                                                            .content
                                                                            .reason[
                                                                            'English'
                                                                        ]}
                                                                </Text>
                                                            </Value>
                                                        </>
                                                    }
                                                />
                                            )
                                        )}
                                    </FlexGrid>
                                </>
                            )
                        )}
                    />
                )}
                <Accordion
                    defaultOpen={false}
                    info={{
                        title: 'Show or Hide Details',
                        content:
                            'Click to show or hide in each section. And you can edit each section by clicking edit. Submit if all data is correct.',
                    }}
                    title={
                        <FlexGrid
                            flex="1"
                            justifyContent="space-between"
                            alignItems="center"
                            m="0 0.5em 0 0"
                        >
                            <Text font="OS Bold" uppercase>
                                Send Notification
                            </Text>
                            {mode !== 'View' && (
                                <EditBtn
                                    m="0"
                                    p="0"
                                    negative
                                    onClick={() => {
                                        stepNavigator(5);
                                    }}
                                >
                                    Edit
                                </EditBtn>
                            )}
                        </FlexGrid>
                    }
                    content={identifierDefinition.appLanguages.map(
                        (language) => (
                            <>
                                <Text
                                    key={`article-text-${language}`}
                                    m="0.5em 0 0 1.6em"
                                >
                                    {language}
                                </Text>
                                <FlexGrid
                                    key={`article-flexgrid-${language}`}
                                    width="100%"
                                    wrap="wrap"
                                    direction="column"
                                >
                                    {isInAppNotification && (
                                        <>
                                            <Value>
                                                <Label>
                                                    Send notification to
                                                </Label>
                                                <Text>In App Notification</Text>
                                            </Value>
                                            <Value>
                                                <Label>Icon</Label>
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.5em"
                                                >
                                                    {
                                                        notificationIcons[
                                                            sendNotification
                                                                .icon
                                                        ]
                                                    }
                                                    {sendNotification.icon}
                                                </FlexGrid>
                                            </Value>

                                            <Value>
                                                <Label>Body Text</Label>
                                                <Text>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: sendNotification
                                                                .inAppNotificationContent
                                                                .bodyText[
                                                                language ||
                                                                    'English'
                                                            ],
                                                        }}
                                                    />
                                                </Text>
                                            </Value>
                                        </>
                                    )}
                                    {isPushNotification && (
                                        <>
                                            <Value>
                                                <Label>
                                                    Send notification to
                                                </Label>
                                                <Text>Push Notification</Text>
                                            </Value>

                                            <Value>
                                                <Label>Preview Text</Label>
                                                <Text>
                                                    {
                                                        sendNotification
                                                            .pushNotificationContent
                                                            .headline[
                                                            language ||
                                                                'English'
                                                        ]
                                                    }
                                                </Text>
                                            </Value>

                                            <Value>
                                                <Label>Notification Text</Label>
                                                <Text>
                                                    <div
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: sendNotification
                                                                .inAppNotificationContent
                                                                .bodyText[
                                                                language ||
                                                                    'English'
                                                            ],
                                                        }}
                                                    />
                                                </Text>
                                            </Value>
                                        </>
                                    )}
                                </FlexGrid>
                            </>
                        )
                    )}
                />

                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() => {
                            if (mode === 'View') {
                                navigate(-1);
                            } else {
                                stepNavigator((prev: number) => prev - 1);
                            }
                        }}
                    />
                    {mode !== 'View' && (
                        <Button
                            onClick={() => createArticle()}
                            p="1em 2em"
                            title="Submit"
                            disabled={disableSubmit}
                        />
                    )}
                </Action>
            </Form>
        </FlexGrid>
    );
};
