import React from 'react';
import { COLORS } from '../styles/variables';
import { CheckBox } from './CheckBox';
import { FlexGrid } from './FlexGrid';
import { Info } from './Info';
import { Text } from './Text';

interface CheckBoxGroupProps {
    options?: { label: string; value: string }[];
    label: string;
    info?: { title: any; content: any };
    values: string[];
    // eslint-disable-next-line no-unused-vars
    onChange: (value: string[]) => void;
    direction?: 'column' | 'row';
}

export const CheckBoxGroup: React.FC<CheckBoxGroupProps> = ({
    label,
    options,
    info,
    values,
    onChange,
    direction,
}) => {
    const toggleCheck = (value: string) => {
        if (values.includes(value)) {
            onChange(values.filter((el) => el !== value));
        } else {
            onChange([...values, value]);
        }
    };

    return (
        <FlexGrid
            gap="0.8em"
            width="100%"
            m="1em 0em"
            position="relative"
            direction="column"
        >
            <FlexGrid alignItems="center" gap="0.3em">
                <Text color={COLORS.darkGray} fontSize="0.75rem">
                    {label}
                </Text>
                {info && <Info info={info} />}
            </FlexGrid>
            <FlexGrid direction={direction} width="100%" gap="1.5em">
                {options &&
                    options.map((option, key) => (
                        <CheckBox
                            key={key}
                            labelColor={COLORS.black}
                            checked={values.includes(option.value)}
                            label={option.label}
                            onChange={() => toggleCheck(option.value)}
                        />
                    ))}
            </FlexGrid>
        </FlexGrid>
    );
};
