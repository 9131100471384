import { axiosRequest, contentfulApiRequest } from '../../../utils/http-client';
import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import { GLOBAL_CONFIG } from '../../../config/config';
import { ApiService } from '../../../services/Api.service';
import { DEFAULT_LANGUAGE_CODE } from '../../../common/constants';
import { getMapValue } from '../../../utils';
import _ from 'lodash';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';

type Input = string;
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const articleId = props.input;

    const data = await ContentfulApiService.getEntry({ entryId: articleId });

    const assetId = _.get(data.fields.articleImage[DEFAULT_LANGUAGE_CODE], 'sys.id');

    const assets = await ContentfulApiService.getAssets({
        'sys.id[in]': assetId,
    });

    data.assets = assets;

    ApiService.getViews(
        articleId,
        data.sys.space.sys.id,
        data.sys.environment.sys.id,
        data.sys.contentType.sys.id
    ).then((res) => {
        props.updateData({ ...data, views: res.views, uniqueViews: res.uniqueViews });
    });

    data.comments = [];

    ApiService.getComments(
        articleId,
        data.sys.space.sys.id,
        data.sys.environment.sys.id,
        data.sys.contentType.sys.id
    ).then((res) => {
        data.comments = res;
        props.updateData({ ...data });
    });

    return data;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useArticleDetailHook = (
    articleId: string,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: articleId }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
