import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgDownload: React.FC<Props> = ({ width, height, color }) => (
    <svg
        data-name="Component 225 \u2013 4"
        xmlns="http://www.w3.org/2000/svg"
        width={width || 30}
        height={height || 30}
        viewBox="0 0 30 30"
    >
        <path data-name="Rectangle 429" fill="none" d="M0 0h30v30H0z" />
        <path
            className="icon-fill"
            data-name="Path 390"
            d="M15.007 28a12.451 12.451 0 0 0 5.018-1.024 13.328 13.328 0 0 0 6.958-6.958 12.866 12.866 0 0 0-.007-10.043A13.4 13.4 0 0 0 24.16 5.82a13.077 13.077 0 0 0-4.148-2.81A12.593 12.593 0 0 0 14.994 2a12.545 12.545 0 0 0-5.011 1.01 13.29 13.29 0 0 0-6.965 6.965 12.866 12.866 0 0 0 .007 10.043 13.39 13.39 0 0 0 6.958 6.958A12.437 12.437 0 0 0 15.007 28Zm.013-19.711a1.059 1.059 0 0 1 .783.3 1.042 1.042 0 0 1 .3.769v6.329l-.12 2.757 1.271-1.539 1.486-1.553a.992.992 0 0 1 .749-.308 1.034 1.034 0 0 1 .743.288.965.965 0 0 1 .3.729 1.049 1.049 0 0 1-.308.749l-4.347 4.352a1.078 1.078 0 0 1-1.7 0l-4.349-4.349a1.044 1.044 0 0 1-.294-.749.976.976 0 0 1 .294-.729 1.059 1.059 0 0 1 1.472.02l1.512 1.552 1.231 1.525-.107-2.743v-6.33a1.03 1.03 0 0 1 .308-.769 1.065 1.065 0 0 1 .776-.301Z"
            fill={color || '#636363'}
        />
    </svg>
);

export default SvgDownload;
