import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgChevronDown: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 13.712}
        height={height || 7.856}
        viewBox="0 0 13.712 7.856"
    >
        <path
            className="icon-stroke"
            d="M12.301 1.414 6.859 6.855 1.417 1.414"
            fill="none"
            stroke={color || '#009343'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        />
    </svg>
);

export default SvgChevronDown;
