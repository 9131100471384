import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgThumbsdown: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 30}
        height={height || 30}
        viewBox="0 0 30 30"
    >
        <path data-name="Rectangle 427" fill="none" d="M0 0h30v30H0z" />
        <path
            className="icon-fill"
            data-name="Path 381"
            d="M14.987 2a12.451 12.451 0 0 1 5.018 1.024 13.32 13.32 0 0 1 4.148 2.817 13.247 13.247 0 0 1 2.81 4.142 12.507 12.507 0 0 1 1.017 5.024 12.451 12.451 0 0 1-1.024 5.018 13.4 13.4 0 0 1-2.816 4.158 13.077 13.077 0 0 1-4.148 2.81 12.593 12.593 0 0 1-5.018 1.01 12.545 12.545 0 0 1-5.011-1.01 13.177 13.177 0 0 1-4.149-2.81A13.25 13.25 0 0 1 3 20.025a12.522 12.522 0 0 1-1.02-5.018A12.437 12.437 0 0 1 3 9.983a13.342 13.342 0 0 1 2.817-4.142 13.342 13.342 0 0 1 4.142-2.817A12.437 12.437 0 0 1 14.987 2Zm-7.4 10.919a4.933 4.933 0 0 0 .368 1.94 3.5 3.5 0 0 0 .99 1.378 2.189 2.189 0 0 0 1.425.508h.963a8.128 8.128 0 0 1-.85-1.532 5.669 5.669 0 0 1-.42-2.23 5.309 5.309 0 0 1 .4-2.215 4.059 4.059 0 0 1 1.378-1.706h-1.23a2.468 2.468 0 0 0-1.552.529 3.624 3.624 0 0 0-1.077 1.4 4.609 4.609 0 0 0-.395 1.928Zm3.413.054a6.029 6.029 0 0 0 .368 2.081 7.454 7.454 0 0 0 1.01 1.746q.789 1.071 1.74 2.322a18.6 18.6 0 0 1 1.726 2.761 2.537 2.537 0 0 0 .428.636.8.8 0 0 0 .6.234.967.967 0 0 0 .7-.281 1.017 1.017 0 0 0 .288-.763 3.569 3.569 0 0 0-.29-1.336q-.294-.723-.582-1.392a3.007 3.007 0 0 1-.288-1.151.706.706 0 0 1 .274-.589 1.176 1.176 0 0 1 .743-.214h2.462a1.165 1.165 0 0 0 .87-.375 1.246 1.246 0 0 0 .361-.9 1.231 1.231 0 0 0-.348-.91q-.161-.12-.027-.227a.886.886 0 0 0 .207-.375 1.6 1.6 0 0 0 .074-.468 1.147 1.147 0 0 0-.549-1.057.164.164 0 0 1-.04-.268 1.178 1.178 0 0 0 .254-.776 1.131 1.131 0 0 0-.234-.716 1.261 1.261 0 0 0-.555-.422q-.12-.054-.04-.2a1.407 1.407 0 0 0 .107-.261 1.113 1.113 0 0 0 .054-.341.92.92 0 0 0-.348-.776 2.287 2.287 0 0 0-.763-.388 5.851 5.851 0 0 0-.789-.107q-.5-.04-1.164-.04l-.95.013a6.365 6.365 0 0 0-2.823.609 4.52 4.52 0 0 0-1.84 1.612A4.128 4.128 0 0 0 11 12.973Z"
            fill={color || '#636363'}
        />
    </svg>
);

export default SvgThumbsdown;
