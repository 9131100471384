import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../../hooks/use-api-hook-wrapper';

import _ from 'lodash';
import { ContentfulApiService } from '../../../../services/ContentfulApi.service';
import { IPagination } from '../../../../types';
import { ContentfulTransformService } from '../../../../services/ContentfulTransform.service';
import { DEFAULT_LANGUAGE_CODE } from '../../../../common/constants';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const learningCards = await ContentfulApiService.searchContent({
        contentType: 'learningCard',
        page: props.input.pagination.page,
        limit: props.input.pagination.perPage,
        sortBy: props.input.pagination.sortBy,
    });

    console.log('==learningCards==', learningCards);

    const learningCardsObj = ContentfulTransformService.getLearningCards(learningCards);

    for (let i = 0; i < learningCardsObj.mapped.length; ++i) {
        const card = learningCardsObj.mapped[i];
        if (card.referencedSection) {
            const linkedContentEntryId = card.referencedSection.sys.id;
            try {
                const response = await ContentfulApiService.getEntry({
                    entryId: linkedContentEntryId,
                });
                if (response) {
                    card.referencedSection = ContentfulTransformService.transformLearningCardSection(undefined, response);
                }
            } catch (err) {
                console.error('==learning card err==', err);
            }
        }
    }

    console.log('learning cards contentful data', learningCards);
    console.log('==learningCardsObj==', learningCardsObj);
    return learningCardsObj;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    console.log('==errorFn==', error, '=exception==', exception);
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useLearningCardHook = (
    pagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialData: {},
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
