import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgEdit: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 20}
        height={height || 19.121}
        viewBox="0 0 20 19.121"
    >
        <g
            className="icon-stroke"
            fill="none"
            stroke={color || '#40904c'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <path data-name="Path 4851" d="M10 18.121h9" />
            <path
                data-name="Path 4852"
                d="M14.5 1.621a2.121 2.121 0 1 1 3 3L5 17.121l-4 1 1-4Z"
            />
        </g>
    </svg>
);

export default SvgEdit;
