import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Text } from '../../components/Text';
import { COLORS } from '../../styles/variables';
import { FastscanLogo } from '../../assets/icons';
import { FlexGrid } from '../../components/FlexGrid';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { CheckBox } from '../../components/CheckBox';
import { useLoginAPI } from './hooks/use-login';
import { GLOBAL_CONFIG } from '../../config/config';
import { addUser, seContentfulTokenAction } from '../../store/store.actions';
import { getUrlKeepQuery } from '../../utils';
// import { useMe } from './hooks/use-me';

const Wrapper = styled.div`
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
`;

const Container = styled(FlexGrid)`
    width: 100vw;
    height: 100vh;
    position: relative;
`;

const Green = styled.div`
    background-color: ${COLORS.green};
    width: 15%;
    height: 100%;
    transform: skew(-18deg);
    position: absolute;
    left: 25%;
`;

const FormContainer = styled(FlexGrid)`
    flex: 1;
    padding: 2em;
    max-width: 600px;
    flex-direction: column;
    margin-top: 5em;
`;

const Left = styled.div`
    position: relative;
    z-index: 2;
    width: 50%;
`;

const Title = styled(Text)`
    color: ${COLORS.green};
    font-size: 42px;
    font-family: 'OS';
    font-weight: bold;
    margin: 0;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0vw 5vw;
`;

const Logo = styled(FlexGrid)`
    margin: 5vw 5vw 4vw;
`;

const Header = styled(FlexGrid)`
    padding-bottom: 2em;
`;

const Form = styled(FlexGrid)`
    width: 100%;
    gap: 1em;
`;

const BottomBorder = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
`;

const TextButton = styled(Button)`
    font-size: 1rem;
    font-family: 'AR';
    text-transform: none;
    margin: 0;
    padding: 0;
`;

const LoginButton = styled(Button)`
    font-family: 'OS Bold';
    padding: 0.8em;
    width: 30%;
    max-width: 180px;
    justify-content: center;
    font-size: 1rem;
    margin: 0em 0em 0em 2em;
`;

const ContentfulLoginButton = styled(Button)`
    font-family: 'OS Bold';
    padding: 0.8em;
    width: 100%;
    justify-content: center;
    font-size: 1rem;
    margin: 0em 0em 0em 2em;
`;

const Terms = styled(FlexGrid)``;

export const Login: React.FC = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [{ isLoading, error, hookData = {} }, LoginData] = useLoginAPI();

    const mockLoginEnabled = GLOBAL_CONFIG.MOCK_LOGIN_ENABLED;

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const handleLogin = () => {
        LoginData({
            password,
            email,
        });
    };

    const handleLoginWithContentful = () => {
        const contentfulLink = `${
            GLOBAL_CONFIG.CONTENTFUL_AUTH_URL
        }/?response_type=token&client_id=${
            GLOBAL_CONFIG.CONTENTFUL_AUTH_APP_CLIENT_ID
        }&redirect_uri=${encodeURI(
            GLOBAL_CONFIG.CONTENTFUL_AUTH_APP_REDIRECT_URI
        )}&scope=content_management_manage`;
        window.location.href = contentfulLink;
    };

    useEffect(() => {
        if (mockLoginEnabled && hookData?.success) {
            sessionStorage.setItem(
                'contentfulToken',
                hookData?.contentfulToken
            );
            dispatch(seContentfulTokenAction(hookData?.contentfulToken));
            sessionStorage.setItem('apiToken', hookData?.apiToken);
            navigate(getUrlKeepQuery('/dashboard'));
        }
    }, [hookData]);

    return (
        <Wrapper>
            <Container>
                <Green />
                <Left>
                    <Logo>
                        <FastscanLogo
                            height="60"
                            style={{
                                backgroundColor: 'white',
                            }}
                        />
                    </Logo>
                    <TitleContainer>
                        <Title>Welcome to Castrol</Title>
                        <Title>Admin Portal</Title>
                    </TitleContainer>
                </Left>
                <FormContainer>
                    <Header
                        justifyContent="space-between"
                        alignItems="center"
                        width="100%"
                    >
                        <FlexGrid direction="column">
                            <Text font="OS Bold" fontSize="28px">
                                LOG IN
                            </Text>
                            <Text color={COLORS.darkGray}>
                                Please sign in to access your account
                            </Text>
                        </FlexGrid>
                        {/* <Button title="Need Help?" icon="next" negative /> */}
                    </Header>
                    <Form direction="column">
                        {mockLoginEnabled && (
                            <Input
                                value={email}
                                label="EMAIL ID"
                                onChange={(val) => setEmail(val)}
                            />
                        )}
                        {mockLoginEnabled && (
                            <Input
                                type="password"
                                value={password}
                                label="PASSWORD"
                                onChange={(val) => setPassword(val)}
                            />
                        )}
                        {mockLoginEnabled && (
                            <BottomBorder
                                p="1.5em 0em"
                                alignItems="center"
                                justifyContent="space-between"
                                width="100%"
                            >
                                <CheckBox
                                    checked={rememberMe}
                                    onChange={() => setRememberMe((p) => !p)}
                                    label="Remember me"
                                />
                                <TextButton negative>
                                    Forgot your password?
                                </TextButton>
                            </BottomBorder>
                        )}
                        {mockLoginEnabled && (
                            <FlexGrid
                                justifyContent="space-between"
                                alignItems="center"
                                p="1.5em 0em"
                                width="100%"
                            >
                                <Terms direction="column">
                                    <Text color={COLORS.darkGray}>
                                        By clicking login button, you agree to
                                        our
                                    </Text>
                                    <Text>
                                        <Text link>Terms of service</Text>
                                        <Text span color={COLORS.darkGray}>
                                            {' '}
                                            and{' '}
                                        </Text>
                                        <Text link> Privacy policy</Text>
                                    </Text>
                                </Terms>
                                <LoginButton
                                    onClick={handleLogin}
                                    disabled={!email || !password}
                                >
                                    LOGIN
                                </LoginButton>
                            </FlexGrid>
                        )}
                        <FlexGrid
                            justifyContent="center"
                            alignItems="center"
                            p="1.5em 0em"
                            width="100%"
                        >
                            <ContentfulLoginButton
                                onClick={handleLoginWithContentful}
                            >
                                LOGIN with Contentful
                            </ContentfulLoginButton>
                        </FlexGrid>
                    </Form>
                </FormContainer>
            </Container>
        </Wrapper>
    );
};
