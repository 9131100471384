import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import { Article } from './pages/Article';
import { VideoPlaylist } from './pages/VideoPlaylist';
import GlobalStyle from './styles/global-styles';

import { ApplicationManagement } from './pages/AppManagement';
import { Banner } from './pages/Banners';
import { CreateBanner } from './pages/Banners/components/CreateBanner';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login';
import { Redirect } from './pages/Redirect';
import { Video } from './pages/Video';
import { Videos } from './pages/Videos';
import { CreateVideo } from './pages/Videos/components/CreateVideo';
import { CreateArticle } from './pages/Articles/components/CreateArticle';
import { Articles } from './pages/Articles';
import { ArticleFolder } from './pages/ArticleFolder';
import { InAppNotifications } from './pages/InAppNotifications';
import { CreateInAppNotification } from './pages/InAppNotifications/components/CreateInAppNotification';
import { PushNotifications } from './pages/PushNotifications';
import { CreatePushNotification } from './pages/PushNotifications/components/CreatePushNotifications';
import { SearchResultPage } from './pages/SearchResult';

import 'react-calendar/dist/Calendar.css';
import 'video-react/dist/video-react.css';
import './Calendar.css';
import 'react-quill/dist/quill.snow.css';
import {
    addUser,
    setCountryAction,
    setLanguageAction,
} from './store/store.actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from './store/store.types';
import { ROLE_TAG_ID_MAP, TAG_COUNTRY_MAP } from './common/constants';
import { getLanguageListByCountry, getRoleId, setCountry, setLanguage } from './utils';
import { useMe } from './pages/Login/hooks/use-me';
import { Processor } from './components/Processor';
import { Quizzes } from './pages/Quizzes';
import { CreateQuiz } from './pages/Quizzes/components/CreateQuiz';
import { OilSelectorManagement } from './pages/OilSelectorManagement';
import { CreateScanningContest } from './pages/ScanningContests/components/CreateScanningContest';
import { ScanningContests } from './pages/ScanningContests';
import { BrandSKULists } from './pages/BrandSKUList';
import { RegistrationContests } from './pages/RegistrationContests';
import { CreateRegistrationContest } from './pages/RegistrationContests/components/CreateRegistrationContest';
import { LearningCardThemes } from './pages/LearningCard/LearningCardTheme';
import { LearningCardSections } from './pages/LearningCard/LearningCardSection';
import { LearningCards } from './pages/LearningCard/LearningCard';
import { CreateLearningCard } from './pages/LearningCard/LearningCard/components/CreateLearningCard';

function MainRoute() {
    const dispatch = useDispatch();

    const country = useSelector<AppState, string>((s) => s.country);
    const language = useSelector<AppState, string>((s) => s.language);

    useEffect(() => {
        const qParams = new URL(window.location.href).searchParams;
        const qCountry = qParams.get('country');
        const roleTagId = ROLE_TAG_ID_MAP[getRoleId()];
        let defaultContry;
        if (roleTagId) {
            defaultContry = qCountry || TAG_COUNTRY_MAP[roleTagId];
        } else {
            defaultContry = qCountry || 'England/UK';
        }

        dispatch(setCountryAction(defaultContry));
    }, [window.location.pathname]);

    useEffect(() => {
        if (!country) {
            return;
        }
        const defaultLanguages = getLanguageListByCountry(country);
        setLanguage(defaultLanguages[0]);
        setCountry(country);
        dispatch(setLanguageAction(defaultLanguages[0]));
    }, [country]);

    if (!language) {
        return null;
    }

    return (
        <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/communication/banners" element={<Banner />} />
            <Route
                path="/communication/banners/create"
                element={<CreateBanner />}
            />
            <Route
                path="/communication/video-playlist"
                element={<VideoPlaylist />}
            />
            <Route
                path="/communication/video-playlist/:videoPlaylistId"
                element={<VideoPlaylist />}
            />
            <Route path="/communication/videos" element={<Videos />} />
            <Route
                path="/communication/videos/create"
                element={<CreateVideo />}
            />
            <Route path="/communication/articles" element={<Articles />} />
            <Route
                path="/communication/articles/create"
                element={<CreateArticle />}
            />
            <Route
                path="/communication/article-folder"
                element={<ArticleFolder />}
            />
            <Route
                path="/communication/article-folder/:articleFolderId"
                element={<ArticleFolder />}
            />
            <Route
                path="/communication/in-app-notifications"
                element={<InAppNotifications />}
            />
            <Route
                path="/communication/in-app-notifications/create"
                element={<CreateInAppNotification />}
            />
            <Route path="/communication/quizzes" element={<Quizzes />} />
            <Route
                path="/communication/quizzes/create"
                element={<CreateQuiz />}
            />
            <Route
                path="/communication/push-notifications"
                element={<PushNotifications />}
            />
            <Route
                path="/communication/push-notifications/create"
                element={<CreatePushNotification />}
            />
            <Route
                path="/application-management"
                element={<ApplicationManagement />}
            />
            <Route
                path="/oil-selector-management"
                element={<OilSelectorManagement />}
            />
            <Route
                path="/scheme-offers/scanning-contests"
                element={<ScanningContests />}
            />
            <Route
                path="/scheme-offers/registration-contests"
                element={<RegistrationContests />}
            />
            <Route
                path="/scheme-offers/scanning-contests/create"
                element={<CreateScanningContest />}
            />
            <Route
                path="/scheme-offers/registration-contests/create"
                element={<CreateRegistrationContest />}
            />
            <Route
                path="/scheme-offers/brand-sku-lists"
                element={<BrandSKULists />}
            />
            <Route
                path="/learning/learning-card/themes"
                element={<LearningCardThemes />}
            />
            <Route
                path="/learning/learning-card/sections"
                element={<LearningCardSections />}
            />
            <Route
                path="/learning/learning-card/cards"
                element={<LearningCards />}
            />
            <Route
                path="/learning/learning-card/cards/create"
                element={<CreateLearningCard />}
            />
            <Route path="/video/:videoId" element={<Video />} />
            <Route path="/article" element={<Article />} />
            <Route path="/article/:articleId" element={<Article />} />
            <Route path="/search" element={<SearchResultPage />} />
            <Route
                path="/search/:searchString"
                element={<SearchResultPage />}
            />
        </Routes>
    );
}

function App() {
    const dispatch = useDispatch();
    const contentfulToken = useSelector<AppState, string>(
        (s) => s.contentfulToken
    );

    useEffect(() => {
        const contentfulToken = sessionStorage.getItem('contentfulToken');
        const apiToken = sessionStorage.getItem('apiToken');
        const { pathname } = window.location;
        if (
            (!contentfulToken || !apiToken) &&
            !pathname.startsWith('/login') &&
            !pathname.startsWith('/redirect')
        ) {
            console.log('redirect to login screen');
            window.location.pathname = '/login';
        }
    }, []);

    const [{ hookData: profile }] = useMe(null, [contentfulToken]);

    useEffect(() => {
        if (profile) {
            dispatch(addUser(profile.profile));
        }
    }, [profile, contentfulToken]);

    return (
        <>
            <GlobalStyle />
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/redirect" element={<Redirect />} />
                    <Route path="/*" element={<MainRoute />} />
                    <Route path="/" element={<Navigate to="/login" />} />
                </Routes>
            </Router>
            <Processor />
        </>
    );
}

export default App;
