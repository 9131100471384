import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
// import moment from 'moment';
import { Button } from '../../../components/Button';
import { FlexGrid } from '../../../components/FlexGrid';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/Modal';
import {
    Table,
    TableCell,
    TableHead,
    TableImg,
    // TablePagination,
    TableRow,
} from '../../../components/Table';

import { COLORS } from '../../../styles/variables';
import { ArrowDownIcon, CheckIcon } from '../../../assets/icons';
// import { sampleBannerValue } from './sampleValue';
import { IconButton } from '../../../components/IconButton';
import { useDashboardBannerHook } from '../../Dashboard/hooks/use-banner-hook';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { Pagination } from '../../../components/Pagination';
// import { useDashboardAssetsHook } from '../../Dashboard/hooks/use-assets-hook';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import {
    LANGUAGE_CONTENTFUL_MAP_REVERSE,
    PLACEHOLDER_IMAGE,
} from '../../../common/constants';
import { getUrlKeepQuery } from '../../../utils';
import { sortFunc } from '../../../common';

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

const Pages = styled(FlexGrid)`
    border: 1px solid ${COLORS.lightGray};
    align-items: center;
`;

interface PageBtnProps {
    active?: boolean;
}

const PageBtn = styled(Button)<PageBtnProps>`
    border-radius: 0;
    padding: 0.2em 0.5em;
    margin: 5px 2px;
    background-color: ${({ active }) =>
        active ? COLORS.green : COLORS.transparent};
    color: ${({ active }) => (active ? COLORS.white : COLORS.darkGray)};
    font-family: 'AR';
    font-size: 1rem;
`;

export const BanneritemToEditModel = (item: any) => {
    const getLanField = (prop) => {
        const result: any = {};
        _.map(prop, (v, k) => {
            result[LANGUAGE_CONTENTFUL_MAP_REVERSE[k]] = v;
        });
        return result;
    };
    const toReturn = {
        raw: item.contentfulRawData,
        status: item.status,

        identifierDefinition: {
            name: item.name,
            appLanguages: item.language,
            startDate: item.startDate && item.startDate.format('DD MMMM YYYY'),
            startTime: item.startDate && item.startDate.format('HH:mm'),
            endDate: item.endDate && item.endDate.format('DD MMMM YYYY'),
            endTime: item.endDate && item.endDate.format('HH:mm'),
        },
        content: {
            headline: getLanField(item.lanHeadline),
            description: getLanField(item.lanDescription),
            actionText: getLanField(item.lanCallToActionText),
            linkSource: getLanField(item.lanLinkSource),
            isLinkInternal: item.isLinkInternal,
            tags: item.tags || [],
            oldImage: !!item.image,
            image: {
                preview: item.image,
                name: ContentfulTransformService.getLinkDetails(item.imageAsset)
                    .name,
                size: ContentfulTransformService.getLinkDetails(item.imageAsset)
                    .size,
            },
        },
        targetGeography: {
            regions: item.regions,
            states: item.stateprovinces,
            distributors: item.distributors,
            districts: item.district,
            pincode: item.pincode,
            rmAreas: ['A', 'B'],
        },
        targetUser: {
            userTypes: item.targetUsers,
            spaceOfMechanics: item.spaceOfMechanics,
            connekt: item.connekt,
            mobile: item.mobile,
            PID: item.pid,
            dealerSegments: item.dealerSegments,
            dealerSubSegments: item.dealerSubSegments,
            mechanicsSegments: item.mechanicsSegments,
            mechanicsSubSegments: item.mechanicsSubSegments,
        },
    };

    console.log('edit infomation', item);
    return toReturn;
};

interface BannerTableProps {
    dashboard?: boolean;
    pos?: number;
}

export const BannerTable: React.FC<BannerTableProps> = ({ dashboard, pos }) => {
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);
    const perPage = dashboard ? 10 : 5;

    const [deleteModal, setDeleteModal] = useState(false);
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const DEFAULT_SORT = {
        name: 0,
        status: 0,
        startDate: 0,
        endDate: 0,
        views: 0,
        uniqueViews: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);

    const [{ isLoading, hookData: bannersHookData }] = useDashboardBannerHook(
        {
            // perPage,
            // page,
            // sortBy: `${sort.name ? '' : '-'}fields.name`,
        },
        [update]
    );
    // const [{ hookData: assetsHookData }] = useDashboardAssetsHook();
    // const assets = banners?.includes?.Asset || [];

    const banners = ContentfulTransformService.getBanners(
        bannersHookData?.assets,
        bannersHookData?.items || []
    );

    const { total } = bannersHookData || {};

    let sortedItems = sortFunc(sort, banners || []);
    // console.log('==sortedItems==11==', sortedItems);

    if (dashboard) {
        sortedItems = sortedItems.slice(pos, pos + 5);
    }

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    sortedItems = sortedItems.slice(startIndex, endIndex);

    const itemToEditModel = (item: any) => {
        return BanneritemToEditModel(item);
    };

    return (
        <FlexGrid width="100%" direction="column" gap="2em">
            <Modal
                open={deleteModal}
                header="Delete Banner"
                onClose={() => setDeleteModal(false)}
                content={
                    <FlexGrid
                        direction="column"
                        width="100%"
                        gap="2em"
                        p="2em 0em"
                    >
                        <Text color={COLORS.darkGray}>
                            Are you sure you want to delete this banner?
                        </Text>
                        <Text color={COLORS.darkGray}>
                            <Text color={COLORS.darkGray} span font="AR Bold">
                                Note:{' '}
                            </Text>
                            Deleting banner is a permanent action and cannot be
                            undone.
                        </Text>
                    </FlexGrid>
                }
                actions={
                    <FlexGrid alignItems="center">
                        <Button
                            onClick={() => {
                                ContentfulApiService.deleteContent(
                                    current.id
                                ).then(() => {
                                    setUpdate(!update);
                                    if (total % perPage === 1) {
                                        setPage(Math.max(page - 1, 1));
                                    }
                                });
                                setDeleteModal(false);
                            }}
                            p="1.3em 2.5em"
                        >
                            DELETE
                        </Button>
                        <Button
                            onClick={() => setDeleteModal(false)}
                            negative
                            p="0.5em 1em"
                        >
                            CANCEL
                        </Button>
                    </FlexGrid>
                }
            />
            <Table>
                <TableRow>
                    <TableHead>Image</TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Banner Title
                            {sort.name === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: 1 });
                                    }}
                                    transform="rotate(-90deg)"
                                />
                            )}
                            {sort.name === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: -1 });
                                    }}
                                />
                            )}
                            {sort.name === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: 0 });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Status
                            {sort.status === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, status: 1 });
                                    }}
                                    transform="rotate(-90deg)"
                                />
                            )}
                            {sort.status === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            status: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.status === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, status: 0 });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Start Date
                            {sort.startDate === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            startDate: 1,
                                        });
                                    }}
                                    transform="rotate(-90deg)"
                                />
                            )}
                            {sort.startDate === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            startDate: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.startDate === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            startDate: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            End Date
                            {sort.endDate === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            endDate: 1,
                                        });
                                    }}
                                    transform="rotate(-90deg)"
                                />
                            )}
                            {sort.endDate === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            endDate: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.endDate === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            endDate: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Views
                            {sort.views === 0 && (
                                <IconButton
                                    negative
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            views: 1,
                                        });
                                    }}
                                    transform="rotate(-90deg)"
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.views === 1 && (
                                <IconButton
                                    negative
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            views: -1,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.views === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            views: 0,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Unique Views
                            {sort.uniqueViews === 0 && (
                                <IconButton
                                    negative
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            uniqueViews: 1,
                                        });
                                    }}
                                    transform="rotate(-90deg)"
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.uniqueViews === 1 && (
                                <IconButton
                                    negative
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            uniqueViews: -1,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                            {sort.uniqueViews === -1 && (
                                <IconButton
                                    negative
                                    transform="rotate(180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            uniqueViews: 0,
                                        });
                                    }}
                                    icon={<ArrowDownIcon />}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>Actions</TableHead>
                </TableRow>
                {sortedItems.map((banner, index) => {
                    const fallBackIcon =
                        '//images.ctfassets.net/4wfl0fpp9t0j/2keSHAhbAJlMjNOoK66i0h/6b6d9444aa7b5d70b4bfe2baaba19f82/image.png';
                    const url = banner.image || fallBackIcon;

                    return (
                        <TableRow key={index}>
                            <TableCell>
                                <TableImg
                                    style={{ cursor: 'pointer' }}
                                    src={
                                        url ? `https:${url}` : PLACEHOLDER_IMAGE
                                    }
                                    onClick={() => {
                                        navigate(
                                            getUrlKeepQuery(
                                                '/communication/banners'
                                            )
                                        );
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <Text
                                    link
                                    fontSize="0.875rem"
                                    color={COLORS.green}
                                    onClick={() => {
                                        navigate(
                                            getUrlKeepQuery(
                                                '/communication/banners'
                                            )
                                        );
                                    }}
                                >
                                    {banner.name || ''}
                                </Text>
                            </TableCell>
                            <TableCell>
                                <FlexGrid gap="0.5em" alignItems="center">
                                    {banner.status === 'Active' && (
                                        <CheckIcon height="10" />
                                    )}
                                    <Text
                                        color={
                                            banner.status === 'Active'
                                                ? COLORS.green
                                                : COLORS.red
                                        }
                                        font="AR Bold"
                                        uppercase
                                        fontSize="0.875rem"
                                    >
                                        {/* TODO check hpw this status is coming */}
                                        {banner.status}
                                    </Text>
                                </FlexGrid>
                            </TableCell>
                            <TableCell>
                                <FlexGrid>
                                    {dashboard ? (
                                        <Text>
                                            {banner.startDate
                                                ? banner.startDate.format(
                                                      'MM/DD/YY - HH:mm'
                                                  )
                                                : ''}
                                        </Text>
                                    ) : (
                                        <FlexGrid
                                            alignItems="center"
                                            direction="column"
                                        >
                                            <Text>
                                                {banner.startDate
                                                    ? banner.startDate.format(
                                                          'MMM DD, YYYY - HH:mm'
                                                      )
                                                    : ''}
                                            </Text>
                                        </FlexGrid>
                                    )}
                                </FlexGrid>
                            </TableCell>
                            <TableCell>
                                <FlexGrid>
                                    {dashboard ? (
                                        <Text>
                                            {banner.endDate
                                                ? banner.endDate.format(
                                                      'MM/DD/YY - HH:mm'
                                                  )
                                                : ''}
                                        </Text>
                                    ) : (
                                        <FlexGrid
                                            alignItems="center"
                                            direction="column"
                                        >
                                            <Text>
                                                {banner.endDate
                                                    ? banner.endDate.format(
                                                          'MMM DD, YYYY - HH:mm'
                                                      )
                                                    : ''}
                                            </Text>
                                        </FlexGrid>
                                    )}
                                </FlexGrid>
                            </TableCell>
                            <TableCell>{banner?.views ?? 0}</TableCell>
                            <TableCell>{banner?.uniqueViews ?? 0}</TableCell>
                            <TableCell>
                                <FlexGrid alignItems="center">
                                    <TableActionBtn
                                        onClick={() =>
                                            navigate(
                                                getUrlKeepQuery(
                                                    '/communication/banners/create'
                                                ),
                                                {
                                                    state: {
                                                        data: itemToEditModel(
                                                            banner
                                                        ),
                                                        page,
                                                        viewOnly: true,
                                                    },
                                                }
                                            )
                                        }
                                        negative
                                    >
                                        View
                                    </TableActionBtn>
                                    <TableActionBtn
                                        onClick={() =>
                                            navigate(
                                                getUrlKeepQuery(
                                                    '/communication/banners/create'
                                                ),
                                                {
                                                    state: {
                                                        data: itemToEditModel(
                                                            banner
                                                        ),
                                                        page,
                                                    },
                                                }
                                            )
                                        }
                                        negative
                                    >
                                        Edit
                                    </TableActionBtn>
                                    <TableActionBtn
                                        onClick={() => {
                                            setCurrent(banner);
                                            setDeleteModal(true);
                                        }}
                                        negative
                                    >
                                        Delete
                                    </TableActionBtn>
                                </FlexGrid>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </Table>
            {!dashboard && (
                <Pagination
                    page={page}
                    perPage={perPage}
                    total={total}
                    setPage={(v) => {
                        setPage(v);
                    }}
                />
            )}
        </FlexGrid>
    );
};
