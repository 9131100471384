import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlexGrid } from '../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../components/Table';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import {
    ThumbsUpIcon,
    ThumbsDownIcon,
    ArrowDownIcon,
} from '../../../assets/icons';
import { Button } from '../../../components/Button';
import { Modal } from '../../../components/Modal';
// import { sampleVideoValue } from './sampleValue';
import { IconButton } from '../../../components/IconButton';
import { useVideosHook } from '../hooks/use-videos-hook';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
// import { useDashboardAssetsHook } from '../../Dashboard/hooks/use-assets-hook';
import { Pagination } from '../../../components/Pagination';
import _ from 'lodash';
import {
    LANGUAGE_CONTENTFUL_MAP_REVERSE,
    PLACEHOLDER_IMAGE,
} from '../../../common/constants';
import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import {
    getUrlKeepQuery,
    QuizItemToEditModel,
    VideoItemToEditModel,
} from '../../../utils';
import { sortFunc } from '../../../common';

const ImageContainer = styled(FlexGrid)`
    width: 100px;
    height: 64px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    img {
        object-fit: cover;
        height: 100%;
    }
`;

// const Length = styled(Text)`
//     position: absolute;
//     bottom: 0.2em;
//     left: 0.2em;
//     font-size: 0.75rem;
//     background-color: ${COLORS.transparentBlack75};
//     color: ${COLORS.white};
//     padding: 0.3em;
//     border-radius: 2px;
// `;

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

export const VideosTable: React.FC = () => {
    const navigate = useNavigate();

    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const DEFAULT_SORT = {
        name: 0,
        featured: 0,
        createdAt: 0,
        views: 0,
        uniqueViews: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);

    const [{ hookData: videosHookData }] = useVideosHook(
        {
            // perPage: 5,
            // page,
            // sortBy: `${sort.date ? '' : '-'}sys.createdAt`,
        },
        [update]
    );
    const perPage = 5;
    const total = videosHookData?.total || 0;
    // const [{ hookData: assetsHookData }] = useDashboardAssetsHook();

    console.log('videos data', update, videosHookData?.assets, videosHookData);
    let videos =
        videosHookData &&
        videosHookData?.assets &&
        ContentfulTransformService.getVideos(
            videosHookData?.assets,
            videosHookData.items,
            videosHookData.playlist?.items
        );

    videos = sortFunc(sort, videos || []);

    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    videos = videos.slice(startIndex, endIndex);

    const [deleteModal, setDeleteModal] = useState(false);
    const [currentVideo, setCurrentVideo] = useState<any>({});

    const itemToEditModel = async (index: number) => {
        const video = videos[index];
        console.log('is from url', video.fromUrl, video);

        let quizEditModel;
        if (video.quiz) {
            const quizEntryId = video.quiz.sys.id;
            const contentfulQuiz = await ContentfulApiService.getEntry({
                contentType: 'quiz',
                entryId: quizEntryId,
            });
            const transformedQuiz =
                ContentfulTransformService.transformQuiz(contentfulQuiz);
            quizEditModel = await QuizItemToEditModel(transformedQuiz);
        }

        const toReturn = VideoItemToEditModel(video);

        if (quizEditModel) {
            toReturn.quiz = {
                raw: quizEditModel.raw,
                status: quizEditModel.status,
                content: quizEditModel.content,
            };
        }

        return toReturn;
    };

    // const startOfPage = page * perPage;
    // const endOfPage = startOfPage + perPage;

    return (
        <>
            <Modal
                open={deleteModal}
                header="Delete Video"
                onClose={() => setDeleteModal(false)}
                content={
                    <FlexGrid
                        direction="column"
                        width="100%"
                        gap="2em"
                        p="2em 0em"
                    >
                        <Text color={COLORS.darkGray}>
                            Are you sure you want to delete this video?
                        </Text>
                        <Text color={COLORS.darkGray}>
                            <Text color={COLORS.darkGray} span font="AR Bold">
                                Note:{' '}
                            </Text>
                            Deleting video is a permanent action and cannot be
                            undone.
                        </Text>
                    </FlexGrid>
                }
                actions={
                    <FlexGrid alignItems="center">
                        <Button
                            onClick={() => {
                                ContentfulApiService.deleteContent(
                                    currentVideo.id
                                ).then(() => {
                                    setUpdate(!update);
                                    if (total % perPage === 1) {
                                        setPage(Math.max(page - 1, 1));
                                    }
                                });
                                setDeleteModal(false);
                            }}
                            p="1.3em 2.5em"
                        >
                            DELETE
                        </Button>
                        <Button
                            onClick={() => {
                                setDeleteModal(false);
                            }}
                            negative
                            p="0.5em 1em"
                        >
                            CANCEL
                        </Button>
                    </FlexGrid>
                }
            />
            <Table>
                <TableRow>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Video
                            {sort.name === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: 1 });
                                    }}
                                />
                            )}
                            {sort.name === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: -1 });
                                    }}
                                />
                            )}
                            {sort.name === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, name: 0 });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Featured
                            {sort.featured === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            featured: 1,
                                        });
                                    }}
                                />
                            )}
                            {sort.featured === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            featured: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.featured === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            featured: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>Playlist</TableHead>
                    <TableHead>Target User</TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Date
                            {sort.createdAt === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            createdAt: 1,
                                        });
                                    }}
                                />
                            )}
                            {sort.createdAt === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            createdAt: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.createdAt === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            createdAt: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Views
                            {sort.views === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, views: 1 });
                                    }}
                                />
                            )}
                            {sort.views === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, views: -1 });
                                    }}
                                />
                            )}
                            {sort.views === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({ ...DEFAULT_SORT, views: 0 });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>
                        <FlexGrid alignItems="center" gap="0.8em">
                            Unique Views
                            {sort.uniqueViews === 0 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-90deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            uniqueViews: 1,
                                        });
                                    }}
                                />
                            )}
                            {sort.uniqueViews === 1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            uniqueViews: -1,
                                        });
                                    }}
                                />
                            )}
                            {sort.uniqueViews === -1 && (
                                <IconButton
                                    negative
                                    icon={<ArrowDownIcon />}
                                    transform="rotate(-180deg)"
                                    onClick={() => {
                                        setSort({
                                            ...DEFAULT_SORT,
                                            uniqueViews: 0,
                                        });
                                    }}
                                />
                            )}
                        </FlexGrid>
                    </TableHead>
                    <TableHead>Likes (vs Dislikes)</TableHead>
                    <TableHead>Actions</TableHead>
                </TableRow>
                {videos.map((video, key) => (
                    <TableRow key={key}>
                        <TableCell>
                            <FlexGrid gap="0.5em" alignItems="center">
                                <ImageContainer
                                    onClick={() => {
                                        navigate(
                                            getUrlKeepQuery(
                                                `/video/${video.id}`
                                            )
                                        );
                                    }}
                                >
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={video.image || PLACEHOLDER_IMAGE}
                                        alt="video thumbnail"
                                    />
                                    {/* <Length>{video.length}</Length> */}
                                </ImageContainer>
                                <Text
                                    link
                                    onClick={() => {
                                        navigate(
                                            getUrlKeepQuery(
                                                `/video/${video.id}`
                                            )
                                        );
                                    }}
                                    fontSize="0.875rem"
                                    color={COLORS.green}
                                >
                                    {video.name}
                                </Text>
                            </FlexGrid>
                        </TableCell>
                        <TableCell>{video.featured ? 'Yes' : 'No'}</TableCell>
                        <TableCell>
                            <Text fontSize="0.875rem" color={COLORS.green}>
                                {video.playlist}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <FlexGrid direction="column">
                                {video.targetUsers.map((target, keyu) => (
                                    <Text key={keyu} fontSize="0.875rem">
                                        {target}
                                    </Text>
                                ))}
                            </FlexGrid>
                        </TableCell>
                        <TableCell>
                            <FlexGrid direction="column">
                                <Text fontSize="0.875rem">{video.date}</Text>
                                <Text fontSize="0.875rem">{video.status}</Text>
                            </FlexGrid>
                        </TableCell>
                        <TableCell>{video.views ?? 0}</TableCell>
                        <TableCell>{video.uniqueViews ?? 0}</TableCell>
                        <TableCell>
                            <FlexGrid gap="0.8em">
                                <FlexGrid gap="0.2em" alignItems="center">
                                    <ThumbsUpIcon color="green" />
                                    <Text fontSize="0.875rem">
                                        {video.likes}
                                    </Text>
                                </FlexGrid>
                                <FlexGrid gap="0.2em" alignItems="center">
                                    <ThumbsDownIcon />
                                    <Text fontSize="0.875rem">
                                        {video.dislikes}
                                    </Text>
                                </FlexGrid>
                            </FlexGrid>
                        </TableCell>
                        <TableCell>
                            <FlexGrid alignItems="center">
                                <TableActionBtn
                                    onClick={async () => {
                                        if (loading) {
                                            return;
                                        }
                                        setLoading(true);
                                        navigate(
                                            getUrlKeepQuery(
                                                '/communication/videos/create'
                                            ),
                                            {
                                                state: {
                                                    data: await itemToEditModel(
                                                        key
                                                    ),
                                                    page,
                                                    viewOnly: true,
                                                },
                                            }
                                        );
                                    }}
                                    negative
                                >
                                    View
                                </TableActionBtn>
                                <TableActionBtn
                                    onClick={async () => {
                                        if (loading) {
                                            return;
                                        }
                                        setLoading(true);
                                        navigate(
                                            getUrlKeepQuery(
                                                '/communication/videos/create'
                                            ),
                                            {
                                                state: {
                                                    data: await itemToEditModel(
                                                        key
                                                    ),
                                                    page,
                                                },
                                            }
                                        );
                                    }}
                                    negative
                                >
                                    Edit
                                </TableActionBtn>
                                <TableActionBtn
                                    onClick={() => {
                                        setCurrentVideo(video);
                                        setDeleteModal(true);
                                    }}
                                    negative
                                >
                                    Remove
                                </TableActionBtn>
                            </FlexGrid>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
            <Pagination
                page={page}
                perPage={perPage}
                total={total}
                setPage={(v) => {
                    setPage(v);
                }}
            ></Pagination>
        </>
    );
};
