import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import { COLORS } from '../styles/variables';
import { Text } from './Text';
import { DateIcon } from '../assets/icons';
import { InputContainer } from './InputContainer';
import { FlexGrid } from './FlexGrid';
import useClickOutside from '../utils/useClickOutside';
import { convertDateToTzDate } from '../utils';

const InputValue = styled(Text)`
    flex: 1;
`;

interface InputProps {
    label: string;
    value?: string | (string | undefined)[];
    // eslint-disable-next-line no-unused-vars
    onChange?: (e: any) => void;
    placeholder?: string;
    info?: { title: any; content: any };
    range?: boolean;
    returnValue?: 'start' | 'range' | 'end';
    minDate?: Date | null;
    maxDate?: Date | null;
    disabled?: boolean;
}

interface CalendarProps {
    open?: boolean;
}

const CalendarContainer = styled(FlexGrid)<CalendarProps>`
    display: ${({ open }) => (open ? 'flex' : 'none')};
    position: absolute;
    top: 120%;
    width: 300px;
    background-color: ${COLORS.white};
    z-index: 1;
`;

export const DatePicker: React.FC<InputProps> = ({
    label,
    value,
    onChange,
    placeholder,
    info,
    range,
    returnValue,
    minDate = null,
    maxDate = null,
    disabled,
}) => {
    const [open, setOpen] = useState(false);

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const close = useCallback(() => setOpen(false), []);
    useClickOutside(ref, close);

    const [start, end] = Array.isArray(value) ? value : [null, null];

    const renderValue =
        range && value && Array.isArray(value) && value[0] && value[1]
            ? [new Date(value[0]), new Date(value[1])]
            : !range && value && typeof value === 'string'
            ? new Date(value)
            : start
            ? new Date(start)
            : end
            ? new Date(end)
            : undefined;

    const handleChange = (val: any) => {
        if (onChange) {
            if (range) {
                if (returnValue === 'start') {
                    onChange(convertDateToTzDate(val[0]));
                } else if (returnValue === 'end') {
                    onChange(convertDateToTzDate(val[1]));
                }
            } else onChange(convertDateToTzDate(val));
        }
        setOpen(false);
    };

    return (
        <FlexGrid ref={ref} width="100%" direction="column" position="relative">
            <InputContainer
                onClick={() => setOpen((prev) => !prev)}
                info={info}
                label={label}
                border
                disabled={disabled}
            >
                <FlexGrid m="0em 1em 0em 0em">
                    <DateIcon />
                </FlexGrid>
                <InputValue color={(!disabled && value) ? COLORS.darkGray : COLORS.gray}>
                    {returnValue === 'start' && value && value[0]
                        ? value[0]
                        : returnValue === 'end' && value && value[1]
                        ? value[1]
                        : value && typeof value === 'string'
                        ? value
                        : placeholder || 'Select Date'}
                </InputValue>
            </InputContainer>
            <CalendarContainer open={open}>
                <Calendar
                    minDate={minDate}
                    maxDate={maxDate}
                    selectRange={range}
                    onClickDay={(val: any) => {
                        if (onChange) {
                            onChange(convertDateToTzDate(val));
                        }
                        setOpen(false);
                    }}
                    // onChange={handleChange}
                    returnValue={returnValue || 'start'}
                    value={renderValue}
                />
            </CalendarContainer>
        </FlexGrid>
    );
};
