import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { FlexGrid } from '../../components/FlexGrid';
import { PageHeader } from '../../components/PageHeader';
import { PageWrapper } from '../../components/PageWrapper';
import { SearchBar } from '../../components/SearchBar';
import { Text } from '../../components/Text';
import { QuizzesTable } from './components/QuizzesTable';
import { PlusIcon } from '../../assets/icons';
import { SelectCountry } from '../../components/SelectCountry';
import { getUrlKeepQuery } from '../../utils';

const NewBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    font-size: 1rem;
`;

const PageContent = styled(FlexGrid)`
    max-width: 1200px;
    width: 100%;
    padding: 2em 0em;
    flex-direction: column;
`;

export const Quizzes: React.FC = () => {
    const navigate = useNavigate();
    return (
        <PageWrapper>
            <PageHeader
                back
                actions={
                    <FlexGrid gap="1.5em">
                        <SearchBar placeholder="Search banners, videos, articles here…" />
                        <SelectCountry />
                    </FlexGrid>
                }
            />
            <FlexGrid width="100%" justifyContent="center">
                <PageContent>
                    <FlexGrid
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Text font="OS" fontSize="2.25rem" uppercase>
                            QUIZZES
                        </Text>
                        <NewBtn
                            negative
                            title="Quiz"
                            icon={<PlusIcon />}
                            iconSlot="start"
                            iconHeight="1.3rem"
                            onClick={() =>
                                navigate(
                                    getUrlKeepQuery('/communication/quizzes/create')
                                )
                            }
                        />
                    </FlexGrid>
                    <QuizzesTable />
                </PageContent>
            </FlexGrid>
        </PageWrapper>
    );
};
