import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { FlexGrid } from '../../components/FlexGrid';
import { PageWrapper } from '../../components/PageWrapper';
import { Tag } from '../../components/Tag';
import { Text } from '../../components/Text';
import { COLORS } from '../../styles/variables';
import { SideBar } from './components/SideBar';
import { v4 as uuidv4 } from 'uuid';

import {
    ThumbsUpIcon,
    ThumbsDownIcon,
    CommentIcon,
    LoveIcon,
    ShareIcon,
    DownloadIcon,
    WatchLaterIcon,
    SortIcon,
} from '../../assets/icons';
import { ActionIcon } from '../../components/ActionIcon';
import { Button } from '../../components/Button';
import { Tooltip } from '../../components/Tooltip';
import { PageHeader } from '../../components/PageHeader';
import { SearchBar } from '../../components/SearchBar';
import { Input } from '../../components/Input';
import { SelectCountry } from '../../components/SelectCountry';
import { useParams } from 'react-router-dom';
import { useVideoHook } from './hooks/use-video-hook';
import {
    addLocalArrayItem,
    getDefaultTimezone,
    getLocalArray,
    removeLocalArrayItem,
    toggleLocalArrayItem,
} from '../../utils';
import {
    STORAGE_DISLIKE_VIDEO_IDS,
    STORAGE_LIKE_VIDEO_IDS,
    STORAGE_SAVED_VIDEO_IDS,
    STORAGE_WATCH_LATER_VIDEO_IDS,
} from '../../common/constants';
import moment from 'moment';
import 'moment-timezone';
import { useDashboardAssetsHook } from '../Dashboard/hooks/use-assets-hook';
import { ContentfulTransformService } from '../../services/ContentfulTransform.service';
import { ApiService } from '../../services/Api.service';

const PageContent = styled(FlexGrid)`
    max-width: 1200px;
    width: 100%;
    padding: 2em 0em;
    gap: 2em;
`;

const VideoContainer = styled(FlexGrid)`
    min-width: 0;
`;

const VideoPlayer = styled(FlexGrid)`
    position: relative;
    width: 100%;
    overflow: hidden;
`;

const VideoHeader = styled(FlexGrid)`
    flex-direction: column;
    padding: 1em;
    width: 100%;
`;

const CommentSection = styled(FlexGrid)`
    width: 100%;
    flex-direction: column;
`;

const Avatar = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 100%;
`;

export const Video: React.FC = () => {
    const [update, setUpdate] = useState(false);
    const [updateApi, setUpdateApi] = useState(false);
    const [video, setVideo] = useState(null);

    const params = useParams();
    const videoId = params.videoId;

    const [{ isLoading, hookData }] = useVideoHook(
        video?.id || videoId,
        video?.spaceId,
        video?.envId,
        video?.contentType,
        [video?.id, updateApi]
    );
    console.log('current video', videoId, video?.id, hookData?.sys?.id, video);

    const [{ isLoading: assetLoading, hookData: assetData }] =
        useDashboardAssetsHook();
        
    const [playlists, setPlaylists] = useState([]);
    const [sortedComments, setSortedComments] = useState([]);
    const [sortBy, setSortBy] = useState<'Latest'>('Latest');
    const [currentComment, setCurrentComment] = useState('');

    const comments = hookData.comments;
    const viewsNumber = hookData.views;

    useEffect(() => {
        // it will raise two video flicker error if miss `!video`
        if (assetData && hookData.sys && !video) {
            setVideo(
                ContentfulTransformService.getVideos(
                    assetData,
                    [hookData],
                    playlists
                )[0]
            );
        }
    }, [assetData, hookData]);

    useEffect(() => {
        if (sortBy === 'Latest') {
            const stCs = comments.sort((x, y) => {
                const dy = moment.tz(y.dateRaw, getDefaultTimezone());
                const dx = moment.tz(x.dateRaw, getDefaultTimezone());
                return dy.valueOf() - dx.valueOf();
            });
            setSortedComments(stCs);
        }
    }, [comments]);

    const isIconActive = (key: string, id: string) => {
        return !!getLocalArray(key).find((id2) => id2 === id);
    };

    const downloadVideo = () => {
        let file_path = video?.link;
        let a = document.createElement('a');
        a.href = file_path;
        a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <PageWrapper>
            <PageHeader
                back
                actions={
                    <FlexGrid gap="1.5em">
                        <SearchBar placeholder="Search banners, videos, articles here…" />
                        <SelectCountry />
                    </FlexGrid>
                }
            />
            <FlexGrid width="100%" justifyContent="center">
                <PageContent>
                    <VideoContainer flex="1" direction="column">
                        <VideoPlayer>
                            <ReactPlayer
                                controls
                                width="100%"
                                // url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                                url={video?.link}
                            />
                        </VideoPlayer>
                        <VideoHeader>
                            <Text m="0 0 1em 0" font="AR Bold" fontSize="1.5rem">
                                {/* Technology Delivering Performance */}
                                {video?.title}
                            </Text>
                            <Text m="0 0 1em 0" fontSize="0.875rem" color={COLORS.darkGray}>
                                {video?.description}
                            </Text>
                            {/* <Tag title={`${video?.playlist}`} /> */}
                            <FlexGrid gap="0.2em" wrap={'wrap'}>
                                {(video?.tags || []).map((tag: string) => (
                                    <Tag key={uuidv4()} title={tag} />
                                ))}
                            </FlexGrid>
                            <FlexGrid
                                width="100%"
                                gap="4rem"
                                alignItems="flex-end"
                            >
                                <Text
                                    fontSize="0.875rem"
                                    color={COLORS.darkGray}
                                >
                                    Views {viewsNumber}
                                    <Text span color={COLORS.lightGray}>
                                        {'  '}•{'  '}
                                    </Text>
                                    3 Days Ago
                                </Text>
                                <FlexGrid gap="0.5em">
                                    <ActionIcon
                                        icon={
                                            <ThumbsUpIcon
                                                height="30"
                                                color={'green'}
                                            />
                                        }
                                        text={`${Number(video?.likes)}`}
                                    />
                                    <ActionIcon
                                        icon={<ThumbsDownIcon height="30" />}
                                        text={`${Number(video?.dislikes)}`}
                                    />
                                    {/* <ActionIcon
                                        icon={<CommentIcon height="30" />}
                                        text="1.2k"
                                    /> */}
                                    <ActionIcon
                                        click={() => {
                                            toggleLocalArrayItem(
                                                STORAGE_SAVED_VIDEO_IDS,
                                                video?.id
                                            );
                                            setUpdate(!update);
                                        }}
                                        icon={
                                            <LoveIcon
                                                height="30"
                                                color={
                                                    isIconActive(
                                                        STORAGE_SAVED_VIDEO_IDS,
                                                        video?.id
                                                    )
                                                        ? 'green'
                                                        : ''
                                                }
                                            />
                                        }
                                    />
                                    {/* <ActionIcon
                                        click={() => {
                                            window.navigator.clipboard.writeText(
                                                video?.link
                                            );
                                        }}
                                        icon={<ShareIcon height="30" />}
                                    /> */}
                                    <ActionIcon
                                        click={() => {
                                            toggleLocalArrayItem(
                                                STORAGE_WATCH_LATER_VIDEO_IDS,
                                                video?.id
                                            );
                                            setUpdate(!update);
                                        }}
                                        icon={
                                            <WatchLaterIcon
                                                height="30"
                                                color={
                                                    isIconActive(
                                                        STORAGE_WATCH_LATER_VIDEO_IDS,
                                                        video?.id
                                                    )
                                                        ? 'green'
                                                        : ''
                                                }
                                            />
                                        }
                                    />
                                    <ActionIcon
                                        click={() => downloadVideo()}
                                        icon={<DownloadIcon height="30" />}
                                    />
                                </FlexGrid>
                            </FlexGrid>
                        </VideoHeader>
                        <CommentSection>
                            <FlexGrid
                                width="100%"
                                justifyContent="space-between"
                                alignItems="center"
                                bg={COLORS.veryLightGray}
                                p="0.5em 1em"
                            >
                                <Text color={COLORS.darkGray}>
                                    {comments.length} Comments
                                </Text>
                                <Button
                                    icon={
                                        <Tooltip title="Sort by">
                                            <SortIcon />
                                        </Tooltip>
                                    }
                                    iconSlot="start"
                                    negative
                                >
                                    <Text
                                        onClick={() => {
                                            setSortBy('Latest');
                                        }}
                                        color={COLORS.green}
                                        fontSize="0.875rem"
                                    >
                                        Latest
                                    </Text>
                                </Button>
                            </FlexGrid>
                            <Input
                                m="0.5em 0em"
                                placeholder="Post a comment..."
                                onChange={(v) => {
                                    setCurrentComment(v);
                                }}
                                onEnter={() => {
                                    if (sortedComments.length) {
                                        ApiService.createComment(
                                            sortedComments[0].id,
                                            currentComment
                                        ).then(() => {
                                            setUpdateApi(!updateApi);
                                        });
                                    }
                                }}
                                value={currentComment}
                            />
                            <FlexGrid width="100%" direction="column">
                                {sortedComments.map((comment) => (
                                    <FlexGrid
                                        justifyContent="space-between"
                                        width="100%"
                                        line
                                        p="1em 0em"
                                    >
                                        <FlexGrid gap="1em">
                                            <Avatar
                                                src={comment.author.image}
                                            />
                                            <FlexGrid
                                                gap="0.3em"
                                                direction="column"
                                            >
                                                <Text font="AR Bold">
                                                    {comment.author.name}
                                                </Text>
                                                <Text
                                                    fontSize="0.875rem"
                                                    color={COLORS.darkGray}
                                                >
                                                    {comment.content}
                                                </Text>
                                                <FlexGrid m="0.5em 0 0 0">
                                                    <Text
                                                        fontSize="0.875rem"
                                                        color={COLORS.darkGray}
                                                    >
                                                        Like {comment.likes}
                                                        <Text
                                                            span
                                                            color={
                                                                COLORS.lightGray
                                                            }
                                                        >
                                                            {'  '}•{'  '}
                                                        </Text>
                                                        Dislike{' '}
                                                        {comment.dislikes}
                                                    </Text>
                                                </FlexGrid>
                                            </FlexGrid>
                                        </FlexGrid>
                                        <Text
                                            fontSize="0.875rem"
                                            color={COLORS.darkGray}
                                        >
                                            {comment.date}
                                        </Text>
                                    </FlexGrid>
                                ))}
                                <Button negative>
                                    <Text color={COLORS.green}>Show more</Text>
                                </Button>
                            </FlexGrid>
                        </CommentSection>
                    </VideoContainer>
                    <SideBar setVideo={setVideo} setPlaylists={setPlaylists} />
                </PageContent>
            </FlexGrid>
        </PageWrapper>
    );
};
