import React from 'react';
import { COLORS } from '../styles/variables';
import { DatePicker } from './DatePicker';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';
import { TimePickerInput } from './TimePicker';

interface DateTimePickerProps {
    label?: string;
    info?: { title: any; content: any };
    value?: { date: string; time: string };
    // eslint-disable-next-line no-unused-vars
    onChange?: (op: string, newValue: string) => void;
    minDate?: Date;
    maxDate?: Date;
    minTime?: string;
    disabled?: boolean;
}

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
    label,
    info,
    value,
    onChange,
    minDate,
    maxDate,
    minTime,
    disabled
}) => {
    return (
        <FlexGrid width="100%" alignItems="center" gap="0.5em">
            <DatePicker
                minDate={minDate}
                maxDate={maxDate}
                value={value?.date}
                onChange={(newValue) => onChange && onChange('date', newValue)}
                label={`${label} Date`}
                disabled={disabled}
            />
            <FlexGrid height="100%" alignItems="center" m="1em 0em 0em 0em">
                <Text color={COLORS.darkGray}>-</Text>
            </FlexGrid>
            <TimePickerInput
                minTime={minTime}
                value={value?.time}
                label={`${label} Time`}
                info={info}
                transform={'translate(0,-2px)'}
                onChange={(newValue) => onChange && onChange('time', newValue)}
                disabled={disabled}
            />
            <FlexGrid height="100%" alignItems="center" m="1em 0em 0em 0em">
            <Text
                onClick={() => {
                    if (onChange) {
                        onChange('reset', null);
                    }
                }}
                link
                fontSize='0.8em'
                disabled={disabled}
            >
                Reset
            </Text>
            </FlexGrid>
        </FlexGrid>
    );
};
