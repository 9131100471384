import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TimePicker from 'react-time-picker';
import { COLORS } from '../styles/variables';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';
import { TimeIcon } from '../assets/icons';
import { InputContainer } from './InputContainer';
import useClickOutside from '../utils/useClickOutside';
import SvgChevronDown from '../assets/icons/chevron-down';
import moment from 'moment';
import 'moment-timezone';
import { getDefaultTimezone, getTime } from '../utils';

const InputValue = styled(Text)`
    flex: 1;
    display: flex;
    justify-content: space-between;

    .react-time-picker__wrapper {
        border: none;
    }
`;

interface InputProps {
    label: string;
    value?: any;
    // eslint-disable-next-line no-unused-vars
    onChange?: (e: any) => void;
    placeholder?: string;
    transform?: string;
    info?: { title: any; content: any };
    minTime?: string;
    disabled?: boolean;
}

export const TimePickerInput: React.FC<InputProps> = ({
    label,
    value,
    onChange,
    placeholder,
    transform,
    info,
    minTime,
    disabled,
}) => {
    const [open, setOpen] = useState(false);
    const initialRef = useRef<boolean>(false);
    const [focusHour, setFoucusHour] = useState(false);

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const close = useCallback(() => setOpen(false), []);
    useClickOutside(ref, close);

    useEffect(() => {
        if (open && !initialRef.current) {
            initialRef.current = true;
            onChange && onChange(minTime || '00:00');
        }
    }, [open]);

    const indecrement = (increment: boolean) => {
        const v = value || minTime || '00:00';
        const splited = v.split(':');
        const h = Number(splited[0]);
        const m = Number(splited[1]);

        let mv = moment.tz(getDefaultTimezone())
            .startOf('day')
            .hours(h)
            .minutes(m)
            .add(increment ? 1 : -1, focusHour ? 'hour' : 'minute')
            .format('HH:mm');

        if (minTime && getTime(minTime) > getTime(mv)) {
            mv = minTime;
        }

        onChange && onChange(mv);
    };

    useEffect(() => {
        if (ref.current) {
            const gClick = (e: MouseEvent) => {
                const el = e.target as HTMLElement;
                if (
                    (ref.current as HTMLElement).contains(
                        e.target as HTMLElement
                    )
                ) {
                    const isHour = el.classList.contains(
                        'react-time-picker__inputGroup__hour'
                    );
                    const isMinute = el.classList.contains(
                        'react-time-picker__inputGroup__minute'
                    );
                    if (isHour) {
                        setFoucusHour(true);
                    }
                    if (isMinute) {
                        setFoucusHour(false);
                    }
                }
            };
            document.addEventListener('click', gClick);

            return () => {
                document.removeEventListener('click', gClick);
            };
        }
    }, [ref.current]);

    return (
        <InputContainer
            // ref={ref}
            containerRef={ref}
            info={info}
            label={label}
            border
            transform={transform}
            disabled={disabled}
        >
            <FlexGrid m="0em 1em 0em 0em">
                <TimeIcon />
            </FlexGrid>
            <InputValue>
                {open ? (
                    <TimePicker
                        className="time-picker"
                        minutePlaceholder="00"
                        hourPlaceholder="00"
                        format="HH:mm"
                        clearIcon={null}
                        clockIcon={null}
                        value={value}
                        onChange={onChange}
                        disableClock
                        disabled={disabled}
                    />
                ) : (
                    <FlexGrid width="100%" onClick={() => !disabled && setOpen(true)}>
                        <Text color={(!disabled && value) ? COLORS.darkGray : COLORS.gray}>
                            {value || placeholder || 'Select Time'}
                        </Text>
                    </FlexGrid>
                )}
                <div style={{ height: '20px' }}>
                    <div
                        style={{
                            display: 'flex',
                            marginBottom: '-3px',
                            transform: 'rotate(180deg)',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            indecrement(true);
                        }}
                    >
                        <SvgChevronDown
                            width={'10px'}
                            height={'10px'}
                        ></SvgChevronDown>
                    </div>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            indecrement(false);
                        }}
                    >
                        <SvgChevronDown
                            width={'10px'}
                            height={'10px'}
                        ></SvgChevronDown>
                    </div>
                </div>
            </InputValue>
            {/* <FlexGrid
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap="0.5em"
            >
                <ChevronUpIcon  />
                <ChevronDownIcon />
            </FlexGrid> */}
        </InputContainer>
    );
};
