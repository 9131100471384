import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { DateTimePicker } from '../../../components/DateTimePicker';
import { FlexGrid } from '../../../components/FlexGrid';
import { Input } from '../../../components/Input';
import { PageHeader } from '../../../components/PageHeader';
import { Select } from '../../../components/Select';
import { Tag } from '../../../components/Tag';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { InAppNotificationType } from '../../../types';
import { checkIfAutoChangeEndtime, composeDate, getCountry, getDefaultTimezone, getLanguageListByCountry } from '../../../utils';
import { LIMIT_NAME_LENGTH } from '../../../common/constants';
const Form = styled(FlexGrid)`
    padding: 1em 2em;
    min-width: 500px;
    flex-direction: column;
    gap: 1em;
    margin-top: 0.5em;
`;

const Action = styled(FlexGrid)`
    border-top: 1px solid ${COLORS.lightGray};
    width: 100%;
    justify-content: space-between;
    padding: 1em 0em 1em 0em;
    margin-top: 2em;
    align-items: center;
`;

interface Props {
    stepNavigator?: any;
    inAppNotificationState: [
        InAppNotificationType,
        React.Dispatch<React.SetStateAction<InAppNotificationType>>
    ];
}

export const IdentifierDefinition: React.FC<Props> = ({
    stepNavigator,
    inAppNotificationState,
}) => {
    const navigate = useNavigate();
    const [inAppNotification, setInAppNotification] = inAppNotificationState;
    const { identifierDefinition } = inAppNotification;
    const { startDate, startTime, endDate, endTime } = identifierDefinition;

    const dataIncomplete =
        identifierDefinition.appLanguages.length === 0 ||
        identifierDefinition.name.trim() === '' ||
        !identifierDefinition.startDate ||
        !identifierDefinition.startTime;

    useEffect(() => {
        if (checkIfAutoChangeEndtime(startDate, startTime, endDate, endTime)) {
            setInAppNotification({
                ...inAppNotification,
                identifierDefinition: {
                    ...inAppNotification.identifierDefinition,
                    endTime: startTime,
                },
            });
        }
    }, [startTime, startDate, endDate, endTime]);

    return (
        <FlexGrid width="100%" direction="column">
            <PageHeader
                title={
                    <Text font="OS" fontSize="1.375rem" uppercase>
                        Identifier Definition
                    </Text>
                }
            />
            <Form>
                <Input
                    label="In-App Notification Name"
                    value={identifierDefinition.name}
                    maxLength={LIMIT_NAME_LENGTH}
                    onChange={(val) =>
                        setInAppNotification({
                            ...inAppNotification,
                            identifierDefinition: {
                                ...inAppNotification.identifierDefinition,
                                name: val,
                            },
                        })
                    }
                    placeholder="Enter"
                />
                <Select
                    form
                    label="App Language"
                    placeholder="Select"
                    info={{
                        title: 'Application language',
                        content:
                            'User can add multiple language, short desc goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                    }}
                    options={getLanguageListByCountry(getCountry())}
                    renderValue={() => '- Select -'}
                    value={identifierDefinition.appLanguages}
                    onChange={(value: any) => {
                        if (
                            !identifierDefinition.appLanguages.includes(value)
                        ) {
                            const newLanguages = [
                                ...identifierDefinition.appLanguages,
                                value,
                            ];
                            setInAppNotification({
                                ...inAppNotification,
                                identifierDefinition: {
                                    ...inAppNotification.identifierDefinition,
                                    appLanguages: newLanguages,
                                },
                            });
                        }
                    }}
                />
                <FlexGrid gap="1em">
                    {identifierDefinition.appLanguages.map((language) => (
                        <Tag
                            title={language}
                            removeBtn
                            onRemove={(value: string) =>
                                setInAppNotification({
                                    ...inAppNotification,
                                    identifierDefinition: {
                                        ...inAppNotification.identifierDefinition,
                                        appLanguages:
                                            identifierDefinition.appLanguages.filter(
                                                (el) => el !== value
                                            ),
                                    },
                                })
                            }
                        />
                    ))}
                </FlexGrid>
                <DateTimePicker
                    label="Start"
                    info={{
                        title: 'Select Date and Time',
                        content:
                            'You can add start-end date, short desc goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                    }}
                    value={{
                        date: identifierDefinition.startDate,
                        time: identifierDefinition.startTime,
                    }}
                    minDate={moment.tz(getDefaultTimezone()).toDate()}
                    maxDate={
                        identifierDefinition.endDate
                            ? composeDate(
                                  identifierDefinition.endDate,
                                  identifierDefinition.endTime || '00:00'
                              ).toDate()
                            : null
                    }
                    onChange={(op: string, newValue: any) => {
                        switch (op) {
                            case 'date':
                                setInAppNotification({
                                    ...inAppNotification,
                                    identifierDefinition: {
                                        ...inAppNotification.identifierDefinition,
                                        startDate: newValue
                                            ? moment.tz(newValue, getDefaultTimezone()).format(
                                                  'D MMMM YYYY'
                                              )
                                            : null,
                                    },
                                });
                                break;
                            case 'time':
                                setInAppNotification({
                                    ...inAppNotification,
                                    identifierDefinition: {
                                        ...inAppNotification.identifierDefinition,
                                        startTime: newValue,
                                    },
                                });
                                break;
                            case 'reset':
                                setInAppNotification({
                                    ...inAppNotification,
                                    identifierDefinition: {
                                        ...inAppNotification.identifierDefinition,
                                        startDate: null,
                                        startTime: null,
                                    },
                                });
                                break;
                            default:
                                break;
                        }
                    }}
                />
                <DateTimePicker
                    label="End"
                    info={{
                        title: 'Select Date and Time',
                        content:
                            'You can add start-end date, short desc goes here, lorem ipsum dolor sit amet, consetetur sadi elitr, sed diam nonumy eirmod tempor labore et dolore magna aliquyam.',
                    }}
                    minDate={
                        identifierDefinition.startDate
                            ? composeDate(
                                  identifierDefinition.startDate,
                                  identifierDefinition.startTime || '00:00'
                              ).toDate()
                            : moment.tz(getDefaultTimezone()).toDate()
                    }
                    value={{
                        date: identifierDefinition.endDate,
                        time: identifierDefinition.endTime,
                    }}
                    onChange={(op: string, newValue: any) => {
                        switch (op) {
                            case 'date':
                                setInAppNotification({
                                    ...inAppNotification,
                                    identifierDefinition: {
                                        ...inAppNotification.identifierDefinition,
                                        endDate: newValue
                                            ? moment.tz(newValue, getDefaultTimezone()).format(
                                                  'D MMMM YYYY'
                                              )
                                            : undefined,
                                    },
                                });
                                break;
                            case 'time':
                                setInAppNotification({
                                    ...inAppNotification,
                                    identifierDefinition: {
                                        ...inAppNotification.identifierDefinition,
                                        endTime: newValue,
                                    },
                                });
                                break;
                            case 'reset':
                                setInAppNotification({
                                    ...inAppNotification,
                                    identifierDefinition: {
                                        ...inAppNotification.identifierDefinition,
                                        endDate: null,
                                        endTime: null,
                                    },
                                });
                                break;
                            default:
                                break;
                        }
                    }}
                />
                <Action>
                    <Button
                        negative
                        icon="back"
                        title="Back"
                        onClick={() => navigate(-1)}
                    />
                    <Button
                        disabled={dataIncomplete}
                        p="1em 2em"
                        icon="next"
                        title="Next"
                        onClick={() =>
                            stepNavigator((prev: number) => prev + 1)
                        }
                    />
                </Action>
            </Form>
        </FlexGrid>
    );
};
