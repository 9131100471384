import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const DragIndicator: React.FC<Props> = ({ width, height, color }) => (
    <svg
        id="drag_indicator_black_24dp"
        xmlns="http://www.w3.org/2000/svg"
        width={width || 24}
        height={height || 24}
        viewBox="0 0 24 24"
    >
        <path
            id="Path_4860"
            data-name="Path 4860"
            d="M0,0H24V24H0Z"
            fill="none"
        />
        <path
            id="Path_4861"
            data-name="Path 4861"
            d="M11,18a2,2,0,1,1-2-2A2.006,2.006,0,0,1,11,18ZM9,10a2,2,0,1,0,2,2A2.006,2.006,0,0,0,9,10ZM9,4a2,2,0,1,0,2,2A2.006,2.006,0,0,0,9,4Zm6,4a2,2,0,1,0-2-2A2.006,2.006,0,0,0,15,8Zm0,2a2,2,0,1,0,2,2A2.006,2.006,0,0,0,15,10Zm0,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,15,16Z"
            fill={color || '#999'}
        />
    </svg>
);

export default DragIndicator;
