import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgCheck: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 13.882}
        height={height || 11.468}
        viewBox="0 0 13.882 11.468"
    >
        <path
            className="icon-stroke"
            d="m1.06 5.871 3.475 3.475 8.286-8.286"
            fill="none"
            stroke={color || '#009343'}
            strokeWidth={3}
        />
    </svg>
);

export default SvgCheck;
