import styled, { css } from 'styled-components';
import { COLORS } from '../styles/variables';

interface TextProps {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    font?: string;
    link?: boolean;
    className?: string;
    span?: boolean;
    uppercase?: boolean;
    m?: string;
    p?: string;
    lineHeight?: string;
    underline?: boolean;
    linkHoverUnderline?: boolean;
    onClick?: () => void;
    workBreakAll?: boolean;
    disabled?: boolean;
}

const TextContainer = styled.p<TextProps>`
    color: ${({ color }) => color || 'auto'};
    display: ${({ span }) => (span ? 'inline' : 'block')};
    font-size: ${({ fontSize }) => fontSize || '1rem'};
    font-weight: ${({ fontWeight }) => fontWeight || 'regular'};
    font-family: ${({ font }) => font || 'AR'};
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    margin: ${({ m }) => m || '0'};
    padding: ${({ p }) => p || '0'};
    text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
    word-break: ${({ workBreakAll }) => (workBreakAll ? 'break-all' : 'unset')};

    ${({ lineHeight }) =>
        lineHeight &&
        css`
            line-height: ${lineHeight};
        `}
`;

const AnchorContainer = styled(TextContainer)`
    color: ${({ color, disabled }) => color || (disabled ? COLORS.gray : COLORS.green)};
    display: inline;
    cursor: pointer;

    &:hover {
        text-decoration: ${({ linkHoverUnderline }) => (linkHoverUnderline ? 'underline' : 'none')};;
    }
`;

export const Text: React.FC<TextProps> = ({
    color,
    fontSize,
    fontWeight,
    font,
    children,
    className,
    link,
    span,
    uppercase,
    m,
    p,
    lineHeight,
    underline,
    linkHoverUnderline=true,
    workBreakAll,
    onClick,
    disabled,
}) => {
    if (link)
        return (
            <AnchorContainer
                className={className}
                color={color}
                fontSize={fontSize}
                fontWeight={fontWeight}
                font={font}
                span={span}
                uppercase={uppercase}
                m={m}
                p={p}
                lineHeight={lineHeight}
                underline={underline}
                workBreakAll={workBreakAll}
                linkHoverUnderline={linkHoverUnderline}
                onClick={disabled ? undefined : onClick}
                disabled={disabled}
            >
                {children}
            </AnchorContainer>
        );

    return (
        <TextContainer
            className={className}
            color={color}
            fontSize={fontSize}
            fontWeight={fontWeight}
            font={font}
            span={span}
            uppercase={uppercase}
            m={m}
            p={p}
            lineHeight={lineHeight}
            underline={underline}
            workBreakAll={workBreakAll}
            disabled={disabled}
        >
            {children}
        </TextContainer>
    );
};
