import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgChevronLeft: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 8.414}
        height={height || 14.828}
        viewBox="0 0 8.414 14.828"
    >
        <path
            className="icon-stroke"
            d="m1.414 13.414 6-6-6-6"
            fill="none"
            stroke={color || '#009343'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        />
    </svg>
);

export default SvgChevronLeft;
