import React from 'react';
import { FlexGrid } from './FlexGrid';
import { Text } from './Text';

interface SectionHeaderProps {
    title: string;
    icon?: any;
    right?: any;
    bg?: string;
    fontFamily?: string;
    height?: string;
    titlePadding?: string;
    titleMargin?: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
    icon,
    title,
    right,
    bg,
    fontFamily,
    height,
    titlePadding,
    titleMargin,
}) => {
    return (
        <FlexGrid
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            bg={bg}
            height={height}
        >
            <FlexGrid gap="0.5em" alignItems="center">
                {icon}
                <Text p={titlePadding} m={titleMargin} font={fontFamily || "AR Bold"}>{title}</Text>
            </FlexGrid>
            {right}
        </FlexGrid>
    );
};
