import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgArticle2: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 16}
        height={height || 20}
        viewBox="0 0 16 20"
    >
        <path
            className="icon-fill"
            data-name="Path 4858"
            d="M4 14h8v2H4Zm0-4h8v2H4Zm6-10H2a2.006 2.006 0 0 0-2 2v16a2 2 0 0 0 1.99 2H14a2.006 2.006 0 0 0 2-2V6Zm4 18H2V2h7v5h5Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgArticle2;
