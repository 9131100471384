import {
    HookReturnType,
    ICallbackParams,
    useApiHookWrapper,
} from '../../../hooks/use-api-hook-wrapper';

import { ContentfulApiService } from '../../../services/ContentfulApi.service';
import { IPagination } from '../../../types';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';

type Input = {
    pagination: IPagination;
};
type Output = any;

const mountFn = async (props: ICallbackParams<Input, Output>) => {
    const registerContest = await ContentfulApiService.searchContent({
        contentType: 'registrationContest',
        page: props.input.pagination.page,
        limit: props.input.pagination.perPage,
        sortBy: props.input.pagination.sortBy,
    });

    const registerContestObj =
        ContentfulTransformService.getRegisterContests(registerContest);

    console.log('registrationContest contentful data', registerContestObj);
    return registerContestObj;
};

const errorFn = async (props: ICallbackParams<Input, Output>) => {
    const { error, exception } = props;
    if (!error || !exception) {
        return {};
    }
    return { error, exception };
};

export const useRegisterContestHook = (
    pagination,
    changeList?: any[]
): HookReturnType<Input, Output> => {
    return useApiHookWrapper({
        initialInput: undefined,
        initialData: {},
        initialIsLoading: true,
        changeList,
        mountFn: (props: ICallbackParams<Input, Output>) =>
            mountFn({ ...props, input: { pagination } }),
        unmountFn: undefined,
        skipInitialApiCallOnEmptyInputs: false,
        errorFn,
    });
};
