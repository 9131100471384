import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../../../components/Button';
import { FlexGrid } from '../../../../components/FlexGrid';
import { Input } from '../../../../components/Input';
import { Modal } from '../../../../components/Modal';
import { CheckCircleBigIcon, ImageIcon } from '../../../../assets/icons';
import { Text } from '../../../../components/Text';
import { COLORS } from '../../../../styles/variables';
import { IMAGE_SIZE_MIN_HEIGHT, IMAGE_SIZE_MIN_WIDTH, LIMIT_DESCRIPTION_LENGTH, LIMIT_HEADLINE_LENGTH, LIMIT_NAME_LENGTH } from '../../../../common/constants';
import { checkImageSize, getCountry, getImageSizeBySource, getLanguageListByCountry, getTagsIntersection, isNotRequireAndWhiteSpaces } from '../../../../utils';
import { Select } from '../../../../components/Select';
import { LearningCardThemeType } from '../../../../types';
import { Tag } from '../../../../components/Tag';
import { ButtonGroup } from '../../../../components/ButtonGroup';
import { FileUploader } from '../../../../components/FileUploader';
import { HorizontalLine } from '../../../../components/HorizontalLine';
import { AddTags } from '../../../../components/AddTags';
import { useDispatch } from 'react-redux';
import { updateModalAction } from '../../../../store/store.actions';

// 1 Theme -> (n) Sections

// 1 Section -> (n) Learning cards

// 1 Learning Card -> (n) Learning Card Slides

// Theme definition:
//   - Upload Image
//   - App Language
//   - Headline
//   - Description
//   - Add Tags

// Section definition:
//   - App Language
//   - Upload Image
//   - Headline
//   - Description
//   - Link to Theme (Drop Down) - Error if languages dont match
//   - Add Tags (Common for both)

// Learning Card:
//   - Identifier Definition
//     - Learning Card Name
//     - App Language
//     - Start Date + Time
//     - End Date + Time
//   - Learning Card Definition
//     - Learning Card Headline
//     - Learning Card Description
//     - Add Tags (Optional)
//     - Section (Option to link to Section - similar to Playlists or Folders) (Error if languages dont match)
//     - Slides
//       - Upload Image (Future - Link Video)
//       - Headline
//       - Description
//       - Add More (+ - like with Scanning contests to add more slides to the learning cards)
//   - Target Geography
//   - Target User
//   - Add Quiz
//   - Send Notification (Notification should guide user to the Theme on clicking)
//   - Review

interface LearningCardThemeModalProps {
    open: boolean;
    onConfirm?: (data: LearningCardThemeType) => void;
    onClose?: () => void;
    title: string;
    success: boolean | null;
    setSuccess: (v: boolean | null) => void;
    errorMsg: string | undefined;
    setErrorMsg: (v: string | undefined) => void;
    disabled?: boolean;
    successContentText: string;
    params?: any;
}

const ActionButton = styled(Button)`
    font-family: 'OS';
    font-size: 1rem;
`;

const BorderBottom = styled(FlexGrid)`
    padding-bottom: 2.5em;
    border-bottom: 1px solid ${COLORS.lightGray};
    gap: 1em;
`;

const PreviewImage = styled(FlexGrid)`
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
    img {
        width: 100%;
        object-fit: cover;
    }
`;

const LinkText = styled(Text)`
    max-width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PreviewBtn = styled(Button)`
    flex-shrink: 0;
`;

export const LearningCardThemeModal: React.FC<LearningCardThemeModalProps> = ({
    open,
    onClose,
    onConfirm,
    success = null,
    setSuccess,
    errorMsg = undefined,
    setErrorMsg,
    successContentText,
    title,
    disabled,
    params,
}) => {
    const defaultValue: LearningCardThemeType = {
        name: '',
        appLanguages: [],
        content: {
            image: null,
            tags: [],
            sections: [],
            headline: {},
            description: {},
        },
    };

    const dispatch = useDispatch();
    const [learningCardTheme, setLearningCardTheme] =
        useState<LearningCardThemeType>(defaultValue);
    const [submitted, setSubmitted] = useState(false);

    const [activeLanguage, setActiveLanguage] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [imageSizeOk, setImageSizeOk] = useState(false);

    useEffect(() => {
        const newLearningCardTheme = { ...learningCardTheme };
        newLearningCardTheme.appLanguages.forEach((language) => {
            if (!newLearningCardTheme.content.headline[language]) {
                newLearningCardTheme.content.headline[language] = '';
            }
            if (!newLearningCardTheme.content.description[language]) {
                newLearningCardTheme.content.description[language] = '';
            }
        });
        setLearningCardTheme(newLearningCardTheme);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [learningCardTheme.appLanguages]);

    const dataIncomplete =
        disabled ||
        !learningCardTheme.content.image ||
        !imageSizeOk ||
        learningCardTheme.name?.trim() === '' ||
        learningCardTheme.appLanguages?.length <= 0 ||
        isNotRequireAndWhiteSpaces(learningCardTheme.content.headline) ||
        isNotRequireAndWhiteSpaces(learningCardTheme.content.description);

    // console.log('==dataIncomplete==', dataIncomplete, learningCardTheme.appLanguages?.length, disabled);

    const checkedLanguage = learningCardTheme.appLanguages?.map(
        (language) =>
            language === activeLanguage
    );

    useEffect(() => {
        if (learningCardTheme.content.oldImage) {
            setShowPreview(true);
        }
    }, []);

    useEffect(() => {
        if (learningCardTheme.content.image) {
            getImageSizeBySource(learningCardTheme.content.image.preview).then((imageSize) => {
                if (!checkImageSize(imageSize[0], imageSize[1])) {
                    setImageSizeOk(false);
                    dispatch(
                        updateModalAction({
                            open: true,
                            title: 'error',
                            content: `image size limit: width > ${IMAGE_SIZE_MIN_WIDTH} height > ${IMAGE_SIZE_MIN_HEIGHT}`,
                        })
                    );
                } else {
                    setImageSizeOk(true);
                }
            });
        }
    }, [learningCardTheme.content.image]);

    const reset = () => {
        if (onClose) {
            onClose();
        }
        resetParams();
    };

    const resetParams = () => {
        setLearningCardTheme(defaultValue);
        setSuccess(null);
        setErrorMsg(undefined);
        setSubmitted(false);
        setActiveLanguage('');
    };

    useEffect(() => {
        if (submitted) {    
            return;
        }
        console.log('=LearningCardThemeModal==params===', params);
        if (!params) {
            resetParams();
            return;
        }
        setLearningCardTheme(params);
        setActiveLanguage(params.appLanguages[0] ?? '');
    }, [params]);

    console.log('===activeLanguage===', activeLanguage);

    const successContent = (
        <FlexGrid
            p="1em 0em 1em 0em"
            alignItems="center"
            width="100%"
            gap="0.5em"
            m="0em 0em 2em 0em"
        >
            <CheckCircleBigIcon height="36" />
            <Text color={COLORS.darkGray}>{successContentText}</Text>
        </FlexGrid>
    );

    const inputContent = (
        <BorderBottom direction="column" width="100%" p="1em 0em">
            <Input
                label="Learning Card Module Name"
                value={learningCardTheme.name}
                maxLength={LIMIT_NAME_LENGTH}
                onChange={(val) =>
                    setLearningCardTheme({
                        ...learningCardTheme,
                        name: val,
                    })
                }
                placeholder="Enter"
            />
            <FileUploader
                fileType="image/*"
                label="Upload Image"
                value={learningCardTheme.content.image}
                onChange={(val) =>
                    setLearningCardTheme({
                        ...learningCardTheme,
                        content: {
                            ...learningCardTheme.content,
                            image: val,
                            oldImage: false,
                        },
                    })
                }
            />
            {learningCardTheme.content.image && !showPreview && (
                <>
                    <FlexGrid width="100%" justifyContent="flex-end">
                        <Button
                            onClick={() => setShowPreview(true)}
                            negative
                            title="Show image"
                        />
                    </FlexGrid>
                    <HorizontalLine
                        m="0"
                        height="1px"
                        backgroundColor={COLORS.lightGray}
                    />
                </>
            )}
            {learningCardTheme.content.image && showPreview && (
                <>
                    <PreviewImage>
                        <img src={learningCardTheme.content.image.preview} alt="learningcard-theme" />
                    </PreviewImage>
                    <FlexGrid
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                        gap="1em"
                    >
                        <FlexGrid width="100%" gap="1em">
                            <ImageIcon color={COLORS.gray} />
                            <FlexGrid direction="column" gap="0.2em">
                                <LinkText color={COLORS.green}>
                                    {learningCardTheme.content.image.name}
                                </LinkText>
                                <Text>
                                    {(learningCardTheme.content.image.size / 1e3).toFixed(2)}{' '}
                                    kb
                                </Text>
                            </FlexGrid>
                        </FlexGrid>
                        <PreviewBtn
                            onClick={() => setShowPreview(false)}
                            negative
                            title="Hide image"
                        />
                    </FlexGrid>
                </>
            )}
             {learningCardTheme.content.image && <AddTags
                value={learningCardTheme.content.tags}
                onChange={(val) =>
                    setLearningCardTheme({
                        ...learningCardTheme,
                        content: {
                            ...learningCardTheme.content,
                            tags: getTagsIntersection(
                                learningCardTheme.content.tags || [],
                                val
                                    .split(' ')
                                    .filter((el: string) => el)
                            ),
                        },
                    })
                }
            />}
            <Select
                form
                label="App Language"
                placeholder="Select"
                options={getLanguageListByCountry(getCountry())}
                renderValue={() => '- Select -'}
                value={learningCardTheme.appLanguages}
                onChange={(value: any) => {
                    if (!learningCardTheme.appLanguages.includes(value)) {
                        const newLanguages = [
                            ...learningCardTheme.appLanguages,
                            value,
                        ];
                        setLearningCardTheme({
                            ...learningCardTheme,
                            appLanguages: newLanguages,
                        });
                        setActiveLanguage(newLanguages[0]);
                    }
                }}
            />
            <FlexGrid gap="1em">
                {learningCardTheme.appLanguages.map((language, index) => (
                    <Tag
                        key={`learningcard-theme-flexgrid-lang-${index}`}
                        title={language}
                        removeBtn
                        onRemove={(value: string) => {
                            const newLanguages = learningCardTheme.appLanguages.filter(
                                (el) => el !== value
                            );
                            if (newLanguages.length > 0 && newLanguages[0] !== activeLanguage) {
                                setActiveLanguage(newLanguages[0]);
                            }
                            setLearningCardTheme({
                                ...learningCardTheme,
                                appLanguages: newLanguages,
                            });
                        }
                        }
                    />
                ))}
            </FlexGrid>
            <ButtonGroup
                titles={learningCardTheme.appLanguages}
                info={{
                    title: 'Multiple Languages',
                    content:
                        'Please fill in all the different language sections according your choices, short desc goes here, lorem ipsum dolor sit amet, consetetur .',
                }}
                active={activeLanguage}
                onChange={setActiveLanguage}
                checked={checkedLanguage}
            />
            <Input
                disabled={activeLanguage.trim() === ''}
                label="Headline"
                value={learningCardTheme.content.headline[activeLanguage] ?? ''}
                maxLength={LIMIT_HEADLINE_LENGTH}
                onChange={(val) =>
                    setLearningCardTheme((state) => {
                        const newState = { ...state };
                        newState.content.headline[activeLanguage] = val;
                        return newState;
                    })
                }
            />
            <Input
                disabled={activeLanguage.trim() === ''}
                label="Description"
                multiline
                height="250px"
                placeholder="Enter"
                rows={10}
                value={learningCardTheme.content.description[activeLanguage] ?? ''}
                maxLength={LIMIT_DESCRIPTION_LENGTH}
                onChange={(val) =>
                    setLearningCardTheme((state) => {
                        const newState = { ...state };
                        newState.content.description[activeLanguage] = val;
                        return newState;
                    })
                }
            />
            {errorMsg && (
                <Text link fontSize="0.875rem" color={COLORS.red}>
                    {errorMsg}
                </Text>
            )}
        </BorderBottom>
    );

    const inputActions = (
        <FlexGrid alignItems="center">
            <ActionButton
                disabled={dataIncomplete}
                onClick={() => {
                    setSubmitted(true);
                    onConfirm && onConfirm(learningCardTheme);
                }}
                p="0.6em 2em"
            >
                {params ? 'Update' : 'Add'} module
            </ActionButton>
            <ActionButton onClick={reset} negative>
                Cancel
            </ActionButton>
        </FlexGrid>
    );

    const successActions = (
        <ActionButton onClick={reset} p="0.6em 2.5em" m="0 0 2em 0">
            Done
        </ActionButton>
    );

    return (
        <Modal
            open={open}
            header={title}
            onClose={reset}
            content={success ? successContent : inputContent}
            actions={success ? successActions : inputActions}
            maxWidth='60%'
        />
    );
};
