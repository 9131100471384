import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { FlexGrid } from '../../../../components/FlexGrid';
import {
    Table,
    TableCell,
    TableHead,
    // TablePagination,
    TableRow,
} from '../../../../components/Table';
import { Text } from '../../../../components/Text';
import { COLORS } from '../../../../styles/variables';
import { ArrowDownIcon, BonusPointsIcon } from '../../../../assets/icons';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { IconButton } from '../../../../components/IconButton';
import { useLearningCardHook } from '../hooks/use-learning-card-hook';
import { Pagination } from '../../../../components/Pagination';
import { ContentfulApiService } from '../../../../services/ContentfulApi.service';
import {
    LearningCardItemToEditModel,
    QuizItemToEditModel,
    getDefaultTimezone,
    getUrlKeepQuery,
    // roundDecimals,
} from '../../../../utils';
import { sortFunc } from '../../../../common';
import { ContentfulTransformService } from '../../../../services/ContentfulTransform.service';
import { ApiService } from '../../../../services/Api.service';
import { ButtonGroup } from '../../../../components/ButtonGroup';
// import { ButtonGroup } from '../../../../components/ButtonGroup';
// import { ApiService } from '../../../../services/Api.service';

const TableActionBtn = styled(Button)`
    text-transform: none;
    font-family: 'AR';
    border-radius: 0;
    margin: 0;
    border-left: 1px solid ${COLORS.lightGray};

    &:first-of-type {
        border-left: none;
    }
`;

export const LearningCardsTable: React.FC = () => {
    const navigate = useNavigate();
    const [current, setCurrent] = useState(null);
    const [update, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeLanguage, setActiveLanguage] = useState('');
    const perPage = 5;
    const DEFAULT_SORT = {
        name: 0,
        languages: 0,
        linkedSection: 0,
        startDate: 0,
    };
    const [sort, setSort] = useState<any>(DEFAULT_SORT);

    const location = useLocation() as any;
    const [page, setPage] = useState(location.state?.page || 1);

    const [{ isLoading, hookData: learningCardsObj }] = useLearningCardHook(
        {
            // page,
            // perPage,
            // sortBy: `${sort.date ? '' : '-'}fields.startDate`,
        },
        [update]
    );

    const [deleteModal, setDeleteModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);

    const [cardsDashboardStats, setCardsDashboardStats] = useState({
        totalCards: 8,
        cardsActive: 5,
        cardsExpired: 3,
        pointsDisbursed: 50,
        totalParticipants: 2,
    });

    useEffect(() => {
        ApiService.getLearningCardsDashboardStats()
            .then((response) => {
                // {
                //     "totalCards": 8,
                //     "cardsActive": 5,
                //     "cardsExpired": 3,
                //     "pointsDisbursed": 50,
                //     "totalParticipants": 2
                // }
                const data = response.data;
                setCardsDashboardStats(data);
                console.log('==getLearningCardsDashboardStats==', data);
            })
            .catch((err) =>
                console.error(
                    '===Failed to fetch learning cards dashboard stats===',
                    err
                )
            );
    }, []);

    let learningCards = sortFunc(sort, learningCardsObj.mapped ?? []);
    const total = learningCardsObj.raw?.total || 0;
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    learningCards = learningCards.slice(startIndex, endIndex);

    const itemToEditModel = async (learningCard) => {
        let quizEditModel;
        if (learningCard.quiz) {
            const quizEntryId = learningCard.quiz.sys.id;
            const contentfulQuiz = await ContentfulApiService.getEntry({
                contentType: 'quiz',
                entryId: quizEntryId,
            });
            const transformedQuiz =
                ContentfulTransformService.transformQuiz(contentfulQuiz);
            quizEditModel = await QuizItemToEditModel(transformedQuiz);
        }

        const toReturn = await LearningCardItemToEditModel(learningCard);

        if (quizEditModel) {
            toReturn.quiz = {
                raw: quizEditModel.raw,
                status: quizEditModel.status,
                content: quizEditModel.content,
            };
        }

        console.log('edit infomation', toReturn);
        return toReturn;
    };

    // useEffect(() => {
    //     // ApiService.getLearningCardsDashboardStats()
    //     //     .then((response) => {
    //     //         // {
    //     //         //     pointsDisbursed: 311;
    //     //         //     learningCardsActive: 3;
    //     //         //     learningCardsExpired: 1;
    //     //         //     totalParticipants: 1;
    //     //         //     totalContestLaunched: 4;
    //     //         // }
    //     //         const data = response.data;
    //     //         setLearningCardsDashboardStats(data);
    //     //         console.log('==getLearningCardsDashboardStats==', data);
    //     //     })
    //     //     .catch((err) =>
    //     //         console.error(
    //     //             '===Failed to fetch learning cards dashboard stats===',
    //     //             err
    //     //         )
    //     //     );
    // }, []);

    return (
        <>
            {!isLoading && (
                <>
                    <Modal
                        open={deleteModal}
                        header="Delete Learning Card"
                        onClose={() => setDeleteModal(false)}
                        content={
                            <FlexGrid
                                direction="column"
                                width="100%"
                                gap="2em"
                                p="2em 0em"
                            >
                                <Text color={COLORS.darkGray}>
                                    Are you sure you want to delete this
                                    learning card?
                                </Text>
                                <Text color={COLORS.darkGray}>
                                    <Text
                                        color={COLORS.darkGray}
                                        span
                                        font="AR Bold"
                                    >
                                        Note:{' '}
                                    </Text>
                                    Deleting a learning card is a permanent
                                    action and cannot be undone.
                                </Text>
                            </FlexGrid>
                        }
                        actions={
                            <FlexGrid alignItems="center">
                                <Button
                                    onClick={() => {
                                        ContentfulApiService.deleteContent(
                                            current.raw.sys.id
                                        )
                                            .then(() => {
                                                setUpdate(!update);
                                                if (total % perPage === 1) {
                                                    setPage(
                                                        Math.max(page - 1, 1)
                                                    );
                                                }
                                            })
                                            .catch(() => {
                                                alert(
                                                    'Cannot delete published'
                                                );
                                            });
                                        setDeleteModal(false);
                                    }}
                                    p="1.3em 2.5em"
                                >
                                    DELETE
                                </Button>
                                <Button
                                    onClick={() => setDeleteModal(false)}
                                    negative
                                    p="0.5em 1em"
                                >
                                    CANCEL
                                </Button>
                            </FlexGrid>
                        }
                    />
                    {current && (
                        <Modal
                            open={detailsModal}
                            header={`${current.identifierDefinition.name} details`}
                            onClose={() => setDetailsModal(false)}
                            content={
                                <FlexGrid direction="column">
                                    <Table>
                                        <TableRow>
                                            <TableHead padding="2em 0em 0em 0em">
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Number
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead padding="2em 0em 0em 0em">
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Slide
                                                </FlexGrid>
                                            </TableHead>
                                            <TableHead padding="2em 0em 0em 0em">
                                                <FlexGrid
                                                    alignItems="center"
                                                    gap="0.8em"
                                                >
                                                    Completed Learners
                                                </FlexGrid>
                                            </TableHead>
                                        </TableRow>
                                        {current.statsData &&
                                        current.statsData.length > 0 ? (
                                            current.statsData.map(
                                                (statData, index) => (
                                                    <TableRow
                                                        key={`stat-detail-${index}`}
                                                    >
                                                        <TableCell
                                                            verticalAlign="top"
                                                            width="250px"
                                                        >
                                                            <Text
                                                                fontSize="0.875rem"
                                                                workBreakAll
                                                            >
                                                                {index + 1}
                                                            </Text>
                                                        </TableCell>
                                                        <TableCell
                                                            verticalAlign="top"
                                                            width="250px"
                                                        >
                                                            <Text
                                                                fontSize="0.875rem"
                                                                workBreakAll
                                                            >
                                                                {statData.slide ??
                                                                    ''}
                                                            </Text>
                                                        </TableCell>
                                                        <TableCell
                                                            verticalAlign="top"
                                                            width="250px"
                                                        >
                                                            <Text
                                                                fontSize="0.875rem"
                                                                workBreakAll
                                                            >
                                                                {statData.completedUsers ??
                                                                    0}
                                                            </Text>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            )
                                        ) : (
                                            <TableRow key={`stat-detail-empty`}>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {''}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {''}
                                                    </Text>
                                                </TableCell>
                                                <TableCell
                                                    verticalAlign="top"
                                                    width="250px"
                                                >
                                                    <Text
                                                        fontSize="0.875rem"
                                                        workBreakAll
                                                    >
                                                        {''}
                                                    </Text>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Table>
                                </FlexGrid>
                            }
                        />
                    )}
                    <Table>
                        <TableRow>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Cards
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Learners (Participants)
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Active Cards
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Total Expired Cards
                                </FlexGrid>
                            </TableHead>
                            <TableHead
                                padding="2em 0em 0em 0em"
                                borderBottomDisabled
                            >
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Point Disbursed
                                </FlexGrid>
                            </TableHead>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {cardsDashboardStats.totalCards}
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {cardsDashboardStats.totalParticipants}
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {cardsDashboardStats.cardsActive}
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {cardsDashboardStats.cardsExpired}
                                </Text>
                            </TableCell>
                            <TableCell
                                padding="0em 0em 0em 0em"
                                borderBottomDisabled
                                verticalAlign="top"
                                width="250px"
                            >
                                <Text
                                    font="OS"
                                    fontSize="1.5rem"
                                    color={COLORS.black}
                                >
                                    {`${cardsDashboardStats.pointsDisbursed} pts`}
                                </Text>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Table>
                        <TableRow>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Name
                                    {sort.name === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.name === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    name: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Languages
                                    {sort.languages === 0 && (
                                        <IconButton
                                            negative
                                            transform="rotate(-90deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    languages: 1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.languages === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    languages: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.languages === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    languages: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Linked Section
                                    {sort.linkedSection === 0 && (
                                        <IconButton
                                            negative
                                            transform="rotate(-90deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    linkedSection: 1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.linkedSection === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    linkedSection: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.linkedSection === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    linkedSection: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>
                                <FlexGrid alignItems="center" gap="0.8em">
                                    Start Date
                                    {sort.startDate === 0 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: 1,
                                                });
                                            }}
                                            transform="rotate(-90deg)"
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.startDate === 1 && (
                                        <IconButton
                                            negative
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: -1,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                    {sort.startDate === -1 && (
                                        <IconButton
                                            negative
                                            transform="rotate(180deg)"
                                            onClick={() => {
                                                setSort({
                                                    ...DEFAULT_SORT,
                                                    startDate: 0,
                                                });
                                            }}
                                            icon={<ArrowDownIcon />}
                                        />
                                    )}
                                </FlexGrid>
                            </TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                        {learningCards.map((learningCard, key) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell>
                                        <Text
                                            link
                                            fontSize="0.875rem"
                                            color={COLORS.green}
                                            onClick={() => {
                                                navigate(
                                                    getUrlKeepQuery(
                                                        `/learningCard/${learningCard.id}`
                                                    )
                                                );
                                            }}
                                        >
                                            {learningCard?.name}
                                        </Text>
                                    </TableCell>
                                    <TableCell>
                                        {learningCard?.language?.join(', ')}
                                    </TableCell>
                                    <TableCell>
                                        {learningCard?.referencedSection ? (
                                            <FlexGrid
                                                alignItems="center"
                                                gap="0.5em"
                                            >
                                                <Text
                                                    color={COLORS.green}
                                                    fontSize="0.875rem"
                                                    workBreakAll
                                                >
                                                    {
                                                        learningCard
                                                            ?.referencedSection
                                                            .name
                                                    }
                                                </Text>
                                            </FlexGrid>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid direction="column">
                                            <Text fontSize="0.875rem">
                                                {moment
                                                    .tz(
                                                        learningCard?.startDate,
                                                        'DD MMMM YYYY',
                                                        getDefaultTimezone()
                                                    )
                                                    .format('MMM DD, YYYY')}
                                            </Text>
                                            <Text fontSize="0.875rem">
                                                {learningCard?.status}
                                            </Text>
                                        </FlexGrid>
                                    </TableCell>
                                    <TableCell>
                                        <FlexGrid alignItems="center">
                                            <TableActionBtn
                                                onClick={async () => {
                                                    const cardItemModel =
                                                        await itemToEditModel(
                                                            learningCard
                                                        );
                                                    setDetailsModal(true);
                                                    setActiveLanguage(
                                                        cardItemModel
                                                            .identifierDefinition
                                                            .appLanguages[0]
                                                    );
                                                    const entryId =
                                                        cardItemModel.raw.sys
                                                            .id;
                                                    const statsResponse =
                                                        await ApiService.getSingleLearningCardStats(
                                                            entryId
                                                        );
                                                    const statsData =
                                                        statsResponse?.data;
                                                    // [
                                                    //     {
                                                    //         "entryId": "1PDprm86139Cl9RwqSybDF",
                                                    //         "slide": "Video Slide 1 Eng",
                                                    //         "completedUsers": 2
                                                    //     },
                                                    //     {
                                                    //         "entryId": "3rHJliJ00NU9vnkTBE0kat",
                                                    //         "slide": "Video Image Slide 2 Eng",
                                                    //         "completedUsers": 2
                                                    //     },
                                                    //     {
                                                    //         "entryId": "3y69BUci2YfWamGaoAjZpY",
                                                    //         "slide": "Video Slide 3 Eng",
                                                    //         "completedUsers": 2
                                                    //     },
                                                    //     {
                                                    //         "entryId": "7p1o3dlSxO0mjilnc4WuZh",
                                                    //         "slide": "Video Slide 4 Eng",
                                                    //         "completedUsers": 1
                                                    //     }
                                                    // ]
                                                    // console.log(
                                                    //     '==statsData==',
                                                    //     statsData
                                                    // );
                                                    if (statsData) {
                                                        (
                                                            cardItemModel as any
                                                        ).statsData = statsData;
                                                    }
                                                    setCurrent(cardItemModel);
                                                }}
                                                negative
                                            >
                                                Stats
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={async () => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    setLoading(true);
                                                    const data =
                                                        await itemToEditModel(
                                                            learningCard
                                                        );
                                                    console.log(
                                                        '=learning card view=data==',
                                                        data
                                                    );
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/learning/learning-card/cards/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data,
                                                                page,
                                                                viewOnly: true,
                                                            },
                                                        }
                                                    );
                                                }}
                                                negative
                                            >
                                                View
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={async () => {
                                                    if (loading) {
                                                        return;
                                                    }
                                                    setLoading(true);
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            '/learning/learning-card/cards/create'
                                                        ),
                                                        {
                                                            state: {
                                                                data: await itemToEditModel(
                                                                    learningCard
                                                                ),
                                                                page,
                                                            },
                                                        }
                                                    );
                                                }}
                                                negative
                                            >
                                                Edit
                                            </TableActionBtn>
                                            <TableActionBtn
                                                onClick={async () => {
                                                    const cardItemModel =
                                                        await itemToEditModel(
                                                            learningCard
                                                        );
                                                    setCurrent(cardItemModel);
                                                    setDeleteModal(true);
                                                }}
                                                negative
                                            >
                                                Remove
                                            </TableActionBtn>
                                        </FlexGrid>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </Table>
                    <Pagination
                        page={page}
                        perPage={perPage}
                        total={total}
                        setPage={(v) => {
                            setPage(v);
                        }}
                    />
                </>
            )}
        </>
    );
};
