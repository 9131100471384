import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { FlexGrid } from '../../../components/FlexGrid';
import { Text } from '../../../components/Text';
import { COLORS } from '../../../styles/variables';
import { Button } from '../../../components/Button';
import { SectionHeader } from '../../../components/SectionHeader';
import { Tag } from '../../../components/Tag';

import {
    PlayButtonIcon,
    VideosIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ThumbsUpIcon,
    ThumbsDownIcon,
    CommentIcon,
    More2Icon,
} from '../../../assets/icons';

import { ActionIcon } from '../../../components/ActionIcon';
import { useDashboardAssetsHook } from '../hooks/use-assets-hook';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { useVideosHook } from '../../Videos/hooks/use-videos-hook';
import { DEFAULT_LANGUAGE_CODE, PLACEHOLDER_IMAGE } from '../../../common/constants';
import { cfLanGet, getUrlKeepQuery } from '../../../utils';

const VideoThumbnail = styled(FlexGrid)`
    position: relative;
    height: 240px;
    width: 420px;
    overflow: hidden;
    border-radius: 5px;

    .thumbnail {
        width: 100%;
        object-fit: cover;
    }
`;
const Length = styled(Text)`
    position: absolute;
    z-index: 1;
    left: 1em;
    bottom: 1em;
    font-size: 0.875rem;
    color: ${COLORS.white};
    background-color: ${COLORS.transparentBlack75};
    padding: 0.5em 0.7em;
    border-radius: 5px;
`;
const PlayButton = styled(FlexGrid)`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    align-items: center;
`;

const Container = styled(FlexGrid)`
    padding: 2em 0em;
    border-bottom: 1px solid ${COLORS.lightGray};
    width: 100%;
    gap: 2em;
`;

const SeeAllBtn = styled(Button)`
    font-family: 'OS';
    font-size: 1rem;
`;

export const Videos: React.FC = () => {
    const navigate = useNavigate();
    const [{ isLoading: videoIsLoading, hookData: videosHookData }] =
        useVideosHook(
            {
                perPage: 5,
                page: 1,
            },
            []
        );
    // const [{ isLoading: assetsIsLoading, hookData: assets }] =
    //     useDashboardAssetsHook();
    const assets = videosHookData?.assets;

    const [vIndex, setVIndex] = useState(0);

    console.log('videos dashboard example', videosHookData);

    // use second example for test now
    const hookData = videosHookData?.items[vIndex];

    const videoFeilds = hookData?.fields;

    // const isLoading = videoIsLoading || assetsIsLoading;
    const isLoading = videoIsLoading;

    let videos =
        videosHookData &&
        assets &&
        ContentfulTransformService.getVideos(
            assets,
            videosHookData.items,
            videosHookData.playlist?.items
        );

    const video = (videos || [])[vIndex];

    const videoIndexBack = () => {
        let nI = vIndex - 1;
        if (nI < 0) {
            nI = videos.length - 1;
        }
        setVIndex(nI);
    };
    const videoIndexNext = () => {
        let nI = vIndex + 1;
        if (nI > videos.length - 1) {
            nI = 0;
        }
        setVIndex(nI);
    };

    return (
        <Container>
            {!isLoading && !!videosHookData?.items?.length && (
                <>
                    <VideoThumbnail>
                        {video?.image ? (
                            <img
                                className="thumbnail"
                                src={video?.image || PLACEHOLDER_IMAGE}
                                alt="thumbnail"
                            />
                        ) : (
                            // the video tag will preload fist frame as thunmbnail
                            <video
                                src={video?.link}
                                style={{ width: '100%', height: '100%' }}
                            ></video>
                        )}
                        {/* <Length>
                            {videoFeilds?.videoDuration &&
                                videoFeilds?.videoDuration[DEFAULT_LANGUAGE_CODE]}
                        </Length> */}
                        <PlayButton>
                            <Button
                                onClick={() =>
                                    navigate(
                                        getUrlKeepQuery(
                                            `/video/${hookData.sys.id}`
                                        )
                                    )
                                }
                                negative
                            >
                                <PlayButtonIcon height="56" />
                            </Button>
                        </PlayButton>
                    </VideoThumbnail>
                    <FlexGrid direction="column" flex="1">
                        <SectionHeader
                            title="VIDEOS"
                            icon={VideosIcon}
                            right={
                                <FlexGrid alignItems="center">
                                    <SeeAllBtn
                                        onClick={() =>
                                            navigate(
                                                getUrlKeepQuery(
                                                    '/communication/videos'
                                                )
                                            )
                                        }
                                        p="0"
                                        m="0em 0.5em"
                                        negative
                                    >
                                        SEE ALL
                                    </SeeAllBtn>
                                    <FlexGrid alignItems="center">
                                        <Button
                                            p="0"
                                            m="0em 1em"
                                            negative
                                            onClick={() => videoIndexBack()}
                                        >
                                            <ChevronRightIcon />
                                        </Button>
                                        <Button
                                            p="0"
                                            m="0em 1em"
                                            negative
                                            onClick={() => videoIndexNext()}
                                        >
                                            <ChevronLeftIcon />
                                        </Button>
                                    </FlexGrid>
                                </FlexGrid>
                            }
                        />
                        <FlexGrid
                            direction="column"
                            flex="1"
                            width="100%"
                            p="1em 2em 1em 0em"
                        >
                            <Tag title={`${video?.playlist}`} />
                            <Text
                                font="AR Bold"
                                m="0.5em 0em"
                                fontSize="1.5rem"
                            >
                                {cfLanGet(videoFeilds, 'videoTitle')}
                            </Text>
                            <Text color={COLORS.darkGray} fontSize="0.875rem">
                                {(video?.description || {})[DEFAULT_LANGUAGE_CODE] ||
                                    ''}{' '}
                                <Text fontSize="0.875rem" link>
                                    Read more
                                </Text>
                            </Text>
                            <FlexGrid m="0.5em 0em 1em 0em" gap="0.5em">
                                <Text
                                    color={COLORS.darkGray}
                                    fontSize="0.75rem"
                                >
                                    Views {video?.views ?? 0}
                                </Text>
                                <Text color={COLORS.gray} fontSize="0.75rem">
                                    •
                                </Text>
                                <Text
                                    color={COLORS.darkGray}
                                    fontSize="0.75rem"
                                >
                                    {/* TODO create Date UTIl */}
                                    {video?.date}
                                </Text>
                            </FlexGrid>
                            <FlexGrid gap="0.8rem" m="1em 0em 0em 0em">
                                <ActionIcon
                                    icon={<ThumbsUpIcon color="green" />}
                                    text={
                                        videoFeilds?.likes
                                            ? videoFeilds?.likes[DEFAULT_LANGUAGE_CODE]
                                            : 0
                                    }
                                />
                                <ActionIcon
                                    icon={<ThumbsDownIcon />}
                                    text={
                                        videoFeilds?.dislikes
                                            ? videoFeilds?.dislikes[
                                                  DEFAULT_LANGUAGE_CODE
                                              ]
                                            : 0
                                    }
                                />
                                <ActionIcon
                                    icon={<CommentIcon />}
                                    text={`${hookData?.comments?.length || 0}`}
                                />
                                <ActionIcon icon={<More2Icon />} />
                            </FlexGrid>
                        </FlexGrid>
                    </FlexGrid>
                </>
            )}
        </Container>
    );
};
