import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    ArticlesIcon,
    FilterIcon,
    FolderIcon,
    ImageIcon,
    InAppNotification2Icon,
    MoreIcon,
    BellIcon,
    SearchIcon,
    VideoPlaylistIcon,
    VideosIcon,
    QuizIcon,
} from '../../assets/icons';
import { Button } from '../../components/Button';
import { DatePicker } from '../../components/DatePicker';
import { FlexGrid } from '../../components/FlexGrid';
import { HorizontalLine } from '../../components/HorizontalLine';
import { IconButton } from '../../components/IconButton';
import { ListItem } from '../../components/ListItem';
import { PageHeader } from '../../components/PageHeader';
import { PageWrapper } from '../../components/PageWrapper';
import { SearchBar } from '../../components/SearchBar';
import { Select } from '../../components/Select';
import { SelectCountry } from '../../components/SelectCountry';
import { Tag } from '../../components/Tag';
import { Text } from '../../components/Text';
import { ContentfulApiService } from '../../services/ContentfulApi.service';
import { ContentfulTransformService } from '../../services/ContentfulTransform.service';
import { COLORS } from '../../styles/variables';
import { useDashboardAssetsHook } from '../Dashboard/hooks/use-assets-hook';
// import COUNTRY_LANGUAGE_MULTIPLE_MAP from '../../assets/locales/country_default_language_mapping.json';
import {
    COUNTRY_LANGUAGE_MULTIPLE_MAP,
    PLACEHOLDER_IMAGE,
    STORAGE_RECENT_SEARCHED,
} from '../../common/constants';
import { clearLocalArray, getDefaultTimezone, getUrlKeepQuery, QuizItemToEditModel } from '../../utils';
import { InappNotificationItemToEditModel } from '../InAppNotifications/components/InAppNotificationsTable';
import { PushNotifciationItemToEditModel } from '../PushNotifications/components/PushNotificationsTable';
import { BanneritemToEditModel } from '../Banners/components/BannerTable';

const PageContent = styled(FlexGrid)`
    max-width: 1020px;
    width: 100%;
    padding: 2em 0em;
    gap: 2em;
`;

const BorderBottom = styled(FlexGrid)`
    border-bottom: 1px solid ${COLORS.lightGray};
    padding: 1em 0em;
`;

const ImageContainer = styled(FlexGrid)`
    width: 100px;
    height: 64px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    img {
        object-fit: cover;
        height: 100%;
    }
`;

const Length = styled(Text)`
    position: absolute;
    bottom: 0.2em;
    left: 0.2em;
    font-size: 0.75rem;
    background-color: ${COLORS.transparentBlack75};
    color: ${COLORS.white};
    padding: 0.3em;
    border-radius: 2px;
`;

export const SearchResultPage: React.FC = () => {
    // moment.tz(getDefaultTimezone()).format('D MMMM YYYY')
    const [activeFilterTab, setActiveFilterTab] = useState('All');
    const [datePost, setDatePost] = useState('All');
    const [startDate, setStartDate] = useState<string | undefined>(undefined);
    const [endDate, setEndDate] = useState<string | undefined>(undefined);
    const [sortBy, setSortBy] = useState('Most Recent');
    const [appLanguage, setAppLanguage] = useState<string[]>([]);
    const [apiResult, setApiResult] = useState();

    const [{ hookData: assets }] = useDashboardAssetsHook();

    const filterTabs = [
        {
            title: 'All',
            icon: <SearchIcon color={COLORS.gray} />,
        },
        {
            title: 'Articles',
            icon: <ArticlesIcon color={COLORS.gray} />,
        },
        {
            title: 'Article Folder',
            icon: <FolderIcon color={COLORS.gray} />,
        },
        {
            title: 'Banners',
            icon: <ImageIcon color={COLORS.gray} />,
        },
        {
            title: 'In-app notification',
            icon: <InAppNotification2Icon color={COLORS.gray} />,
        },
        {
            title: 'Push notification',
            icon: <BellIcon color={COLORS.gray} />,
        },
        {
            title: 'Videos',
            icon: <VideosIcon color={COLORS.gray} />,
        },
        {
            title: 'Video playlist',
            icon: <VideoPlaylistIcon color={COLORS.gray} />,
        },
        {
            title: 'Quiz',
            icon: <QuizIcon color={COLORS.gray} />,
        },
    ];

    let results: any[] = [];

    if (assets && apiResult) {
        results = ContentfulTransformService.getContents(
            assets,
            apiResult,
            [],
            []
        );
    }

    if (activeFilterTab !== 'All') {
        results = results.filter((r) => r.type === activeFilterTab);
    }

    if (appLanguage.length) {
        results = results.filter((r) => {
            for (const lan of appLanguage) {
                if (!(r.language || []).includes(lan)) {
                    return false;
                }
            }
            return true;
        });
    }

    if (datePost !== 'All') {
        if (datePost === 'Today') {
            results = results.filter(
                (r) => moment.tz(r.startDate, getDefaultTimezone()).valueOf() > moment.tz(getDefaultTimezone()).valueOf()
            );
        }
        if (datePost === 'This Week') {
            results = results.filter(
                (r) =>
                    moment.tz(r.startDate, getDefaultTimezone()).valueOf() >
                    moment.tz(getDefaultTimezone()).add(-7, 'days').valueOf()
            );
        }
        if (datePost === 'This Month') {
            results = results.filter(
                (r) =>
                    moment.tz(r.startDate, getDefaultTimezone()).valueOf() >
                    moment.tz(getDefaultTimezone()).add(-30, 'days').valueOf()
            );
        }
        if (datePost === 'Custom Date') {
            if (startDate) {
                results = results.filter(
                    (r) =>
                        moment.tz(r.startDate, getDefaultTimezone()).valueOf() >
                        moment.tz(startDate, getDefaultTimezone()).valueOf()
                );
            }
            if (endDate) {
                results = results.filter(
                    (r) =>
                        moment.tz(r.startDate, getDefaultTimezone()).valueOf() <
                        moment.tz(endDate, getDefaultTimezone()).valueOf()
                );
            }
        }
    }

    useEffect(() => {}, []);

    const params = useParams();
    const navigate = useNavigate();

    const itemNavigate = (result) => {
        switch (result.type) {
            case 'Videos':
                navigate(getUrlKeepQuery(`/video/${result.id}`));
                break;
            case 'Video playlist':
                navigate(
                    getUrlKeepQuery(
                        `/communication/video-playlist/${result.id}`
                    )
                );
                break;
            case 'Banners':
                navigate(getUrlKeepQuery(`/communication/banners/create`), {
                    state: {
                        data: BanneritemToEditModel(result),
                    },
                });
                break;
            case 'Articles':
                navigate(getUrlKeepQuery(`/article/${result.id}`), {
                    state: { articleId: result.id },
                });
                break;
            case 'Article Folder':
                navigate(
                    getUrlKeepQuery(
                        `/communication/article-folder/${result.id}`
                    )
                );
                break;
            case 'In-app notification':
                navigate(
                    getUrlKeepQuery(
                        `/communication/in-app-notifications/create`
                    ),
                    {
                        state: {
                            data: InappNotificationItemToEditModel(result),
                        },
                    }
                );
                break;
            case 'Push notification':
                navigate(
                    getUrlKeepQuery(`/communication/push-notifications/create`),
                    {
                        state: {
                            data: PushNotifciationItemToEditModel(result),
                        },
                    }
                );
                break;
            // case 'Quiz':
            //     QuizItemToEditModel(result).then((resultData) => navigate(
            //         getUrlKeepQuery(`/communication/quizzes/create`),
            //         {
            //             state: {
            //                 data: resultData,
            //             },
            //         }
            //     ));
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (params.searchString) {
            ContentfulApiService.searchContent({
                query: params.searchString,
            }).then((res) => {
                console.log('searched', params.searchString, res);
                setApiResult(res);
            });
        } else {
            setApiResult(null);
        }
    }, [params.searchString]);

    return (
        <PageWrapper>
            <PageHeader
                back
                actions={
                    <FlexGrid gap="1.5em">
                        <SearchBar placeholder="Search banners, videos, articles here…" />
                        <SelectCountry />
                    </FlexGrid>
                }
            />
            <FlexGrid width="100%" justifyContent="center">
                <PageContent>
                    <FlexGrid direction="column" width="30%" gap="1em">
                        <FlexGrid
                            width="100%"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <FlexGrid alignItems="center" gap="0.5em">
                                <FilterIcon />
                                <Text uppercase font="AR Bold">
                                    Filter
                                </Text>
                            </FlexGrid>
                            <Button
                                negative
                                onClick={() => {
                                    // clearLocalArray(STORAGE_RECENT_SEARCHED)
                                    navigate(getUrlKeepQuery(`/search`));
                                }}
                            >
                                Reset
                            </Button>
                        </FlexGrid>
                        <FlexGrid width="100%" direction="column">
                            {filterTabs.map((tab) => (
                                <ListItem
                                    active={activeFilterTab === tab.title}
                                    onClick={() =>
                                        setActiveFilterTab(tab.title)
                                    }
                                    contentPadding="0.8em 1em"
                                    icon={tab.icon}
                                    color={COLORS.green}
                                    title={
                                        <Text
                                            className="list-title"
                                            color={
                                                activeFilterTab === tab.title
                                                    ? COLORS.black
                                                    : COLORS.green
                                            }
                                        >
                                            {tab.title}
                                        </Text>
                                    }
                                />
                            ))}
                        </FlexGrid>
                        <HorizontalLine
                            backgroundColor={COLORS.veryLightGray}
                            height="1px"
                        />
                        <Select
                            form
                            label="Date post"
                            value={datePost}
                            options={[
                                'All',
                                'Today',
                                'This Week',
                                'This Month',
                                'Custom Date',
                            ]}
                            onChange={(value) => setDatePost(value)}
                            renderOption={(option) =>
                                option === 'Custom Date' ? (
                                    <Text color={COLORS.green}>{option}</Text>
                                ) : (
                                    option
                                )
                            }
                            renderValue={(value) => (
                                <Text color={COLORS.green}>{value}</Text>
                            )}
                        />
                        {datePost === 'Custom Date' && (
                            <>
                                <DatePicker
                                    label="From"
                                    maxDate={
                                        endDate
                                            ? moment.tz(endDate, getDefaultTimezone()).toDate()
                                            : null
                                    }
                                    value={[startDate, endDate]}
                                    onChange={(value) => {
                                        setStartDate(
                                            moment.tz(value, getDefaultTimezone()).format('D MMMM YYYY')
                                        );
                                    }}
                                    range
                                    returnValue="start"
                                />
                                <DatePicker
                                    label="To"
                                    minDate={
                                        startDate
                                            ? moment.tz(startDate, getDefaultTimezone())
                                                  .add('days', 1)
                                                  .toDate()
                                            : null
                                    }
                                    value={[startDate, endDate]}
                                    onChange={(value) => {
                                        setEndDate(
                                            moment.tz(value, getDefaultTimezone()).format('D MMMM YYYY')
                                        );
                                    }}
                                    range
                                    returnValue="end"
                                />
                            </>
                        )}
                        <Select
                            form
                            label="Sort by"
                            value={sortBy}
                            options={['Most Recent']}
                            onChange={(value) => setSortBy(value)}
                            renderValue={(value) => (
                                <Text color={COLORS.green}>{value}</Text>
                            )}
                        />
                        <Select
                            form
                            label="App Language"
                            value={appLanguage}
                            options={_.values(COUNTRY_LANGUAGE_MULTIPLE_MAP).map(
                                (x) => x[0]
                            )}
                            onChange={(value: string) => {
                                setAppLanguage((p) => {
                                    if (!p.includes(value)) {
                                        p.push(value);
                                    }
                                    return [...p];
                                });
                            }}
                            renderValue={(value) =>
                                value.length === 0 ? (
                                    <Text color={COLORS.green}>All</Text>
                                ) : (
                                    <Text color={COLORS.darkGray}>Select</Text>
                                )
                            }
                        />
                        <FlexGrid gap="0.5em" width="100%" wrap="wrap">
                            {appLanguage.map((language) => (
                                <Tag
                                    key={language}
                                    removeBtn
                                    onRemove={() => {
                                        setAppLanguage((p) =>
                                            p.filter((el) => el !== language)
                                        );
                                    }}
                                    title={language}
                                />
                            ))}
                        </FlexGrid>
                    </FlexGrid>
                    <FlexGrid flex="1" direction="column" gap="1em">
                        <Text font="OS" fontSize="2.25rem">
                            RESULTS ({results.length})
                        </Text>
                        <FlexGrid width="100%" direction="column" gap="1em">
                            {results.map((result) => (
                                <BorderBottom
                                    justifyContent="space-between"
                                    width="100%"
                                    alignItems="center"
                                >
                                    <FlexGrid flex="1" gap="1em">
                                        <ImageContainer
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                itemNavigate(result);
                                            }}
                                        >
                                            {result.type ===
                                            'In-app notification' ? (
                                                <FlexGrid
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    bg={COLORS.lightGray}
                                                    width="100%"
                                                    height="100%"
                                                >
                                                    <InAppNotification2Icon
                                                        color={COLORS.white}
                                                    />
                                                </FlexGrid>
                                            ) : result.type ===
                                              'Push notification' ? (
                                                <FlexGrid
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    bg={COLORS.lightGray}
                                                    width="100%"
                                                    height="100%"
                                                >
                                                    <BellIcon
                                                        color={COLORS.white}
                                                    />
                                                </FlexGrid>
                                            ) : (
                                                <img
                                                    src={
                                                        result.image ||
                                                        PLACEHOLDER_IMAGE
                                                    }
                                                    alt="thumbnail"
                                                />
                                            )}

                                            {result.type === 'Videos' && (
                                                <Length>{result.length}</Length>
                                            )}
                                        </ImageContainer>
                                        <FlexGrid
                                            direction="column"
                                            width="100%"
                                            gap="0.5em"
                                        >
                                            <Text
                                                font="AR Bold"
                                                color={COLORS.green}
                                                link
                                                onClick={() => {
                                                    itemNavigate(result);
                                                }}
                                            >
                                                {result.title}
                                            </Text>
                                            <Text
                                                fontSize="0.875rem"
                                                color={COLORS.darkGray}
                                            >
                                                {result.type}
                                            </Text>
                                            <Text
                                                fontSize="0.875rem"
                                                color={COLORS.darkGray}
                                            >
                                                {result.type ===
                                                    'In-app notification' ||
                                                result.type ===
                                                    'Push notification' ? (
                                                    <Text
                                                        fontSize="0.875rem"
                                                        span
                                                    >
                                                        {result.targetUser}
                                                    </Text>
                                                ) : (
                                                    <Text
                                                        fontSize="0.875rem"
                                                        span
                                                    >
                                                        Views {result.views}
                                                    </Text>
                                                )}

                                                <Text
                                                    span
                                                    color={COLORS.lightGray}
                                                >
                                                    {'  '}•{'  '}
                                                </Text>
                                                {result.date}
                                            </Text>
                                        </FlexGrid>
                                    </FlexGrid>
                                    <IconButton
                                        negative
                                        icon={<MoreIcon height="30" />}
                                    />
                                </BorderBottom>
                            ))}
                        </FlexGrid>
                    </FlexGrid>
                </PageContent>
            </FlexGrid>
        </PageWrapper>
    );
};
