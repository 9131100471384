import { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { COLORS, COMMON } from '../styles/variables';
import { FlexGrid } from './FlexGrid';
import { ListItem } from './ListItem';

import {
    DashboardIcon,
    CommunicationIcon,
    ApplicationManagementIcon,
    ShemeOffersIcon,
    SiteManagementIcon,
    ReportsIcon,
    BannersIcon,
    PlaySquareIcon,
    VideoPlaylist2Icon,
    Article2Icon,
    ArticleFolderIcon,
    InAppNotification2Icon,
    PushNotification2Icon,
    QuizIcon,
    OilSelectorIcon,
    LearningIcon,
} from '../assets/icons';

import { Text } from './Text';
import { HorizontalLine } from './HorizontalLine';
import { getCountry } from '../utils';
import AccountMultiple from '../assets/icons/account-multiple';

interface MenuProps {
    open?: boolean;
}

const NavigationContainer = styled(FlexGrid)<MenuProps>`
    position: fixed;
    top: 75px;
    width: ${({ open }) => (open ? '100vw' : 'auto')};
    height: calc(100vh - 75px);
    z-index: 2;
`;

const BackDrop = styled(FlexGrid)<MenuProps>`
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex: 1;
    height: 100%;
    background-color: ${({ open }) =>
        open ? COLORS.black : COLORS.transparent};
    opacity: 50%;
    transition: all 200ms ease-in;
    transition-delay: 10ms;
`;

const Menu = styled(FlexGrid)<MenuProps>`
    width: ${({ open }) => (open ? '20em' : '3.75em')};
    background-color: ${COLORS.white};
    height: 100%;
    transition: width 200ms ease-in;
    box-shadow: ${COMMON.verticalShadow};
    padding-top: 1em;
`;

const MenuIcon = styled(FlexGrid)`
    max-width: 3.75em;
    padding: 1em;
    justify-content: center;
    width: 100%;
`;

const MenuList = styled(FlexGrid)`
    width: 100%;
    flex-direction: column;
    overflow-x: hidden;
    flex: 1;
`;

interface MenuItemProps {
    title: string;
    icon: any;
    href?: string;
    hasSubItems?: boolean;
    subItems?: MenuItemProps[];
    active?: boolean;
}

export const Navigation = () => {
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const isInDashboard = location.pathname.includes('/dashboard');
    const isInCommunication = location.pathname.includes('/communication');
    const isInAppManagement = location.pathname.includes(
        '/application-management'
    );
    const isInOilSelectorManagement = location.pathname.includes(
        '/oil-selector-management'
    );
    const isInSchemeAndOffers = location.pathname.includes('/scheme-offers');

    let menuItems: MenuItemProps[] = [
        {
            title: 'Dashboard',
            icon: (
                <DashboardIcon
                    color={isInDashboard ? COLORS.darkRed : COLORS.green}
                />
            ),
            href: '/dashboard',
            active: isInDashboard,
        },
        {
            title: 'Communication',
            icon: (
                <CommunicationIcon
                    color={isInCommunication ? COLORS.darkRed : COLORS.green}
                />
            ),
            hasSubItems: true,
            active: isInCommunication,
            subItems: [
                {
                    title: 'Banner',
                    icon: <BannersIcon color={COLORS.green} />,
                    href: '/communication/banners',
                },
                {
                    title: 'Video',
                    icon: <PlaySquareIcon color={COLORS.green} />,
                    href: '/communication/videos',
                },
                {
                    title: 'Video Playlist',
                    icon: (
                        <VideoPlaylist2Icon
                            height="18px"
                            width="18px"
                            color={COLORS.green}
                        />
                    ),
                    href: '/communication/video-playlist',
                },
                {
                    title: 'Article',
                    icon: <Article2Icon color={COLORS.green} />,
                    href: '/communication/articles',
                },
                {
                    title: 'Article Folder',
                    icon: <ArticleFolderIcon color={COLORS.green} />,
                    href: '/communication/article-folder',
                },
                {
                    title: 'Quiz',
                    icon: <QuizIcon color={COLORS.green} />,
                    href: '/communication/quizzes',
                },
                {
                    title: 'In-App Notification',
                    icon: <InAppNotification2Icon color={COLORS.green} />,
                    href: '/communication/in-app-notifications',
                },
                {
                    title: 'Push Notification',
                    icon: <PushNotification2Icon color={COLORS.green} />,
                    href: '/communication/push-notifications',
                },
            ],
        },
        {
            title: 'Oil selector Management',
            active: isInOilSelectorManagement,
            icon: (
                <OilSelectorIcon
                    color={
                        isInOilSelectorManagement
                            ? COLORS.darkRed
                            : COLORS.green
                    }
                />
            ),
            href: '/oil-selector-management',
        },
        // {
        //     title: 'Application Management',
        //     active: isInAppManagement,
        //     icon: (
        //         <ApplicationManagementIcon
        //             color={isInAppManagement ? COLORS.darkRed : COLORS.green}
        //         />
        //     ),
        //     href: '/application-management',
        // },

        // {
        //     title: 'Site Management',
        //     icon: <SiteManagementIcon color={COLORS.green} />,
        //     hasSubItems: true,
        //     subItems: [],
        // },
        // {
        //     title: 'Reports',
        //     icon: <ReportsIcon color={COLORS.green} />,
        //     hasSubItems: true,
        //     subItems: [],
        // },
    ];

    const currentCountry = getCountry();

    if (currentCountry === 'Thailand') {
        menuItems = [
            ...menuItems,
            {
                title: 'Scheme and Offers',
                icon: <ShemeOffersIcon color={COLORS.green} />,
                active: isInSchemeAndOffers,
                hasSubItems: true,
                subItems: [
                    {
                        title: 'Brand & SKU List',
                        icon: <BannersIcon color={COLORS.green} />,
                        href: '/scheme-offers/brand-sku-lists',
                    },
                    {
                        title: 'Scanning Contest',
                        icon: <PlaySquareIcon color={COLORS.green} />,
                        href: '/scheme-offers/scanning-contests',
                    },
                    {
                        title: 'Registration Contest',
                        icon: <AccountMultiple color={COLORS.green} />,
                        href: '/scheme-offers/registration-contests',
                    },
                ],
            },
        ];
    }
    if (currentCountry !== 'Thailand') {
        menuItems = [
            ...menuItems,
            {
                title: 'Scheme and Offers',
                icon: <ShemeOffersIcon color={COLORS.green} />,
                active: isInSchemeAndOffers,
                hasSubItems: true,
                subItems: [
                    {
                        title: 'Registration Contest',
                        icon: <AccountMultiple color={COLORS.green} />,
                        href: '/scheme-offers/registration-contests',
                    },
                ],
            },
        ];
    }

    menuItems = [
        ...menuItems,
        {
            title: 'Learning Card',
            icon: <LearningIcon color={COLORS.green} />,
            active: isInSchemeAndOffers,
            hasSubItems: true,
            subItems: [
                {
                    title: 'Module',
                    icon: <BannersIcon color={COLORS.green} />,
                    href: '/learning/learning-card/themes',
                },
                {
                    title: 'Section',
                    icon: <ArticleFolderIcon color={COLORS.green} />,
                    href: '/learning/learning-card/sections',
                },
                {
                    title: 'Card',
                    icon: <Article2Icon color={COLORS.green} />,
                    href: '/learning/learning-card/cards',
                },
            ],
        },
    ];

    return (
        <NavigationContainer open={open}>
            <Menu
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                open={open}
                direction="column"
            >
                <MenuList>
                    {menuItems.map((menu, key) =>
                        open ? (
                            <ListItem
                                key={key}
                                title={
                                    <Text
                                        className="inner-title-text"
                                        font="OS"
                                        uppercase
                                    >
                                        {menu.title}
                                    </Text>
                                }
                                color={
                                    menu.active ? COLORS.darkRed : COLORS.black
                                }
                                icon={menu.icon}
                                isAccordion={menu.hasSubItems}
                                href={menu.href}
                                uppercase
                                accordionContent={
                                    menu.subItems && (
                                        <>
                                            {menu.subItems.map(
                                                (subItem, key2) => (
                                                    <ListItem
                                                        key={key2}
                                                        uppercase
                                                        color={COLORS.black}
                                                        title={
                                                            <Text
                                                                uppercase
                                                                font="OS"
                                                            >
                                                                {subItem.title}
                                                            </Text>
                                                        }
                                                        icon={subItem.icon}
                                                        href={subItem.href}
                                                        isAccordion={subItem.hasSubItems}
                                                        p="0 0 0 1.5em"
                                                    />
                                                )
                                            )}
                                        </>
                                    )
                                }
                            />
                        ) : (
                            <MenuIcon key={key}>{menu.icon}</MenuIcon>
                        )
                    )}
                </MenuList>
                {open && (
                    <FlexGrid width="100%" direction="column">
                        <HorizontalLine
                            height="1px"
                            backgroundColor={COLORS.lightGray}
                        />
                        <FlexGrid
                            justifyContent="center"
                            width="100%"
                            p="1em 0.5em 1.5em 0.5em"
                            gap="2em"
                        >
                            <Text fontSize="12px">Copyright Castrol 2021</Text>
                            <Text fontSize="12px" underline link>
                                Terms & Conditions
                            </Text>
                        </FlexGrid>
                    </FlexGrid>
                )}
            </Menu>
            <BackDrop open={open} />
        </NavigationContainer>
    );
};
