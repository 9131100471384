import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FlexGrid } from './FlexGrid';
import { CloseIcon, SearchIcon } from '../assets/icons';
import { COLORS, COMMON } from '../styles/variables';
import useClickOutside from '../utils/useClickOutside';
import { Text } from './Text';
import { IconButton } from './IconButton';
import { ContentfulApiService } from '../services/ContentfulApi.service';
import {
    addLocalArrayItem,
    getLocalArray,
    getUrlKeepQuery,
    removeLocalArrayItemByIndex,
    toggleLocalArrayItem,
} from '../utils';
import { STORAGE_RECENT_SEARCHED } from '../common/constants';

interface SearchProps {
    placeholder?: string;
}

const Container = styled(FlexGrid)`
    position: relative;
    border: 1px solid ${COLORS.lightGray};
    background-color: ${COLORS.white};
    border-radius: 50px;
    padding: 0.3em 0.5em;
    gap: 0.5em;
    align-items: center;
    width: 400px;
    height: 2em;

    &:focus-within {
        border: 1px solid ${COLORS.darkGray};
    }
`;

const InputField = styled.input`
    border: none;
    flex: 1;

    &:focus {
        outline: none;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${COLORS.gray};
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${COLORS.gray};
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${COLORS.gray};
    }
`;

interface OptionProps {
    open: boolean;
}

const RecentContainer = styled(FlexGrid)<OptionProps>`
    position: absolute;
    border-radius: 5px;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    top: 0;
    left: 0%;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding-top: 2em;
    z-index: -1;
    width: 100%;
    border: 1px solid ${COLORS.lightGray};
    box-shadow: ${COMMON.shadow};
    background-color: ${COLORS.white};
    max-height: 400px;
    flex-direction: column;
`;

const RecentContent = styled(FlexGrid)`
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    flex-direction: column;
`;

const Option = styled(FlexGrid)`
    width: 100%;
    padding: 0.5em 1em;
    border-bottom: 1px solid ${COLORS.veryLightGray};
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: ${COLORS.green};
        color: ${COLORS.white};
    }
`;

const RecentLabel = styled(FlexGrid)`
    width: 100%;
    padding: 0.5em 1em;
    border-bottom: 1px solid ${COLORS.veryLightGray};
`;

export const SearchBar: React.FC<SearchProps> = ({ placeholder }) => {
    const navigate = useNavigate();
    const searchSplited = window.location.pathname.split('/search/');
    const searchValue = searchSplited.length > 1 ? searchSplited.pop() : '';
    const [inputValue, setInputValue] = useState(decodeURI(searchValue) || '');
    const [open, setOpen] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [update, setUpdate] = useState(false);

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>;
    const close = useCallback(() => setOpen(false), []);
    useClickOutside(ref, close);

    const recentSearches = getLocalArray(STORAGE_RECENT_SEARCHED);

    return (
        <Container ref={ref}>
            <SearchIcon height="20" />
            <InputField
                placeholder={placeholder}
                onClick={() => setOpen(true)}
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e.target.value);
                }}
                onKeyDown={(event) => {
                    if (event.code === 'Enter') {
                        if (inputValue.trim() !== '') {
                            addLocalArrayItem(
                                STORAGE_RECENT_SEARCHED,
                                inputValue.trim(),
                                true,
                                50,
                                true
                            );
                            navigate(getUrlKeepQuery(`/search/${inputValue}`));
                        }
                    }
                }}
            />
            {inputValue && (
                <IconButton
                    width="30px"
                    onClick={() => setInputValue('')}
                    negative
                    icon={
                        <CloseIcon
                            color={COLORS.darkGray}
                            width="12"
                            height="12"
                        />
                    }
                />
            )}
            <RecentContainer open={open}>
                <RecentContent>
                    <RecentLabel>
                        <Text font="AR Bold" fontSize="0.875rem">
                            Recent searches
                        </Text>
                    </RecentLabel>
                    {recentSearches.map((recentSearch, key) => (
                        <Option
                            key={key}
                            onClick={() =>
                                navigate(
                                    getUrlKeepQuery(`/search/${recentSearch}`)
                                )
                            }
                        >
                            <Text>{recentSearch}</Text>
                            <IconButton
                                negative
                                icon={
                                    <FlexGrid
                                        onClick={() => {
                                            removeLocalArrayItemByIndex(
                                                STORAGE_RECENT_SEARCHED,
                                                key
                                            );
                                            setUpdate(!update);
                                        }}
                                    >
                                        <CloseIcon
                                            color={COLORS.white}
                                            width="12"
                                            height="12"
                                        />
                                    </FlexGrid>
                                }
                            />
                        </Option>
                    ))}
                </RecentContent>
            </RecentContainer>
        </Container>
    );
};
