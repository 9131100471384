import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgMore2: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 30}
        height={height || 30}
        viewBox="0 0 30 30"
    >
        <circle
            className="icon-fill"
            data-name="Ellipse 125"
            cx={13}
            cy={13}
            r={13}
            transform="translate(2 2)"
            fill={color || '#e3e3e3'}
        />
        <path data-name="Rectangle 432" fill="none" d="M0 0h30v30H0z" />
        <circle
            data-name="Ellipse 126"
            cx={2}
            cy={2}
            r={2}
            transform="translate(13 13)"
            fill="#636363"
        />
        <circle
            data-name="Ellipse 127"
            cx={2}
            cy={2}
            r={2}
            transform="translate(19 13)"
            fill="#636363"
        />
        <circle
            data-name="Ellipse 128"
            cx={2}
            cy={2}
            r={2}
            transform="translate(7 13)"
            fill="#636363"
        />
    </svg>
);

export default SvgMore2;
