import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import ReactQuill from 'react-quill';
import { FlexGrid } from './FlexGrid';
import { InputContainer } from './InputContainer';

interface TextEditorProps {
    label?: string;
    placeholder?: string;
    value?: string;
    // eslint-disable-next-line no-unused-vars
    onChange?: (val: string) => void;
}

const QuillToolbar = () => (
    <div id="toolbar" style={{ width: '100%' }}>
        <span className="ql-formats">
            <button type="button" aria-label="bold" className="ql-bold" />
            <button type="button" aria-label="italic" className="ql-italic" />
            <button
                type="button"
                aria-label="underline"
                className="ql-underline"
            />
            <button
                type="button"
                aria-label="ordered"
                className="ql-list"
                value="ordered"
            />
            <button
                type="button"
                aria-label="bullet"
                className="ql-list"
                value="bullet"
            />
        </span>
    </div>
);

const modules = {
    toolbar: {
        container: '#toolbar',
    },
};

const formats = ['bold', 'italic', 'underline', 'list', 'bullet'];

export const TextEditor: React.FC<TextEditorProps> = ({
    label,
    placeholder,
    value,
    onChange,
}) => {
    // note: tested react quill will trigger(remember ?) the old onChange function if input value is not consistent with the changed value,
    // hence use onMouseLeave and onMouseEnter  event to hack this issue.
    const ref1 = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState('100%');
    const [blur, setBlur] = useState(true);

    const changeFunc = blur ? null : onChange;

    useLayoutEffect(() => {
        if (ref1.current) {
            const width = ref1.current.getBoundingClientRect().width;
            setWidth(width + 'px');
        }
    }, [ref1.current]);

    return (
        <InputContainer type="textEditor" label={label} border>
            <FlexGrid
                width={width}
                height="100%"
                direction="column"
                ref={ref1}
                onMouseLeave={() => setBlur(true)}
                onMouseEnter={() => setBlur(false)}
            >
                <QuillToolbar />
                <ReactQuill
                    style={{ width: '100%', flex: 1, overflow: 'auto' }}
                    modules={modules}
                    formats={formats}
                    value={value}
                    onChange={changeFunc}
                    theme="snow"
                    placeholder={placeholder}
                />
            </FlexGrid>
        </InputContainer>
    );
};
