import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgSearch: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 20}
        height={height || 20}
        viewBox="0 0 20 20"
    >
        <path
            className="icon-fill"
            d="M10.006 20a9.578 9.578 0 0 0 3.86-.787 10.252 10.252 0 0 0 5.353-5.353 9.9 9.9 0 0 0-.005-7.725 10.309 10.309 0 0 0-2.167-3.2A10.059 10.059 0 0 0 13.856.773a9.687 9.687 0 0 0-3.86-.777 9.65 9.65 0 0 0-3.855.777A10.223 10.223 0 0 0 .783 6.134a9.9 9.9 0 0 0 .005 7.725 10.3 10.3 0 0 0 5.352 5.353 9.567 9.567 0 0 0 3.866.788Zm0-1.832a8.012 8.012 0 0 1-3.181-.633 8.18 8.18 0 0 1-4.354-4.359A8.176 8.176 0 0 1 6.82 2.46a8.032 8.032 0 0 1 3.176-.628A8.093 8.093 0 0 1 15.78 4.21a8.2 8.2 0 0 1 1.755 2.6 8.012 8.012 0 0 1 .633 3.181 7.888 7.888 0 0 1-.623 3.181 8.245 8.245 0 0 1-1.75 2.6 8.1 8.1 0 0 1-5.79 2.388Zm-1.04-5.216a3.916 3.916 0 0 0 2.033-.546 4.129 4.129 0 0 0 1.467-1.462 4.07 4.07 0 0 0 0-4.071 4.157 4.157 0 0 0-1.467-1.472 4.028 4.028 0 0 0-4.066 0A4.2 4.2 0 0 0 5.461 6.87a4.028 4.028 0 0 0 0 4.066 4.157 4.157 0 0 0 1.472 1.467 3.916 3.916 0 0 0 2.033.549Zm0-1.379a2.547 2.547 0 0 1-1.338-.365 2.766 2.766 0 0 1-.968-.973 2.637 2.637 0 0 1 0-2.661 2.779 2.779 0 0 1 .968-.968 2.639 2.639 0 0 1 2.671-.005 2.75 2.75 0 0 1 .962.962 2.656 2.656 0 0 1 0 2.671 2.78 2.78 0 0 1-.962.973 2.52 2.52 0 0 1-1.333.362Zm5.239 3.5a.8.8 0 0 0 .607-.247.884.884 0 0 0 .237-.638.839.839 0 0 0-.072-.34.855.855 0 0 0-.206-.288l-3.006-3.026-1.307 1.215 3.026 3.016a.966.966 0 0 0 .721.304Z"
            fill={color || '#969696'}
        />
    </svg>
);

export default SvgSearch;
