import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { FlexGrid } from '../../../components/FlexGrid';
import { SectionHeader } from '../../../components/SectionHeader';
import { Button } from '../../../components/Button';

import {
    ArticlesIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '../../../assets/icons';
import { COLORS } from '../../../styles/variables';
import { Tag } from '../../../components/Tag';
import { Text } from '../../../components/Text';
import { useDashboardArticleHook } from '../hooks/use-article-hook';
import { useDashboardAssetsHook } from '../hooks/use-assets-hook';
import { ContentfulTransformService } from '../../../services/ContentfulTransform.service';
import { getDefaultTimezone, getUrlKeepQuery } from '../../../utils';
import { PLACEHOLDER_IMAGE } from '../../../common/constants';

const SeeAllBtn = styled(Button)`
    font-family: 'OS';
    font-size: 1rem;
`;

const Container = styled(FlexGrid)`
    flex-direction: column;
    padding: 2em 0em;
    border-bottom: 1px solid ${COLORS.lightGray};
    width: 100%;
    gap: 2em;
    min-width: 0;
    min-height: 0;
    overflow: hidden;
`;

const Carousel = styled(FlexGrid)`
    gap: 1em;
    min-width: 0;
    min-height: 0;
`;

const Card = styled(FlexGrid)`
    width: 265px;
    height: 320px;
    flex-direction: column;
    gap: 0.5em;
`;

const CardImg = styled(FlexGrid)`
    height: 50%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 5px;
    flex-shrink: 0;

    .thumbnail {
        width: 100%;
        object-fit: cover;
    }
`;

const Tags = styled(FlexGrid)`
    display: flex;
    flex-wrap: wrap;
`;

const CardDetails = styled(FlexGrid)`
    flex-direction: column;
    gap: 0.5em;
    width: 90%;
    flex: 1;
`;

const CardContent = styled(Text)`
    flex: 1;
    line-height: 1.2em;
`;

const AvoidLongTextInline = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
`;

export const Articles: React.FC = () => {
    const navigate = useNavigate();
    const [{ isLoading, hookData }] = useDashboardArticleHook({
        page: 1,
        perPage: 10,
    });

    // const [{ hookData: assetsHookData }] = useDashboardAssetsHook();

    const [pos, setPos] = useState(0);

    let items = hookData?.items || [];
    items = items.slice(pos, pos + 5);

    const articles = ContentfulTransformService.getArticles(
        hookData?.assets,
        items,
        hookData?.articleFolder?.items || []
    );

    console.log('articles', articles);
    // const assets = hookData?.includes?.Asset;

    const indecrement = (increment: boolean) => {
        if (increment) {
            if (pos < hookData.items.length - 5) {
                setPos(pos + 1);
            }
        } else {
            if (pos !== 0) {
                setPos(pos - 1);
            }
        }
    };

    return (
        <Container>
            {!isLoading && !!hookData?.items?.length && (
                <>
                    <SectionHeader
                        title="ARTICLES"
                        icon={ArticlesIcon}
                        right={
                            <FlexGrid alignItems="center">
                                <SeeAllBtn
                                    onClick={() =>
                                        navigate(
                                            getUrlKeepQuery(
                                                '/communication/articles'
                                            )
                                        )
                                    }
                                    p="0"
                                    m="0em 0.5em"
                                    negative
                                >
                                    SEE ALL
                                </SeeAllBtn>
                                <FlexGrid alignItems="center">
                                    <Button
                                        p="0"
                                        m="0em 1em"
                                        negative
                                        onClick={() => {
                                            indecrement(false);
                                        }}
                                    >
                                        <ChevronRightIcon />
                                    </Button>
                                    <Button
                                        p="0"
                                        m="0em 1em"
                                        negative
                                        onClick={() => {
                                            indecrement(true);
                                        }}
                                    >
                                        <ChevronLeftIcon />
                                    </Button>
                                </FlexGrid>
                            </FlexGrid>
                        }
                    />
                    <Carousel>
                        {articles?.map((article) => {
                            const url = article.image;

                            return (
                                <Card
                                    onClick={() => {
                                        // navigate('/article', {
                                        //     state: {
                                        //         articleId: article.id,
                                        //     },
                                        // });
                                    }}
                                    key={uuidv4()}
                                >
                                    <CardImg>
                                        <img
                                            style={{
                                                cursor: 'pointer',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            className="cardimg"
                                            src={
                                                url
                                                    ? `https:${url}`
                                                    : PLACEHOLDER_IMAGE
                                            }
                                            alt="article"
                                            onClick={() => {
                                                navigate(
                                                    getUrlKeepQuery(
                                                        `/article/${article.id}`
                                                    ),
                                                    {
                                                        state: {
                                                            articleId:
                                                                article.id,
                                                        },
                                                    }
                                                );
                                            }}
                                        />
                                    </CardImg>
                                    <CardDetails>
                                        <Tags>
                                            {(article.tags || []).map((tag) => (
                                                <Tag
                                                    key={`tag-${uuidv4()}`}
                                                    title={tag}
                                                />
                                            ))}
                                        </Tags>
                                        <AvoidLongTextInline>
                                            <Text
                                                link
                                                color={COLORS.green}
                                                font="AR Bold"
                                                onClick={() => {
                                                    navigate(
                                                        getUrlKeepQuery(
                                                            `/article/${article.id}`
                                                        ),
                                                        {
                                                            state: {
                                                                articleId:
                                                                    article.id,
                                                            },
                                                        }
                                                    );
                                                }}
                                            >
                                                {article.headline}
                                            </Text>
                                        </AvoidLongTextInline>
                                        <AvoidLongTextInline>
                                            <CardContent
                                                color={COLORS.darkGray}
                                                fontSize="0.875rem"
                                            >
                                                <AvoidLongTextInline>
                                                    {article.description}
                                                </AvoidLongTextInline>
                                                <Text fontSize="0.875rem" link>
                                                    Read more.
                                                </Text>
                                            </CardContent>
                                        </AvoidLongTextInline>
                                        <FlexGrid
                                            m="auto 0em 1em 0em"
                                            gap="0.5em"
                                        >
                                            <Text
                                                color={COLORS.darkGray}
                                                fontSize="0.75rem"
                                            >
                                                Likes {article.likes || '0'}
                                            </Text>
                                            <Text
                                                color={COLORS.gray}
                                                fontSize="0.75rem"
                                            >
                                                •
                                            </Text>
                                            {article.endDate && <Text
                                                color={COLORS.darkGray}
                                                fontSize="0.75rem"
                                            >
                                                {`Expires ${moment.tz(
                                                    article.endDate, getDefaultTimezone()
                                                ).fromNow()}`}
                                            </Text>}
                                        </FlexGrid>
                                    </CardDetails>
                                </Card>
                            );
                        })}
                    </Carousel>
                </>
            )}
        </Container>
    );
};
