import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexGrid } from '../../components/FlexGrid';
import { PageHeader } from '../../components/PageHeader';
import { SearchBar } from '../../components/SearchBar';
import { PageWrapper } from '../../components/PageWrapper';
import { Articles } from './components/Articles';
import { Banners } from './components/Banners';
import { SideBar } from './components/SideBar';
import { Videos } from './components/Videos';
import { SelectCountry } from '../../components/SelectCountry';
import { useDashboardHook } from './hooks/use-dashboard-hooks';
import { ContentfulApiService } from '../../services/ContentfulApi.service';
import { isStringNotRequireAndWhiteSpaces } from '../../utils';
import { useMe } from '../Login/hooks/use-me';
import { AppState } from '../../store/store.types';
import { useDispatch, useSelector } from 'react-redux';
import { addUser } from '../../store/store.actions';
import { CreateEditModal } from '../../components/CreateEditModal';
import { Input } from '../../components/Input';
import {
    LIMIT_DESCRIPTION_LENGTH,
    LIMIT_NAME_LENGTH,
} from '../../common/constants';

const SubContent = styled(FlexGrid)`
    position: relative;
    flex: 1;
    width: 100%;
`;

const DashboardContent = styled(FlexGrid)`
    min-height: 0;
    min-width: 0;
`;

export const Dashboard: React.FC = () => {
    const [newPlaylistModal, setNewPlaylistModal] = useState(false);
    const [newFolderModal, setNewFolderModal] = useState(false);
    const [playlistName, setPlaylistName] = useState('');
    const [playlistDescription, setPlaylistDescription] = useState('');
    const [createPlaylistSuccess, setCreatePlaylistSuccess] = useState(null);
    const [createPlaylistDisabled, setCreatePlaylistDisabled] = useState(false);

    const [folderName, setFolderName] = useState('');
    const [folderDescription, setFolderDescription] = useState('');
    const [createFolderSuccess, setCreateFolderSuccess] = useState(null);
    const [createArticleDisabled, setCreateArticleDisabled] = useState(false);
    const [{}] = useDashboardHook();

    // const navigate = useNavigate();

    const contentfulToken = useSelector<AppState, string>(
        (s) => s.contentfulToken
    );

    const [{ hookData: profile }] = useMe(null, [contentfulToken]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (profile) {
            dispatch(addUser(profile.profile));
        }
    }, [profile, contentfulToken]);

    return (
        <>
            <CreateEditModal
                mode="create"
                open={newPlaylistModal}
                onClose={() => {
                    setPlaylistName('');
                    setPlaylistDescription('');
                    setNewPlaylistModal(false);
                }}
                onSubmit={() => {
                    setCreatePlaylistDisabled(true);
                    ContentfulApiService.createContent('videoPlaylist', {
                        name: playlistName,
                        description: playlistDescription,
                    })
                        .then(() => {
                            setCreatePlaylistSuccess(true);
                            setPlaylistName('');
                            setPlaylistDescription('');
                        })
                        .finally(() => {
                            setCreatePlaylistDisabled(false);
                        });
                }}
                setSuccess={setCreatePlaylistSuccess}
                success={createPlaylistSuccess}
                title="Create New Playlist"
                disabled={
                    createPlaylistDisabled ||
                    playlistName.trim() === '' ||
                    isStringNotRequireAndWhiteSpaces(playlistDescription)
                }
                inputComponents={[
                    <Input
                        maxLength={LIMIT_NAME_LENGTH}
                        label="Playlist Name"
                        value={playlistName}
                        onChange={(val) => setPlaylistName(val)}
                    />,
                    <Input
                        maxLength={LIMIT_DESCRIPTION_LENGTH}
                        placeholder="Enter"
                        multiline
                        height="88px"
                        label="Description"
                        value={playlistDescription}
                        onChange={(val) => setPlaylistDescription(val)}
                    />,
                ]}
            />
            <CreateEditModal
                mode="create"
                open={newFolderModal}
                onClose={() => {
                    setFolderName('');
                    setFolderDescription('');
                    setNewFolderModal(false);
                }}
                onSubmit={() => {
                    setCreateArticleDisabled(true);
                    ContentfulApiService.createContent('articleFolder', {
                        name: folderName,
                        description: folderDescription,
                    })
                        .then(() => {
                            setCreateArticleDisabled(false);
                            setCreateFolderSuccess(true);
                            setFolderName('');
                            setFolderDescription('');
                        })
                        .finally(() => {
                            setCreateArticleDisabled(false);
                        });
                }}
                setSuccess={setCreateFolderSuccess}
                success={createFolderSuccess}
                title="Create New Folder"
                disabled={
                    createArticleDisabled ||
                    folderName.trim() === '' ||
                    isStringNotRequireAndWhiteSpaces(folderDescription)
                }
                inputComponents={[
                    <Input
                        maxLength={LIMIT_NAME_LENGTH}
                        label="Folder Name"
                        value={folderName}
                        onChange={(val) => setFolderName(val)}
                    />,
                    <Input
                        maxLength={LIMIT_DESCRIPTION_LENGTH}
                        placeholder="Enter"
                        multiline
                        height="88px"
                        label="Description"
                        value={folderDescription}
                        onChange={(val) => setFolderDescription(val)}
                    />,
                ]}
            />
            <PageWrapper>
                <PageHeader
                    title="Dashboard"
                    actions={
                        <FlexGrid gap="1.5em">
                            <SearchBar placeholder="Search banners, videos, articles here…" />
                            <SelectCountry />
                        </FlexGrid>
                    }
                />
                <SubContent>
                    <DashboardContent direction="column" p="0em 2em" flex="1">
                        <Videos />
                        <Articles />
                        <Banners />
                    </DashboardContent>
                    <SideBar
                        newPlaylistOpen={() => setNewPlaylistModal(true)}
                        newFolderOpen={() => setNewFolderModal(true)}
                    />
                </SubContent>
            </PageWrapper>
        </>
    );
};
