import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { FlexGrid } from '../../components/FlexGrid';
import { PageHeader } from '../../components/PageHeader';
import { PageWrapper } from '../../components/PageWrapper';
import { SearchBar } from '../../components/SearchBar';
import { SelectCountry } from '../../components/SelectCountry';
import { COLORS } from '../../styles/variables';
import { OilSelectors } from './components/OilSelectors';

const PageContent = styled(FlexGrid)`
    max-width: 1020px;
    width: 100%;
    padding: 2em 0em;
    gap: 1em;
    flex-direction: column;
    max-height: 100%;
`;

const TabHead = styled(FlexGrid)`
    width: 100%;
    height: 40px;
`;

interface TabButtonProps {
    active?: boolean;
}

const TabButton = styled(Button)<TabButtonProps>`
    padding: 1em;
    margin: 0;
    font-family: ${({ active }) => (active ? 'AR Bold' : 'AR')};
    border-bottom: ${({ active }) =>
        active ? `1px solid ${COLORS.green}` : `1px solid ${COLORS.lightGray}`};
    color: ${({ active }) => (active ? COLORS.green : COLORS.black)};
    border-radius: 0;
    background-color: ${COLORS.transparent};
    height: 100%;
`;

const TabFiller = styled(FlexGrid)`
    flex: 1;
    padding: 1em;
    height: 100%;
    border-bottom: 1px solid ${COLORS.lightGray};
`;

export const OilSelectorManagement: React.FC = () => {
    const [activeTab, setActiveTab] = useState(0);
    const tabs = [<OilSelectors />];
    return (
        <PageWrapper>
            <PageHeader
                title="Oil Selector Management"
                actions={
                    <FlexGrid gap="1.5em">
                        <SearchBar placeholder="Search banners, videos, articles here…" />
                        <SelectCountry />
                    </FlexGrid>
                }
            />
            <FlexGrid width="100%" justifyContent="center">
                <PageContent>
                    <TabHead>
                        <TabButton
                            active={activeTab === 0}
                            onClick={() => setActiveTab(0)}
                        >
                            Oil Selector Management
                        </TabButton>
                        <TabFiller />
                    </TabHead>
                    {tabs[activeTab]}
                </PageContent>
            </FlexGrid>
        </PageWrapper>
    );
};
