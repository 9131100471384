import React from 'react';
import { Button } from './Button';
import { FlexGrid } from './FlexGrid';

interface IconButtonProps {
    icon: any;
    className?: string;
    bg?: string;
    negative?: boolean;
    width?: string;
    height?: string;
    onClick?: () => void;
    disabled?: boolean;
    transform?: string;
    m?: string;
    p?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
    negative,
    bg,
    icon,
    className,
    width,
    height,
    onClick,
    disabled,
    transform,
    m,
    p
}) => {
    return (
        <Button
            className={className}
            negative={negative}
            bg={bg}
            width={width}
            height={height}
            onClick={disabled ? undefined : onClick}
            m={m || "0"}
            p={p || "0"}
        >
            <FlexGrid
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                transform={transform}
            >
                {icon}
            </FlexGrid>
        </Button>
    );
};