import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgVideoPlaylist: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 23.485}
        height={height || 19.485}
        viewBox="0 0 23.485 19.485"
    >
        <g
            className="icon-stroke"
            fill="none"
            stroke={color || '#009343'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
        >
            <path data-name="Line 109" d="M1 1h13" />
            <path data-name="Line 110" d="M1 7h13" />
            <path data-name="Line 111" d="M1 13h8" />
            <path data-name="Line 112" d="M17.243 9v8.486" />
            <path data-name="Line 113" d="M13 13.243h8.485" />
        </g>
    </svg>
);

export default SvgVideoPlaylist;
