import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FlexGrid } from './FlexGrid';
import { FastscanLogo, BellIcon, DotIcon } from '../assets/icons';
// import HeaderBanner from '../assets/svg/header.svg';
import { Button } from './Button';
import HeaderProfileImg from '../assets/sample-images/header-profile.png';
import { Text } from './Text';
import { COLORS } from '../styles/variables';
import { AppState, User } from '../store/store.types';
import { getRoleId } from '../utils';
import { ROLE_TAG_ID_MAP, TAG_COUNTRY_MAP } from '../common/constants';

const Background = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: top;
    z-index: -1;
`;

const HeaderContainer = styled(FlexGrid)`
    position: sticky;
    top: 0;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    height: 75px;
    z-index: 3;
    overflow: hidden;
`;

const LogoContainer = styled(FlexGrid)`
    border: 1px solid beige;
    height: 100%;
    align-items: center;
    left: 130px;
    right: 0px;
    top: -5%;

    @media (min-width: 1700px) {
        left: 8%;
    }
`;

const Notification = styled(Button)`
    position: relative;
    margin: 0em 1em;
`;

const RedDot = styled(FlexGrid)`
    position: absolute;
    width: 10px;
    top: 1px;
    right: 6px;
`;

const Avatar = styled.img`
    border-radius: 100%;
    width: 2.25em;
    height: 2.25em;
    margin: 0em 1em;
`;

const BorderLeft = styled(FlexGrid)`
    border-left: 1px solid ${COLORS.transparentWhite};
    padding-left: 0.5em;
`;

export const Header: React.FC = () => {
    const user = useSelector<AppState, User>((s) => s.user);

    let userCountry;
    const qParams = new URL(window.location.href).searchParams;
    const qCountry = qParams.get('country');
    const roleTagId = ROLE_TAG_ID_MAP[getRoleId()];
    if (roleTagId) {
        userCountry = qCountry || TAG_COUNTRY_MAP[roleTagId];
    } else {
        userCountry = 'SUPER';
    }

    return (
        <HeaderContainer>
            <Background
                src="https://www.castrol.com/content/dam/castrol/business-sites-new/en/global/corporate/images/cgi-header-image/nav_image_2340x1050_reverted_version.jpg"
                alt="header background"
            />
            <LogoContainer>
                <FastscanLogo
                    height="50"
                    width="318"
                    style={{
                        backgroundColor: 'white',
                        height: 'inherit',
                        padding: '0px 20px',
                    }}
                />
            </LogoContainer>
            <FlexGrid alignItems="center" height="100%" p="1rem">
                <Notification negative>
                    <RedDot>
                        <DotIcon />
                    </RedDot>
                    <BellIcon height="24" />
                </Notification>
                <FlexGrid alignItems="center">
                    <BorderLeft direction="column" alignItems="flex-end">
                        <Text
                            uppercase
                            color={COLORS.white}
                            fontSize="0.875rem"
                        >
                            {user?.firstName} {user?.lastName}
                        </Text>
                        <Text uppercase color={COLORS.white} fontSize="0.75rem">
                            {userCountry} ADMIN
                        </Text>
                    </BorderLeft>
                    <Avatar
                        src={user?.avatarUrl || HeaderProfileImg}
                        alt="user avatar"
                    />
                </FlexGrid>
            </FlexGrid>
        </HeaderContainer>
    );
};
