import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgArrowDown: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 12}
        height={height || 12}
        viewBox="0 0 12 12"
    >
        <defs>
            <clipPath id="arrow-down_svg__a">
                <path
                    className="icon-fill"
                    data-name="Rectangle 2462"
                    transform="translate(515 556)"
                    fill={color || '#636363'}
                    d="M0 0h12v12H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 14347">
            <g
                data-name="ic sort arrow"
                transform="translate(-515 -556)"
                clipPath="url(#arrow-down_svg__a)"
            >
                <path
                    className="icon-fill"
                    data-name="Path 4759"
                    d="m527 562-1.057-1.057-4.193 4.185V556h-1.5v9.128l-4.185-4.193L515 562l6 6Z"
                    fill={color || '#636363'}
                />
            </g>
        </g>
    </svg>
);

export default SvgArrowDown;
