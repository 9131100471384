import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgInAppNotification: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 20}
        height={height || 20}
        viewBox="0 0 20 20"
    >
        <path
            className="icon-fill"
            d="M12 12v3H4V8a4 4 0 0 1 4-4 3.89 3.89 0 0 1 2.28.72l1.43-1.43A5.87 5.87 0 0 0 9.5 2.2v-.7a1.5 1.5 0 0 0-3 0v.7A5.987 5.987 0 0 0 2 8v7H0v2h16v-2h-2v-3Zm-4 8a2.006 2.006 0 0 0 2-2H6a2.006 2.006 0 0 0 2 2ZM20 6h-3V3h-2v3h-3v2h3v3h2V8h3Z"
            fill={color || '#009343'}
        />
    </svg>
);

export default SvgInAppNotification;
