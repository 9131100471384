import * as React from 'react';

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const SvgOilSelector: React.FC<Props> = ({ width, height, color }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width || 20}
        height={height || 22}
        viewBox="0 0 20 22"
    >
        <g id="surface1">
            <path
                fill={color || 'red'}
                d="M 18.453125 19.609375 C 19.460938 13.890625 19.441406 8.109375 18.390625 2.394531 C 18.855469 2.371094 19.230469 1.949219 19.230469 1.433594 C 19.230469 0.902344 18.839844 0.472656 18.355469 0.472656 L 1.304688 0.472656 C 0.820312 0.472656 0.429688 0.902344 0.429688 1.429688 C 0.429688 1.945312 0.800781 2.371094 1.269531 2.390625 C 0.222656 8.105469 0.199219 13.886719 1.207031 19.605469 C 0.769531 19.664062 0.429688 20.070312 0.429688 20.566406 C 0.429688 21.097656 0.820312 21.527344 1.304688 21.527344 L 18.355469 21.527344 C 18.839844 21.527344 19.230469 21.097656 19.230469 20.566406 C 19.230469 20.070312 18.890625 19.664062 18.453125 19.609375 Z M 2.988281 19.601562 C 1.9375 13.886719 1.957031 8.105469 3.050781 2.394531 L 16.605469 2.394531 C 17.699219 8.105469 17.722656 13.886719 16.667969 19.601562 Z M 2.988281 19.601562 "
            />
            <path
                fill={color || 'red'}
                d="M 3.601562 11.082031 C 3.601562 10.015625 3.863281 9.183594 4.382812 8.582031 C 4.902344 7.980469 5.574219 7.679688 6.398438 7.679688 C 6.9375 7.679688 7.421875 7.820312 7.855469 8.105469 C 8.289062 8.386719 8.617188 8.78125 8.84375 9.289062 C 9.070312 9.796875 9.183594 10.371094 9.183594 11.011719 C 9.183594 11.660156 9.0625 12.246094 8.824219 12.761719 C 8.585938 13.277344 8.246094 13.664062 7.808594 13.929688 C 7.371094 14.191406 6.898438 14.324219 6.390625 14.324219 C 5.839844 14.324219 5.351562 14.179688 4.917969 13.886719 C 4.484375 13.59375 4.15625 13.195312 3.933594 12.691406 C 3.714844 12.179688 3.601562 11.644531 3.601562 11.082031 Z M 4.398438 11.09375 C 4.398438 11.867188 4.585938 12.476562 4.964844 12.921875 C 5.34375 13.367188 5.816406 13.585938 6.386719 13.585938 C 6.96875 13.585938 7.445312 13.363281 7.820312 12.914062 C 8.195312 12.460938 8.382812 11.824219 8.382812 11 C 8.382812 10.476562 8.300781 10.019531 8.140625 9.632812 C 7.980469 9.242188 7.746094 8.941406 7.4375 8.726562 C 7.128906 8.511719 6.78125 8.40625 6.398438 8.40625 C 5.851562 8.40625 5.382812 8.609375 4.988281 9.023438 C 4.597656 9.441406 4.398438 10.132812 4.398438 11.09375 Z M 4.398438 11.09375 "
            />
            <path
                fill={color || 'red'}
                d="M 10.308594 14.210938 L 10.308594 7.789062 L 11.082031 7.789062 L 11.082031 14.210938 Z M 10.308594 14.210938 "
            />
            <path
                fill={color || 'red'}
                d="M 12.410156 14.210938 L 12.410156 7.789062 L 13.183594 7.789062 L 13.183594 13.453125 L 16.058594 13.453125 L 16.058594 14.210938 Z M 12.410156 14.210938 "
            />
        </g>
    </svg>
);

export default SvgOilSelector;
